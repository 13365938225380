export const menus = [
  {
    icon: "📋",
    title: "Orders",
    path: "/orders",
    state: 'order',
    isLink: true,
  },
  {
    icon: "🧑",
    title: "Customers",
    path: "/customers",
    state: 'customer',
    isLink: true,
    isAdd: true,
  },
  {
    icon: "🚖",
    title: "Drivers",
    path: "/drivers",
    state: 'driver',
    isLink: true,
    isAdd: true,
  },
  {
    icon: "🏬",
    title: "Stores",
    path: "/stores",
    state: 'store',
    isLink: true,
    isAdd: true,
    children: [
      {
        icon: "📋",
        title: "Reviews",
        path: "/reviews",
        state: 'store',
        isLink: true,
      },
    ],
  },
  {
    icon: "🛍️",
    title: "Products",
    path: "/products",
    state: 'product',
    isLink: true,
    children: [
      {
        icon: "📋",
        title: "Categories",
        path: "/categories",
        state: 'product',
        isLink: true,
      },
      {
        icon: "📋",
        title: "Master Products",
        path: "/master-products",
        state: 'product',
        isLink: true,
      },
    ],
  },
  {
    icon: "🎫",
    title: "Promotions",
    path: "/coupons",
    state: 'coupon',
    isLink: true,
    isAdd: true,
    children: [
      {
        icon: "📋",
        title: "Bulk Notifications",
        path: "/bulk-notifications",
        state: 'coupon',
        isLink: true,
      },
    ],
  },
  {
    icon: "🏧",
    title: "Withdrawals",
    state: 'withdrawal',
    isLink: false,
    path: "",
    children: [
      {
        icon: "📋",
        title: "Stores",
        path: "/withdrawals/store",
        state: 'withdrawal',
        isLink: true,
      },
      {
        icon: "📋",
        title: "Drivers",
        path: "/withdrawals/driver",
        state: 'withdrawal',
        isLink: true,
      },
    ],
  },
  {
    icon: "💳",
    title: "Transactions",
    path: "/transactions",
    state: 'transaction',
    isLink: true,
    children: [
      {
        icon: "📋",
        title: "Failed Payments",
        path: "/failed-payments/pending",
        state: 'transaction',
        isLink: true,
      },
    ],
  },
  {
    icon: "📃",
    title: "Reports",
    path: "/reports",
    state: 'report',
    isLink: true,
  },
];
