import React, { useState, useEffect } from "react";
import GItemTable from "../gComponents/gCartItems";
import { ProductFilter } from "../components/productFilterComponent";
import useCart from "../useCart";
import { Card, CardHead } from "../styledComponents/cardStyles";
import { ApiLoginService, ApiService } from "../services";
import { GTableContent } from "../gComponents/gContent";
import OrderProductVariants from "../components/orderProductVariants";

import { useSnackbar } from "react-simple-snackbar";
import useSettings from "../useSettings";

export default function OrderEdit(props) {
  const [openSnackbar] = useSnackbar();
  const [selectedProduct, setSelectedProduct] = useState({});
  const {
    cart,
    setCart,
    cartStore,
    addProduct,
    removeProduct,
    decreaseQuantity,
  } = useCart();
  const settings = useSettings(true);
  const [newOrderData, setNewOrderData] = useState({
    products: [],
    summary: {
      data: [],
    },
  });
  const [orderVarVisible, setOrderVarVisible] = useState(false);

  useEffect(() => {
    const getCartProducts = () => {
      ApiLoginService("POST", "c/order/cart", {
        store_id: props.cartStore,
        products: props.cart,
      }).then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setNewOrderData(response.data);
          setCart(response.data.products);
        } else {
          setCart(response.data.products);
          openSnackbar(response.message);
        }
      });
    };
    getCartProducts();
  }, []);

  useEffect(() => {
    const getCartProducts = () => {
      ApiLoginService("POST", "c/order/cart", {
        store_id: cartStore,
        products: cart,
      }).then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setNewOrderData(response.data);
        } else {
          setNewOrderData(response.data);
          openSnackbar(response.message);
        }
      });
    };
    if (cartStore) {
      getCartProducts();
    }
  }, [cart]);

  const UpdateItems = () => {
    console.log(cart);
    ApiService("PUT", "order/products/" + props.orderId, {
      products: cart,
    }).then((response) => {
      console.log(JSON.stringify(response));
      if (response.status_code === 1) {
        props.onUpdate();
        openSnackbar(response.message);
      } else {
        openSnackbar(response.message);
      }
    });
  };

  return (
    <div>
      <Card>
        <CardHead>
          Search Items
          <div style={{ float: "right" }}>
            <span
              style={{ cursor: "pointer", fontSize: "15px", color: "#f08c37" }}
              onClick={() => UpdateItems()}
            >
              Update
            </span>{" "}
            &nbsp; &nbsp;
            <span
              style={{ cursor: "pointer", fontSize: "15px", color: "#f08c37" }}
              onClick={() => props.onCancel()}
            >
              Cancel
            </span>
          </div>
        </CardHead>
        <ProductFilter
          value={selectedProduct ? selectedProduct.id : ""}
          style={{
            marginBottom: "10px",
          }}
          storeId={cartStore}
          handleProductChange={(id, value) => {
            setSelectedProduct(value ? value : "");
            if (value) {
              console.log(value);
              if (value.variants_available === false) {
                value.variants = [];
                addProduct(value);
              } else {
                setSelectedProduct(value);
                setOrderVarVisible(true);
              }
            }
          }}
        />

        {cart && newOrderData && newOrderData.products.length > 0 ? (
          <React.Fragment>
            <br />
            <br />
            <CardHead>Items ({newOrderData.products.length})</CardHead>
            <GItemTable
              items={newOrderData.products}
              currencySymbol={settings.currency_symbol}
              editable={true}
              onRemove={(item) => removeProduct(item)}
              onIncQuantity={(item) => addProduct(item, item.variants)}
              onDecQuantity={(item) => decreaseQuantity(item)}
            />
          </React.Fragment>
        ) : (
            ""
          )}

        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <div style={{ width: "250px", display: "inline-block" }}>
            {cart && newOrderData ? (
              <React.Fragment>
                {newOrderData?.summary?.data.map((p) => {
                  return (
                    <GTableContent
                      key={p.title}
                      title={p.title}
                      value={settings.currency_symbol + "" + p.value}
                      align="right"
                      lAlign="right"
                      color="#777"
                    />
                  );
                })}
              </React.Fragment>
            ) : (
                ""
              )}
            <hr />

            <GTableContent
              title="Total"
              value={
                settings.currency_symbol +
                "" +
                newOrderData?.summary?.total_display
              }
              align="right"
              lAlign="right"
              bold="550"
            />
          </div>
        </div>
      </Card>
      {orderVarVisible === true ? (
        <OrderProductVariants
          item={selectedProduct}
          orderVarVisible={orderVarVisible}
          setOrderVarVisible={setOrderVarVisible}
          onSubmitVariants={(itemVariants) => {
            console.log(itemVariants);
            addProduct(selectedProduct, itemVariants);

            // setCart([...cart, item]);
          }}
        />
      ) : (
          ""
        )}
    </div>
  );
}
