import React, { useEffect, useState, useRef } from "react";
import { Filter } from "./selectListFilter";
import { List } from "./selectListView";
import { ApiService } from "../services";
import { useDetectOutsideClick } from "./useDetectOutsideClick";
import { LoadingBar } from "../gComponents/gLoadingBar";

export default function SelectSearch() {
  const [word, setWord] = useState("");
  const [records, setRecords] = useState([]);
  const wrapperRef = useRef(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showList, setShowList] = useDetectOutsideClick(wrapperRef, false);
  const [filterDisplay, setFilterDisplay] = useState([]);

  useEffect(() => {
    const getSearchList = () => {
      ApiService("GET", "search?keyword=" + word)
        .then((response) => {
          console.log(JSON.stringify(response));
          if (response.status_code === 1) {
            setRecords(response.data);
            setFilterDisplay(response.data);
            // setTimeout(function () {
            //   getRecords();
            // }, 500);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getSearchList();
  }, [word]);

  const handleChange = (e) => {
    setWord(e);
  };

  const getRecords = () => {
    let oldList = records.map((data) => {
      return data;
    });

    setFilterDisplay(oldList);
    // if (word !== ""{
    //     let newList
    // })
  };
  // function useOutsideAlerter(ref) {
  //   useEffect(() => {
  //     /**
  //      * Alert if clicked on outside of element
  //      */
  //     function handleClickOutside(event) {
  //       if (ref.current && !ref.current.contains(event.target)) {
  //         setShowList(false);
  //       }
  //     }

  //     // Bind the event listener
  //     document.addEventListener("mousedown", handleClickOutside);
  //     return () => {
  //       // Unbind the event listener on clean up
  //       document.removeEventListener("mousedown", handleClickOutside);
  //     };
  //   }, [ref]);
  // }

  return (
    <div ref={wrapperRef}>
      <Filter
        value={word}
        handleChange={(e) => handleChange(e.target.value)}
        // handleBlur={() => setShowList(true)}
        handleFocus={() => {
          setShowLoader(true);
          setTimeout(function () {
            setShowLoader(false);
            setShowList(true);
          }, 1300);
        }}
        placeholder="Search by User, Store & Order"
      />
      {showLoader === true ? (
        <LoadingBar width={400} percent={0.25} style={{ margin: "8px" }} />
      ) : (
        ""
      )}
      {/* {showList === true ? ( */}
      <List
        data={word.length < 1 ? records : filterDisplay}
        show={showList}
        handleClick={() => setShowList(false)}
      />
      {/* ) : (
        ""
      )} */}
    </div>
  );
}
