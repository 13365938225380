import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import GSwitch from "../gComponents/gSwitch";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useLocation } from "react-router-dom";
import { StoreFilter } from "./storeFilterComponent"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  option: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#888",
    width: "40px",
    height: "40px",
    fontSize: "18px",
    borderRadius: "5px",
    cursor: "pointer",
    //transition: 'background 0.3s',
    transitionProperty: "background-color, color",
    transitionDuration: "300ms, 300ms",
    "&:hover": {
      backgroundColor: "#333",
      color: "#bbb",
      fontWeight: "500",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var buttonStyle = {
  background: "#f08c37",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "100%",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  marginTop: "30px",
  fontWeight: "600",
};

export default function CreateCoupon(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const location = useLocation();
  console.log("props--" + JSON.stringify(props.data));
  const initialState = {
    validFrom: props.data ? (props.data.valid_from ? 1 : 0) : 0,
    validUpto: props.data ? (props.data.expiry ? 1 : 0) : 0,
    totalLimit: props.data ? (props.data.limit_total ? 1 : 0) : 0,
    userLimit: props.data ? (props.data.limit_user ? 1 : 0) : 0,
    maxDiscount: props.data ? (props.data.max_discount ? 1 : 0) : 0,
    minOrder: props.data ? (props.data.minimum_order_amount ? 1 : 0) : 0,
    maxOrder: props.data ? (props.data.maximum_order_amount ? 1 : 0) : 0,
  };

  console.log(initialState);
  const [
    {
      validFrom,
      validUpto,
      totalLimit,
      userLimit,
      maxDiscount,
      minOrder,
      maxOrder,
    },
    setInputList,
  ] = useState(initialState);
  const initialValues = {
    title: props.data ? props.data.title : "",
    code: props.data ? props.data.code : "",
    discount: props.data ? props.data.discount : "",
    description: props.data ? props.data.description : "",
    store_id: props.data ? props.data.store_id : "",
    valid_from: props.data ? new Date(props.data.valid_from) : null,
    expiry: props.data ? new Date(props.data.expiry) : null,
    limit_total: props.data ? props.data.limit_total : "0",
    limit_user: props.data ? props.data.limit_user : "0",
    max_discount: props.data ? props.data.max_discount : "0",
    minimum_order_amount: props.data ? props.data.minimum_order_amount : "0",
    maximum_order_amount: props.data ? props.data.maximum_order_amount : "0",
  };


  const handleChange = (d) => {
    // var date = new Date(d).getDate(); //Current Date
    //var month = new Date(d).getMonth() + 1; //Current Month
    var date = ("0" + new Date(d).getDate()).slice(-2); //Current Date
    var month = ("0" + (new Date(d).getMonth() + 1)).slice(-2); //Current Month
    var year = new Date(d).getFullYear(); //Current Year
    console.log(year + "-" + month + "-" + date);
    return year + "-" + month + "-" + date;
  };

  function toggleValue(newValue, valueId) {
    console.log(newValue + "--" + valueId);
    setInputList((prevState) => ({ ...prevState, [valueId]: newValue }));
  }

  const onSubmit = (values) => {
    console.log(values);
    values.valid_from = values.valid_from
      ? handleChange(values.valid_from)
      : "";
    values.expiry = values.expiry ? handleChange(values.expiry) : "";

    console.log(values);

    // return;
    var hitMethod = props.data ? "PUT" : "POST";
    var hitUrl = props.data ? "coupons/" + props.data.id : "coupons";
    ApiService(hitMethod, hitUrl, values).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        props.setCcpnvisible(false);
        if (props.data) {
          props.onSubmit(response.data);
          openSnackbar(response.message);
        }
        if (!props.data) {
          openSnackbar(response.message);
          if (location.pathname === "/coupons") {
            window.location.reload(false);
          } else {
            window.location = "/coupons";
          }
        }

        // openSnackbar(response.message);
      } else {
        openSnackbar(response.message);
        return;
      }
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    code: Yup.string().required("Required"),
    discount: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    // valid_from: Yup.date().when("validFrom", {
    //   is: "1" || 1,
    //   then: Yup.date().required("Required"),
    // }),
    // max_discount: Yup.string().when("maxDiscount", {
    //   is: "1" || 1,
    //   then: Yup.string().required("Required"),
    // }),
  });

  //console.log(handleChange);
  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setCcpnvisible(false);
  };


  if (props.ccpnvisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.ccpnvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.ccpnvisible === true ? "fadeM" : ""}`}
          style={{ padding: "20px", maxWidth: "500px" }}
        >
          <h3>{props.data ? "Edit" : "Add"} Coupon</h3>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <FormikControl
                  control="input"
                  type="text"
                  label="Title"
                  name="title"
                  placeholder="Get X% discount up to Rs.125"
                />
                <div style={{ display: "inline-flex" }}>
                  <FormikControl
                    control="input"
                    type="text"
                    label="Coupon Code"
                    name="code"
                    placeholder="FLAT50"
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    label="Discount (in $)"
                    name="discount"
                    maxLength={3}
                    placeholder="15"
                    onChange={(event) => {
                      const re = /^[0-9\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("discount", event.target.value);
                      }
                    }}
                  />
                </div>
                <FormikControl
                  control="input"
                  as="textarea"
                  label="Description"
                  name="description"
                  rows="4"
                  placeholder="Use code FLAT & get 15$ discount up to Rs.125 on your order above Rs.500"
                />
                <div style={{ padding: '4px 15px' }}>
                  <label>Store</label>
                  <StoreFilter
                    value={values.store_id}
                    style={{
                      marginBottom: "10px", display: 'block', width: '100%'
                    }}
                    handleStoreChange={(value) => {
                      console.log(value)
                      setFieldValue('store_id', value ? value : '');
                    }}
                  /></div>

                <h4>Apply Limits on Coupon (optional)</h4>
                <div style={{ padding: "5px 15px" }}>
                  <div style={{ display: "flex", minHeight: "55px" }}>
                    <label style={{ width: "140px" }}>Valid from</label>
                    <GSwitch
                      id="apply_valid_from"
                      uId="validFrom"
                      currentValue={validFrom}
                      style={{ width: "90px" }}
                      onSwitchChange={toggleValue}
                    />
                    {validFrom === "1" || validFrom === 1 ? (
                      <FormikControl
                        control="date"
                        name="valid_from"
                        format="yyyy-MM-dd"
                        placeholderText="Choose"
                        className="noBorder noMargin"
                        divstyle={{ width: '170px' }}
                      />
                    ) : (
                        (values.valid_from =
                          validFrom === "1" ? values.valid_from : null)
                      )}
                  </div>
                  <div style={{ display: "flex", minHeight: "55px" }}>
                    <label style={{ width: "140px" }}>Valid Upto</label>
                    <GSwitch
                      id="apply_valid_upto"
                      uId="validUpto"
                      currentValue={validUpto}
                      style={{ width: "90px" }}
                      onSwitchChange={toggleValue}
                    />
                    {validUpto === "1" || validUpto === 1 ? (
                      <FormikControl
                        control="date"
                        name="expiry"
                        format="yyyy-MM-dd"
                        placeholderText="Choose"
                        className="noBorder noMargin"
                        divstyle={{ width: '170px' }}
                      />
                    ) : (
                        (values.expiry = validUpto === "1" ? values.expiry : null)
                      )}
                  </div>
                  <div style={{ display: "flex", minHeight: "55px" }}>
                    <label style={{ width: "140px" }}>Total Limit</label>
                    <GSwitch
                      id="apply_total_limit"
                      uId="totalLimit"
                      currentValue={totalLimit}
                      style={{ width: "90px" }}
                      onSwitchChange={(val, id) => {
                        toggleValue(val, id);
                        if (val === "0" || val === 0) {
                          setFieldValue("limit_total", "0");
                        }
                      }}
                    />
                    {totalLimit === "1" || totalLimit === 1 ? (
                      <FormikControl
                        control="input"
                        type="text"
                        name="limit_total"
                        placeholder="500"
                        className="noBorder noMargin"
                        style={{ width: '170px' }}
                      />
                    ) : (
                        ""
                        // (values.limit_total =
                        //   totalLimit === "1" ? values.limit_total : "0")
                      )}
                  </div>
                  <div style={{ display: "flex", minHeight: "55px" }}>
                    <label style={{ width: "140px" }}>User Limit</label>
                    <GSwitch
                      id="apply_user_limit"
                      uId="userLimit"
                      currentValue={userLimit}
                      style={{ width: "90px" }}
                      onSwitchChange={(val, id) => {
                        toggleValue(val, id);
                        if (val === "0" || val === 0) {
                          setFieldValue("limit_user", "0");
                        }
                      }}
                    />
                    {userLimit === "1" || userLimit === 1 ? (
                      <FormikControl
                        control="input"
                        type="text"
                        name="limit_user"
                        placeholder="500"
                        className="noBorder noMargin"
                        style={{ width: '170px' }}
                      />
                    ) : (
                        ""
                        // (values.limit_user =
                        //   userLimit === "1" ? values.limit_user : "0")
                      )}
                  </div>
                  <div style={{ display: "flex", minHeight: "55px" }}>
                    <label style={{ width: "140px" }}>Max Discount</label>
                    <GSwitch
                      id="apply_max_discount"
                      uId="maxDiscount"
                      currentValue={maxDiscount}
                      style={{ width: "90px" }}
                      onSwitchChange={(val, id) => {
                        toggleValue(val, id);
                        if (val === "0" || val === 0) {
                          setFieldValue("max_discount", "0");
                        }
                      }}
                    />
                    {maxDiscount === "1" || maxDiscount === 1 ? (
                      <FormikControl
                        control="input"
                        type="text"
                        name="max_discount"
                        placeholder="100"
                        className="noBorder noMargin"
                        style={{ width: '170px' }}
                      />
                    ) : (
                        ""
                        // (values.max_discount =
                        //   maxDiscount === "1" ? values.max_discount : "0")
                      )}
                  </div>

                  <div style={{ display: "flex", minHeight: "55px" }}>
                    <label style={{ width: "140px" }}>Minimum Order</label>
                    <GSwitch
                      id="apply_min_order"
                      uId="minOrder"
                      currentValue={minOrder}
                      style={{ width: "90px" }}
                      onSwitchChange={(val, id) => {
                        toggleValue(val, id);
                        if (val === "0" || val === 0) {
                          setFieldValue("minimum_order_amount", "0");
                        }
                      }}
                    />
                    {minOrder === "1" || minOrder === 1 ? (
                      <FormikControl
                        control="input"
                        type="number"
                        min={0}
                        name="minimum_order_amount"
                        placeholder="500"
                        className="noBorder noMargin"
                        style={{ width: '170px' }}
                      />
                    ) : (
                        ""
                        // (values.minimum_order_amount =
                        //   minOrder === "1" ? values.minimum_order_amount : "0")
                      )}
                  </div>
                  <div style={{ display: "flex", minHeight: "55px" }}>
                    <label style={{ width: "140px" }}>Maximum Order</label>
                    <GSwitch
                      id="apply_max_order"
                      uId="maxOrder"
                      currentValue={maxOrder}
                      style={{ width: "90px" }}
                      onSwitchChange={(val, id) => {
                        toggleValue(val, id);
                        if (val === "0" || val === 0) {
                          setFieldValue("maximum_order_amount", "0");
                        }
                      }}
                    />
                    {maxOrder === "1" || maxOrder === 1 ? (
                      <FormikControl
                        control="input"
                        type="number" min={0}
                        name="maximum_order_amount"
                        placeholder="500"
                        className="noBorder noMargin"
                        style={{ width: '170px' }}
                      />
                    ) : (
                        ""
                      )}
                  </div>
                </div>
                <button
                  style={Object.assign({}, buttonStyle, { marginTop: "20px" })}
                  type="submit"
                >
                  {props.data ? "Update" : "Create"}
                </button>
              </Form>
            )}
          </Formik>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
