import React, { useRef, useState } from "react";
import styled from "styled-components";
import GDropdown from "./gDropdown";
import { useSnackbar } from "react-simple-snackbar";
import GButton from "./gButton";
import GInfo from "./gInfo";
import { useDetectOutsideClick } from "../components/useDetectOutsideClick";
import { AddCircleOutline } from "@material-ui/icons";
import AssignDriverPopup from "../components/assignDriver";
import AssigningIcon from "../assets/images/wall-clock-white.svg";

import { ApiService } from "../services";
const AgentDiv = styled.div`
  margin-bottom: 10px;
  small {
    color: #777;
    font-style: italic;
    display: block;
    padding: 5px 10px;
  }
`;

const assignList = [
  {
    title: "Assign Manually",
    value: "manually",
  },
  {
    title: "Assign Automatically",
    value: "automatically",
  },
];

export default function GCancelBox(props) {
  const [advisible, setAdvisible] = useState(false);
  const AssignDropdownRef = useRef(null);
  const [openSnackbar] = useSnackbar();
  const [isDropdownActive, setIsDropdownActive] = useDetectOutsideClick(
    AssignDropdownRef,
    false
  );

  const handleSelectValue = (selectedValue) => {
    if (selectedValue === "manually") {
      setAdvisible(true);
    } else {
      ApiService(
        "POST",
        "order/delivery/auto-assign/" + props.deliveryId,
        ""
      ).then((response) => {
        console.log("r" + response.status_code);
        props.onAssignTask();
        openSnackbar("Request sent to the driver successfully");
      });
    }
  };

  return (
    <AgentDiv>
      {props.isAssign === false ? (
        <React.Fragment>
          <GButton
            variant="linkable"
            onClick={() => {
              setIsDropdownActive(true);
            }}
          >
            <AddCircleOutline style={{ verticalAlign: "middle" }} /> Assign
            Driver
          </GButton>

          {isDropdownActive === true ? (
            <div ref={AssignDropdownRef}>
              <GDropdown
                id="assign"
                className={`menu ${isDropdownActive ? "active" : "inactive"}`}
                listData={assignList}
                onSelectValue={(value) => {
                  setIsDropdownActive(false);
                  handleSelectValue(value);
                }}
              />
            </div>
          ) : (
            ""
          )}
          <small>No driver assigned yet</small>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {" "}
          {props.taskLog ? (
            <React.Fragment>
              <p
                style={{
                  color: "#fff",
                  fontStyle: "italic",
                  background: "#f08c37",
                  padding: "5px",
                  display: "inline-block",
                  fontFamily: "LatoWebSemibold",
                  margin: "10px 0 15px",
                }}
              >
                <img
                  src={AssigningIcon}
                  alt="Task"
                  style={{ width: "20px", verticalAlign: "middle" }}
                />{" "}
                &nbsp;&nbsp;Assigning ({props.taskLog.current_count} of{" "}
                {props.taskLog.retries_count}) - {props.taskLog.expire_time}
                sec each
              </p>
              <GInfo
                title={props.driverData.title + " (current)"}
                key={props.driverData.id}
                subtitle={props.driverData.phone}
                photo={props.driverData.thumb_photo}
                imgType="user"
                link={"/driver/" + props.driverData.id + "/tasks"}
                linkState="driver"
              />
            </React.Fragment>
          ) : (
            <GInfo
              title={props.driverData.title}
              key={props.driverData.id}
              subtitle={props.driverData.phone}
              photo={props.driverData.thumb_photo}
              imgType="user"
              link={"/driver/" + props.driverData.id + "/tasks"}
              linkState="driver"
            />
          )}
        </React.Fragment>
      )}

      {advisible === true ? (
        <AssignDriverPopup
          advisible={advisible}
          setAdvisible={setAdvisible}
          linkedId={props.deliveryId}
          onSubmit={() => props.onAssignTask()}
        />
      ) : (
        ""
      )}
    </AgentDiv>
  );
}
