import React, { useState, useEffect } from "react";
import { makeStyles, Paper, TableContainer } from "@material-ui/core";
import { ApiService } from "../services";
import { Assignment } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import GTableShimmer from "../gComponents/gTableShimmer";
import GEmpty from "../gComponents/gEmpty";
import OrderStatuses from "../gComponents/gOrderStatus";
import QueryWith from "../Query";
import { DriverFilter } from "../components/driverFilterComponent";
import GSelectbox from "../gComponents/gSelectbox";
import GSorting from "../gComponents/gSorting";
import useSettings from "../useSettings";

const columns = [
  { id: "order_id", label: "Order Id", minWidth: 30, type: "task" },
  {
    id: "driver",
    label: "Driver",
    minWidth: 100,
    type: "agent_id",
    align: "center",
  },
  {
    id: "commission",
    label: "Commission",
    minWidth: 80,
    type: "task",
    align: "center",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 120,
    type: "task",
    align: "center",
  },
  {
    id: "created_at_formatted",
    label: "Created",
    minWidth: 130,
    type: "task",
    align: "center",
  },
  {
    id: "delivery_time",
    label: "Delivered",
    minWidth: 130,
    type: "task",
    align: "center",
  },
];

const orderSorting = [
  {
    title: "Created At",
    value: "created_at",
  },
  {
    title: "Delivery Time",
    value: "delivery_to",
  },
];

const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
  },
});

export default function Tasks(props) {
  const classes = useStyles();

  const [tasksData, setTaskData] = useState([]);
  const [taskPaginationData, setTaskPaginationData] = useState("");
  const [taskStatusData, setTaskStatusData] = useState([]);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    task_status: params.get("task_status")
      ? params.get("task_status")
      : "pending",
    agent_id: params.get("agent_id") ? params.get("agent_id") : "",
    orderby: params.get("orderby") ? params.get("orderby") : "created_at",
    order: params.get("order") ? params.get("order") : "DESC",
  });

  const settings = useSettings(true);
  useEffect(() => {
    console.log(props);

    if (props.type) {
      props.onChange("tasks");
      var obj = "?page=" + filters.page + "&" + props.title + "=" + props.value;
    } else {
      var params = QueryWith(history.location, filters);
      if (params.length > 0) {
        history.push({
          pathname: "/tasks",
          search: params[0],
        });
      }
    }
    var query = props.type ? obj : params[0];
    var hitUrl = "order/deliveries";

    ApiService("GET", hitUrl, query)
      .then((response) => {
        console.log("gh" + response);
        if (response.status_code === 1) {
          setTaskData(response.data.data);
          setTaskPaginationData(response.data);
          setTaskStatusData(response.statuses);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [history, filters]);

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({
      page: NewPageNo,
    });
  };
  const handleChangeStatus = (oStatus) => {
    console.log(oStatus);
    setFilters({
      page: "1",
      order_status: oStatus,
    });
  };

  return (
    <div className={props.type ? "" : "mainInnerView"}>
      {!props.type ? (
        <React.Fragment>
          <h1>
            <Assignment /> Tasks
          </h1>
        </React.Fragment>
      ) : (
        ""
      )}

      {!props.type ? (
        <React.Fragment>
          <OrderStatuses
            statusList={taskStatusData}
            onClickStatus={handleChangeStatus}
            defaultStatus={filters.order_status}
            currencySymbol={settings.currency_symbol}
          />

          <div>
            <DriverFilter
              value={filters.agent_id}
              style={{
                marginRight: "10px",
              }}
              handleCustomerChange={(value) => {
                setFilters({
                  agent_id: value,
                  order_status: filters.order_status,
                });
              }}
            />
            <GSorting
              defaultvalue={filters.orderby}
              defaultOrdervalue={filters.order}
              data={orderSorting}
              id="sortBy"
              option_key="title"
              option_value="value"
              onChange={(newVal) => {
                setFilters({
                  orderby: newVal,
                  order_status: filters.order_status,
                });
              }}
              style={{
                height: "40px",
                marginRight: "10px",
                minWidth: "150px",
              }}
              onOrderChange={(newVal) => {
                setFilters({
                  order: newVal,
                  order_status: filters.order_status,
                });
              }}
            />
          </div>
        </React.Fragment>
      ) : (
        ""
      )}

      {tasksData.length !== 0 && !props.type ? (
        <div style={{ textAlign: "right" }}>
          <GPagination
            style={{ display: "inline-block" }}
            count={taskPaginationData.total}
            from={taskPaginationData.from}
            to={taskPaginationData.to}
            onChangeNextPage={() =>
              handleChangePage(taskPaginationData.next_page_url)
            }
            onChangePrevPage={() =>
              handleChangePage(taskPaginationData.prev_page_url)
            }
            rowsPerPage={taskPaginationData.per_page}
            prevDisabled={taskPaginationData.prev_page_url ? false : true}
            nextDisabled={taskPaginationData.next_page_url ? false : true}
          ></GPagination>
          {taskPaginationData ? (
            <GPaginationSelect
              defaultvalue={filters.page}
              totalPage={taskPaginationData.last_page}
              onChangePage={(NewPageNo) => setFilters({ page: NewPageNo })}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      <Paper
        className={classes.root}
        style={{ marginTop: props.type ? "" : "10px" }}
      >
        {tasksData.length !== 0 && props.type ? (
          <GPagination
            count={taskPaginationData.total}
            from={taskPaginationData.from}
            to={taskPaginationData.to}
            onChangeNextPage={() =>
              handleChangePage(taskPaginationData.next_page_url)
            }
            onChangePrevPage={() =>
              handleChangePage(taskPaginationData.prev_page_url)
            }
            rowsPerPage={taskPaginationData.per_page}
            prevDisabled={taskPaginationData.prev_page_url ? false : true}
            nextDisabled={taskPaginationData.next_page_url ? false : true}
          ></GPagination>
        ) : (
          ""
        )}

        {!taskPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer className={classes.container}>
            {tasksData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns
                      .filter((column) => column.type != props.title)
                      .map((c) => {
                        return (
                          <TableHeader
                            key={c.id}
                            style={{
                              minWidth: c.minWidth,
                              width: c.width,
                              textAlign: c.align,
                            }}
                          >
                            {c.label}
                          </TableHeader>
                        );
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tasksData.map((s) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={s.id}>
                        <TableRowData>
                          <Link
                            to={{
                              pathname: "/order/" + s.order_id,
                              state: "order",
                            }}
                          >
                            #{s.order_id}
                          </Link>
                        </TableRowData>
                        {props.title === "agent_id" ? (
                          ""
                        ) : (
                          <TableRowData style={{ textAlign: "center" }}>
                            {s.agent ? (
                              <Link
                                to={{
                                  pathname: "/driver/" + s.agent.id + "/tasks",
                                  state: "driver",
                                }}
                              >
                                {s.agent.title}
                              </Link>
                            ) : (
                              "-"
                            )}
                          </TableRowData>
                        )}
                        <TableRowData style={{ textAlign: "center" }}>
                          {settings.currency_symbol}
                          {s.agent_commission}
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          <span
                            className="orderStatus"
                            style={{
                              color: "#" + s.status_display.color1,
                              background: "#" + s.status_display.color2,
                            }}
                          >
                            {s.status_display.title}
                          </span>
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          {s.created}
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          {s.completed ? s.completed : "-"}
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </Paper>
    </div>
  );
}
