import React, { useEffect, useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { useParams } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import { TableContainer, Link } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import { ApiService } from "../services";
import { Add, Edit, Delete } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import GEmpty from "../gComponents/gEmpty";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GTableShimmer from "../gComponents/gTableShimmer";
import StoreTimeSlotUpdate from "./StoreTimeSlotUpdate";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const columns = [
  { id: "from_time", label: "From Time", minWidth: 150 },
  { id: "to_time", label: "To Time", minWidth: 150 },
  { id: "limit", label: "Order Limit", minWidth: 150, align: "center" },
  { id: "actions", label: "Actions", minWidth: 60, align: "center" },
];

const timeRoutes = [
  {
    title: "mon",
    link: "/settings/timeslots/mon", state: "setting"
  },
  {
    title: "tue",
    link: "/settings/timeslots/tue", state: "setting"
  },
  {
    title: "wed",
    link: "/settings/timeslots/wed", state: "setting"
  },
  {
    title: "thu",
    link: "/settings/timeslots/thu", state: "setting"
  },
  {
    title: "fri",
    link: "/settings/timeslots/fri", state: "setting"
  },
  {
    title: "sat",
    link: "/settings/timeslots/sat", state: "setting"
  },
  {
    title: "sun",
    link: "/settings/timeslots/sun", state: "setting"
  },
];

export default function TimeSlots({props}) {
    const [storeId, setStoreId] = useState(props.match.params.storeId);
  const [openSnackbar] = useSnackbar();
  const [typeValue, setTypeValue] = React.useState("mon");
  const [timeslotData, setTimeslotData] = React.useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTimeslotId, setSelectedTimeslotId] = useState("");
  const [tsvisible, setTsvisible] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [weekdays, setWeekdays] = useState([]);
  let { weekday } = useParams();

  function getTimeslots() {
    var hitUrl = `store-timings?store_id=${storeId}`

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log("response get ===>",response.data);
        if (response.status_code === 1) {
          setTimeslotData(response.data);
          setIsLoading(false);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    setStoreId(props.match.params.storeId);
    console.log(typeValue);
    getTimeslots();
    setTypeValue(weekday ? weekday : "mon"); 
    // [weekday]
},[]);

  const deleteTimeslot = () => {
    var hitUrl = "store-timings/" + selectedTimeslotId;
    ApiService("DELETE", hitUrl)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          openSnackbar(response.message, [5000]);
          getTimeslots();
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("timeslot data", timeslotData)
//   const arr = [{weekday:"mon"},{weekday:"tue"}]
  const dumy =  {
    "id": 1,
    "weekday": "none",
    "from_time": "00:00:0",
    "to_time": "00:00:00",
    "store_id": storeId,
    "status": "0"
  }
//   let array = [Monday,Tuesday,Wednesday,Thursday,Friday,Saturday,Sunday]

  const Monday = timeslotData?.find(e=>e.weekday.slice(0,3)==="mon")
  const Tuesday = timeslotData?.find(e=>e.weekday.slice(0,3)==="tue")
  const Wednesday = timeslotData?.find(e=>e.weekday.slice(0,3)==="wed")
  const Thursday = timeslotData?.find(e=>e.weekday.slice(0,3)==="thu")
  const Friday = timeslotData?.find(e=>e.weekday.slice(0,3)==="fri")
  const Saturday = timeslotData?.find(e=>e.weekday.slice(0,3)==="sat")
  const Sunday = timeslotData?.find(e=>e.weekday.slice(0,3)==="sun")
//   const Weekdays = {Monday:Monday,Tuesday:Tuesday,Wednesday:Wednesday,Thursday:Thursday,Friday:Friday,Saturday:Saturday,Sunday:Sunday};
//   const array = [Monday,Tuesday,Wednesday,Thursday,Friday,Saturday,Sunday]
  const array = [Monday?Monday:dumy,Tuesday?Tuesday:dumy,Wednesday?Wednesday:dumy,Thursday?Thursday:dumy,Friday?Friday:dumy,Saturday?Saturday:dumy,Sunday?Sunday:dumy]



    // console.log("arrrr222=>>>", array)


  
  return (
    <div className="rightInnerView">
      

      <div className="listPaper">
        <div>
          {isLoading === true ? (
            <GTableShimmer></GTableShimmer>
          ) : (


              timeslotData.length ===
                0 ? (
                  <GEmpty></GEmpty>
                ) :

                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    
                    <TableBody>
                      {array.map((c) => {
                          console.log("cccc=>",c)
                          return (
                            c.id===1?undefined:<TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={c.id}
                            >
                            <TableRowData><b>{c.weekday.toUpperCase()}</b></TableRowData>
                            <TableRowData>{c.from_time}</TableRowData>
                              <TableRowData>{c.to_time}</TableRowData>
                              {/* <TableRowData style={{
                                textAlign: "center",
                              }}>{c.order_limit}</TableRowData> */}
                              <TableRowData
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <GButton
                                  variant="linkable"
                                  onClick={(e) => {
                                    setSelectedSlot(c);
                                    setIsEmpty(false);
                                    setTsvisible(true);
                                  }}
                                >
                                  <Edit fontSize="small" />
                                </GButton>

                                <GButton
                                  variant="linkable"
                                  onClick={(e) => {
                                    setSelectedTimeslotId(c.id)
                                    setDialogOpen(true);
                                  }}
                                >
                                  <Delete fontSize="small" />
                                </GButton>
                              </TableRowData>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>

                </TableContainer>
            )}
            <div style={{textAlign: 'center'}}>
            <GButton
            variant="linkable"
            onClick={(e) => {
                setIsEmpty(true)
                setTsvisible(true)
            }}>
            <AddCircleOutlineIcon fontSize="large"/></GButton></div>
        </div>
      </div>
      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Timeslot"
          text="Are you sure you want to delete this timeslot?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteTimeslot();
          }}
        ></GConfirmDialog>
      ) : (
          ""
        )}

      {tsvisible === true ? (
        <StoreTimeSlotUpdate
          tsvisible={tsvisible}
          setTsvisible={setTsvisible}
          isEmpty={isEmpty}
          data={selectedSlot}
          storeId={storeId}
        //   weekday={typeValue}
          onSubmit={getTimeslots}
        />
      ) : (
          ""
        )}
    </div>
  );
}


