import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
// } from "react-google-maps";
import GMap from "../gComponents/gMaps";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GGeoTagsInput from "../gComponents/gGeofencesInput";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
var buttonStyle = {
  background: "#f08c37",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "95%",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  margin: "30px 15px 0",
  fontWeight: "600",
};

function CreateStore(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const location = useLocation();
  const [geofencesList, setGeofencesList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const initialValues = {
    title: props.data ? props.data.title : "",
    phone: props.data ? props.data.phone : "",
    address: props.data ? props.data.address : "",
    photo: "",
    latitude: "",
    longitude: "",
    min_order: "",
    commission: "",
    order_type: "",
    delivery_type: "",
    geofences: [],
  };

  const deliveryTypes = [
    {
      title: "Both",
      value: "",
    },
    {
      title: "ASAP Only",
      value: "asap",
    },
    {
      title: "Scheduled Only",
      value: "scheduled",
    },
  ];

  const orderTypes = [
    {
      title: "Both",
      value: "",
    },
    {
      title: "Home Delivery",
      value: "home_delivery",
    },
    {
      title: "Store Pickup",
      value: "store_pickup",
    },
  ];

  const onSubmit = (values) => {
    //setIsDisabled(true);
    console.log(values);
    //return;
    var hitMethod = props.data ? "PUT" : "POST";
    var hitUrl = props.data ? "stores/" + props.data.id : "stores";
    ApiService(hitMethod, hitUrl, values).then((response) => {
      console.log("r" + response.status_code);
      openSnackbar(response.message);
      if (response.status_code === 1) {
        if (location.pathname === "/stores") {
          window.location.reload(false);
        } else {
          window.location = "/stores";
        }
        props.setCsvisible(false);
      } else {
        setIsDisabled(false);
        return;
      }
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    phone: Yup.string()
      // .test(
      //   "len",
      //   "he phone must be between 7 and 15 digits",
      //   (val) => val.length < 15 && val.length > 7
      // )
      .required("Required"),
    address: Yup.string().required("Required"),
    commission: Yup.string().required("Required"),
    // latitude: Yup.string().required("Required"),
  });

  const handleClose = (e) => {
    if (e.target.id != 2721) {
      return;
    }
    props.setCsvisible(false);
  };

  // const handleChange = (e) => {
  //   let value = e.target.value;
  //   this.setState((prevState) => ({
  //     newUser: {
  //       ...prevState.newUser,
  //       name: value,
  //     },
  //   }));
  // };

  if (props.csvisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2721"
        className={classes.backdrop}
        open={props.csvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.csvisible === true ? "fadeM" : ""}`}
          style={{ padding: "20px", maxWidth: "500px" }}
        >
          <h3>{props.isEmpty === true ? "Create" : "Edit"} Store</h3>
          {/* <GoogleMap
            defaultZoom={10}
            defaultCenter={{ lat: 45.4211, lng: -75.6903 }}
          >
            <Marker position={{ lat: -34.397, lng: 150.644 }} />
            {/* We will render our data here *
          </GoogleMap> */}

          {/* <GMap center={{ lat: -24.9923319, lng: 135.2252427 }} /> */}

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div style={{ display: "inline-flex" }}>
                  <FormikControl
                    control="input"
                    type="text"
                    label="Title"
                    name="title"
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    label="Phone"
                    name="phone"
                    maxlength={15}
                    onChange={(event) => {
                      const re = /^[0-9 +\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("phone", event.target.value);
                      }
                    }}
                  />
                </div>

                <GMap
                  centerLocation={{
                    lat: process.env.REACT_APP_DEFAULT_LATITUDE,
                    lng: process.env.REACT_APP_DEFAULT_LONGITUDE,
                  }}
                  search={true}
                  height="30vh"
                  type="createStore"
                  onChange={(address, loc) => {
                    values.latitude = loc.lat();
                    values.longitude = loc.lng();
                    setFieldValue("address", address.address[0].name);
                  }}
                  label="Location"
                  padding={true}
                />

                <FormikControl
                  control="input"
                  type="text"
                  label="Address"
                  name="address"
                />

                <h4 style={{ margin: "15px -5px 10px" }}>
                  Additional Information
                </h4>
                <FormikControl
                  control="input"
                  type="text"
                  label="Commission (in %)"
                  name="commission"
                  onChange={(event) => {
                    const re = /^[0-9.\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("commission", event.target.value);
                    }
                  }}
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />

                <FormikControl
                  control="input"
                  type="text"
                  label="Min Order"
                  name="min_order"
                  onChange={(event) => {
                    const re = /^[0-9.\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("min_order", event.target.value);
                    }
                  }}
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />

                <div style={{ margin: "15px" }}>
                  <label>Geofences</label>
                  <GGeoTagsInput
                    data={geofencesList}
                    onSubmit={(tags) => {
                      console.log(tags);
                      values.geofences = tags.map((d) => d.id);
                    }}
                    showButton={false}
                  />
                </div>

                <FormikControl
                  control="radio"
                  label="Delivery Types"
                  name="delivery_type"
                  options={deliveryTypes}
                  key_title="title"
                  key_value="value"
                />
                <FormikControl
                  control="radio"
                  label="Order Types"
                  name="order_type"
                  options={orderTypes}
                  key_title="title"
                  key_value="value"
                />

                <br />
                <button style={buttonStyle} type="submit" disabled={isDisabled}>
                  {props.data ? "Update" : "Create"}
                </button>
              </Form>
            )}
          </Formik>
        </Paper>
      </Backdrop>
    </div>
  );
}

export default CreateStore;
