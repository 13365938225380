import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { Card, CardHead } from "../styledComponents/cardStyles";
import { ProductFilter } from "./productFilterComponent";
import GInfo from "../gComponents/gInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  option: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#888",
    width: "40px",
    height: "40px",
    fontSize: "18px",
    borderRadius: "5px",
    cursor: "pointer",
    //transition: 'background 0.3s',
    transitionProperty: "background-color, color",
    transitionDuration: "300ms, 300ms",
    "&:hover": {
      backgroundColor: "#333",
      color: "#bbb",
      fontWeight: "500",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var buttonStyle = {
  background: "#FF6500",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "100%",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  marginTop: "30px",
  fontWeight: "600",
};

export default function ProductCreateForm(props) {
  const classes = useStyles();
  const [categoriesList, setCategoriesList] = useState([]);
  const [sale, setSale] = useState(false);
  const [selectedMasterProduct, setSelectedMasterProduct] = useState({});
  const [saleOn, setSaleOn] = useState(true);
  const [openSnackbar] = useSnackbar();
  const initialValues = {
    unlimited: "0",
    sale: "0",
    sale_price: "0",
    tax: "",
    price: "",
    stock: "0",
    store_id: props.storeId,
    master_id: "",
    description: "",
  };

  console.log(props.data + "--");

  useEffect(() => {
    ApiService("GET", "product/group?store_id=" + props.storeId).then(
      (response) => {
        console.log("r" + response.status_code);
        if (response.status_code === 0) {
          //openSnackbar(response.message);
          return;
        }
        setCategoriesList(response.data);
      }
    );
  }, [props]);

  const onSubmit = (values) => {
    console.log(values);
    // return;
    ApiService("POST", "products", values).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        props.setPcvisible(false);
        props.onSubmit();
        openSnackbar(response.message);
      } else {
        openSnackbar(response.message);
        return;
      }
    });
  };

  const validationSchema = Yup.object({});

  //console.log(handleChange);
  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setPcvisible(false);
  };

  // const handleChange = (e) => {
  //   let value = e.target.value;
  //   this.setState((prevState) => ({
  //     newUser: {
  //       ...prevState.newUser,
  //       name: value,
  //     },
  //   }));
  // };

  if (props.pcvisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.pcvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.pcvisible === true ? "fadeM" : ""}`}
          style={{ padding: "20px", maxWidth: "450px" }}
        >
          <h3>Add Product</h3>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              //  function updateField(newValue){
              //      values.icon = newValue
              // }

              <Form>
                <CardHead style={{ padding: "0 15px", fontSize: "15px" }}>
                  Search Items
                </CardHead>
                <ProductFilter
                  value={selectedMasterProduct ? selectedMasterProduct.id : ""}
                  style={{
                    marginBottom: "10px",
                  }}
                  storeId={0}
                  type="master"
                  handleProductChange={(id, value) => {
                    console.log(value);
                    setSelectedMasterProduct(value ? value : "");
                    // setItemsData([...itemsData, value]);
                    // if (value) {
                    //   addProduct(value);
                    // }
                    setFieldValue("master_id", id);
                  }}
                />

                {values.master_id ? (
                  <React.Fragment>
                    <Card
                      style={{
                        border: "1px solid #e2e2e2",
                        margin: "12px",
                        padding: "10px",
                      }}
                    >
                      <GInfo
                        title={selectedMasterProduct.title}
                        subtitle={
                          selectedMasterProduct.unit
                            ? props.settings.currency_symbol +
                              selectedMasterProduct.price +
                              " (" +
                              selectedMasterProduct.unit +
                              ")"
                            : props.settings.currency_symbol +
                              selectedMasterProduct.price
                        }
                        subtitle2={selectedMasterProduct.categories_title}
                        photo={selectedMasterProduct.thumb_photo}
                        imgType="item"
                      />
                    </Card>

                    <FormikControl
                      control="input"
                      type="text"
                      label="Price"
                      name="price"
                      onChange={(event) => {
                        const re = /^[0-9.\b]+$/;
                        if (
                          event.target.value &&
                          !re.test(event.target.value)
                        ) {
                          return;
                        } else {
                          setFieldValue("price", event.target.value);
                        }
                      }}
                      divstyle={{
                        display: "inline-block",
                        width: "50%",
                        boxSizing: "border-box",
                        verticalAlign: "top",
                      }}
                    />

                    <FormikControl
                      control="input"
                      type="text"
                      onChange={(event) => {
                        const re = /^[0-9.\b]+$/;
                        if (
                          event.target.value &&
                          !re.test(event.target.value)
                        ) {
                          return;
                        } else {
                          setFieldValue("tax", event.target.value);
                        }
                      }}
                      label="Tax"
                      name="tax"
                      divstyle={{
                        display: "inline-block",
                        width: "50%",
                        boxSizing: "border-box",
                        verticalAlign: "top",
                      }}
                    />

                    <FormikControl
                      control="input"
                      as="textarea"
                      label="Description"
                      name="description"
                    />

                    <FormikControl
                      control="toggle"
                      label="Unlimited"
                      isToggled={values.unlimited == "1" ? 1 : 0}
                      name="unlimited"
                      onToggle={() => {
                        let unlimitedVal =
                          values.unlimited === "1" ? true : false;
                        unlimitedVal = !unlimitedVal;
                        unlimitedVal = unlimitedVal === true ? "1" : "0";
                        setFieldValue("unlimited", unlimitedVal);
                      }}
                      style={{ width: "50%", boxSizing: "border-box" }}
                    />

                    {props.isStockShow === "1" && values.unlimited != "1" ? (
                      <FormikControl
                        control="input"
                        type="text"
                        label="Stock"
                        name="stock"
                        divstyle={{
                          display: "inline-block",
                          width: "50%",
                          boxSizing: "border-box",
                        }}
                      />
                    ) : (
                      ""
                    )}

                    <br />

                    {/* <FormikControl
                      control="toggle"
                      label="Sale"
                      isToggled={values.sale == "1" ? 1 : 0}
                      name="sale"
                      onToggle={() => {
                        let saleVal =
                          values.sale === "1" ? true : false;
                        // unlimitedVal = !unlimitedVal;
                        saleVal = saleVal === true ? "1" : "0";
                        setFieldValue("sale", saleVal);
                      }}
                      style={{ width: "50%", boxSizing: "border-box" }}
                    /> */}
                    <FormikControl
                      control="toggle"
                      label="Sale"
                      // isToggled={values.sale == "1"}
                      name="sale"
                      onChange={(e) => {
                        setSaleOn(!saleOn);
                        setFieldValue("sale", saleOn ? "1" : "0");
                      }}
                      // onToggle={(e) => {
                      //   console.log(e.target);
                      //   // let saleVal = values.sale === "1" ? true : false;
                      //   // // unlimitedVal = !unlimitedVal;
                      //   // saleVal = saleVal === true ? "1" : "0";
                      //   // if(values.sale_price > 0){
                      //   //   setFieldValue("sale", "1");

                      //   // }
                      //   // setFieldValue("sale", saleVal);
                      // }}
                      style={{ width: "50%", boxSizing: "border-box" }}
                    />

                    {!saleOn ? (
                      <FormikControl
                        control="input"
                        type="text"
                        label="Sale Discount Price"
                        name="sale_price"
                        divstyle={{
                          display: "inline-block",
                          width: "50%",
                          boxSizing: "border-box",
                        }}
                      />
                    ) : (
                      ""
                    )}

                    {/* <FormikControl
                      control="toggle"
                      label="Sale"
                      // isToggled={values.sale == "1" ? 1 : 0}
                      name="sale"
                      onToggle={() => {
                        if(sale){
                          setFieldValue("sale", "1");
                        }else if(!sale){
                          setFieldValue("sale_price", "0")
                          setFieldValue("sale", "0");
                        }
                        setSale(!sale);
                      }}
                      style={{ width: "50%", boxSizing: "border-box" }}
                    />

                    {sale ? (
                      <FormikControl
                        onChange={(e) => {
                          setFieldValue("sale_price", e.target.value);
                        }}
                        defaultValue="0"
                        // value={values}
                        control="input"
                        type="number"
                        label="Sale Percentage"
                        name="sale_price"
                        divstyle={{
                          display: "inline-block",
                          width: "50%",
                          boxSizing: "border-box",
                        }}
                      />
                    ) : (
                      ""
                    )} */}

                    <button style={buttonStyle} type="submit">
                      Create
                    </button>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </Form>
            )}
          </Formik>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
