import React, { useState, useEffect } from "react";
import { makeStyles, Paper, TableContainer } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { ApiService, ImgUrl, addDefaultSrc } from "../services";
import { Add, Edit, Delete } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import DocumentFormPopup from "./documentForm";
import GLightBox from "../gComponents/gLightBox";
// import { browserHistory } from "react-router";

const columns = [
  { id: "title", label: "Title", minWidth: 120 },
  { id: "photo", label: "Photo", minWidth: 50 },
  { id: "created_at", label: "Created", minWidth: 60, align: "center" },
  { id: "actions", label: "Actions", minWidth: 60, align: "center" },
];

const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
  },
});

export default function Documents(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();

  const [documentData, setDocumentData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [linkedId, setLinkedId] = useState("");
  const [selectedDocument, setSelectedDocument] = useState({});
  const [selectedDocumentId, setSelectedDocumentId] = useState("");
  const [isEmpty, setIsEmpty] = useState(true);
  const [docvisible, setDocvisible] = useState(false);
  const [selectedImgPreview, setSelectedImgPreview] = useState(false);
  const [isImgPreview, setImgPreview] = useState(false);

  let user_type =
    props.type === "customer"
      ? "1"
      : props.type === "driver"
        ? "3"
        : props.type === "store"
          ? "4"
          : "2";
  const getDocuments = () => {
    var query = "?user_type=" + user_type + "&user_id=" + props.value;
    var hitUrl = "documents";

    console.log(hitUrl);

    ApiService("GET", hitUrl, query)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setDocumentData(response.data);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log(props);
    if (props.type) {
      if (props.type === "manager") {
        props.onChange("manager-documents");
      } else {
        props.onChange("documents");
      }
    }
    setLinkedId(props.value);

    getDocuments();
  }, [props]);

  const deleteDocument = () => {
    var hitUrl = "documents/" + selectedDocumentId;
    ApiService("DELETE", hitUrl)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          openSnackbar(response.message, [5000]);
          //getAddress();
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={props.type ? "" : "mainInnerView"}>
      {props.type ? "" : <h1>Documents</h1>}
      <GButton
        variant="linkable"
        children="Add"
        onClick={() => {
          setSelectedDocument({});
          setIsEmpty(true);
          setDocvisible(true);
        }}
        style={{
          margin: "-40px 0px 10px",
          float: "right",
        }}
      >
        <Add fontSize="large" />
      </GButton>
      <Paper className={classes.root}>
        {!documentData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
            <TableContainer className={classes.container}>
              {documentData.length === 0 ? <GEmpty></GEmpty> :
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableHeader
                          key={column.id}
                          style={{
                            minWidth: column.minWidth,
                            textAlign: column.align,
                          }}
                        >
                          {column.label}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documentData.map((c) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                          <TableRowData>{c.document_type_title}</TableRowData>
                          <TableRowData>
                            <img
                              alt=""
                              src={ImgUrl("document") + "/" + c.thumb_photo}
                              onError={addDefaultSrc}
                              className="thumbnail"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setSelectedImgPreview(c.photo);
                                setImgPreview(true);
                              }}
                            />
                          </TableRowData>
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {c.created}
                          </TableRowData>
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <GButton
                              variant="linkable"
                              //  onClick={(e) =>
                              //       window.confirm(
                              //         "Are you sure you wish to delete this customer?"
                              //       ) && deleteCustomer(c.id)
                              // }

                              onClick={(e) => {
                                setSelectedDocument(c);
                                setIsEmpty(false);
                                setDocvisible(true);
                              }}
                            >
                              {" "}
                              <Edit fontSize="small" />
                            </GButton>

                            <GButton
                              variant="linkable"
                              //  onClick={(e) =>
                              //       window.confirm(
                              //         "Are you sure you wish to delete this customer?"
                              //       ) && deleteCustomer(c.id)
                              // }

                              onClick={(e) => {
                                setSelectedDocumentId(c.id);
                                setDialogOpen(true);
                              }}
                            >
                              <Delete fontSize="small" />
                            </GButton>
                          </TableRowData>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              }
            </TableContainer>
          )}
      </Paper>

      {/* <GToast title="Success" message=""></GToast> */}

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Document"
          text="Are you sure you want to delete this document?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteDocument();
          }}
        ></GConfirmDialog>
      ) : (
          ""
        )}

      {isImgPreview === true ? (
        <GLightBox
          images={selectedImgPreview}
          isImgPreview={isImgPreview}
          setImgPreview={setImgPreview}
          imgType="document"
        ></GLightBox>
      ) : (
          ""
        )}
      {docvisible === true ? (
        <DocumentFormPopup
          docvisible={docvisible}
          setDocvisible={setDocvisible}
          type="customer"
          data={selectedDocument}
          isEmpty={isEmpty}
          linkedId={linkedId}
          userType={
            props.type === "customer"
              ? "1"
              : props.type === "driver"
                ? "3"
                : props.type === "store"
                  ? "4"
                  : "2"
          }
          onSubmit={getDocuments}
        />
      ) : (
          ""
        )}
    </div>
  );
}

//export default Customers;
