import React from "react";
// import styled from "styled-components";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  SettingRoute: {
    color: "#000",

    "& a": {
      color: "#000",
      cursor: "pointer",
      display: "block",
      padding: "6px 20px",
      fontSize: "13.5px",
      textDecoration: "none",

      "&:hover": {
        color: "#f08c37",
      },
    },
    "& a.active": {
      color: "#f08c37",
    },
  },
}));
var headerStyle = {
  fontSize: "15px",
  margin: "0px",
  fontWeight: "500",
  padding: "0 20px",
  marginBottom: "10px",
  fontFamily: "LatoWebSemibold",
};

var lineStyle = {
  width: "90%",
  marginTop: "10px",
  marginBottom: "15px",
  border: "0px",
  borderTop: "1px solid #eee",
};

export default function SettingRoutes(props) {
  const classes = useStyles();

  console.log(JSON.stringify(props));
  // export default function SettingView = ({ match }) => {
  return (
    <div className={classes.SettingRoute}>
      <br />
      <h3 style={headerStyle}>General Settings</h3>
      <Link
        to={{ pathname: "/settings/basic", state: "setting" }}
        className={props.url === "/settings/basic" ? "active" : ""}
      >
        Basic Settings
      </Link>
      <Link
        to={{
          pathname: "/settings/social",
          state: "setting",
        }}
        className={props.url === "/settings/social" ? "active" : ""}
      >
        Social Links
      </Link>
      <Link
        to={{
          pathname: "/settings/invoice",
          state: "setting",
        }}
        className={props.url === "/settings/invoice" ? "active" : ""}
      >
        Invoice Settings
      </Link>
      {/* <Link
        to="/settings/api-keys", state: 'setting' }}
        className={props.url === "/settings/api-keys" ? "active" : ""}
      >
        Api Keys
      </Link> */}
      <Link
        to={{
          pathname: "/settings/document-types/customer",
          state: "setting",
        }}
        className={props.url === "/settings/document-types" ? "active" : ""}
      >
        Document Types
      </Link>
      {/* <Link
        to={{pathname:"/settings/addresses", state: 'setting' }}
        className={props.url === "/settings/addresses" ? "active" : ""}
      >
        Address
      </Link> */}
      <hr style={lineStyle} />

      <h3 style={headerStyle}>Order Settings</h3>
      <Link
        to={{
          pathname: "/settings/order-notifications/customer",
          state: "setting",
        }}
        className={
          props.url === "/settings/order-notifications" ? "active" : ""
        }
      >
        Notifications{" "}
      </Link>
      <Link
        to={{
          pathname: "/settings/order-cancel-reasons/customer",
          state: "setting",
        }}
        className={
          props.url === "/settings/order-cancel-reasons" ? "active" : ""
        }
      >
        Cancel Reasons{" "}
      </Link>
      <Link
        to={{
          pathname: "/settings/other-settings",
          state: "setting",
        }}
        className={props.url === "/settings/other-settings" ? "active" : ""}
      >
        Other Settings
      </Link>

      <hr style={lineStyle} />

      <h3 style={headerStyle}>Delivery Settings</h3>
      {/* <Link
        to={{ pathname:"/settings/delivery-prefrences", state: 'setting' }}
        className={
          props.url === "/settings/delivery-prefrences" ? "active" : ""
        }
      >
        Delivery Prefrences
      </Link> */}
      {/* <Link
        to="{{ pathname:/settings/vehicle-types"
        className={props.url === "/settings/vehicle-types" ? "active" : ""}
      >
        Vehicle Types
      </Link> */}
      <Link
        to={{
          pathname: "/settings/pricing-rule",
          state: "setting",
        }}
        className={props.url === "/settings/pricing-rule" ? "active" : ""}
      >
        Pricing Rule
      </Link>
      <Link
        to={{
          pathname: "/settings/task-allocation",
          state: "setting",
        }}
        className={props.url === "/settings/task-allocation" ? "active" : ""}
      >
        Task Allocation
      </Link>
      <Link
        to={{
          pathname: "/settings/task-actions",
          state: "setting",
        }}
        className={props.url === "/settings/task-actions" ? "active" : ""}
      >
        Task Actions
      </Link>
      <Link
        to={{
          pathname: "/settings/task-notifications/customer/delivery",
          state: "setting",
        }}
        className={props.url === "/settings/task-notifications" ? "active" : ""}
      >
        Notifications
      </Link>
      {/* <Link
        to={{ pathname:"/settings/express-cost", state: 'setting' }}
        className={props.url === "/settings/express-cost" ? "active" : ""}
      >
        Express Cost
      </Link>
      <Link
        to={{ pathname:"/settings/driver-app", state: 'setting' }}
        className={props.url === "/settings/driver-app" ? "active" : ""}
      >
        Driver App
      </Link> */}
      <hr style={lineStyle} />

      <h3 style={headerStyle}>Store Settings</h3>
      <Link
        to={{
          pathname: "/settings/events",
          state: "setting",
        }}
        className={props.url === "/settings/events" ? "active" : ""}
      >
        Events
      </Link>
      <Link
        to={{
          pathname: "/settings/timeslot-settings",
          state: "setting",
        }}
        className={props.url === "/settings/timeslot-settings" ? "active" : ""}
      >
        Timeslot Settings
      </Link>
      <Link
        to={{
          pathname: "/settings/timeslots/mon",
          state: "setting",
        }}
        className={props.url === "/settings/timeslots" ? "active" : ""}
      >
        Timeslots
      </Link>
      <Link
        to={{
          pathname: "/settings/tags/store",
          state: "setting",
        }}
        className={props.url === "/settings/tags" ? "active" : ""}
      >
        Tags
      </Link>
      <hr style={lineStyle} />

      <h3 style={headerStyle}>Item Settings</h3>
      <Link
        to={{
          pathname: "/settings/variant-types",
          state: "setting",
        }}
        className={props.url === "/settings/variant-types" ? "active" : ""}
      >
        Variant Types{" "}
      </Link>
      <Link
        to={{
          pathname: "/settings/bulk-upload",
          state: "setting",
        }}
        className={props.url === "/settings/bulk-upload" ? "active" : ""}
      >
        Bulk Upload{" "}
      </Link>
      <hr style={lineStyle} />

      <h3 style={headerStyle}>Payment Settings</h3>
      <Link
        to={{
          pathname: "/settings/tax",
          state: "setting",
        }}
        className={props.url === "/settings/tax" ? "active" : ""}
      >
        Tax{" "}
      </Link>
      <Link
        to={{
          pathname: "/settings/wallet",
          state: "setting",
        }}
        className={props.url === "/settings/wallet" ? "active" : ""}
      >
        Wallet{" "}
      </Link>
      <hr style={lineStyle} />

      <h3 style={headerStyle}>Content Settings</h3>
      <Link
        to={{
          pathname: "/settings/banners",
          state: "setting",
        }}
        className={props.url === "/settings/banners" ? "active" : ""}
      >
        Banners{" "}
      </Link>
      <Link
        to={{
          pathname: "/settings/faqs/customer",
          state: "setting",
        }}
        className={props.url === "/settings/faqs" ? "active" : ""}
      >
        FAQs{" "}
      </Link>
      <Link
        to={{
          pathname: "/settings/privacy-policy",
          state: "setting",
        }}
        className={props.url === "/settings/privacy-policy" ? "active" : ""}
      >
        Privacy Policy
      </Link>
      <Link
        to={{
          pathname: "/settings/terms-conditions",
          state: "setting",
        }}
        className={props.url === "/settings/terms-conditions" ? "active" : ""}
      >
        Terms & Conditions
      </Link>
      <Link
        to={{
          pathname: "/settings/driver-instructions",
          state: "setting",
        }}
        className={
          props.url === "/settings/driver-instructions" ? "active" : ""
        }
      >
        Driver Instructions
      </Link>
      <Link
        to={{ pathname: "/settings/website-settings", state: "setting" }}
        className={props.url === "/settings/website-settings" ? "active" : ""}
      >
        Website Settings
      </Link>
      <hr style={lineStyle} />

      <h3 style={headerStyle}>Geo-location Settings</h3>

      <Link
        to={{
          pathname: "/settings/cities",
          state: "setting",
        }}
        className={props.url === "/settings/cities" ? "active" : ""}
      >
        Cities
      </Link>
      <Link
        to={{ pathname: "/settings/zipcodes", state: "setting" }}
        className={props.url === "/settings/zipcodes" ? "active" : ""}
      >
        Zipcodes
      </Link>
      <hr style={lineStyle} />

      <h3 style={headerStyle}>Notification Settings</h3>
      <Link
        to={{
          pathname: "/settings/email-notifications",
          state: "setting",
        }}
        className={
          props.url === "/settings/email-notifications" ? "active" : ""
        }
      >
        Email
      </Link>
      <Link
        to={{
          pathname: "/settings/sms-notifications",
          state: "setting",
        }}
        className={props.url === "/settings/sms-notifications" ? "active" : ""}
      >
        SMS{" "}
      </Link>
    </div>
  );
}
