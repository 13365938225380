import React, { useState, useEffect } from "react";
import { makeStyles, Paper, TableContainer, Avatar } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { ApiService, ImgUrl } from "../services";
import { ShoppingBasket, Edit, Delete } from "@material-ui/icons";
import itemPlaceholder from "../assets/images/placeholders/p-item.png";
import CatIcon from "../assets/images/category.svg";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GPagination from "../gComponents/gPagination";
import { useHistory, Link } from "react-router-dom";
import ProductCreatePopup from "../components/productCreateForm";
import ProductEditPopup from "../components/productEditForm";
import QueryWith from "../Query";
import GSelectbox from "../gComponents/gSelectbox";
import { GSearch } from "../gComponents/gSearch";
import { StoreFilter } from "../components/storeFilterComponent";
import GSwitch from "../gComponents/gSwitch";
import useSettings from "../useSettings";
//import { browserHistory } from "react-router";

const columns = [
  { id: "title", label: "Title", minWidth: 130 },
  { id: "category", label: "Subcategory", minWidth: 150 },
  { id: "photo", label: "Photo", minWidth: 50, type: "image" },
  {
    id: "product_stock",
    label: "Stock",
    minWidth: 90,
    align: "center",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 60,
  },
  { id: "actions", label: "Actions", minWidth: 60, align: "CENTER" },
];

const statusFilters = [
  {
    title: "Active",
    value: "1",
  },
  {
    title: "Inactive",
    value: "0",
  },
];

const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
    marginTop: "10px",
  },
});

export default function Products(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  // const [provisible, setProvisible] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [productsPaginationData, setProductsPaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProductData, setSelectedProductData] = useState({});
  const [selectedProductId, setSelectedProductId] = useState("");
  const [pcvisible, setPcvisible] = useState(false);
  const [pevisible, setPevisible] = useState(false);
  const [productCatData, setProductCatData] = useState([]);
  const settings = useSettings(false);

  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    search: params.get("search") ? params.get("search") : "",
    category: params.get("category") ? params.get("category") : "",
    store_id: params.get("store_id") ? params.get("store_id") : "",
    status: params.get("status") ? params.get("status") : "",
  });

  console.log(filters);
  const getProducts = () => {
    var params = QueryWith(history.location, filters);
    if (params.length > 0) {
      history.push({
        pathname: "/products",
        state: "product",
        search: params[0],
      });
    }

    console.log(params);
    var hitUrl = "products";

    console.log(hitUrl);

    ApiService("GET", hitUrl, params[0])
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setProductsData(response.data.data);
          setProductsPaginationData(response.data);
        } else {
          console.log(response.data);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (!filters.store_id) {
      setProductCatData([]);
      setProductsData([]);
      setProductsPaginationData("");
      return;
    }
    ApiService("GET", "product/group?store_id=" + filters.store_id).then(
      (response) => {
        console.log("r" + JSON.stringify(response.data));
        if (response.status_code === 1) {
          setProductCatData(response.data);
          getProducts();
        } else {
          //openSnackbar(response.message);
          return;
        }

        //return response.data ? response.data : [];
      }
    );
  }, [filters.store_id]);

  useEffect(() => {
    getProducts();
  }, [filters.category, filters.search, filters.status, filters.page]);

  function addDefaultLogo(e) {
    e.target.src = itemPlaceholder;
  }
  const updateSubaCategory = (newVal, selectedProductId, index) => {
    var sendData = {
      categories: newVal,
    };
    console.log(sendData);
    ApiService("PUT", "products/" + selectedProductId, sendData).then(
      (response) => {
        console.log("r" + JSON.stringify(response.data));
        if (response.status_code === 1) {
          productsData[index] = response.data;
          setProductsData(productsData);
          //getProducts();
          openSnackbar(response.message);
        }
        return;
        //return response.data ? response.data : [];
      }
    );
  };

  const deleteProduct = () => {
    var hitUrl = "products/" + selectedProductId;
    ApiService("DELETE", hitUrl)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          openSnackbar(response.message, [5000]);
          getProducts();
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ page: NewPageNo, store_id: filters.store_id });
  };

  const handleToggleStatus = (newVal, id, ind) => {
    console.log(newVal);
    ApiService("PUT", "products/" + id, {
      status: newVal,
    })
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          productsData[ind].status = newVal;
          setProductsData(productsData);
          openSnackbar(response.message, [5000]);
        } else {
          console.log(response.data);
          openSnackbar(response.message);
          getProducts();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mainInnerView">
      <h1>
        <ShoppingBasket /> Products
        <StoreFilter
          value={filters.store_id}
          isStored={true}
          style={{
            marginLeft: "10px",
            float: "right",
            width: "300px",
          }}
          handleStoreChange={(value) => {
            console.log(value);
            if (value) {
              setFilters({ store_id: value });
            } else {
              setFilters({ store_id: value, category: "", search: "" });
            }
          }}
          handleClick={(v) =>
            setFilters({ store_id: v, category: "", search: "" })
          }
          // onBlur={() => {
          //   setFilters({
          //     store_id: filters.store_id,
          //     category: "",
          //     search: "",
          //   });
          // }}
        />
      </h1>

      {filters.store_id ? (
        <React.Fragment>
          <div
            style={{
              display: "inline-flex",
              width: "60%",
              verticalAlign: "top",
            }}
          >
            <GSearch
              placeholder="Search by title.."
              value={filters.search}
              handleChange={(val) => {
                setFilters({
                  search: val,
                  store_id: filters.store_id,
                });
                // getProducts();
              }}
            />

            <GSelectbox
              placeholder="Select Subcategory"
              defaultvalue={filters.category}
              data={productCatData}
              id="category_filter"
              option_key="title"
              option_value="id"
              optGroup={true}
              onChange={(newVal) => {
                setFilters({
                  category: newVal,
                  store_id: filters.store_id,
                  // status: filters.status,
                  // search: filters.search,
                });
                // getProducts();
              }}
              style={{
                border: "1px solid #e2e2e2",
                height: "40px",
                marginLeft: "10px",
              }}
            />
            <GSelectbox
              placeholder="All"
              defaultvalue={filters.status}
              data={statusFilters}
              id="item_status_filter"
              option_key="title"
              option_value="value"
              onChange={(newVal) => {
                setFilters({
                  status: newVal,
                  store_id: filters.store_id,
                  // search: filters.search,
                  // category: filters.category,
                });
              }}
              style={{
                border: "1px solid #e2e2e2",
                height: "40px",
                marginLeft: "10px",
                minWidth: "150px",
              }}
            />
          </div>
          <div
            style={{
              display: "inline-block",
              width: "40%",
              paddingTop: "10px",
            }}
          >
            <GButton
              variant="condensed"
              children="Add"
              onClick={() => {
                setPcvisible(true);
              }}
              style={{
                float: "right",
                marginTop: "-5px",
              }}
            >
              Add
            </GButton>
            {productsData.length !== 0 ? (
              <GPagination
                count={productsPaginationData.total}
                from={productsPaginationData.from}
                to={productsPaginationData.to}
                onChangeNextPage={() =>
                  handleChangePage(productsPaginationData.next_page_url)
                }
                onChangePrevPage={() =>
                  handleChangePage(productsPaginationData.prev_page_url)
                }
                rowsPerPage={productsPaginationData.per_page}
                prevDisabled={
                  productsPaginationData.prev_page_url ? false : true
                }
                nextDisabled={
                  productsPaginationData.next_page_url ? false : true
                }
              ></GPagination>
            ) : (
              ""
            )}
          </div>

          <Paper className={classes.root}>
            {!productsPaginationData ? (
              <GTableShimmer></GTableShimmer>
            ) : (
              <TableContainer className={classes.container}>
                {productsData.length === 0 ? (
                  <GEmpty></GEmpty>
                ) : (
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) =>
                          column.id === "product_stock" &&
                          settings.product_stock === "0" ? (
                            ""
                          ) : (
                            <TableHeader
                              key={column.id}
                              style={{
                                minWidth: column.minWidth,
                                textAlign: column.align,
                              }}
                            >
                              {column.label}
                            </TableHeader>
                          )
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {productsData.map((c, i) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={c.id}
                          >
                            <TableRowData
                              style={{
                                BorderBottom: "1px solid #f4f5f9",
                              }}
                            >
                              {c.title}
                            </TableRowData>
                            <TableRowData>
                              {/* {c.categories_title} */}

                              {productCatData ? (
                                <GSelectbox
                                  placeholder=""
                                  defaultvalue={c.categories || ""}
                                  display="block"
                                  data={productCatData}
                                  id={c.id}
                                  option_key="title"
                                  option_value="id"
                                  optGroup={true}
                                  onChange={(newVal) => {
                                    setSelectedProductId(c.id);
                                    updateSubaCategory(newVal, c.id, i);
                                  }}
                                  style={{
                                    border: "0",
                                    background: "#edf7fc",
                                    WebkitAppearance: "none",
                                    borderRadius: "20px",
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </TableRowData>
                            <TableRowData>
                              <img
                                alt={c.title}
                                src={ImgUrl("item") + "/" + c.thumb_photo}
                                onError={addDefaultLogo}
                                style={{
                                  height: "45px",
                                  width: "45px",
                                  borderRadius: "50%",
                                }}
                              />
                            </TableRowData>
                            {settings.product_stock === "1" ? (
                              <TableRowData
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {c.unlimited === 1
                                  ? "∞"
                                  : c.stock
                                  ? c.stock
                                  : "-"}
                              </TableRowData>
                            ) : (
                              "∞"
                            )}
                            <TableRowData>
                              <GSwitch
                                id={"item" + c.id}
                                uId={c.id}
                                currentValue={
                                  c.status === "true" ||
                                  c.status === "1" ||
                                  c.status === 1
                                    ? 1
                                    : 0
                                }
                                onSwitchChange={(newVal, id) =>
                                  handleToggleStatus(newVal, id, i)
                                }
                              />
                            </TableRowData>
                            <TableRowData
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <GButton
                                variant="linkable"
                                onClick={(e) => {
                                  setSelectedProductData(c);
                                  setPevisible(true);
                                }}
                              >
                                <Edit fontSize="small" />
                              </GButton>
                              <GButton
                                variant="linkable"
                                //  onClick={(e) =>
                                //       window.confirm(
                                //         "Are you sure you wish to delete this customer?"
                                //       ) && deleteCustomer(c.id)
                                // }

                                onClick={(e) => {
                                  setSelectedProductId(c.id);
                                  setDialogOpen(true);
                                }}
                              >
                                <Delete fontSize="small" />
                              </GButton>
                            </TableRowData>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            )}
          </Paper>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div
            style={{
              background: "#ebf6fa",
              display: "inline-flex",
              width: "100%",
              padding: "15px",
              boxSizing: "border-box",
              border: "1px solid #e2e2e2",
            }}
          >
            <img
              src={CatIcon}
              alt="catIcon"
              style={{ width: "30px", marginRight: "10px" }}
            />
            <div>
              <h4 style={{ margin: "0 0 5px", fontWeight: "450" }}>
                Categories for each store are managed seperately
              </h4>
              <p style={{ margin: "0", color: "#777", fontSize: "15px" }}>
                <Link
                  to={{ pathname: "/categories", state: "product" }}
                  style={{ textDecoration: "underline" }}
                >
                  Click here
                </Link>{" "}
                to manage categories
              </p>
            </div>
          </div>
          <Paper style={{ marginTop: "15px" }}>
            <GEmpty
              title="Products"
              subtitle="Please choose the store to fetch the products list"
            />
          </Paper>
        </React.Fragment>
      )}

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Product"
          text="Are you sure you want to delete this product?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteProduct();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      {pcvisible === true ? (
        <ProductCreatePopup
          pcvisible={pcvisible}
          setPcvisible={setPcvisible}
          storeId={filters.store_id}
          onSubmit={getProducts}
          isStockShow={settings.product_stock}
          settings={settings}
        />
      ) : (
        ""
      )}

      {pevisible === true ? (
        <ProductEditPopup
          pevisible={pevisible}
          setPevisible={setPevisible}
          storeId={filters.store_id}
          data={selectedProductData}
          onSubmit={getProducts}
          isStockShow={settings.product_stock}
        />
      ) : (
        ""
      )}
    </div>
  );
}
