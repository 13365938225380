import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { Breadcrumbs, Typography, Link } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { ApiService} from "../services";
import { useSnackbar } from "react-simple-snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Ul = styled.ul`
  display: inline-flex;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 0;
  list-style-type: none;
`;
const Li = styled.li`
  background: #d3d9e5;
  color: #747d8c;
  padding: 7px 0 7px 25px;
  text-align: center;
  font-size: 14px;
  position: relative;
  display: block;
  float: left;
  &:not(:last-child):before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid white;
    position: absolute;
    top: 50%;
    margin-top: -16px;
    margin-left: 1px;
    left: 100%;
    z-index: 1;
  }
  &:not(:last-child):after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    position: absolute;
    top: 50%;
    margin-top: -16px;
    left: 100%;
    z-index: 2;
    border-left: 13px solid #d3d9e5;
  }
  &:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  &:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &.Completed,
  &.colored {
    background: #f08c37;
    color: #fff;
  }
  &.Completed:not(:last-child):after,
  &.colored:after {
    border-left: 14px solid #f08c37;
  }
  &.Cancelled {
    background: #e88689;
    color: #fff;
  }
  &.Cancelled:not(:last-child):after {
    border-left: 14px solid #e88689;
  }
`;




export default function CustomSeparator(props) {
  const [openSnackbar] = useSnackbar();
  const [status, setStatus]=useState("");
  console.log(props);
  const classes = useStyles();

  function handleClick(s) {
    // event.preventDefault();
    if(s.identifier==="accepted"){
      ApiService("POST", "order/accept/"+props.orderId)
      .then((response) => {
        openSnackbar(response.status_text+"!"+response.message)
        setStatus(s.identifier);
        window.location.reload();
        // console.log(response);.
        
      })
      .catch((error) => {
        console.log(error);
      });
    }
    else if (s.identifier==="completed"){
      ApiService("POST", "order/complete/"+props.orderId)
      .then((response) => {
        openSnackbar(response.status_text+"!"+response.message)
        setStatus(s.identifier);
        window.location.reload();
        // console.log(response);
        
      })
      .catch((error) => {
        console.log(error);
      });
    }
    else if (s.identifier==="ready"){
      ApiService("POST", "order/ready/"+props.orderId)
      .then((response) => {
        openSnackbar(response.status_text+"!"+response.message)
        setStatus(s.identifier);
        window.location.reload();

        // console.log(response);
        
      })
      .catch((error) => {
        console.log(error);
      });
    }else {
      ApiService("POST", "order/"+s.identifier+"/"+props.orderId)
      .then((response) => {
        openSnackbar(response.status_text+"!"+response.message)
        setStatus(s.identifier);
        // console.log(response);
        
      })
      .catch((error) => {
        console.log(error);
      });
    }
    
  }

  function handleStatus(s){
    if(window.confirm("Are you sure? You want to " + s.title +" this order." )){
      handleClick(s)
  }
}

  
    // const p = ( ? (s.identifier==="completed") ? ("complete") : ("accept") : (s.identifier)
    // const url =  `order/${p}/${props.orderId}`
    console.info("You clicked a breadcrumb.",props.orderId);
    
      

      // useEffect(() => {
      //   // window.location.reload()
      // }, []);
      
  return ( 
    <div>
      {props.class !== "Failed" && props.class !== "Cancelled" ? (
        <Ul>
          {props.statusList
            .filter(
              (status) =>
                status.identifier !== "failed" &&
                status.identifier !== "cancelled"
            )
            .map((s) => {
              return (
                <Li
                  style={{ width: "18%",cursor:"pointer" }}
                  onClick={()=>handleStatus(s)}
                  key={s.identifier}
                  className={(props.class, s.color)}
                >
                  {" "}
                  {s.title}
                </Li>
              );
            })}
        </Ul>
      ) : (
        <Ul>
          {props.statusList
            .filter(
              (status) =>
                status.identifier !== "completed" &&
                status.identifier !== "failed"
            )
            .map((s) => {
              return (
                <Li
                  style={{ width: "18%" }}
                  key={s.identifier}
                  className={props.class}
                >
                  {s.title}
                </Li>
              );
            })}
        </Ul>
      )}
    </div>
  );
}
