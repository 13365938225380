import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { ApiService } from "../services";
import styled from "styled-components";
import NotificationSmsForm from "./notificationsSmsForm";
import NotificationEmailForm from "./notificationsEmailForm";
import NotificationPushForm from "./notificationsPushForm";
const Tab = styled.button`
  font-size: 15px;
  padding: 8px 50px;
  cursor: pointer;
  opacity: 0.6;
  background: white;
  border: 0;
  border-radius: 0px;
  outline: 0;
  ${({ active }) =>
    active &&
    `
    border-bottom: 2px solid #f08c37;
    opacity: 1;
  `}
`;
const ButtonGroup = styled.div`
  display: flex;
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 15px;
`;
const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  option: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#888",
    width: "40px",
    height: "40px",
    fontSize: "18px",
    borderRadius: "5px",
    cursor: "pointer",
    //transition: 'background 0.3s',
    transitionProperty: "background-color, color",
    transitionDuration: "300ms, 300ms",
    "&:hover": {
      backgroundColor: "#333",
      color: "#bbb",
      fontWeight: "500",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

// const tabTypes = [
//   {
//     title: "SMS",
//   },
//   {
//     title: "EMAIL",
//   },
//   {
//     title: "PUSH",
//   },
// ];
const tabTypes = ["SMS", "EMAIL", "PUSH"];

function NotificationsForm(props) {
  console.log(props);
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(tabTypes[0]);
  const [selectedNofify, setSelectedNotify] = useState(props.data);
  const [notificationVariables, setNotificationVariables] = useState([]);

  console.log(tabTypes);

  const getNotificationVariables = () => {
    ApiService(
      "GET",
      "notification-variables?event_type=" + props.data.module
    ).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        setNotificationVariables(response.data);
      } else {
        return;
      }
    });
  };
  useEffect(() => {
    setSelectedNotify(props.data);
    getNotificationVariables();
  }, [props, selectedTab]);

  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setNfvisible(false);
  };

  const handleSubmit = () => {
    props.setNfvisible(false);
    props.onSubmit();
  };
  if (props.nfvisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.nfvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className="modal"
          style={{ padding: "20px", minWidth: "550px", width: "50vw" }}
        >
          <h3>{selectedNofify.title}</h3>

          <ButtonGroup>
            {tabTypes.map((type) => (
              <Tab
                key={type}
                active={selectedTab === type}
                onClick={() => {
                  setSelectedTab("");
                  setSelectedTab(type);
                }}
              >
                {type}
              </Tab>
            ))}
          </ButtonGroup>

          {selectedTab === "SMS" ? (
            <NotificationSmsForm
              module={props.data.module}
              eventIdentifier={selectedNofify.identifier}
              userType={props.userType}
              data={selectedNofify.sms}
              onSubmit={handleSubmit}
              variables={notificationVariables}
              notificationType={props.notificationType}
            />
          ) : selectedTab === "EMAIL" ? (
            <NotificationEmailForm
              module={props.data.module}
              eventIdentifier={selectedNofify.identifier}
              userType={props.userType}
              data={selectedNofify.email}
              onSubmit={handleSubmit}
              variables={notificationVariables} notificationType={props.notificationType}
            />
          ) : (
                <NotificationPushForm
                  module={props.data.module}
                  eventIdentifier={selectedNofify.identifier}
                  userType={props.userType}
                  data={selectedNofify.push}
                  onSubmit={handleSubmit}
                  variables={notificationVariables} notificationType={props.notificationType}
                />
              )}
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
export default NotificationsForm;
