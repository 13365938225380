import React, { useState, useEffect } from "react";
import { ApiService, addDefaultSrc } from "../services";
import GEditable from "../gComponents/gEditable";
import GImageEditor from "../gComponents/gImageEditor";
import { ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { GImagePicker } from "../gComponents/gImagePicker";

export default function BsicSettings(props) {
  const [openSnackbar] = useSnackbar();
  const [linksData, setLinksData] = useState({
    currency_symbol: {},
    admin_phone: {},
    admin_email: {},
    admin_logo: {},
    email_logo: {},
    project_name: {},
    country_code: {},
    invitation_url: {},
    admin_whatsapp: {},
    distance_unit: {},
  });
  const [pSettings, setPSettings] = useState({
    invite_string: {},
  });
  //const { currency_symbol, admin_logo, email_logo } = useState({});

  useEffect(() => {
    getBasicSettings();
    getPromotionSettings();
  }, []);

  const getBasicSettings = () => {
    ApiService("GET", "setting/basic")
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setLinksData(response.data);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPromotionSettings = () => {
    ApiService("GET", "setting/promotion")
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setPSettings(response.data);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function updateField(newValue, selectedFieldId, selectedKey) {
    console.log(newValue);
    console.log(selectedFieldId);
    // return;
    var send_data = {
      key_value: newValue,
    };
    var hitUrl = "setting/" + selectedFieldId;
    ApiService("PUT", hitUrl, send_data)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          //getBasicSettings();
          linksData[selectedKey] = response.data;
          setLinksData(linksData);
          getPromotionSettings();
          openSnackbar(response.message);
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView">
      <h2>Basic Settings</h2>

      <div className="listPaper">
        <div
          style={{
            display: "inline-block",
            width: "30%",
            verticalAlign: "top",
            boxSizing: "border-box",
            padding: "20px",
          }}
        >
          <div>
            <label style={{ display: "block", marginBottom: "10px" }}>
              Project Logo
            </label>
            <div
              style={{
                background: "#f4f5f9",
                padding: "10px",
                display: "inline-block",
              }}
            >
              <GImageEditor
                image={ImgUrl("logo") + "/" + linksData.admin_logo.key_value}
                onSubmit={(newVal, fieldId) => {
                  updateField(newVal, fieldId, "admin_logo");
                }}
                id={linksData.admin_logo.id}
                type="logo"
                isPreviewImg={true}
              />
            </div>
          </div>
          <hr style={{ margin: "20px 0" }} />
          <div>
            <label style={{ display: "block", marginBottom: "10px" }}>
              Email Logo
            </label>
            <div
              style={{
                background: "#f4f5f9",
                padding: "10px",
                display: "inline-block",
              }}
            >
              {/* <GImageEditor
                image={ImgUrl("logo") + "/" + linksData.email_logo.key_value}
                onSubmit={(newVal, fieldId) => {
                  updateField(newVal, fieldId, "email_logo");
                }}
                id={linksData.email_logo.id}
                type="logo"
                isPreviewImg={true}
              /> */}

              {linksData.email_logo.key_value ? (
                <GImagePicker
                  type="logo"
                  alt={linksData.project_name.key_value}
                  height={100}
                  width={100}
                  radius={1}
                  //editor
                  editorProps={{
                    height: 200,
                    width: 200,
                    radius: 100,
                  }}
                  serverProps={{
                    size: 200,
                  }}
                  onSuccess={(v) => {
                    console.log(v);
                    updateField(v.image, linksData.email_logo.id, "email_logo");
                  }}
                  isPreviewImg={true}
                  default={
                    ImgUrl("logo") + "/" + linksData.email_logo.key_value
                  }
                />
              ) : (
                  ""
                )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "inline-block",
            width: "70%",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <GEditable
            key={linksData.currency_symbol.id}
            type="text"
            title={true}
            editable={false}
            defaultValue={linksData.currency_symbol.key_value}
            className={props.display}
            placeholder={linksData.currency_symbol.key_display_title}
            id={linksData.currency_symbol.id}
            onSubmit={(newVal, fieldId) => {
              updateField(newVal, fieldId, "currency_symbol");
            }}
            style={{ margin: "10px 0 20px" }}
          />
          <GEditable
            key={linksData.admin_phone.id}
            type="number"
            title={true}
            editable={false}
            defaultValue={linksData.admin_phone.key_value}
            className={props.display}
            placeholder={linksData.admin_phone.key_display_title}
            id={linksData.admin_phone.id}
            onSubmit={(newVal, fieldId) => {
              updateField(newVal, fieldId, "admin_phone");
            }}
            style={{ margin: "10px 0 20px" }}
          />
          <GEditable
            key={linksData.admin_email.id}
            type="email"
            title={true}
            editable={false}
            required={true}
            defaultValue={linksData.admin_email.key_value}
            className={props.display}
            placeholder={linksData.admin_email.key_display_title}
            id={linksData.admin_email.id}
            onSubmit={(newVal, fieldId) => {
              updateField(newVal, fieldId, "admin_email");
            }}
            style={{ margin: "10px 0 20px" }}
          />
          <GEditable
            key={linksData.project_name.id}
            type="text"
            title={true}
            editable={false}
            defaultValue={linksData.project_name.key_value}
            className={props.display}
            placeholder={linksData.project_name.key_display_title}
            id={linksData.project_name.id}
            onSubmit={(newVal, fieldId) => {
              linksData.project_name.key_value = newVal;
              setLinksData(linksData);
              updateField(newVal, fieldId, "project_name");
            }}
            style={{ margin: "10px 0 20px" }}
          />
          <GEditable
            key={linksData.country_code.id}
            type="number"
            title={true}
            editable={false}
            defaultValue={linksData.country_code.key_value}
            className={props.display}
            placeholder={linksData.country_code.key_display_title}
            id={linksData.country_code.id}
            onSubmit={(newVal, fieldId) => {
              updateField(newVal, fieldId, "country_code");
            }}
            style={{ margin: "10px 0 20px" }}
          />
          <GEditable
            key={linksData.invitation_url.id}
            type="text"
            title={true}
            editable={false}
            defaultValue={linksData.invitation_url.key_value}
            className={props.display}
            placeholder={linksData.invitation_url.key_display_title}
            id={linksData.invitation_url.id}
            onSubmit={(newVal, fieldId) => {
              updateField(newVal, fieldId, "country_code");
            }}
            style={{ margin: "10px 0 20px" }}
          />
          <GEditable
            key={linksData.admin_whatsapp.id}
            type="number"
            title={true}
            editable={false}
            defaultValue={linksData.admin_whatsapp.key_value}
            className={props.display}
            placeholder={linksData.admin_whatsapp.key_display_title}
            id={linksData.admin_whatsapp.id}
            onSubmit={(newVal, fieldId) => {
              updateField(newVal, fieldId, "admin_whatsapp");
            }}
            style={{ margin: "10px 0 20px" }}
          />
          <GEditable
            key={linksData.distance_unit.id}
            type="alpha"
            title={true}
            editable={false}
            defaultValue={linksData.distance_unit.key_value}
            className={props.display}
            placeholder={linksData.distance_unit.key_display_title}
            id={linksData.distance_unit.id}
            onSubmit={(newVal, fieldId) => {
              updateField(newVal, fieldId, "distance_unit");
            }}
            style={{ margin: "10px 0 20px" }}
          />
        </div>
        <hr />
        <br />
        <div>
          <label>{pSettings.invite_string.key_display_title}</label>
          <p
            style={{
              color: "#777",
              fontStyle: "italic",
              margin: "10px 0 0",
              fontSize: "14px",
            }}
          >
            Use [referral_code] for Referral Code{" "}
          </p>

          <GEditable
            key={pSettings.invite_string.id}
            type="textarea"
            title={false}
            editable={false}
            defaultValue={pSettings.invite_string.key_value}
            className={props.display}
            id={pSettings.invite_string.id}
            onSubmit={updateField}
            style={{ margin: "10px 0 20px" }}
          />
        </div>
      </div>
    </div>
  );
}
