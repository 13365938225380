import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { Breadcrumbs } from "@material-ui/core";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles((theme) => ({
  root: {
    float: 'right',
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Ul = styled.ul`
  display: inline-flex;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 0;
  list-style-type: none;margin:0px;padding-right:10px;
`;
const Li = styled.li`

 color: #a4a5a9;
    padding: 7px 3px;
    text-align: center;
    font-size:14px;
    line-height: 20px;
    text-transform:capitalize;
    & a{

 color: #a4a5a9;
    }
    & .MuiSvgIcon-fontSizeSmall{

    }
     & p{
       margin: 0;
    font-size: 15px;color:#555
     }
  
  /* &:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  &:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  } */

`;

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function CustomSeparator(props) {
  console.log(props);
  const classes = useStyles();


  return (
    <div className={classes.root}>
      <Ul>
        <Li><Link color="inherit" to="/dashboard" >
          Home
      </Link></Li>
        <Li><NavigateNextIcon fontSize="small" /></Li>
        {props.links.map((s) => {
          return (
            <React.Fragment>
              <Li><Link color="inherit" to={{ pathname: s.link, state: s.state }}>
                {s.title}
              </Link></Li>
              <Li><NavigateNextIcon fontSize="small" /></Li>
            </React.Fragment>
          );
        })}
        <Li><p color="textPrimary">{props.currentPage}</p></Li>
      </Ul>

    </div>
  )
}
