import React, { useEffect, useState } from "react";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ContactQueries from "./ContactQueries";
import LeadQueries from "./LeadQueries";
import "./queries.css";

export default function Queries() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [filter, setFilter] = useState("");

  // Function To change Tabs *******
const handleChangeTab = (event, newValue) => {
  setSelectedTab(newValue);
};

// useEffect(() => {
//   setFilter(filter)
// }, [selectedTab]);


  return (
    <div className="rightInnerView">
    <h2>Queries</h2>
    <div className="heading">
    <Tabs value={selectedTab} indicatorColor="primary" onChange={handleChangeTab}>
          <Tab label="Contact Form" />
          <Tab label="Leads" />
        </Tabs>
   { selectedTab === 0 && <span>
            
            <select name="filter" onChange={(e)=>setFilter(e.target.value)}>
            <option selected={filter===""} value="">Filter</option>
            <option selected={filter==="customer"} value="customer">Customer</option>
            <option selected={filter==="store"} value="store">Store</option>
            <option selected={filter==="partner"} value="partner">Driver</option>
            <option selected={filter==="other"} value="other">Other</option>
            </select>
        </span>}
    </div>
      {selectedTab === 0 && <ContactQueries filter={filter} />}
      {selectedTab === 1 && <LeadQueries />}
      
    </div>
  );
}
