import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var buttonStyle = {
  background: "#f08c37",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "100%",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  marginTop: "30px",
  fontWeight: "600",
};

export default function CreateCity(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [switchStatusVal, setSwitchStatusVal] = useState(
    props.data.tax_applied === "1" ? true : false
  );

  const initialValues = {
    zipcode: !props.isEmpty ? props.data.zipcode : "",
    tax_applied: !props.isEmpty ? props.data.tax_applied : "",
  };

  const onSubmit = (values) => {
    console.log(values);
    values.tax_applied = switchStatusVal === true ? "1" : "0";

    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "zipcodes/" + props.data.id : "zipcodes";
    ApiService(hitMethod, hitUrl, values).then((response) => {
      console.log("r" + response.status_code);
      openSnackbar(response.message);
      if (response.status_code === 0) {
        return;
      }
      props.setZipcodefvisible(false);
      props.onSubmit(response);
    });
  };

  const validationSchema = Yup.object({
    zipcode: Yup.string().required("Required"),
  });

  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setZipcodefvisible(false);
  };

  if (props.zipcodefvisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.zipcodefvisible}
        onClick={handleClose}
      >
        <Paper
          className={`modalRight
              ${props.zipcodefvisible === true ? "fade" : ""}`}
          style={{ padding: "20px" }}
        >
          <h3>{!props.isEmpty ? "Edit" : "Create"} City</h3>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <FormikControl
                  control="input"
                  type="text"
                  label="Zipcode"
                  name="zipcode"
                  maxLength={10}
                  onChange={(event) => {
                    const re = /^[0-9\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("zipcode", event.target.value);
                    }
                  }}
                />

                <FormikControl
                  control="input"
                  type="text"
                  label=" Tax Applicable"
                  name="tax_applied"
                  onChange={(event) => {
                    const re = /^[0-9.\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("tax_applied", event.target.value);
                    }
                  }}
                />

                <br />
                <button style={buttonStyle} type="submit">
                  {!props.isEmpty ? "Update" : "Create"}
                </button>
              </Form>
            )}
          </Formik>
        </Paper>
      </Backdrop>
    </div>
  );
}
