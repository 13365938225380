import React from "react";
import { makeStyles, Paper, Slide } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import GButton from "../gComponents/gButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function CreateCustomer(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const location = useLocation();

  const initialValues = {
    first_name: props.data ? props.data.first_name : "",
    last_name: props.data ? props.data.last_name : "",
    email: props.data ? props.data.email : "",
    phone: props.data ? props.data.phone : "",
    password: ""
  };

  const onSubmit = (values) => {
    if (props.data) {
      if (!values.password) {
        delete values.password;
      }
    }
    console.log(values);

    var hitMethod = props.data ? "PUT" : "POST";
    var hitUrl = props.data ? "customers/" + props.data.id : "customers";
    ApiService(hitMethod, hitUrl, values).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        if (props.onSubmit) {
          props.onSubmit();
          openSnackbar(response.message);
        }
        if (!props.data) {
          openSnackbar(response.message);
          if (location.pathname === "/customers") {
            window.location.reload(false);
          } else {
            window.location = "/customers";
          }
        }

        props.setCcvisible(false);
      } else {
        return;
      }
    });
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid Email Address").required("Required"),
    phone: Yup.string()
      .matches(
        /^([+ ]*)([\d{1,2}]*)(\d{5}([- ]*)\d{5})$/g,
        "Invalid phone number"
      )
      .min(9, "Min length is 10")
      .max(14, "Too Long!")
      .required("Required"),
    password: props.data
      ? ""
      : Yup.string()
        .required("Required")
        .min(6, "Password is too short - should be 6 chars minimum"),
  });

  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setCcvisible(false);
  };

  if (props.ccvisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.ccvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Slide direction="right" in={true}>
          <Paper
            className={`modalRight
              ${props.ccvisible === true ? "fade" : ""}`}
            style={{ padding: "20px" }}
          >
            <h3>{props.data ? "Edit" : "Create"} Customer</h3>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              validateOnBlur={false}
            >
              {({ values, isValid, setFieldValue }) => (
                <Form>
                  <FormikControl
                    control="input"
                    type="text"
                    label="First Name"
                    name="first_name"
                  // onChange={(event) => {
                  //   const re = /[^A-Za-z]/gi;
                  //   if (event.target.value && !re.test(event.target.value)) {
                  //     return;
                  //   } else {
                  //     setFieldValue("first_name", event.target.value);
                  //   }
                  // }}
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    label="Last Name"
                    name="last_name"
                  />
                  <FormikControl
                    control="input"
                    type="email"
                    label="Email ID"
                    name="email"
                  />

                  <FormikControl
                    control="input"
                    type="text"
                    label="Password"
                    name="password"
                  />
                  <FormikControl
                    control="numberInput"
                    type="text"
                    label="Phone"
                    name="phone"
                    onChange={(event) => {
                      const re = /^[0-9.+\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("phone", event.target.value);
                      }
                    }}
                  />
                  <br />
                  <GButton
                    variant="condensed"
                    disabled={!isValid}
                    style={{
                      margin: "0 15px",
                      width: "95%",
                    }}
                    children={props.data ? "Update" : "Create"}
                    type="submit"
                  />
                  aaa
                </Form>
              )}
            </Formik>
          </Paper>
        </Slide>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
