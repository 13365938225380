import React, { useEffect, useState } from "react";
import { makeStyles, Paper, Backdrop } from "@material-ui/core";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import GPolygon from "../leaflet/gPolygon";
import GCircle from "../leaflet/gCircle";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const geoTypes = [
  { title: "Circle", value: "circle" },
  { title: "Polygon", value: "polygon" },
];

export default function GeofencePopup(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [citiesData, setCities] = useState([]);
  const [selectedPolygons, setSelectedPolygons] = useState([]);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [selectedRdius, setSelectedRadius] = useState({
    radius: "",
    lat: "",
    lng: "",
  });

  useEffect(() => {
    ApiService("GET", "cities").then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        setCities(response.data);
      }
    });
  }, [props]);

  const initialValues = {
    title: !props.isEmpty ? props.data.title : "",
    city_id: props.selectedCity || "",
    type: !props.isEmpty ? props.data.type : "",
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    console.log(values);

    if (values.type === "polygon") {
      values.polygon = selectedPolygons;
      if (values.polygon.length === 0) {
        openSnackbar("Polygon Area is required");
        setIsBtnDisabled(false);
        return;
      }
    }
    if (values.type === "circle") {
      values.circle = selectedRdius;
    }
    console.log(JSON.stringify(values));
    // return;

    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "geofences/" + props.data.id : "geofences";
    ApiService(hitMethod, hitUrl, values).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        props.setGeofvisible(false);
        openSnackbar(response.message);
        props.onSubmit(response);
        setIsBtnDisabled(false);
      } else {
        setIsBtnDisabled(false);
        openSnackbar(response.message);
        return;
      }
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
  });

  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setGeofvisible(false);
  };

  if (props.geofvisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.geofvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.geofvisible === true ? "fade" : ""}`}
          style={{
            width: "50%",
            maxWidth: "900px",
            minWidth: "700px",
            padding: "20px",
          }}
        >
          <h3>{!props.isEmpty ? "Edit" : "Create"} Geofence</h3>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values }) => (
              <Form>
                <GButton
                  type="submit"
                  disabled={isBtnDisabled}
                  variant="condensed"
                  children={!props.isEmpty ? "Update" : "Create"}
                  style={{
                    margin: "0 15px",
                    width: "150px",
                    float: "right",
                    marginTop: "-50px",
                  }}
                />

                <FormikControl
                  control="input"
                  type="text"
                  label="Title"
                  name="title"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />
                {citiesData ? (
                  <FormikControl
                    control="select"
                    as="select"
                    label="City"
                    placeholder="Select city"
                    name="city_id"
                    options={citiesData}
                    key_title="title"
                    key_value="id"
                    divstyle={{
                      display: "inline-block",
                      width: "50%",
                      boxSizing: "border-box",
                      verticalAlign: "top",
                    }}
                  />
                ) : (
                  ""
                )}

                <FormikControl
                  control="select"
                  as="select"
                  label="Type"
                  name="type"
                  placeholder="Select type"
                  options={geoTypes}
                  key_title="title"
                  key_value="value"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />

                <div style={{ margin: "15px" }}>
                  {values.type === "polygon" ? (
                    // <GMaping
                    //   getGeoJSON={(json) => {
                    //     console.log(json);
                    //     setSelectedPolygons([
                    //       ...selectedPolygons,
                    //       json.geometry.coordinates[0],
                    //     ]);
                    //   }}
                    // />
                    <React.Fragment>
                      <label>Area</label>

                      <GPolygon
                        getGeoJSON={(jsonArray) => {
                          console.log(jsonArray);
                          setSelectedPolygons(jsonArray);
                        }}
                      />
                    </React.Fragment>
                  ) : values.type === "circle" ? (
                    <React.Fragment>
                      <label>Radius</label>
                      {/* <GMapingCircle
                        getRadiusValue={(rad, lat, lng) =>
                          setSelectedRadius({ radius: rad, lat: lat, lng: lng })
                        }
                      /> */}
                      <GCircle
                        getRadiusValue={(json) => {
                          console.log(json);
                          setSelectedRadius(json);
                        }}
                      />
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
