import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import GEditable from "../gComponents/gEditable";
import { useSnackbar } from "react-simple-snackbar";
import GSwitch from "../gComponents/gSwitch";
import { DIV } from "../gComponents/gWalletStyles";
import { Info } from "@material-ui/icons";

import TooltipComponent from "../gComponents/gTooltip";

export default function WalletSettings(props) {
  const [openSnackbar] = useSnackbar();
  const [walletData, setWalletsData] = useState({
    earn_order_max_coin_limits: {},
    earn_task_max_coin_limits: {},
    invite_text: {},
    order_cashback: {},
    order_complete_points_percentage: {},
    point_value: {},
    max_wallet_spend_limt: {}, // new key added
    referral_rule: {},
    sender_referral_points: {},
    spend_order_points_amount_percentage: {},
    spend_task_points_amount_percentage: {},
    task_cashback: {},
    task_complete_points_percentage: {},
    user_referral_points: {},
    wallet_agent: {},
    wallet_refund: {},
    wallet_store: {},
    withdraw_agent: {},
    withdraw_store: {},
  });

  useEffect(() => {
    getWalletSettings();
  }, []);

  const getWalletSettings = () => {
    var hitUrl = "setting/wallet";

    console.log("getwallet settings: "+hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setWalletsData(response.data);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function toggleValue(newValue, valueId, keyTitle) {
    console.log(newValue + "--" + valueId);
    updateField(newValue, valueId, keyTitle);
  }

  function updateField(newValue, selectedFieldId, keyTitle) {
    console.log(newValue);
    console.log(selectedFieldId);
    // return;
    var send_data = {
      key_value: newValue,
    };
    var hitUrl = "setting/" + selectedFieldId;
    ApiService("PUT", hitUrl, send_data)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          if (keyTitle) {
            walletData[keyTitle].key_value = newValue;
            setWalletsData(walletData);
          }
        } else {
          console.log(response.data);
          getWalletSettings();
        }

        openSnackbar(response.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView">
      <h2>Wallet Settings</h2>

      <div className="listPaper">
        <div>
          <h4>Referral Points</h4>
          <DIV>
            <h4>Friend Referral</h4>
            <div style={{ width: "50%" }}>
              <GEditable
                type="text"
                editable={false}
                id={walletData.sender_referral_points.id}
                defaultValue={walletData.sender_referral_points.key_value}
                onSubmit={(newVal, id) =>
                  updateField(newVal, id, "sender_referral_points")
                }
                style={{ margin: "0px" }}
                inputStyle={{ minWidth: "80px" }}
              />
            </div>
          </DIV>
          <DIV>
            <h4>New User</h4>
            <div style={{ width: "50%" }}>
              <GEditable
                type="text"
                editable={false}
                id={walletData.user_referral_points.id}
                defaultValue={walletData.user_referral_points.key_value}
                onSubmit={(newVal, id) =>
                  updateField(newVal, id, "user_referral_points")
                }
                style={{ margin: "0px" }}
                inputStyle={{ minWidth: "80px" }}
              />
            </div>
          </DIV>
          <DIV>
            <h4>
              Restrict on Order Completion
              <TooltipComponent
                title="
If turned on, then both the users (Friend Referral & New User) will get points only after the New User will complete its 1st order successfully.
"
                position="bottom"
                id={walletData.referral_rule.id}
              >
                <Info fontSize="small" />
              </TooltipComponent>
            </h4>
            <div style={{ width: "50%" }}>
              <GSwitch
                id={walletData.referral_rule.id}
                uId={walletData.referral_rule.id}
                currentValue={walletData.referral_rule.key_value}
                style={{ margin: "10px 0" }}
                onSwitchChange={(newVal, id) =>
                  toggleValue(newVal, id, "referral_rule")
                }
              />
            </div>
          </DIV>

          <hr />
          <h4>
            Cashback Points{" "}
            <GSwitch
              id={walletData.order_cashback.id}
              uId={walletData.order_cashback.id}
              currentValue={walletData.order_cashback.key_value}
              style={{
                display: "inline-block",
                paddingLeft: "20px",
                verticalAlign: "middle",
              }}
              onSwitchChange={(newVal, id) =>
                toggleValue(newVal, id, "order_cashback")
              }
            />
          </h4>
          <DIV>
            <h4>Cashback per Order</h4>
            <div style={{ width: "50%" }}>
              <GEditable
                type="text"
                editable={false}
                id={walletData.order_complete_points_percentage.id}
                defaultValue={
                  walletData.order_complete_points_percentage.key_value
                }
                onSubmit={(newVal, id) =>
                  updateField(newVal, id, "order_complete_points_percentage")
                }
                style={{ margin: "0px" }}
                inputStyle={{ minWidth: "80px" }}
              />
            </div>
          </DIV>
          <DIV>
            <h4>Maximum Points earned per Order</h4>
            <div style={{ width: "50%" }}>
              <GEditable
                type="text"
                editable={false}
                id={walletData.earn_order_max_coin_limits.id}
                defaultValue={walletData.earn_order_max_coin_limits.key_value}
                onSubmit={(newVal, id) =>
                  updateField(newVal, id, "earn_order_max_coin_limits")
                }
                style={{ margin: "0px" }}
                inputStyle={{ minWidth: "80px" }}
              />
            </div>
          </DIV>

          <hr />
          <h4>Discount</h4>
          <DIV>
            <h4>Maximum discount</h4>
            <div style={{ width: "50%" }}>
              <GEditable
                type="text"
                editable={false}
                id={walletData.spend_order_points_amount_percentage.id}
                defaultValue={
                  walletData.spend_order_points_amount_percentage.key_value
                }
                onSubmit={(newVal, id) =>
                  updateField(
                    newVal,
                    id,
                    "spend_order_points_amount_percentage"
                  )
                }
                style={{ margin: "0px" }}
                inputStyle={{ minWidth: "80px" }}
              />
            </div>
          </DIV>
          <DIV>
            <h4>Points Conversion Rate</h4>
            <div style={{ width: "50%" }}>
              <GEditable
                type="text"
                editable={false}
                id={walletData.point_value.id}
                defaultValue={walletData.point_value.key_value}
                onSubmit={(newVal, id) =>
                  updateField(newVal, id, "point_value")
                }
                style={{ margin: "0px" }}
                inputStyle={{ minWidth: "80px" }}
              />
            </div>
          </DIV>
          <DIV>
            <h4>Maximum Wallet Spent Limit</h4>
            <div style={{ width: "50%" }}>
              <GEditable
                type="text"
                editable={false}
                id={walletData.max_wallet_spend_limt.id}
                defaultValue={walletData.max_wallet_spend_limt.key_value}
                onSubmit={(newVal, id) =>
                  updateField(newVal, id, "max_wallet_spend_limt")
                }
                style={{ margin: "0px" }}
                inputStyle={{ minWidth: "80px" }}
              />
            </div>
          </DIV>

          <hr />

          <h4>
            Store Wallet
            <GSwitch
              id={walletData.wallet_store.id}
              uId={walletData.wallet_store.id}
              currentValue={walletData.wallet_store.key_value}
              style={{
                display: "inline-block",
                paddingLeft: "20px",
                verticalAlign: "middle",
              }}
              onSwitchChange={(newVal, id) =>
                updateField(newVal, id, "wallet_store")
              }
            />
          </h4>
          {walletData.wallet_store.key_value === "1" ? (
            <DIV>
              <h4>Allow Withdraw</h4>
              <div style={{ width: "50%" }}>
                <GSwitch
                  id={walletData.withdraw_store.id}
                  uId={walletData.withdraw_store.id}
                  currentValue={walletData.withdraw_store.key_value}
                  style={{ margin: "10px 0" }}
                  onSwitchChange={(newVal, id) =>
                    updateField(newVal, id, "withdraw_store")
                  }
                />
              </div>
            </DIV>
          ) : (
            ""
          )}

          <hr />
          <h4>
            Driver Wallet
            <GSwitch
              id={walletData.wallet_agent.id}
              uId={walletData.wallet_agent.id}
              currentValue={walletData.wallet_agent.key_value}
              style={{
                display: "inline-block",
                paddingLeft: "20px",
                verticalAlign: "middle",
              }}
              onSwitchChange={(newVal, id) =>
                updateField(newVal, id, "wallet_agent")
              }
            />
          </h4>

          {walletData.wallet_agent.key_value === "1" ? (
            <DIV>
              <h4>Allow Withdraw</h4>
              <div style={{ width: "50%" }}>
                <GSwitch
                  id={walletData.withdraw_agent.id}
                  uId={walletData.withdraw_agent.id}
                  currentValue={walletData.withdraw_agent.key_value}
                  style={{ margin: "10px 0" }}
                  onSwitchChange={(newVal, id) =>
                    updateField(newVal, id, "withdraw_agent")
                  }
                />
              </div>
            </DIV>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
