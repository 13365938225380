import React from "react";
import styled from "styled-components";
import { CheckCircle } from "@material-ui/icons";

const TaskStatusDiv = styled.div`
  position: relative;

  ul {
    list-style-type: none;
    padding: 0px;
    margin: 25px 0;

    li {
      display: inline-block;
      padding: 0 25px 0 0px;
      min-width: 130px;
      max-width: 20%;
      box-sizing: border-box;
      div {
        display: inline-block;
        vertical-align: middle;
        padding-left: 10px;
        box-sizing: border-box;
      }
    }
  }
  svg {
    margin-top: 5px;
  }
  & h4 {
    font-size: 14px;
    margin: 2px 0;
    font-weight: 400;
    color: #333;
  }
  & p {
    font-size: 14px;
    margin: 0;
    font-weight: 400;
    color: #777;
    line-height: 22px;
  }
`;

export default function gTaskStatusView(props) {
  return (
    <TaskStatusDiv>
      <ul>
        <li>
          <CheckCircle
            style={{ color: props.data.accepted ? "#333" : "#ccc" }}
          />
          <div>
            <p>Accepted</p>
            <h4>{props.data.accepted || "-"}</h4>
          </div>
        </li>
        <li>
          <CheckCircle
            style={{ color: props.data.reached ? "#333" : "#ccc" }}
          />
          <div>
            <p>Reached</p>
            <h4>{props.data.reached || "-"}</h4>
          </div>
        </li>
        <li>
          <CheckCircle style={{ color: props.data.picked ? "#333" : "#ccc" }} />
          <div>
            <p>Picked</p>
            <h4>{props.data.picked || "-"}</h4>
          </div>
        </li>
        <li>
          <CheckCircle
            style={{ color: props.data.arrived ? "#333" : "#ccc" }}
          />
          <div>
            <p>Arrived</p>
            <h4>{props.data.arrived || "-"}</h4>
          </div>
        </li>
        <li>
          <CheckCircle
            style={{ color: props.data.completed ? "#333" : "#ccc" }}
          />
          <div>
            <p>Completed</p>
            <h4>{props.data.completed || "-"}</h4>
          </div>
        </li>
      </ul>
    </TaskStatusDiv>
  );
}
