import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GImageEditor from "../gComponents/gImageEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  option: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#888",
    width: "40px",
    height: "40px",
    fontSize: "18px",
    borderRadius: "5px",
    cursor: "pointer",
    //transition: 'background 0.3s',
    transitionProperty: "background-color, color",
    transitionDuration: "300ms, 300ms",
    "&:hover": {
      backgroundColor: "#333",
      color: "#bbb",
      fontWeight: "500",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var buttonStyle = {
  background: "#f08c37",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "100%",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  marginTop: "30px",
  fontWeight: "600",
};

export default function DocumentForm(props) {
  const classes = useStyles();
  const [docTypesList, setDocTypes] = useState([]);
  const [isNoteShow, setIsNoteShow] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false)
  const [openSnackbar] = useSnackbar();
  const initialValues = {
    document_type_id: !props.isEmpty ? props.data.document_type_id : "",
    photo: !props.isEmpty ? props.data.photo : "",
    thumb_photo: !props.isEmpty ? props.data.thumb_photo : "",
    user_id: props.linkedId || "",
    user_type: props.userType || "",
  };

  console.log(props.data + "--" + props.isEmpty);

  useEffect(() => {
    ApiService("GET", "document/types?user_type=" + props.userType).then(
      (response) => {
        console.log("r" + response.status_code);
        if (response.status_code === 1) {
          setDocTypes(response.data);
          if (response.data.length === 0) {
            setIsNoteShow(true)
          }
        } else {
          return;
        }
      }
    );
  }, [props]);

  const onSubmit = (values) => {
    setIsBtnDisabled(true)
    console.log(values);
    //return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "documents/" + props.data.id : "documents";
    ApiService(hitMethod, hitUrl, values).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        props.setDocvisible(false);
        props.onSubmit();
        openSnackbar(response.message);
        setIsBtnDisabled(false)
      } else {
        setIsBtnDisabled(false)
        openSnackbar(response.message);
        return;
      }
    });
  };

  const validationSchema = Yup.object({
    document_type_id: Yup.string().required("Required"),
  });

  //console.log(handleChange);
  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setDocvisible(false);
  };

  // const handleChange = (e) => {
  //   let value = e.target.value;
  //   this.setState((prevState) => ({
  //     newUser: {
  //       ...prevState.newUser,
  //       name: value,
  //     },
  //   }));
  // };

  if (props.docvisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.docvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.docvisible === true ? "fade" : ""}`}
          style={{ padding: "20px" }}
        >
          <h3>{!props.isEmpty ? "Edit" : "Add"} Document</h3>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values }) => (
              //  function updateField(newValue){
              //      values.icon = newValue
              // }

              <Form>
                <GImageEditor
                  onSubmit={(newValue, id, thumb_val) => {
                    values.photo = newValue;
                    values.thumb_photo = thumb_val;
                  }}
                  id="thumb_photo"
                  image={
                    values.thumb_photo
                      ? ImgUrl("document") + "/" + values.thumb_photo
                      : ""
                  }
                  type="document"
                  style={{ height: "160px", paddingTop: "60px" }}
                  divstyle={{ margin: "0 auto", display: "block" }}
                />
                <br />
                {docTypesList ?
                  <React.Fragment>
                    <FormikControl
                      control="select"
                      as="select"
                      label="Document Types"
                      name="document_type_id"
                      options={docTypesList}
                      key_title="title"
                      key_value="id"
                    />
                    {isNoteShow && docTypesList.length === 0 ?
                      <p style={{
                        margin: '8px 15px',
                        fontStyle: 'italic', fontSize: '14px', color: '#777'
                      }}>
                        <strong>Note : </strong> Please add the Document Types from the General settings
                      </p>
                      : ""
                    }
                  </React.Fragment>
                  : (
                    ""
                  )}

                <br />
                <button disabled={isBtnDisabled} style={buttonStyle} type="submit">
                  {!props.isEmpty ? "Update" : "Create"}
                </button>
              </Form>
            )}
          </Formik>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
