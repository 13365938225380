import React, { useState, useEffect, useRef } from "react";
import { ApiService } from "../services";
import styled from "styled-components";
import { Search, Close, ArrowDropDown } from "@material-ui/icons";
import { useDetectOutsideClick } from "./useDetectOutsideClick";
import useLocalStorage from "../useAsyncStorage";

const LI = styled.li`
  padding: 6px 8px;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: #f08c37;
    color: #fff;
  }
`;

const Input = styled.input`
  width: 82%;
  /* min-width: 350px; */
  max-width: 450px;
  margin: 5px 0 0;
  height: 30px;
  background: transparent;
  border: 0px;
  outline: 0;
  box-shadow: none;
  color: #777;
  padding-left: 10px;
`;
export const StoreFilterCustom = ({
  value,
  handleStoreChange,
  handleBlur,
  handleFocus,
  placeholder,
  style,
  isStored,
  toggler,
}) => {
  const [searchWord, setSearchWord] = useState("");
  const wrapperStoreRef = useRef(null);
  const [showFilterList, setShowFilterList] = useDetectOutsideClick(
    wrapperStoreRef,
    false
  );
  const [selectedTitle, setSelectedTitle] = useState("");
  const [storesFilterList, setStores] = useState([]);
  const [selectedValue, setSelectedValue] = useLocalStorage(
    "product_store_id",
    value
  );

  const getStores = () => {
    var hitUrl = "stores?per_page=30&search=" + searchWord;
    ApiService("GET", hitUrl)
      .then((response) => {
        console.log("gh" + response);
        if (response.status_code === 1) {
          console.log(response);
          setStores(response.data.data);
          console.log(selectedValue + "--" + isStored);
          response.data.data.map((values) => {
            if (isStored === true) {
              console.log(selectedValue);
              if (selectedValue) {
                handleStoreChange(selectedValue);
                toggler(true);
              }

              if (values.id === parseInt(selectedValue)) {
                setSelectedTitle(values.title);
                return;
              }
            }
            if (values.id === parseInt(value)) {
              setSelectedTitle(values.title);
            }
          });

          //setSelectedValue(value);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getStores();
  }, [searchWord]);

  return (
    <div
      ref={wrapperStoreRef}
      style={Object.assign(
        {},
        {
          width: "212px",
          display: "inline-block",
          // verticalAlign: "middle",
        },
        style
      )}
    >
      <div
        style={{
          background: "#fff",
          border: "1px solid rgb(226, 226, 226)",
          height: "40px",
          fontSize: "0.97rem",
          color: "#555",
          boxSizing: "border-box",
          padding: "10px",
          fontFamily: "sans-serif",
        }}
        onClick={() => setShowFilterList(true)}
      >
        {selectedTitle ? (
          selectedTitle
        ) : (
          <span style={{ color: "#555" }}>Select Store</span>
        )}
        <ArrowDropDown
          style={{
            float: "right",
            fontSize: "19px",
            color: "#555",
          }}
        />
        {selectedTitle ? (
          <Close
            fontSize="small"
            style={{
              float: "right",
              fontSize: "18px",
              color: "#777",
              cursor: "pointer",
            }}
            onClick={() => {
              if (isStored) {
                setSelectedValue("");
              }
              handleStoreChange();
              setSelectedTitle();
            }}
          />
        ) : (
          ""
        )}
      </div>
      {showFilterList === true ? (
        <div
          style={{
            background: "#fff",
            // position: "absolute",
            zIndex: "9",
            width: "200px",
            padding: "0 5px 5px",
            border: "1px solid rgb(226, 226, 226)",
          }}
        >
          <React.Fragment>
            <Input
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Search.."
            />
            <Search
              fontSize="small"
              style={{
                verticalAlign: "middle",
                fontSize: "22px",
                color: "#ccc",
              }}
            />
          </React.Fragment>
          <ul
            style={{
              listStyleType: "none",
              padding: "5px 0",
              margin: "0",
              borderTop: "1px solid #e2e2e2",
              height: "220px",
              overflow: "auto",
            }}
          >
            {storesFilterList.map((c, i) => {
              return (
                <LI
                  key={i}
                  onClick={() => {
                    if (isStored) {
                      setSelectedValue(c.id);
                    }
                    setSelectedTitle(c.title);
                    setShowFilterList(false);
                    handleStoreChange(c.id, c);
                  }}
                >
                  {c.title}
                </LI>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

// export default storeFilterComponent;
