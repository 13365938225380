import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { makeStyles, TableContainer, Link } from "@material-ui/core";
import { Close, Edit, Delete } from "@material-ui/icons";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import GInput from "../gComponents/gInput";
import { Formik, Form, Field, FieldArray } from "formik";
import FormikControl from "../formikComponents/formikControl";
import * as Yup from "yup";
import { useSnackbar } from "react-simple-snackbar";
import TaxForm from "../components/taxForm"

const useStyles = makeStyles({
  root: {
    padding: "15px 0",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
  },
  container: {
    padding: "0 18px", boxSizing: 'border-box', maxHeight: 'calc(100vh - 250px)', overflow: 'overlay'
  }
});

export default function TaxSettings(props) {
  const classes = useStyles();
  const [taxData, setTaxData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar] = useSnackbar();

  const initialValues = {
    options: [],
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    var hitUrl = "taxes";
    ApiService("POST", hitUrl, values.options[0])
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {

          //getTaxList();
          response.data.isDisabled = true;
          // taxData.push(response.data)
          setTaxData([...taxData, response.data]);
          resetForm(initialValues)
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validationSchema = Yup.object({
    options: Yup.array()
      .of(
        Yup.object({
          title: Yup.string().required("Required"), // these constraints take precedence
          percentage: Yup.string().required("Required"), // these constraints take precedence
        })
      )
      .required("required")
  });
  useEffect(() => {
    getTaxList();
  }, []);

  const getTaxList = () => {
    var hitUrl = "taxes";

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          response.data.map((c) => {
            c.isDisabled = true;
          });
          setTaxData(response.data);
          setIsLoading(false);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };




  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView" style={{ padding: '20px 20px 0' }}>
      <h2>Tax</h2>

      <div className={classes.root}>
        {/* {!inputList ? <button onClick={handleAddClick}>Add</button> : ""} */}

        <TableContainer className={classes.container}>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <FieldArray name="options">
                  {(fieldArrayProps) => {
                    console.log(fieldArrayProps);
                    const { push, remove, form } = fieldArrayProps;
                    const { values } = form;
                    const { options } = values;
                    return (
                      <React.Fragment>
                        <div>
                          <Link
                            href="#"
                            onClick={(e) => {
                              push({ title: "", percentage: "" });
                            }}
                            style={{
                              float: "right",
                              position: "absolute",
                              right: "25px",
                              marginTop: "-70px",
                              background: "#f08c37",
                              color: "#fff",
                              padding: "7px 20px",
                              textDecoration: "none",
                            }}
                          >
                            Add
                        </Link>
                        </div>

                        {options.map((o, index) => {
                          return (
                            <div key={index}
                              style={{
                                display: "inline-flex",
                                width: "100%",
                                padding: "0px 0 0 15px",
                                boxSizing: "border-box", borderBottom: '1px solid #e2e2e2'
                              }}
                              className="arrayFields"
                            >

                              <div style={{ padding: '10px 5px', maxWidth: '250px', minWidth: '250px' }}>
                                {/* <label>Title</label> */}
                                <FormikControl
                                  control="input"
                                  type="text"
                                  label="Title"
                                  name={`options[${index}].title`}
                                  divstyle={{ padding: '0' }}
                                  style={{ width: "92%", margin: '5px 0' }}
                                />
                              </div>
                              <div style={{ padding: '10px 5px', maxWidth: '200px' }}>
                                {/* <label>Percentage</label> */}
                                <FormikControl
                                  control="input"
                                  type="text"
                                  label="Percentage"
                                  name={`options[${index}].percentage`}
                                  divstyle={{ padding: '0' }}
                                  style={{ width: "92%", margin: '5px 0' }}
                                  maxLength={7}
                                  onChange={(event) => {
                                    const re = /^[0-9.\b]+$/;
                                    if (event.target.value && !re.test(event.target.value)) {
                                      return;
                                    } else {
                                      setFieldValue(`options[${index}].percentage`, event.target.value);
                                    }
                                  }}
                                />
                              </div>

                              <span style={{ textAlign: 'right', width: 'calc(100% - 420px)', padding: '13px', marginTop: '25px' }}>
                                <GButton
                                  type="submit"
                                  variant="condensed"
                                  style={{ padding: '10px 25px', verticalAlign: 'bottom' }}
                                >
                                  Submit
                                      </GButton>&nbsp;&nbsp;
                                      <GButton
                                  variant="linkable"
                                  onClick={(e) => {
                                    remove(index);
                                  }}
                                >
                                  <Close />
                                </GButton>
                              </span>
                            </div>)
                        })}

                      </React.Fragment>
                    );
                  }}
                </FieldArray>
              </Form>
            )}
          </Formik>

          {isLoading === true ? (
            <GTableShimmer></GTableShimmer>
          ) : taxData.length === 0 ? (
            <GEmpty></GEmpty>
          ) : (

                <TaxForm data={taxData} onTaxRemove={() => getTaxList} />
              )}
        </TableContainer>

      </div>
    </div>
  );
}
