import React from "react";
import { makeStyles, Paper } from "@material-ui/core";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
  },
});
export default function BulkNotifications() {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();

  const initialValues = {
    title: "",
    subtitle: "",
  };

  const onSubmit = (values, { setFieldValue, resetForm }) => {
    console.log(values);

    ApiService("POST", "promotion/push", values).then((response) => {
      console.log("r" + response.status_code);
      openSnackbar(response.message);
      if (response.status_code === 1) {
        setFieldValue("title", "");
        setFieldValue("subtitle", "");
        resetForm(initialValues);
      } else {
        return;
      }
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    subtitle: Yup.string().required("Required"),
  });

  return (
    <div className="mainInnerView">
      <h1>Bulk Notifications</h1>
      <Paper className={classes.root}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form style={{ width: "500px" }}>
            <FormikControl
              control="input"
              type="text"
              label="Title"
              name="title"
            />
            <FormikControl
              control="input"
              as="textarea"
              type="text"
              label="Subtitle"
              name="subtitle"
              rows="5"
            />
            <br />
            <div style={{ paddingBottom: "40px", margin: "5px 15px" }}>
              <GButton
                type="submit"
                variant="condensed"
                children="Send Notification"
                style={{ float: "right", fontSize: "14px" }}
              />
            </div>
          </Form>
        </Formik>
      </Paper>
    </div>
  );
}
