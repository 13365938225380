import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GImageEditor from "../gComponents/gImageEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  option: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#888",
    width: "40px",
    height: "40px",
    fontSize: "18px",
    borderRadius: "5px",
    cursor: "pointer",
    //transition: 'background 0.3s',
    transitionProperty: "background-color, color",
    transitionDuration: "300ms, 300ms",
    "&:hover": {
      backgroundColor: "#333",
      color: "#bbb",
      fontWeight: "500",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var buttonStyle = {
  background: "#FF6500",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "100%",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  marginTop: "30px",
  fontWeight: "600",
};

export default function ProductMasterCreateForm(props) {
  const classes = useStyles();
  const [categoriesList, setCategoriesList] = useState([]);

  const [isDisabled, setIsDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();
  const initialValues = {
    title: "",
    photo: "",
    thumb_photo: "",
    unit: "",
    categories: "",
    // price: "",
    // tax: ""
  };

  console.log(props.data + "--");

  useEffect(() => {
    ApiService("GET", "product/group").then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 0) {
        //openSnackbar(response.message);
        return;
      }
      setCategoriesList(response.data);
    });
  }, [props]);

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);
    // return;
    ApiService("POST", "product/masters", values).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        props.setPmcvisible(false);
        props.onSubmit();
        openSnackbar(response.message);
      } else {
        setIsDisabled(false);
        openSnackbar(response.message);
        return;
      }
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    price: Yup.string().required("Required"),
    unit: Yup.string().required("Required"),
    categories: Yup.string().required("Required"),
  });

  //console.log(handleChange);
  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setPmcvisible(false);
  };

  // const handleChange = (e) => {
  //   let value = e.target.value;
  //   this.setState((prevState) => ({
  //     newUser: {
  //       ...prevState.newUser,
  //       name: value,
  //     },
  //   }));
  // };

  if (props.pmcvisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.pmcvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.pmcvisible === true ? "fadeM" : ""}`}
          style={{ padding: "20px", maxWidth: "500px" }}
        >
          <h3>Add Master Product</h3>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              //  function updateField(newValue){
              //      values.icon = newValue
              // }

              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmit(values);
                  console.log(e);
                }}
              >
                <GImageEditor
                  onSubmit={(newValue, id, thumb_val) => {
                    values.photo = newValue;
                    values.thumb_photo = thumb_val;
                  }}
                  id="thumb_photo"
                  image={
                    values.photo ? ImgUrl("item") + "/" + values.photo : ""
                  }
                  type="item"
                  style={{ height: "160px", paddingTop: "60px" }}
                  divstyle={{ margin: "0 auto", display: "block" }}
                />
                <br />

                <FormikControl
                  control="input"
                  type="text"
                  label="Title"
                  name="title"
                />

                {/* <FormikControl
                  control="input"
                  type="text"
                  label="Price"
                  name="price"
                  onChange={(event) => {
                    const re = /^[0-9.\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("price", event.target.value);
                    }
                  }}
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                /> */}

                <FormikControl
                  control="input"
                  type="text"
                  label="Unit"
                  name="unit"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />
                {categoriesList ? (
                  <FormikControl
                    control="selectGroup"
                    as="select"
                    label="Category"
                    name="categories"
                    options={categoriesList}
                    key_title="title"
                    key_value="id"
                    divstyle={{
                      display: "inline-block",
                      width: "50%",
                      boxSizing: "border-box",
                      verticalAlign: "top",
                    }}
                  />
                ) : (
                  ""
                )}

                {/* <FormikControl
                  control="input"
                  type="text"
                  maxlength={7}
                  label="Tax"
                  name="tax"
                  onChange={(event) => {
                    const re = /^[0-9.\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue('tax', event.target.value);
                    }
                  }}
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                /> */}

                <br />
                <button style={buttonStyle} type="submit" disabled={isDisabled}>
                  Create
                </button>
              </Form>
            )}
          </Formik>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
