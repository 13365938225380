import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { ApiService, ImgUrl, addDefaultSrc } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import ProductImages from "../products/productImages";
import ProductVariants from "../products/productVariants";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  option: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#888",
    width: "40px",
    height: "40px",
    fontSize: "18px",
    borderRadius: "5px",
    cursor: "pointer",
    //transition: 'background 0.3s',
    transitionProperty: "background-color, color",
    transitionDuration: "300ms, 300ms",
    "&:hover": {
      backgroundColor: "#333",
      color: "#bbb",
      fontWeight: "500",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var formTitle = {
  fontWeight: "400",
  margin: "20px 15px",
};

export default function ProductEditForm(props) {
  const classes = useStyles();
  const [categoriesList, setCategoriesList] = useState([]);
  const [productBasicInfo, setProductBasicInfo] = useState({});
  const [productVariants, setProductVariants] = useState([]);
  const [productImages, setProductImgaes] = useState([]);
  const [variantTypeIndex, setVariantTypeIndex] = useState(0);
  const [saleOn, setSaleOn] = useState(true);

  const [openSnackbar] = useSnackbar();
  const initialValues = {
    title: "",
    price: "",
    unit: "",
    categories: "",
    store_id: props.storeId,
    stock: 0,
    unlimited: 0,
    sale: 0,
    sale_price: 0,
    tax: 0,
    discount: 0,
    description: "",
  };

  console.log(props.data + "--");

  const getProductDetail = () => {
    ApiService("GET", "products/" + props.data.id).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 0) {
        //openSnackbar(response.message);
        return;
      }
      setProductBasicInfo(response.data);
      initialValues.title = response.data.title;
      initialValues.id = response.data.id;
      initialValues.price = response.data.price;
      initialValues.sale_off = response.data.sale;
      initialValues.unit = response.data.unit;
      initialValues.categories = response.data.categories;
      initialValues.max_qty = response.data.max_qty;
      initialValues.unlimited = response.data.unlimited;
      initialValues.stock = response.data.stock;
      initialValues.sale = response.data.sale;
      initialValues.sale_price = response.data.sale_price;
      initialValues.tax = response.data.tax;
      initialValues.discount = response.data.discount;
      initialValues.description = response.data.description;
      setProductVariants(response.data.master_variants);
      setProductImgaes(response.data.images);
    });
  };
  useEffect(() => {
    getProductDetail();

    ApiService("GET", "product/group?store_id=" + props.storeId).then(
      (response) => {
        console.log("r" + response.status_code);
        if (response.status_code === 0) {
          //openSnackbar(response.message);
          return;
        }
        setCategoriesList(response.data);
      }
    );
  }, []);

  const onSubmit = (values) => {
    console.log(values);
    values.stock = values.stock ? values.stock : "0";
    values.sale = values.sale ? values.sale : "0";
    // return;
    ApiService("PUT", "products/" + props.data.id, values).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        //openSnackbar(response.message);
        props.setPevisible(false);
        props.onSubmit();
      } else {
        openSnackbar(response.message);
        return;
      }
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    unit: Yup.string().required("Required"),
    price: Yup.string().required("Required"),
    categories: Yup.string().required("Required"),
  });

  //console.log(handleChange);
  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setPevisible(false);
  };

  if (props.pevisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.pevisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.pevisible === true ? "fadeMW" : ""}`}
          style={{ padding: "20px", minWidth: "680px" }}
        >
          <h3>Edit Product</h3>

          <h2 style={formTitle}>Basic Infromation</h2>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              //  function updateField(newValue){
              //      values.icon = newValue
              // }

              <Form>
                <FormikControl
                  control="input"
                  type="text"
                  label="Title"
                  disabled={true}
                  name="title"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />

                {categoriesList.length >= 0 ? (
                  <FormikControl
                    control="selectGroup"
                    as="select"
                    label="Category"
                    name="categories"
                    disabled={true}
                    options={categoriesList}
                    key_title="title"
                    key_value="id"
                    divstyle={{
                      display: "inline-block",
                      width: "50%",
                      boxSizing: "border-box",
                      verticalAlign: "top",
                    }}
                  />
                ) : (
                  ""
                )}

                <FormikControl
                  control="input"
                  type="text"
                  label="Unit"
                  name="unit"
                  disabled={true}
                  divstyle={{
                    display: "inline-block",
                    width: "33.3%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />
                <FormikControl
                  control="input"
                  type="text"
                  onChange={(event) => {
                    const re = /^[0-9.\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("price", event.target.value);
                    }
                  }}
                  label="Price"
                  name="price"
                  disabled={false}
                  divstyle={{
                    display: "inline-block",
                    width: "33.3%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Master ID"
                  disabled={true}
                  name="id"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />

                <FormikControl
                  control="input"
                  type="text"
                  onChange={(event) => {
                    const re = /^[0-9.\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("tax", event.target.value);
                    }
                  }}
                  label="Tax"
                  name="tax"
                  divstyle={{
                    display: "inline-block",
                    width: "33%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />
                <FormikControl
                  control="input"
                  as="textarea"
                  label="Description"
                  name="description"
                />

                <FormikControl
                  control="toggle"
                  label="Unlimited"
                  isToggled={values.unlimited == "1" ? 1 : 0}
                  name="unlimited"
                  onToggle={() => {
                    let unlimitedVal = values.unlimited === "1" ? true : false;
                    unlimitedVal = !unlimitedVal;
                    unlimitedVal = unlimitedVal === true ? "1" : "0";
                    setFieldValue("unlimited", unlimitedVal);
                  }}
                  style={{
                    width: "50%",
                    boxSizing: "border-box",
                    verticleAlign: "top",
                  }}
                />
                {props.isStockShow === "1" && values.unlimited != "1" ? (
                  <FormikControl
                    control="input"
                    type="text"
                    label="Stock"
                    name="stock"
                    divstyle={{
                      display: "inline-block",
                      width: "50%",
                      boxSizing: "border-box",
                      verticleAlign: "top",
                    }}
                  />
                ) : (
                  ""
                )}

                <br />

                <FormikControl
                  control="toggle"
                  label="Sale"
                  isToggled={values.sale_off == "1" || values.sale_off == "2"}
                  name="sale"
                  onChange={(e) => {
                    setSaleOn(!saleOn);
                    setFieldValue("sale_off", saleOn ? "1" : "0");

                  }}

                  style={{
                    width: "80%",
                    boxSizing: "border-box",
                    verticleAlign: "top",
                  }}
                />

                {values.sale_off == "1" || values.sale_off == "2" ? (
                  <select value={values.sale} style={{ width: "33%", boxSizing: "border-box" }} onChange={(e) => {
                    setSaleOn(!saleOn);
                    setFieldValue("sale", saleOn ? e.target.value : "0");
                  }}>
                    <option value="">Select sale type</option>

                    <option selected value='1' key='1'>
                      simple sale
                    </option>
                    <option value='2' key='2'>
                      Buy Two Get Off
                    </option>


                  </select>
                ) : (
                  ""
                )}
                {values.sale_off == "1" || values.sale_off == "2" ? (
                  <FormikControl
                    control="input"
                    type="text"
                    label="Max Quantity"
                    name="max_qty"
                    divstyle={{
                      display: "inline-block",
                      width: "33.3%",
                      boxSizing: "border-box",
                    }}
                  />
                ) : (
                  ""
                )}
                {values.sale_off == "1" || values.sale_off == "2" ? (
                  <FormikControl
                    control="input"
                    type="text"
                    label="Sale Discount Price"
                    name="sale_price"
                    divstyle={{
                      display: "inline-block",
                      width: "33.3%",
                      boxSizing: "border-box",
                    }}
                  />
                ) : (
                  ""
                )}

                {/* <FormikControl
                  control="toggle"
                  label="Sale"
                  isToggled={values.sale == "1" ? 1 : 0}
                  name="sale"
                  // onToggle={() => {
                  //   let unlimitedVal =
                  //     values.unlimited === "1" ? true : false;
                  //   unlimitedVal = !unlimitedVal;
                  //   unlimitedVal = unlimitedVal === true ? "1" : "0";
                  //   setFieldValue("unlimited", unlimitedVal);
                  // }}

                  onToggle={() => {
                    setSale(values.sale);
                    setFieldValue("sale", sale);
                  }}
                  style={{ width: "50%", boxSizing: "border-box" }}
                />

                {values.sale != 1 ? (
                  <FormikControl
                    onChange={(e) => {
                      setFieldValue("sale_price", e.target.value);
                    }}
                    control="input"
                    type="number"
                    label="Sale Percentage"
                    name="sale"
                    divstyle={{
                      display: "inline-block",
                      width: "50%",
                      boxSizing: "border-box",
                    }}
                  />
                ) : (
                  ""
                )} */}

                <h2 style={formTitle}>Product Images</h2>

                <div style={{ margin: "15px" }}>
                  <ProductImages
                    onUpload={(data) => {
                      setProductImgaes([...productImages, data]);
                    }}
                    productImages={productImages}
                    add={false}
                    productId={props.data.id}
                  />
                </div>

                {productVariants && productVariants.length > 0 ? (
                  <React.Fragment>
                    <h2 style={formTitle}>Product Variants</h2>
                    <div style={{ background: "#f8f9fd", padding: "15px" }}>
                      <ProductVariants
                        productVariants={productVariants}
                        productId={props.data.id}
                        selectedIndex={variantTypeIndex}
                        handleVariantAdd={(index, data) => {
                          setVariantTypeIndex(index);
                          // productVariants[index].options.push(data);
                          // setProductVariants(productVariants);
                          getProductDetail();
                        }}
                        handleVariantRemove={(index, i) => {
                          setVariantTypeIndex(index);
                          console.log(i);
                          // productVariants[index].options.splice(i, 1);
                          // setProductVariants([]);
                          getProductDetail();
                        }}
                        handleVariantEdit={(index, data, i) => {
                          setVariantTypeIndex(index);
                          productVariants[index].options[i] = data;
                          setProductVariants(productVariants);
                        }}
                        isVarStockShow={props.isStockShow}
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )}
                <br />
                <GButton
                  variant="condensed"
                  style={{
                    margin: "0 15px",
                    width: "95%",
                  }}
                  children={!props.isEmpty ? "Update" : "Create"}
                  type="submit"
                />
              </Form>
            )}
          </Formik>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
