import React, { useEffect } from "react";
import { ApiService } from "./services";
import useLocalStorage from "./useAsyncStorage";

const useSettings = (refresh = false) => {
  const [settings, setSettings] = useLocalStorage("settings", data);

  const getSettings = () => {
    ApiService("GET", "me").then((response) => {
      if (response.data) {
        setSettings(response.data);
      }
    });
  };
  useEffect(() => {
    if (refresh === true) {
      getSettings();
    }
  }, []);
  return settings;
};

export default useSettings;

const data = {
  user: {
    wallet: 0,
  },
};
