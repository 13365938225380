import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import GButton from "../gComponents/gButton";
import "../styledComponents/htmlEditorStyles.css";
import { useSnackbar } from "react-simple-snackbar";

export default function PrivacyPolicy() {
  const [policyData, setPolicyData] = useState({});
  const [openSnackbar] = useSnackbar();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const getHtml = (editorState) => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  //convertFromRaw(editorState)

  const onEditorStateChange = (editorState) => {
    console.log(editorState);
    return setEditorState(editorState);
  };

  const onContentStateChange = (contentState) => {
    console.log("as HTML:", contentState);
    //this.setState({ contentState });
  };
  const getPrivacyPolicy = () => {
    var hitUrl = "setting/identifier/driver_instructions";

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setPolicyData(response.data);
          setEditorState(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                htmlToDraft(response.data.key_value).contentBlocks
              )
            )
          );

          console.log(editorState);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  const updateInstructions = () => {
    var text = getHtml(editorState);
    var hitUrl = "setting/" + policyData.id;
    console.log(hitUrl);

    var policyValue = {
      key_value: text,
    };
    ApiService("PUT", hitUrl, policyValue)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setEditorState(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                htmlToDraft(response.data.key_value).contentBlocks
              )
            )
          );
          openSnackbar(response.message);
          console.log(editorState);
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView">
      <h2>Driver Instructions</h2>
      <div className="listPaper">
        <Editor
          editorState={editorState}
          initialContentState={editorState}
          editorContent={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onContentStateChange={onContentStateChange}
          onEditorStateChange={onEditorStateChange}
        />
        {/* {getHtml(editorState)} */}
        <br />
        <GButton
          variant="condensed"
          children="Update"
          onClick={updateInstructions}
        ></GButton>
      </div>
    </div>
  );
}
