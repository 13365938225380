import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { TableContainer } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import GSwitch from "../gComponents/gSwitch";
import GEditable from "../gComponents/gEditable";

const columns = [
  { id: "actions", label: "Action", minWidth: 100 },
  { id: "enabled", label: "Enabled", minWidth: 80 },
  { id: "required", label: "Required", minWidth: 80 },
  { id: "title", label: "Title", minWidth: 200 },
];

export default function AddressSettings() {
  const [addressSettingsData, setAddressSettingsData] = useState([]);

  // const [selectedtaskActionData, setSelectedTaskActionData] = useState({});

  const getAddressSettings = () => {
    var hitUrl = "setting/address";

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setAddressSettingsData(response.data);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAddressSettings();
  }, []);

  const switchActionValue = (cData, sTitle) => {
    var hitUrl = "setting/" + cData.id;
    console.log(JSON.stringify(cData));

    cData.key_value = cData.key_value === "1" ? "0" : "1";
    var switchData = {
      key_value: cData.key_value,
    };
    console.log(hitUrl);
    console.log(switchData);
    ApiService("PUT", hitUrl, switchData)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          getAddressSettings();
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function updateField(newValue, selectedFieldId) {
    console.log(newValue);
    console.log(selectedFieldId);
    // return;
    var send_data = {
      key_value: newValue,
    };
    var hitUrl = "setting/" + selectedFieldId;
    ApiService("PUT", hitUrl, send_data)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          getAddressSettings();
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView">
      <h2>Address Settings</h2>
      <div className="listPaper">
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableHeader
                    key={column.id}
                    style={{
                      minWidth: column.minWidth,
                      textAlign: column.align,
                    }}
                  >
                    {column.label}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>Address Line 1</TableRowData>
                <TableRowData>
                  <GSwitch
                    id={"enable_address_line1"}
                    currentValue={1}
                    disabled={true}
                  />
                </TableRowData>
                <TableRowData>
                  <GSwitch
                    id={"required_address_line1"}
                    currentValue={1}
                    disabled={true}
                  />
                </TableRowData>
                {addressSettingsData
                  .filter(
                    (address) => address.key_display_title === "Address Line 1"
                  )
                  .map((t) => {
                    return (
                      <TableRowData key={t.id}>
                        <GEditable
                          type="text"
                          title={false}
                          defaultValue={t.key_value}
                          placeholder={t.key_display_title}
                          id={t.id}
                          onSubmit={updateField}
                        />
                      </TableRowData>
                    );
                  })}
              </TableRow>
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>Address Line 2</TableRowData>
                {addressSettingsData
                  .filter(
                    (address) => address.key_display_title === "Address Line 2"
                  )
                  .map((t) => {
                    return (
                      <TableRowData key={t.id}>
                        {t.key_title === "v_address_line2" ? (
                          <GSwitch
                            id={"enable" + t.id}
                            uId={t.id}
                            currentValue={t.key_value}
                            handleToggle={(e) => {
                              switchActionValue(t, "enable");
                            }}
                          />
                        ) : (
                            ""
                          )}
                        {t.key_title === "r_address_line2" ? (
                          <GSwitch
                            id={"requird" + t.id}
                            uId={t.id}
                            currentValue={t.key_value}
                            handleToggle={(e) => {
                              switchActionValue(t, "required");
                            }}
                          />
                        ) : (
                            ""
                          )}
                        {t.key_title === "t_address_line2" ? (
                          <GEditable
                            type="text"
                            title={false}
                            defaultValue={t.key_value}
                            placeholder={t.key_display_title}
                            id={t.id}
                            onSubmit={updateField}
                          />
                        ) : (
                            ""
                          )}
                      </TableRowData>
                    );
                  })}
              </TableRow>
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>Address Phone</TableRowData>
                {addressSettingsData
                  .filter((address) => address.key_display_title === "Phone")
                  .map((t) => {
                    return (
                      <TableRowData key={t.id}>
                        {t.key_title === "v_address_phone" ? (
                          <GSwitch
                            id={"enable" + t.id}
                            currentValue={t.key_value}
                            handleToggle={(e) => {
                              switchActionValue(t, "enable");
                            }}
                          />
                        ) : (
                            ""
                          )}
                        {t.key_title === "r_address_phone" ? (
                          <GSwitch
                            id={"requird" + t.id}
                            currentValue={t.key_value}
                            handleToggle={(e) => {
                              switchActionValue(t, "required");
                            }}
                          />
                        ) : (
                            ""
                          )}
                        {t.key_title === "t_address_phone" ? (
                          <GEditable
                            type="text"
                            title={false}
                            defaultValue={t.key_value}
                            placeholder={t.key_display_title}
                            id={t.id}
                            onSubmit={updateField}
                          />
                        ) : (
                            ""
                          )}
                      </TableRowData>
                    );
                  })}
              </TableRow>
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>City</TableRowData>
                {addressSettingsData
                  .filter((address) => address.key_display_title === "City")
                  .map((t) => {
                    return (
                      <TableRowData key={t.id}>
                        {t.key_title === "v_city" ? (
                          <GSwitch
                            id={"enable" + t.id}
                            currentValue={t.key_value}
                            handleToggle={(e) => {
                              switchActionValue(t, "enable");
                            }}
                          />
                        ) : (
                            ""
                          )}
                        {t.key_title === "r_city" ? (
                          <GSwitch
                            id={"requird" + t.id}
                            currentValue={t.key_value}
                            handleToggle={(e) => {
                              switchActionValue(t, "required");
                            }}
                          />
                        ) : (
                            ""
                          )}
                        {t.key_title === "t_city" ? (
                          <GEditable
                            type="text"
                            title={false}
                            defaultValue={t.key_value}
                            placeholder={t.key_display_title}
                            id={t.id}
                            onSubmit={updateField}
                          />
                        ) : (
                            ""
                          )}
                      </TableRowData>
                    );
                  })}
              </TableRow>
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>State</TableRowData>
                {addressSettingsData
                  .filter((address) => address.key_display_title === "State")
                  .map((t) => {
                    return (
                      <TableRowData key={t.id}>
                        {t.key_title === "v_state" ? (
                          <GSwitch
                            id={"enable" + t.id}
                            currentValue={t.key_value}
                            handleToggle={(e) => {
                              switchActionValue(t, "enable");
                            }}
                          />
                        ) : (
                            ""
                          )}
                        {t.key_title === "r_state" ? (
                          <GSwitch
                            id={"requird" + t.id}
                            currentValue={t.key_value}
                            handleToggle={(e) => {
                              switchActionValue(t, "required");
                            }}
                          />
                        ) : (
                            ""
                          )}
                        {t.key_title === "t_state" ? (
                          <GEditable
                            type="text"
                            title={false}
                            defaultValue={t.key_value}
                            placeholder={t.key_display_title}
                            id={t.id}
                            onSubmit={updateField}
                          />
                        ) : (
                            ""
                          )}
                      </TableRowData>
                    );
                  })}
              </TableRow>
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>Zipcode</TableRowData>
                {addressSettingsData
                  .filter((address) => address.key_display_title === "Zipcode")
                  .map((t) => {
                    return (
                      <TableRowData key={t.id}>
                        {t.key_title === "v_pincode" ? (
                          <GSwitch
                            id={"enable" + t.id}
                            currentValue={t.key_value}
                            handleToggle={(e) => {
                              switchActionValue(t, "enable");
                            }}
                          />
                        ) : (
                            ""
                          )}
                        {t.key_title === "r_pincode" ? (
                          <GSwitch
                            id={"requird" + t.id}
                            currentValue={t.key_value}
                            handleToggle={(e) => {
                              switchActionValue(t, "required");
                            }}
                          />
                        ) : (
                            ""
                          )}
                        {t.key_title === "t_pincode" ? (
                          <GEditable
                            type="text"
                            title={false}
                            defaultValue={t.key_value}
                            placeholder={t.key_display_title}
                            id={t.id}
                            onSubmit={updateField}
                          />
                        ) : (
                            ""
                          )}
                      </TableRowData>
                    );
                  })}
              </TableRow>
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>Description</TableRowData>
                {addressSettingsData
                  .filter(
                    (address) => address.key_display_title === "Description"
                  )
                  .map((t) => {
                    return (
                      <TableRowData key={t.id}>
                        {t.key_title === "v_description" ? (
                          <GSwitch
                            id={"enable" + t.id}
                            currentValue={t.key_value}
                            handleToggle={(e) => {
                              switchActionValue(t, "enable");
                            }}
                          />
                        ) : (
                            ""
                          )}
                        {t.key_title === "r_description" ? (
                          <GSwitch
                            id={"requird" + t.id}
                            currentValue={t.key_value}
                            handleToggle={(e) => {
                              switchActionValue(t, "required");
                            }}
                          />
                        ) : (
                            ""
                          )}
                        {t.key_title === "t_description" ? (
                          <GEditable
                            type="text"
                            title={false}
                            defaultValue={t.key_value}
                            placeholder={t.key_display_title}
                            id={t.id}
                            onSubmit={updateField}
                          />
                        ) : (
                            ""
                          )}
                      </TableRowData>
                    );
                  })}
              </TableRow>
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>Name</TableRowData>
                {addressSettingsData
                  .filter((address) => address.key_display_title === "Name")
                  .map((t) => {
                    return (
                      <TableRowData key={t.id}>
                        {t.key_title === "v_name" ? (
                          <GSwitch
                            id={"enable" + t.id}
                            currentValue={t.key_value}
                            handleToggle={(e) => {
                              switchActionValue(t, "enable");
                            }}
                          />
                        ) : (
                            ""
                          )}
                        {t.key_title === "r_name" ? (
                          <GSwitch
                            id={"requird" + t.id}
                            currentValue={t.key_value}
                            handleToggle={(e) => {
                              switchActionValue(t, "required");
                            }}
                          />
                        ) : (
                            ""
                          )}
                        {t.key_title === "t_name" ? (
                          <GEditable
                            type="text"
                            title={false}
                            defaultValue={t.key_value}
                            placeholder={t.key_display_title}
                            id={t.id}
                            onSubmit={updateField}
                          />
                        ) : (
                            ""
                          )}
                      </TableRowData>
                    );
                  })}
              </TableRow>
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>Email</TableRowData>
                {addressSettingsData
                  .filter((address) => address.key_display_title === "Email")
                  .map((t) => {
                    return (
                      <TableRowData key={t.id}>
                        {t.key_title === "v_email" ? (
                          <GSwitch
                            id={"enable" + t.id}
                            currentValue={t.key_value}
                            handleToggle={(e) => {
                              switchActionValue(t, "enable");
                            }}
                          />
                        ) : (
                            ""
                          )}
                        {t.key_title === "r_email" ? (
                          <GSwitch
                            id={"requird" + t.id}
                            currentValue={t.key_value}
                            handleToggle={(e) => {
                              switchActionValue(t, "required");
                            }}
                          />
                        ) : (
                            ""
                          )}
                        {t.key_title === "t_email" ? (
                          <GEditable
                            type="text"
                            title={false}
                            defaultValue={t.key_value}
                            placeholder={t.key_display_title}
                            id={t.id}
                            onSubmit={updateField}
                          />
                        ) : (
                            ""
                          )}
                      </TableRowData>
                    );
                  })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
