import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { Formik, Form } from "formik";
import { useSnackbar } from "react-simple-snackbar";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";

var buttonStyle = {
  background: "#f08c37",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "120px",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  margin: "15px",
  float: "right",
  fontWeight: "600",
};

function NotificationsSMSForm(props) {
  console.log(props);
  const [openSnackbar] = useSnackbar();
  const [selectedNofifyType, setSelectedNotifyType] = useState(props.data);
  const [selectionStart, setSelectionStart] = useState(0);
  const initialValues = {
    medium: props.data ? props.data.medium : "sms",
    user_type: props.userType,
    event_identifier: props.eventIdentifier,
    status: props.data ? props.data.status : "1",
    sub_title: props.data ? selectedNofifyType.sub_title : "",
  };

  useEffect(() => {
    setSelectedNotifyType(props.data);
  }, [props]);

  const onSubmit = (values) => {
    console.log(values);
    // return;
    if (props.data) {
      values.id = props.data.id;
    }

    if (props.module === 'task') {
      values.notification_type = props.notificationType ? props.notificationType : 'delivery'
    }
    var hitMethod = props.data ? "PUT" : "POST";
    var hitUrl = props.data ? "notification-settings" : "notification-settings";
    ApiService(hitMethod, hitUrl, values).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        openSnackbar(response.message);
        props.onSubmit();
      } else {
        return;
      }
    });
  };

  const validationSchema = Yup.object({
    sub_title: Yup.string().required("Required"),
  });

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue }) => {
          const handleSelectChange = (newVal, fieldName) => {
            if (!newVal) {
              return;
            }
            let cursorPos2 = selectionStart;
            var v2 = values[fieldName];
            var var2Val = "**" + newVal + "**";
            var textBefore2 = v2.substring(0, cursorPos2);
            var textAfter2 = v2.substring(cursorPos2, v2.length);
            setFieldValue(fieldName, textBefore2 + var2Val + textAfter2);
          };
          return (
            <Form>
              <div>
                <FormikControl
                  control="select"
                  as="select"
                  name="variable"
                  options={props.variables}
                  key_title="title"
                  key_value="identifier"
                  onChange={(e) =>
                    handleSelectChange(e.target.value, "sub_title")
                  }
                  style={{ width: "170px", float: "right", border: "0px" }}
                />

                <FormikControl
                  control="input"
                  as="textarea"
                  label="Message"
                  name="sub_title"
                  onBlur={(e) => setSelectionStart(e.target.selectionStart)}
                  rows={4}
                />
              </div>

              <br />
              <button style={buttonStyle} type="submit">
                {props.data ? "Update" : "Add"}
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
export default NotificationsSMSForm;
