import React, { useState, useEffect } from "react";
import "../css/invoice.css";
import { ApiService, ImgUrl, addDefaultLogo } from "../services";

function OrderThermalInvoice(props) {
  const [orderId, setOrderId] = useState(props.match.params.orderId);
  const [invoiceData, setInvoiceData] = useState({});
  const [linksData, setLinksData] = useState({
    invoice_logo: {},
    invoice_header_text: {},
    invoice_footer_text: {},
  });

  const getInvoiceSettings = () => {
    ApiService("GET", "setting/invoice")
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setLinksData(response.data);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log(props.match.params.orderId + "==" + orderId);
    setOrderId(props.match.params.orderId);
    const getOrderDetail = () => {
      var hitUrl = "orders/" + orderId;

      console.log(hitUrl);

      ApiService("GET", hitUrl)
        .then((response) => {
          console.log(JSON.stringify(response));
          if (response.status_code === 1) {
            setInvoiceData(response.data);
            getInvoiceSettings();
            console.log(invoiceData);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getOrderDetail();
  }, []);

  return (
    <div className="thermalInvoiceDiv">
      <img
        src={ImgUrl("logo") + "/" + linksData.invoice_logo.key_value}
        alt="logo"
        onError={addDefaultLogo}
      />
      <hr />

      <div>
        <p>{linksData.invoice_header_text.key_value}</p>
        <h4>Order no.</h4>
        <p>{"#" + orderId}</p>
        <p>{invoiceData.store ? invoiceData.created : ""}</p>
        <br />
        <p>{invoiceData.customer ? invoiceData.customer.title : ""}</p>
        <p>{invoiceData.customer ? invoiceData.customer.phone : ""}</p>
        <p>
          {invoiceData.delivery ? invoiceData.delivery.delivery_address : ""}
        </p>
        <hr />
        <p>{invoiceData.store ? invoiceData.store.title : ""}</p>
        <p>{invoiceData.store ? invoiceData.store.address : ""}</p>
        <hr />
        <p>
          Delivery Type -{" "}
          {invoiceData.delivery_type ? invoiceData.delivery_type : ""}
        </p>
        <hr />
        <h4>
          Items (
          {invoiceData.order_products ? invoiceData.order_products.length : ""})
        </h4>
      </div>

      <div>
        <table>
          {invoiceData.order_products ? (
            <tbody>
              {invoiceData.order_products.map((item, ind) => {
                return (
                  <tr key={ind}>
                    <td>{item.quantity}X</td>
                    <td>
                      {item.title}
                      <br />({item.price}
                      {item.unit ? "/" + item.unit : ""})
                      <br />
                      {item.variants.map((v, $index) => {
                        return (
                          <small
                            key={v.id}
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "400",
                              display: "inline-block",
                              paddingRight: "5px",
                              lineHeight: "20px",
                              color: "#333",
                            }}
                          >
                            {v.value}
                            {item.variants.length > $index + 1 ? (
                              <span>, </span>
                            ) : (
                              ""
                            )}
                          </small>
                        );
                      })}
                    </td>
                    <td style={{ textAlign: "right", verticalAlign: "bottom" }}>
                      {item.quantity * item.price}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            ""
          )}
        </table>

        <table style={{ borderTop: "0.5px solid #ccc" }}>
          {invoiceData.payment_summary ? (
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td
                  style={{ textAlign: "right", verticalAlign: "bottom" }}
                ></td>
              </tr>
              {invoiceData.payment_summary.data
                // .filter((p) => p.title !== "line")
                .map((payment, ind) => {
                  return (
                    <tr className={payment.title + " payment"} key={ind}>
                      <td>{payment.title}</td>
                      <td></td>
                      <td style={{ textAlign: "right" }}>{payment.value}</td>
                    </tr>
                  );
                })}

              <tr className="payment total">
                <td>
                  Total{" "}
                  {invoiceData.gateway_display ? (
                    <span>({invoiceData.gateway_display})</span>
                  ) : (
                    ""
                  )}
                </td>
                <td></td>
                <td style={{ textAlign: "right" }}>
                  {invoiceData.payment_summary.total}
                </td>
              </tr>
            </tbody>
          ) : (
            ""
          )}
        </table>
      </div>
      <br />

      <p>Thank you for using the services of Grocery.</p>
      <p>Powered by Ripeontime</p>
      <br />
      <p>{linksData.invoice_footer_text.key_value}</p>
    </div>
  );
}

export default OrderThermalInvoice;
