import React from "react";
import styled from "styled-components";

export const DIV = styled.div`
  display: inline-flex;
  width: 85%;
  padding: 5px;
  & h4 {
    font-size: 0.875rem;
    text-align: left;
    font-weight: 400;
    line-height: 1.43;
    padding: 10px 5px;
    margin: 0;
    width: 65%;
  }
`;
