import axios from "axios";
import placeholder from "./assets/images/placeholders/list-placeholder.png";
import AppLogo from "./assets/images/app-logo.png";
import moment from "moment-timezone";
import Axios from "./Axios";

export function addDefaultSrc(e) {
  e.target.src = placeholder;
}
export function addDefaultLogo(e) {
  e.target.src = AppLogo;
}
export function ImgUrl(imgType) {
  if (imgType) {
    var ImageUrl = process.env.REACT_APP_IMAGE_URL + "/" + imgType;

    return ImageUrl;
  }
}

export async function ApiService(method, hUrl, param) {
  let urlParam = method === "GET" ? (param ? param : "") : "";
  let postData = method === "GET" ? "" : param;
  console.log(process.env.REACT_APP_API_URL);

  try {
    let response1 = await axios({
      method: method,
      url: process.env.REACT_APP_API_URL + "/a/" + hUrl + "" + urlParam,
      data: postData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        timezone: moment.tz.guess(),
      },
    });
    console.log(response1);
    return response1.data;
  } catch (error) {
    console.log(error.response);
    return error;
  }
}
export async function csvFileApiService(method, hUrl, param) {
  try {
    var fd = new FormData();
    fd.append("csv", param.csv);
    fd.append("store_id", param.store_id);

    let response1 = await axios({
      method: method,
      url: process.env.REACT_APP_API_URL + "/a/" + hUrl,
      data: fd,
      headers: {
        ContentType: undefined,
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    });
    return response1.data;
  } catch (error) {
    return error;
  }
}

export async function ApiDownloadService(method, hUrl, param) {
  let urlParam = method === "GET" ? (param ? param : "") : "";
  let postData = method === "GET" ? "" : param;

  try {
    let response1 = await axios({
      method: method,
      url: process.env.REACT_APP_API_URL + "/a/" + hUrl + "" + urlParam,
      data: postData,
      responseType: "arraybuffer",
      headers: {
        "Content-Type": undefined,
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        timezone: moment.tz.guess(),
      },
    });
    console.log(response1);
    return response1.data;
  } catch (error) {
    console.log(error.response);
    return error;
  }
}

export async function FileApiService(method, hUrl,cat, param) {
  try {
    console.log("category",cat,param);
    var fd = new FormData();
    fd.append("image", param);

    let response1 = await axios({
      method: method,
      url: process.env.REACT_APP_API_URL + "/a/upload?type=" + hUrl+"&category="+cat,
      data: fd,
      headers: {
        ContentType: undefined,
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    });
    return response1.data;
  } catch (error) {
    return error;
  }
}

export async function ApiLoginService(method, hUrl, param) {
  try {
    console.log(hUrl);
    let response1 = await axios({
      method: method,
      url: process.env.REACT_APP_API_URL + "/" + hUrl,
      data: param,
      headers: {
        Accept: "application/json",
        Authorization: "",
      },
    });
    console.log(response1);
    return response1.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function LogoutService(hUrl) {
  try {
    let response1 = await axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL + "/" + hUrl,
      data: "",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    });
    return response1.data;
  } catch (error) {
    return error;
  }
}
