import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { Delete } from "@material-ui/icons";
import GEditable from "../gComponents/gEditable";
import GButton from "../gComponents/gButton";
import SocialLinkForm from "../components/createSocialLinks";
import GEmpty from "../gComponents/gEmpty";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import { useSnackbar } from "react-simple-snackbar";

export default function SocialLinks(props) {
  const [linksData, setLinksData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedSocialData, setSelectedSocialData] = useState({});
  const [selectedSocialId, setSelectedSocialId] = useState("");
  const [csvisible, setCsvisible] = React.useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [openSnackbar] = useSnackbar();

  useEffect(() => {
    getSocialSettings();
  }, []);

  const getSocialSettings = () => {
    var hitUrl = "social";

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setLinksData(response.data);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function updateField(newValue, selectedFieldId) {
    console.log(newValue);
    console.log(selectedFieldId);
    // return;
    var send_data = {
      url: newValue,
    };
    var hitUrl = "social/" + selectedFieldId;
    ApiService("PUT", hitUrl, send_data)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          getSocialSettings();
          openSnackbar(response.message);
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const deleteSocialLink = () => {
    var hitUrl = "social/" + selectedSocialId;
    ApiService("DELETE", hitUrl)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          getSocialSettings();
          openSnackbar(response.message);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView">
      <h2>
        Social Links
        <GButton
          variant="condensed"
          children="Add"
          onClick={() => {
            setSelectedSocialData({});
            setIsEmpty(true);
            setCsvisible(true);
          }}
          style={{
            float: "right",
          }}
        >
          Add
        </GButton>
      </h2>
      <div className="listPaper">
        {linksData.length > 0 ? (
          linksData.map((link, i) => (
            <div style={{ display: "inline-flex", width: "80%" }} key={i}>
              <GEditable
                key={link.id}
                type="text"
                title={true}
                defaultValue={link.url}
                className={props.title}
                placeholder={link.title}
                id={link.id}
                onSubmit={updateField}
                style={{ margin: "10px 0 20px", width: "70%" }}
              />
              <GButton
                variant="linkable"
                onClick={(e) => {
                  setSelectedSocialId(link.id);
                  setDialogOpen(true);
                }}
                style={{ paddingTop: "20px" }}
              >
                <Delete fontSize="small" />
              </GButton>
            </div>
          ))
        ) : (
            <GEmpty subtitle="No Social links found"></GEmpty>
          )}
      </div>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Social Link"
          text="Are you sure you want to delete this link?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteSocialLink();
          }}
        ></GConfirmDialog>
      ) : (
          ""
        )}

      {csvisible === true ? (
        <SocialLinkForm
          csvisible={csvisible}
          setCsvisible={setCsvisible}
          data={selectedSocialData}
          onSubmit={getSocialSettings}
          isEmpty={isEmpty}
        />
      ) : (
          ""
        )}
    </div>
  );
}
