import React, { useState, useEffect } from "react";
import { ApiService, ImgUrl, addDefaultSrc } from "../services";
import GImageEditor from "../gComponents/gImageEditor";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GButton from "../gComponents/gButton";
import styled from "styled-components";
import CreateBanner from "../components/createBanner";

const BannerDiv = styled.div`
  min-width: 250px;
  width: 33%;
  box-sizing: border-box;
  display: inline-block;
  padding: 10px 15px;
  position: relative;
  &:hover .hoverImg {
    display: block;
  }
  & img {
    width: 100%;
    object-fit: cover;
    height: 180px;
    padding: 5px;
    border: 1px solid #e2e2e2;
  }
  & .hoverImg {
    display: none;
    height: 180px;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    top: 0px;
    left: 15px;
    right: 3px;
    padding: 5px;
    margin: 15px 5px 0;
    position: absolute;
    box-sizing: border-box;
  }
`;

const SubTextDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default function Banners(props) {
  const [bannerData, setBannerData] = useState([]);
  const [webBannerData, setWebBannerData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [csvisible, setCsvisible] = React.useState(false);
  const [selectedBannerId, setSelectedBannerId] = useState("");

  const [addBannerToggler, setAddBannerToggler] = useState(false);
  const [category, setCategory] = useState("");
  const [device, setDevice] = useState("");

  useEffect(() => {
    getBannersList();
    getWebBannersList();
  }, [addBannerToggler]);

  const getBannersList = () => {
    var hitUrl = "offer-banners?identifier=app";

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setBannerData(response.data);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getWebBannersList = () => {
    var hitUrl = "offer-banners?identifier=web";

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setWebBannerData(response.data);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteBanner = () => {
    var hitUrl = "offer-banners/" + selectedBannerId;
    ApiService("DELETE", hitUrl)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          getBannersList();
          getWebBannersList();
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateField = (newValue) => {
    var hitUrl = "offer-banners";
    var send_data = {
      type: "mobile",
      photo: newValue,
    };
    ApiService("POST", hitUrl, send_data)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          getBannersList();
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toggler = (newValue) => {
    return newValue;
  };

  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView">
      <h2>Banners</h2>
      <SubTextDiv>
        <span style={{ fontSize: "15px" }}>
          Use images with size 400(h) x 700(w).
        </span>
        <GButton
          variant="condensed"
          children="Add"
          onClick={() => {
            // setSelectedSocialData({});
            // setIsEmpty(true);
            // setCsvisible(true);
            setAddBannerToggler(true);
            setCategory("app")
            setDevice("app")
            // toggler();
          }}
          style={{
            float: "right",
          }}
        >
          Add
        </GButton>
      </SubTextDiv>
      <br />
      <div className="listPaper">
      <h4>App Banners</h4>
      <div>
        {bannerData.map((b) => {
          console.log('*******************************************')
          console.log(b)
          console.log('###############################################')
          console.log(bannerData)
          console.log('***********************************************')
          return (
            <BannerDiv key={b.id}>
              <img
                alt=""
                src={ImgUrl("banners") + "/" + b.photo}
                onError={addDefaultSrc}
              />
              <div className="hoverImg">
                <GButton
                  variant="linkable"
                  children="delete"
                  style={{
                    float: "right",
                    color: "#fff",
                    textDecoration: "underline",
                    paddingTop: "145px",
                  }}
                  onClick={(e) => {
                    setSelectedBannerId(b.id);
                    setDialogOpen(true);
                  }}
                >
                  Delete
                </GButton>
              </div>
            </BannerDiv>
          );
        })}
        </div>

        {/* <GImageEditor
          onSubmit={updateField}
          id="photo"
          type="banners"
          style={{ height: "190px", paddingTop: "80px" }}
        /> */}
      </div>
      <div className="listPaper">
      <h4>Website Banners</h4>
      <SubTextDiv>
        <span style={{ fontSize: "15px" }}>
          Use images with size 300(h) x 1200(w).
        </span>
        <GButton
          variant="condensed"
          children="Add"
          onClick={() => {
            // setSelectedSocialData({});
            // setIsEmpty(true);
            // setCsvisible(true);
            setAddBannerToggler(true);
            setDevice("web")
            setCategory("web")
            // toggler();
          }}
          style={{
            float: "right",
          }}
        >
          Add
        </GButton>
      </SubTextDiv>
      <div>
        {webBannerData.map((b) => {
          console.log('*******************************************')
          console.log(b)
          console.log('###############################################')
          console.log(bannerData)
          console.log('***********************************************')
          return (
            <BannerDiv key={b.id}>
              <img
                alt=""
                src={ImgUrl("banners") + "/" + b.photo}
                onError={addDefaultSrc}
              />
              <div className="hoverImg">
                <GButton
                  variant="linkable"
                  children="delete"
                  style={{
                    float: "right",
                    color: "#fff",
                    textDecoration: "underline",
                    paddingTop: "145px",
                  }}
                  onClick={(e) => {
                    setSelectedBannerId(b.id);
                    setDialogOpen(true);
                  }}
                >
                  Delete
                </GButton>
              </div>
            </BannerDiv>
          );
        })}
        </div>

        {/* <GImageEditor
          onSubmit={updateField}
          id="photo"
          type="banners"
          style={{ height: "190px", paddingTop: "80px" }}
        /> */}
      </div>
      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Banner"
          text="Are you sure you want to delete this banner?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteBanner();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}
      <CreateBanner
        toggler={toggler}
        addBannerToggler={addBannerToggler}
        category={category}
        device={device}
        setAddBannerToggler={setAddBannerToggler}
      />
    </div>
  );
}
