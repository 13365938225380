import React from "react";
import styled from "styled-components";
import { Search } from "@material-ui/icons";

const SearchDiv = styled.div`
  display: inline-flex;
  border: 1px solid #e2e2e2;
  background: #fff;
  padding: 8px;
  width: 250px;
  min-width: 130px;
  input {
    border: 0px;
    outline: 0;
    box-shadow: none;
    width: 89%;
  }
`;

export const GSearch = ({
  value,
  handleChange,
  handleBlur,
  handleFocus,
  placeholder,
}) => {
  return (
    <SearchDiv>
      <input
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder}
      />

      <Search
        fontSize="small"
        style={{ verticalAlign: "middle", fontSize: "22px", color: "#ccc" }}
      />
    </SearchDiv>
  );
};
