import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
// } from "react-google-maps";
import GMap from "../gComponents/gMaps";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GGeoTagsInput from "../gComponents/gGeofencesInput";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
var buttonStyle = {
  background: "#f08c37",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "95%",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  margin: "30px 15px 0",
  fontWeight: "600",
};

function CreateStore(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [switchFeaturedVal, setSwitchFeaturedVal] = useState(
    props.data.featured === 1 ? true : false
  );
  const [switchStatusVal, setSwitchStatusVal] = useState(
    props.data.store_status === 1 ? true : false
  );
  const [switchStatusCommingVal, setSwitchCommingVal] = useState(
    props.data.comming_soon === 1 ? true : false
  );
  const initialValues = {
    title: props.data.title || "",
    phone: props.data.phone || "",
    // address: props.data.address || "",
    // latitude: "",
    // longitude: "",
    photo: "",
    commission: props.data.commission || 0,
    order_type: props.data.order_type || "",
    delivery_type: props.data.delivery_type || "",
    min_order: props.data.min_order || "0",
    featured: props.data.featured || 0,
    store_status: props.data.store_status || 0,
    comming_soon: props.data.comming_soon || 0,
    geofences: props.data.geofences || [],
  };

  const deliveryTypes = [
    {
      title: "Both",
      value: "",
    },
    {
      title: "ASAP Only",
      value: "asap",
    },
    {
      title: "Scheduled Only",
      value: "scheduled",
    },
  ];

  const orderTypes = [
    {
      title: "Both",
      value: "",
    },
    {
      title: "Home Delivery",
      value: "home_delivery",
    },
    {
      title: "Store Pickup",
      value: "store_pickup",
    },
  ];

  const onSubmit = (values) => {
    values.featured = switchFeaturedVal === true ? 1 : 0;
    values.store_status = switchStatusVal === true ? 1 : 0;
    values.comming_soon = switchStatusCommingVal === true ? 1 : 0;
    console.log(values);
    // return;
    ApiService("PUT", "stores/" + props.data.id, values).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        props.setSevisible(false);
        props.onSubmit();
        openSnackbar(response.message);
        return;
      } else {
        openSnackbar(response.message);
        return;
      }
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    phone: Yup.string()
      .test(
        "len",
        "phone must be between 7 and 15 digits",
        (val) => val.length < 15 && val.length > 7
      )
      .required("Required"),
    commission: Yup.string().required("Required"),
    // latitude: Yup.string().required("Required"),
  });

  const handleClose = (e) => {
    if (e.target.id != 2721) {
      return;
    }
    props.setSevisible(false);
  };
  // const handleLocation = (address, loc) => {
  //   console.log(address);
  //   console.log(loc);
  //   var lat = loc.lat();
  //   var lon = loc.lng();
  //   setStoreLocation({
  //     lat: loc.lat(),
  //     lng: loc.lng(),
  //     address: address.name,
  //   });
  //   console.log(lat + "," + lon);
  // };

  // const handleChange = (e) => {
  //   let value = e.target.value;
  //   this.setState((prevState) => ({
  //     newUser: {
  //       ...prevState.newUser,
  //       name: value,
  //     },
  //   }));
  // };

  if (props.sevisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2721"
        className={classes.backdrop}
        open={props.sevisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.sevisible === true ? "fadeM" : ""}`}
          style={{ padding: "20px", maxWidth: "500px" }}
        >
          <h3>Edit Store</h3>
          {/* <GoogleMap
            defaultZoom={10}
            defaultCenter={{ lat: 45.4211, lng: -75.6903 }}
          >
            <Marker position={{ lat: -34.397, lng: 150.644 }} />
            {/* We will render our data here *
          </GoogleMap> */}

          {/* <GMap center={{ lat: -24.9923319, lng: 135.2252427 }} /> */}

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div
                  style={{
                    margin: "-15px 15px 10px",
                    float: "right",
                    marginTop: "-50px",
                    paddingLeft: "75px",
                    display: "inline-flex",
                  }}
                >
                  <label
                    style={{
                      display: "inline-block",
                      fontSize: "0.98rem",
                      color: "#333",
                      padding: "5px",
                      fontStyle: "italic",
                      textDecoration: "underline",
                    }}
                  >
                    Availability
                  </label>
                  <FormikControl
                    control="toggle"
                    isToggled={switchStatusVal}
                    name="store_status"
                    onToggle={() => {
                      setSwitchStatusVal(!switchStatusVal);
                    }}
                  />
                </div>

                <div
                  style={{
                    margin: "-15px 15px 10px",
                    float: "right",
                    // marginTop: "21px",
                    paddingLeft: "75px",
                    display: "inline-flex",
                  }}
                >
                  <label
                    style={{
                      display: "inline-block",
                      fontSize: "0.98rem",
                      color: "#333",
                      padding: "5px",
                      fontStyle: "italic",
                      textDecoration: "underline",
                    }}
                  >
                    Comming Soon
                  </label>
                  <FormikControl
                    control="toggle"
                    isToggled={switchStatusCommingVal}
                    name="comming_soon"
                    onToggle={() => {
                      setSwitchCommingVal(!switchStatusCommingVal);
                    }}
                  />
                </div>
                <br />
                <div style={{ display: "inline-flex" }}>
                  <FormikControl
                    control="input"
                    type="text"
                    label="Title"
                    name="title"
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    label="Phone"
                    name="phone"
                    onChange={(event) => {
                      const re = /^[0-9 +\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("phone", event.target.value);
                      }
                    }}
                  />
                </div>

                <h4
                  style={{
                    padding: "15px 15px 10px",
                    fontSize: "18px",
                    fontWeight: "450",
                  }}
                >
                  Additional Information
                </h4>

                <FormikControl
                  control="input"
                  type="text"
                  label="Commission (in %)"
                  name="commission"
                  onChange={(event) => {
                    const re = /^[0-9.\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("commission", event.target.value);
                    }
                  }}
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Min Order"
                  name="min_order"
                  onChange={(event) => {
                    const re = /^[0-9.\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("min_order", event.target.value);
                    }
                  }}
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />

                <div style={{ margin: "15px" }}>
                  <label>Geofences</label>
                  <GGeoTagsInput
                    data={values.geofences}
                    onSubmit={(tags) => {
                      console.log(tags);
                      values.geofences = tags.map((d) => d.id);
                    }}
                    showButton={false}
                  />
                </div>
                {/* <div style={{ margin: "10px 15px 10px" }}>
                  <label
                    style={{
                      display: "inline-block",
                      fontSize: "0.98rem",
                      color: "#333",
                      paddingRight: "15px",
                    }}
                  >
                    Featured
                  </label>
                  <FormikControl
                    control="toggle"
                    isToggled={switchFeaturedVal}
                    name="featured"
                    onToggle={() => {
                      setSwitchFeaturedVal(!switchFeaturedVal);
                    }}
                  />
                </div> */}

                <FormikControl
                  control="radio"
                  label="Delivery Types"
                  name="delivery_type"
                  options={deliveryTypes}
                  key_title="title"
                  key_value="value"
                />
                <FormikControl
                  control="radio"
                  label="Order Type"
                  name="order_type"
                  options={orderTypes}
                  key_title="title"
                  key_value="value"
                  divstyle={{ display: "block", padding: "3px 0" }}
                />
                <br />
                <button style={buttonStyle} type="submit">
                  {props.data ? "Update" : "Create"}
                </button>
              </Form>
            )}
          </Formik>
        </Paper>
      </Backdrop>
    </div>
  );
}

export default CreateStore;
