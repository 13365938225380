import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
// } from "react-google-maps";
import GMap from "../gComponents/gMaps";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
var buttonStyle = {
  background: "#f08c37",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "95%",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  margin: "30px 15px 0",
  fontWeight: "600",
};

const addressTitle = [
  {
    key: "HOME",
    value: "home",
  },
  {
    key: "WORK",
    value: "work",
  },
  {
    key: "OTHER",
    value: "other",
  },
];

function CreateAddress(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [addressLocation, setAddressLocation] = useState(
    !props.isEmpty
      ? {
          lat: parseFloat(props.data.latitude),
          lng: parseFloat(props.data.longitude),
        }
      : {
          lat: process.env.REACT_APP_DEFAULT_LATITUDE,
          lng: process.env.REACT_APP_DEFAULT_LONGITUDE,
        }
  );
  const initialValues = {
    type: "saved",
    customer_id: props.id,
    title: !props.isEmpty ? props.data.title : "home",
    line1: !props.isEmpty ? props.data.line1 : "",
    line2: !props.isEmpty ? props.data.line2 : "",
    phone: !props.isEmpty ? props.data.phone : "",
    // name: props.data ? props.data.name : "",
    // email: props.data ? props.data.email : "",
    latitude: !props.isEmpty ? props.data.latitude : "",
    longitude: !props.isEmpty ? props.data.longitude : "",
    city: !props.isEmpty ? props.data.city : "",
    state: !props.isEmpty ? props.data.state : "",
    country: !props.isEmpty ? props.data.country : "",
    zipcode: !props.isEmpty ? props.data.zipcode : "",
    instructions: !props.isEmpty ? props.data.instructions : "",
  };

  const onSubmit = (values) => {
    console.log(values);
    // values.latitude = addressLocation.lat;
    // values.longitude = addressLocation.lng;
    // values.address = storeLocation.address
    console.log(values);
    // return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "addresses/" + props.data.id : "addresses";
    ApiService(hitMethod, hitUrl, values).then((response) => {
      console.log("r" + response.status_code);
      openSnackbar(response.message);
      if (response.status_code === 1) {
        props.onSubmit();
        props.setAfvisible(false);
      } else {
        return;
      }
    });
  };

  const validationSchema = Yup.object({
    //title: Yup.string().required("Required"),
    line1: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
    zipcode: Yup.string().required("Required").min(5).max(10),
    phone: Yup.string().required("Required"),
    // latitude: Yup.string().required("Required"),
  });

  const handleClose = (e) => {
    if (e.target.id != 2721) {
      return;
    }
    props.setAfvisible(false);
  };

  // const handleChange = (e) => {
  //   let value = e.target.value;
  //   this.setState((prevState) => ({
  //     newUser: {
  //       ...prevState.newUser,
  //       name: value,
  //     },
  //   }));
  // };

  if (props.afvisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2721"
        className={classes.backdrop}
        open={props.afvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.afvisible === true ? "fadeM" : ""}`}
          style={{ padding: "20px", maxWidth: "500px" }}
        >
          <h3>{props.isEmpty === true ? "Create" : "Edit"} Address</h3>
          {/* <GoogleMap
            defaultZoom={10}
            defaultCenter={{ lat: 45.4211, lng: -75.6903 }}
          >
            <Marker position={{ lat: -34.397, lng: 150.644 }} />
            {/* We will render our data here *
          </GoogleMap> */}

          {/* <GMap center={{ lat: -24.9923319, lng: 135.2252427 }} /> */}

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <GMap
                  centerLocation={addressLocation}
                  search={true}
                  height="30vh"
                  type="createAddress"
                  onChange={(address, loc) => {
                    values.latitude = loc.lat();
                    values.longitude = loc.lng();
                    values.line1 = address.address[0].name;
                    values.city = address.city;
                    values.state = address.state;
                    values.country = address.country;
                    values.zipcode = address.pincode;
                    setFieldValue("line1", address.address[0].name);
                  }}
                  padding={true}
                />

                <FormikControl
                  control="radio"
                  name="title"
                  options={addressTitle}
                  key_title="key"
                  selected={values.title}
                  key_value="value"
                  icons={true}
                  style={{ display: "none" }}
                  divstyle={{
                    display: "inline-block",
                    width: "33%",
                    border: "1px solid #d2d2d2",
                    textAlign: "center",
                    padding: "5px",
                    boxSizing: "border-box",
                    cursor: "pointer",
                  }}
                  labelStyle={{
                    width: "100%",
                    boxSizing: "border-box",
                    margin: "3px 0",
                  }}
                />

                <FormikControl
                  control="input"
                  type="text"
                  placeholder="Address line1"
                  name="line1"
                  className="noBorder"
                />
                <FormikControl
                  control="input"
                  type="text"
                  placeholder="Address line2"
                  name="line2"
                  className="noBorder"
                />

                <h4 style={{ padding: "15px 15px 10px" }}>Other Details</h4>
                <div style={{ display: "inline-flex" }}>
                  <FormikControl
                    control="input"
                    type="text"
                    placeholder="City"
                    name="city"
                    maxLength={30}
                    style={{ height: "40px" }}
                    onChange={(event) => {
                      const re = /^[A-Za-z\s\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("city", event.target.value);
                      }
                    }}
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    placeholder="State"
                    name="state"
                    maxLength={15}
                    style={{ height: "40px" }}
                    onChange={(event) => {
                      const re = /^[A-Za-z\s\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("state", event.target.value);
                      }
                    }}
                  />
                </div>
                <div style={{ display: "inline-flex" }}>
                  <FormikControl
                    control="input"
                    type="text"
                    placeholder="Country"
                    name="country"
                    style={{ height: "40px" }}
                    maxLength={51}
                    onChange={(event) => {
                      const re = /^[A-Za-z\s\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("country", event.target.value);
                      }
                    }}
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    placeholder="Zipcode"
                    name="zipcode"
                    style={{ height: "40px" }}
                    maxLength={10}
                    onChange={(event) => {
                      const re = /^[0-9\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("zipcode", event.target.value);
                      }
                    }}
                  />
                </div>
                <FormikControl
                  control="input"
                  type="text"
                  placeholder="Phone"
                  name="phone"
                  style={{ height: "40px" }}
                  maxLength={13}
                  onChange={(event) => {
                    const re = /^[0-9+\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("phone", event.target.value);
                    }
                  }}
                />
                <FormikControl
                  control="input"
                  as="textarea"
                  placeholder="Instructions"
                  name="instructions"
                />
                <button style={buttonStyle} type="submit">
                  {!props.isEmpty ? "Update" : "Create"}
                </button>
              </Form>
            )}
          </Formik>
        </Paper>
      </Backdrop>
    </div>
  );
}

export default CreateAddress;
