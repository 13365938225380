import React, { useEffect, useState } from "react";
import { makeStyles, Paper, Backdrop } from "@material-ui/core";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var buttonStyle = {
  background: "#f08c37",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "100%",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  marginTop: "30px",
  fontWeight: "600",
};

export default function CancelReasons(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [orderCancelReasons, setOrderCancelReasons] = useState([]);

  useEffect(() => {
    function getCancelReasons() {
      ApiService("GET", "cancel-reasons?user_type=1").then((response) => {
        console.log("r" + response.status_code);

        response.data.push({ title: 'Other' });
        setOrderCancelReasons(response.data);

      });
    }
    getCancelReasons();
  }, [props]);
  const initialValues = {
    cancel_reason: "",
    other_reason: "",
  };

  const onSubmit = (values) => {
    console.log(values);
    if (values.cancel_reason !== "Other") {
      delete values.other_reason;
    }
    else {
      values.cancel_reason = values.other_reason;
    }

    ApiService("PUT", "order/cancel/" + props.linkedId, values).then(
      (response) => {
        console.log("r" + response.status_code);
        openSnackbar(response.message);
        if (response.status_code === 1) {
          props.setOcrvisible(false);
          props.onSubmit();
        } else {
          return;
        }
      }
    );
  };

  const validationSchema = Yup.object({
    cancel_reason: Yup.string().required("Required"),
    other_reason: Yup.string().when("cancel_reason", {
      is: "Other",
      then: Yup.string().required("Required"),
    }),
  });

  const handleClose = (e) => {
    if (e.target.id !== (2739).toString()) {
      return;
    }
    props.setOcrvisible(false);
  };

  if (props.ocrvisible === false) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2739"
        className={classes.backdrop}
        open={props.ocrvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.ocrvisible === true ? "fadeM" : ""}`}
          style={{ padding: "20px", maxWidth: "475px" }}
        >
          <h3>Cancel Order</h3>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values }) => (
              <Form>
                <label style={{ margin: "15px 15px 5px" }}>
                  Please choose the cancellation reason below
                </label>
                {orderCancelReasons.length > 0 ? <FormikControl
                  control="radio"
                  name="cancel_reason"
                  options={orderCancelReasons}
                  key_title="title"
                  key_value="title"
                  divstyle={{ display: "block", padding: "5px 0" }}
                /> : ''}
                {values.cancel_reason === "Other" ? (
                  <FormikControl
                    control="input"
                    as="textarea"
                    placeholder="Type reason here"
                    name="other_reason"
                  // style={{ display: "inline-block", width: "30%" }}
                  />
                ) : (
                    ""
                  )}

                <br />
                <button style={buttonStyle} type="submit">
                  Cancel Order
                </button>
              </Form>
            )}
          </Formik>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
