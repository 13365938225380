import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { GImagePicker } from "../gComponents/gImagePicker";
// import GImageEditor from "../gComponents/gImageEditor";
import useSettings from "../useSettings";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  option: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#888",
    width: "40px",
    height: "40px",
    fontSize: "18px",
    borderRadius: "5px",
    cursor: "pointer",
    //transition: 'background 0.3s',
    transitionProperty: "background-color, color",
    transitionDuration: "300ms, 300ms",
    "&:hover": {
      backgroundColor: "#333",
      color: "#bbb",
      fontWeight: "500",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var buttonStyle = {
  background: "#f08c37",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "100%",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  marginTop: "30px",
  fontWeight: "600",
};

export default function EditProfile(props) {
  const settings = JSON.parse(localStorage.getItem("settings"));
  const classes = useStyles();
  const initialValues = {
    first_name: settings?.user?.first_name || "",
    last_name: settings?.user?.last_name || "",
    email: settings?.user?.email || "",
    photo: settings?.user?.photo || "",
  };
  const [openSnackbar] = useSnackbar();
  const [icon, setIcon] = useState("");

  const onSubmit = (values) => {
    if (!values.password) {
      delete values.password;
    }
    console.log(values);

    ApiService("PUT", "me", values).then((response) => {
      console.log("r" + response.status_code);
      openSnackbar(response.message);
      if (response.status_code === 1) {
        openSnackbar(response.message);
        settings.user = response.data;
        localStorage.setItem("settings", JSON.stringify(settings));
        props.setEpvisible(false);
      } else {
        return;
      }
    });
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid Email Address").required("Required"),
  });

  const handleClose = (e) => {
    if (e.target.id != 2732) {
      return;
    }
    props.setEpvisible(false);
  };

  if (props.epvisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: "200px",
        height: "200px",
      }}
    >
      <Backdrop
        id="2732"
        className={classes.backdrop}
        open={props.epvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper style={{ padding: "20px", width: "350px" }}>
          <h3>Edit Profile</h3>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values }) => (
              <Form>
                {/* <GImageEditor
                  onSubmit={(newValue, id, thumb_val) => {
                    values.photo = newValue;
                    values.thumb_photo = thumb_val;
                  }}
                  id="thumb_photo"
                  type="admin"
                  style={{ height: "160px", paddingTop: "60px" }}
                  divstyle={{ margin: "0 auto", display: "block" }}
                /> */}

                <GImagePicker
                  type="admin"
                  alt={settings.user.first_name}
                  height={100}
                  width={100}
                  radius={50}
                  editor
                  editorProps={{
                    height: 200,
                    width: 200,
                    radius: 100,
                  }}
                  serverProps={{
                    size: 200,
                  }}
                  onSuccess={(v) => {
                    console.log(v);
                    setIcon(v.image);
                    values.photo = v.image;
                  }}
                  isPreviewImg={true}
                  default={ImgUrl("admin") + "/" + settings.user.photo}
                />

                <br />

                <FormikControl
                  control="input"
                  type="text"
                  label="First name"
                  name="first_name"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box", verticalAlign: 'top'
                  }}
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Last name"
                  name="last_name"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box", verticalAlign: 'top'
                  }}
                />
                <FormikControl
                  control="input"
                  type="email"
                  label="Email Id"
                  name="email"
                  disabled={true}
                />
                <br />
                <button style={buttonStyle} type="submit">
                  Update
                </button>
              </Form>
            )}
          </Formik>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
