import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ApiService } from "../services";
import { TableContainer } from "@material-ui/core";
import {
  Add,
  Edit,
  Delete,
  ThumbDownAlt,
  ThumbUpAlt,
} from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GButton from "../gComponents/gButton";
import ProgressBar from "../gComponents/gProgressBar";
import CreateFaq from "../components/createFaq";
import { useSnackbar } from "react-simple-snackbar";

const columns = [
  { id: "question", label: "Question", minWidth: 200 },
  { id: "votes", label: "Votes", minWidth: 200, align: "center" },
  { id: "type", label: "Type", minWidth: 120, align: "center" },
  { id: "actions", label: "Actions", minWidth: 60, align: "center" },
];

export default function Child(props) {
  let { type } = useParams();
  var typeId = type === "customer" ? "1" : type === "manager" ? "2" : "3";

  const [faqData, setFaqData] = useState([]);
  const [faqTypes, setFaqTypes] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedFaqData, setSelectedFaqData] = useState({});
  const [selectedFaqId, setSelectedFaqId] = useState("");
  const [cfvisible, setCfvisible] = React.useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar] = useSnackbar();

  useEffect(() => {
    //  typeId = type === "customer" ? "1" : type === "manager" ? "2" : "3";
    getFaqList();
    props.onChange(type);
  }, [type]);

  const getFaqList = () => {
    var hitUrl = "faqs?user_type=" + typeId;

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setFaqData(response.data);
          setFaqTypes(response.options);
          setIsLoading(false);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteFaq = () => {
    var hitUrl = "faqs/" + selectedFaqId;
    ApiService("DELETE", hitUrl)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          getFaqList();
        } else {
          console.log(response.data);
        }
        openSnackbar(response.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div>
      <GButton
        variant="linkable"
        children="Add"
        onClick={() => {
          setSelectedFaqData({});
          setIsEmpty(true);
          setCfvisible(true);
        }}
        style={{
          margin: "-60px -15px 0px",
          float: "right",
        }}
      >
        <Add fontSize="large" />
      </GButton>
      {isLoading === true ? (
        <GTableShimmer></GTableShimmer>
      ) : (
          <TableContainer>
            {faqData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableHeader
                          key={column.id}
                          style={{
                            minWidth: column.minWidth,
                            textAlign: column.align,
                          }}
                        >
                          {column.label}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {faqData.map((c) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                          <TableRowData
                            style={{
                              BorderBottom: "1px solid #f4f5f9",
                            }}
                          >
                            {c.question}
                          </TableRowData>
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <ul
                              style={{
                                minWidth: '30%',
                                display: "inline-flex",
                                color: "#8f8f8f",
                                listStyleType: "none",
                                margin: "0 0 10px",
                                padding: "0px",
                              }}
                            >
                              <li style={{ padding: "0 5px", display: "inline-flex", width: '50%' }}>
                                <ThumbUpAlt
                                  fontSize="small"
                                  style={{ verticalAlign: "bottom" }}
                                />{" "}
                                {c.likes}
                              </li>
                              <li style={{ padding: "0 5px", display: "inline-flex", width: '50%' }}>
                                <ThumbDownAlt
                                  fontSize="small"
                                  style={{ verticalAlign: "bottom" }}
                                />
                                {c.dislikes}
                              </li>
                            </ul>
                            <ProgressBar
                              completed={
                                c.likes
                                  ? (100 / (c.likes + c.dislikes)) * c.likes
                                  : 0
                              }
                              max={c.likes + c.dislikes}
                            />
                          </TableRowData>
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {c.faq_type}
                          </TableRowData>
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <GButton
                              variant="linkable"
                              onClick={(e) => {
                                setSelectedFaqData(c);
                                setIsEmpty(false);
                                setCfvisible(true);
                              }}
                            >
                              <Edit fontSize="small" />
                            </GButton>

                            <GButton
                              variant="linkable"
                              //  onClick={(e) =>
                              //       window.confirm(
                              //         "Are you sure you wish to delete this customer?"
                              //       ) && deleteCustomer(c.id)
                              // }

                              onClick={(e) => {
                                setSelectedFaqId(c.id);
                                setDialogOpen(true);
                              }}
                            >
                              <Delete fontSize="small" />
                            </GButton>
                          </TableRowData>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
          </TableContainer>
        )}

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Faq"
          text="Are you sure you want to delete this FAQ?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteFaq();
          }}
        ></GConfirmDialog>
      ) : (
          ""
        )}

      <CreateFaq
        cfvisible={cfvisible}
        setCfvisible={setCfvisible}
        data={selectedFaqData}
        isEmpty={isEmpty}
        onSubmit={getFaqList}
        userType={typeId}
        faqTypes={faqTypes}
      />
    </div>
  );
}
