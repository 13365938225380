import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GImageEditor from "../gComponents/gImageEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  option: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#888",
    width: "40px",
    height: "40px",
    fontSize: "18px",
    borderRadius: "5px",
    cursor: "pointer",
    //transition: 'background 0.3s',
    transitionProperty: "background-color, color",
    transitionDuration: "300ms, 300ms",
    "&:hover": {
      backgroundColor: "#333",
      color: "#bbb",
      fontWeight: "500",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var buttonStyle = {
  background: "#f08c37",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "100%",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  marginTop: "30px",
  fontWeight: "600",
};

export default function ProductCreateForm(props) {
  console.log(props);
  const classes = useStyles();
  const [categoriesList, setCategoriesList] = useState([]);
  const [openSnackbar] = useSnackbar();
  const initialValues = {
    title: !props.isEmpty ? props.data.title : "",
    photo: !props.isEmpty && props.categoryId ? props.data.photo : "",
    thumb_photo:
      !props.isEmpty && props.categoryId ? props.data.thumb_photo : "",
    // description: !props.isEmpty ? props.data.description : "",
    store_id: props.storeId,
    parent_id: props.categoryId ? props.categoryId : "",
  };

  console.log(props.data + "--");

  useEffect(() => {
    ApiService("GET", "product/group?store_id=" + props.storeId).then(
      (response) => {
        console.log("r" + response.status_code);
        if (response.status_code === 0) {
          //openSnackbar(response.message);
          return;
        }
        setCategoriesList(response.data);
      }
    );
  }, [props]);

  const onSubmit = (values) => {
    console.log(values);

    // return;

    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "product/categories/" + props.data.id
      : "product/categories";
    ApiService(hitMethod, hitUrl, values).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        props.setCatFormvisible(false);
        // setCategoriesList(response.data);
        props.onSubmit();
        openSnackbar(response.message);
      } else {
        openSnackbar(response.message);
        return;
      }
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    parent_id: props.categoryId ? Yup.string().required("Required") : "",
    // description: Yup.string().required("Required")
  });

  //console.log(handleChange);
  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setCatFormvisible(false);
  };

  // const handleChange = (e) => {
  //   let value = e.target.value;
  //   this.setState((prevState) => ({
  //     newUser: {
  //       ...prevState.newUser,
  //       name: value,
  //     },
  //   }));
  // };

  if (props.catFormvisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.catFormvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.catFormvisible === true ? "fadeM" : ""}`}
          style={{ padding: "20px", maxWidth: "500px" }}
        >
          <h3>
            {!props.isEmpty ? "Edit" : "Add"}{" "}
            {!props.categoryId ? "Category" : "Subcategory"}
          </h3>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values }) => (
              //  function updateField(newValue){
              //      values.icon = newValue
              // }

              <Form>
                {props.categoryId ? (
                  <GImageEditor
                    onSubmit={(newValue, id, thumb_val) => {
                      values.photo = newValue;
                      values.thumb_photo = thumb_val;
                    }}
                    id="thumb_photo"
                    image={
                      values.photo
                        ? ImgUrl("category") + "/" + values.photo
                        : ""
                    }
                    type="category"
                    style={{ height: "160px", paddingTop: "60px" }}
                    divstyle={{ margin: "0 auto", display: "block" }}
                  />
                ) : (
                  ""
                )}
                <br />

                <FormikControl
                  control="input"
                  type="text"
                  label="Title"
                  name="title"
                />

                {/* <FormikControl
                  control="input"
                  as="textarea"
                  label="Description"
                  name="description"
                /> */}

                {props.categoryId ? (
                  <FormikControl
                    control="select"
                    as="select"
                    label="Category"
                    name="parent_id"
                    options={categoriesList}
                    key_title="title"
                    key_value="id"
                  />
                ) : (
                  ""
                )}

                <br />
                <button style={buttonStyle} type="submit">
                  {!props.isEmpty ? "Update" : "Create"}
                </button>
              </Form>
            )}
          </Formik>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
