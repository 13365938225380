import React from "react";
import styled from "styled-components";
import { WorkOutlineOutlined, PinDropOutlined } from "@material-ui/icons";

const TaskDiv = styled.div`
  position: relative;
  svg {
    margin-top: 5px;
  }
  & h4 {
    font-size: 16px;
    margin: 2px 0;
    font-weight: 400;
  }
  & p {
    font-size: 15px;
    margin: 0;
    font-weight: 400;
    color: #777;
    line-height: 22px;
  }
`;
const PickupDiv = styled.div`
  display: inline-flex;
  width: 95%;
  &:after {
    content: " ";
    position: absolute;
    display: block;
    top: 18px;
    left: 12px;
    width: 1px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-color: rgba(0, 0, 0, 0.25);
    min-height: 15px;
    max-height: 20px;
  }
  div {
    display: inline-block;
    padding-left: 10px;
    width: 90%;
    margin-bottom: 10px;
    vertical-align: top;
    padding-bottom: 10px;
    box-sizing: border-box;
  }
`;
const DeliveryDiv = styled.div`
  display: inline-flex;
  width: 95%;
  div {
    display: inline-block;
    padding-left: 10px;
    width: 90%;
    vertical-align: top;
    padding-bottom: 10px;
  }
`;

export default function gTaskView(props) {
  return (
    <TaskDiv>
      <PickupDiv>
        <PinDropOutlined />
        <div>
          <h4>{props.storeTitle}</h4>
          <p>{props.task.pickup_address}</p>
        </div>
      </PickupDiv>
      <DeliveryDiv>
        <WorkOutlineOutlined />
        <div>
          <h4>Home</h4>
          <p>{props.task.delivery_address}</p>
        </div>
      </DeliveryDiv>
    </TaskDiv>
  );
}
