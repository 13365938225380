import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
} from "../styledComponents/tableStyle";
import { Info } from "@material-ui/icons";
import GEditable from "../gComponents/gEditable";
import GSwitch from "../gComponents/gSwitch";
import TooltipComponent from "../gComponents/gTooltip";

export default function OrderSettings() {
  const [orderSettingsData, setOrderSettingsData] = useState({
    min_order: {},
    free_delivery: {},
    product_stock: {},
  });
  const [openSnackbar] = useSnackbar();
  const [minOrderSwitch, setMinOrderSwitchVal] = useState();
  const [cancelOrderSwitch, setCancelOrderSwitchVal] = useState();
  const [freeDeliverySwitch, setFreeDeliverySwitchVal] = useState();
  const [stockSwitch, setStockSwitchVal] = useState();

  const getOrderSettings = () => {
    var hitUrl = "setting/order";

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setOrderSettingsData(response.data);
          setMinOrderSwitchVal(response.data.min_order.key_value);
          setCancelOrderSwitchVal(response.data.cancel_order_penality.key_value);
          setFreeDeliverySwitchVal(response.data.free_delivery.key_value);
          setStockSwitchVal(response.data.product_stock.key_value);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOrderSettings();
  }, []);

  const switchActionValue = (newValue, sId, keyTitle) => {
    if (keyTitle !== "product_stock") {
      if (!(newValue === "1" || newValue === 1)) {
        //setMinOrderVal(newValue);
        updateSetting(newValue, sId, keyTitle);
      }
    } else {
      updateSetting(newValue, sId, keyTitle);
    }
  };

  function updateField(newValue, selectedFieldId, keyTitle) {
    console.log(newValue);
    console.log(selectedFieldId);
    updateSetting(newValue, selectedFieldId, keyTitle);
  }
  function updateSetting(newValue, selectedFieldId, keyTitle) {
    var switchData = {
      key_value: newValue,
    };

    ApiService("PUT", "setting/" + selectedFieldId, switchData)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          if (keyTitle) {
            orderSettingsData[keyTitle] = response.data;
            setOrderSettingsData(orderSettingsData);
          }
        } else {
          console.log(response.data);
          getOrderSettings();
        }

        openSnackbar(response.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView">
      <h2>Other Settings</h2>
      <div className="listPaper">
        <Table>
          <TableBody>
            <TableRow hover role="checkbox" tabIndex={-1}>
              <TableRowData>
                {orderSettingsData.min_order.key_display_title}

                <TooltipComponent
                  title="You can turn it on if you do not want to recieve orders below a certain amount."
                  position="bottom"
                  id="min_order"
                >
                  <Info fontSize="small" />
                </TooltipComponent>
              </TableRowData>
              <TableRowData>
                <GSwitch
                  id={orderSettingsData.min_order.id}
                  uId={orderSettingsData.min_order.id}
                  currentValue={minOrderSwitch !== "0" ? "1" : "0"}
                  onSwitchChange={(newVal, id) => {
                    setMinOrderSwitchVal(newVal);
                    switchActionValue(newVal, id, "min_order");
                  }}
                />
              </TableRowData>
              <TableRowData style={{ width: "200px", padding: '0' }}>
                {minOrderSwitch !== "0" ? (
                  <GEditable
                    type="text"
                    title={false}
                    editable={false}
                    defaultValue={orderSettingsData.min_order.key_value}
                    id={orderSettingsData.min_order.id}
                    onSubmit={(newVal, id) => {
                      orderSettingsData.min_order.key_value = newVal;
                      setOrderSettingsData(orderSettingsData);
                      updateField(newVal, id, "min_order");
                    }}
                    style={{ margin: "0px 0 10px" }}
                    inputStyle={{ minWidth: "80px" }}
                  />
                ) : (
                    ""
                  )}
              </TableRowData>
            </TableRow>
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              style={{ background: "#fff" }}
            >
              <TableRowData>
                {orderSettingsData.free_delivery.key_display_title}
                <TooltipComponent
                  title="If turned on, then it offers 0 delivery charges when the order amount is more than the amount set."
                  position="bottom"
                  id={orderSettingsData.free_delivery.id}
                >
                  <Info fontSize="small" />
                </TooltipComponent>
              </TableRowData>
              <TableRowData>
                <GSwitch
                  id={orderSettingsData.free_delivery.id}
                  uId={orderSettingsData.free_delivery.id}
                  currentValue={freeDeliverySwitch !== "0" ? "1" : "0"}
                  onSwitchChange={(newVal, id) => {
                    setFreeDeliverySwitchVal(newVal);
                    switchActionValue(newVal, id, "free_delivery");
                  }}
                />
              </TableRowData>
              <TableRowData style={{ width: "200px", padding: '0' }}>
                {freeDeliverySwitch !== "0" ? (
                  <GEditable
                    type="text"
                    title={false}
                    editable={false}
                    defaultValue={orderSettingsData.free_delivery.key_value}
                    id={orderSettingsData.free_delivery.id}
                    onSubmit={(newVal, id) => {
                      orderSettingsData.free_delivery.key_value = newVal;
                      setOrderSettingsData(orderSettingsData);
                      updateField(newVal, id, "free_delivery");
                    }}
                    style={{ margin: "0px 0 10px" }}
                    inputStyle={{ minWidth: "80px" }}
                  />
                ) : (
                    ""
                  )}
              </TableRowData>
            </TableRow>
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              style={{ background: "#fff" }}
            >
              <TableRowData>
                {orderSettingsData.product_stock.key_display_title}
                {/* <TooltipComponent
                  title="If turned on, then it offers 0 delivery charges when the order amount is more than the amount set."
                  position="bottom"
                  id={orderSettingsData.free_delivery.id}
                >
                  <Info fontSize="small" />
                </TooltipComponent> */}
              </TableRowData>
              <TableRowData>
                <GSwitch
                  id={orderSettingsData.product_stock.id}
                  uId={orderSettingsData.product_stock.id}
                  currentValue={stockSwitch !== "0" ? "1" : "0"}
                  onSwitchChange={(newVal, id) => {
                    setStockSwitchVal(newVal);
                    switchActionValue(newVal, id, "product_stock");
                  }}
                />
              </TableRowData>
              <TableRowData style={{ width: "200px", padding: '0' }}></TableRowData>
            </TableRow>
            <TableRow hover role="checkbox" tabIndex={-1}>
              <TableRowData>
                {orderSettingsData.cancel_order_penality?.key_display_title}

                <TooltipComponent
                  title="You can turn it on if you do not want to apply cancel order penality."
                  position="bottom"
                  id="min_order"
                >
                  <Info fontSize="small" />
                </TooltipComponent>
              </TableRowData>
              <TableRowData>
                <GSwitch
                  id={orderSettingsData.cancel_order_penality?.id}
                  uId={orderSettingsData.cancel_order_penality?.id}
                  currentValue={cancelOrderSwitch !== "0" ? "1" : "0"}
                  onSwitchChange={(newVal, id) => {
                    setCancelOrderSwitchVal(newVal);
                    switchActionValue(newVal, id, "cancel_order");
                  }}
                />
              </TableRowData>
              <TableRowData style={{ width: "200px", padding: '0' }}>
                {cancelOrderSwitch !== "0" ? (
                  <GEditable
                    type="text"
                    title={false}
                    editable={false}
                    defaultValue={orderSettingsData.cancel_order_penality?.key_value}
                    id={orderSettingsData.cancel_order_penality?.id}
                    onSubmit={(newVal, id) => {
                      orderSettingsData.cancel_order_penality.key_value = newVal;
                      setOrderSettingsData(orderSettingsData);
                      updateField(newVal, id, "cancel_order");
                    }}
                    style={{ margin: "0px 0 10px" }}
                    inputStyle={{ minWidth: "80px" }}
                  />
                ) : (
                    ""
                  )}
              </TableRowData>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div >
  );
}
