import React, { useState, useEffect } from "react";
import { makeStyles, Paper, TableContainer, Avatar } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { ApiService, ImgUrl } from "../services";
import { PeopleAlt, Edit, Delete } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import { Link, useHistory } from "react-router-dom";
import CustomerCreate from "../components/createCustomerPopup";
import QueryWith from "../Query";
import { GSearch } from "../gComponents/gSearch";
import useSettings from "../useSettings";
//import { browserHistory } from "react-router";

const columns = [
  { id: "first_name", label: "Name", minWidth: 130 },
  { id: "photo", label: "Photo", minWidth: 50, type: "image" },
  { id: "email", label: "Email", minWidth: 150 },
  { id: "phone", label: "Mobile", minWidth: 120 },
  {
    id: "wallet_points_value",
    label: "Balance",
    minWidth: 90,
    // format: (value) => value.toLocaleString('en-US'),
    format: (value) => value.toFixed(2),
  },
  { id: "created_at_formatted", label: "Created", minWidth: 150 },
  { id: "actions", label: "Actions", minWidth: 60, align: "center" },
];

const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
    marginTop: "10px",
  },
});

export default function Customers(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [ccvisible, setCcvisible] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [customerPaginationData, setCustomerPaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCustomerData, setSelectedCustomerData] = useState({});
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const settings = useSettings(true);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);

  const [currentPage, setCurrentPage] = useState(
    params.get("page") ? params.get("page") : "1"
  );
  const [searchWord, setSearchWord] = useState(
    params.get("search") ? params.get("search") : ""
  );

  const getCustomers = () => {
    var additional = {
      page: currentPage,
      search: searchWord,
    };

    var params = QueryWith(history.location, additional);

    if (params.length > 0) {
      history.push({
        pathname: "/customers",
        state: "customer",
        search: params[0],
      });
    }
    var hitUrl = "customers";

    console.log(hitUrl);

    ApiService("GET", hitUrl, params[0])
      .then((response) => {
        console.log(response);
        if (response.status_code === 1) {
          setCustomerData(response.data.data);
          setCustomerPaginationData(response.data);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCustomers();
  }, [currentPage, history, searchWord]);

  const deleteCustomer = () => {
    var hitUrl = "customers/" + selectedCustomerId;
    ApiService("DELETE", hitUrl)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          openSnackbar(response.message, [5000]);

          getCustomers();
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setCurrentPage(NewPageNo);
    // getCustomers();
  };

  return (
    <div className="mainInnerView">
      <h1>
        <PeopleAlt /> Customers
      </h1>

      <div style={{ display: "inline-block", width: "60%" }}>
        <GSearch
          placeholder="Search by name, email or phone.."
          value={searchWord}
          handleChange={(val) => {
            setSearchWord(val);
            setCurrentPage(1);
          }}
        />
      </div>
      <div
        style={{ display: "inline-block", width: "40%", textAlign: "right" }}
      >
        {customerData.length !== 0 ? (
          <React.Fragment>
            <GPagination
              style={{ display: "inline-block" }}
              count={customerPaginationData.total}
              from={customerPaginationData.from}
              to={customerPaginationData.to}
              onChangeNextPage={() =>
                handleChangePage(customerPaginationData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(customerPaginationData.prev_page_url)
              }
              rowsPerPage={customerPaginationData.per_page}
              prevDisabled={customerPaginationData.prev_page_url ? false : true}
              nextDisabled={customerPaginationData.next_page_url ? false : true}
            ></GPagination>

            {customerPaginationData ? (
              <GPaginationSelect
                defaultvalue={currentPage}
                totalPage={customerPaginationData.last_page}
                onChangePage={(NewPageNo) => setCurrentPage(NewPageNo)}
              />
            ) : (
              ""
            )}
          </React.Fragment>
        ) : (
          ""
        )}
      </div>

      <Paper className={classes.root}>
        {!customerPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer className={classes.container}>
            {customerData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerData.map((c) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData
                          style={{
                            BorderBottom: "1px solid #f4f5f9",
                          }}
                        >
                          <Link
                            to={{
                              pathname: "/customer/" + c.id + "/orders",
                              state: "customer",
                            }}
                          >
                            {c.title}
                          </Link>
                        </TableRowData>
                        <TableRowData>
                          <Avatar
                            alt={c.title}
                            src={ImgUrl("user") + "/" + c.thumb_photo}
                          />
                        </TableRowData>
                        <TableRowData>{c.email}</TableRowData>
                        <TableRowData>{c.phone}</TableRowData>
                        <TableRowData>
                          {c.wallet}({settings.currency_symbol + "" + c.balance}
                          )
                        </TableRowData>
                        <TableRowData>{c.created}</TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              setSelectedCustomerData(c);
                              setCcvisible(true);
                            }}
                          >
                            <Edit fontSize="small" />
                          </GButton>
                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              setSelectedCustomerId(c.id);
                              setDialogOpen(true);
                            }}
                          >
                            <Delete fontSize="small" />
                          </GButton>
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </Paper>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Customer"
          text="Are you sure you want to delete this customer?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteCustomer();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      {ccvisible === true ? (
        <CustomerCreate
          ccvisible={ccvisible}
          setCcvisible={setCcvisible}
          data={selectedCustomerData}
          onSubmit={getCustomers}
        />
      ) : (
        ""
      )}
    </div>
  );
}
