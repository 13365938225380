import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
// import TimePicker from "../gComponents/gTimePicker";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  option: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#888",
    width: "40px",
    height: "40px",
    fontSize: "18px",
    borderRadius: "5px",
    cursor: "pointer",
    //transition: 'background 0.3s',
    transitionProperty: "background-color, color",
    transitionDuration: "300ms, 300ms",
    "&:hover": {
      backgroundColor: "#333",
      color: "#bbb",
      fontWeight: "500",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var buttonStyle = {
  background: "#FF6500",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "100%",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  marginTop: "30px",
  fontWeight: "600",
};

export default function StoreTimeSlotUpdate(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false)

  const initialValues = {
    id:!props.isEmpty?props.data?.id:undefined,
    weekday: !props.isEmpty?props.data.weekday?.slice(0,3):undefined,
    from_time: !props.isEmpty?props.data ? props.data.from_time : '':undefined,
    to_time: !props.isEmpty?props.data ? props.data.to_time : '':undefined,
    store_id:props.storeId,
    status:!props.isEmpty?props.data.status:1
    // order_limit: props.data ? props.data.order_limit : '',
  };

  const showSecond = true;
  const str = showSecond ? "HH:mm:ss" : "HH:mm";

  console.log(props.data + "--" + props.isEmpty);

  const onSubmit = (values) => {
    setIsDisabled(true)
    console.log(values);
    // return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "store-timings/" + props.data.id : "store-timings";
    ApiService(hitMethod, hitUrl, values).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        props.setTsvisible(false);
        props.onSubmit();
        openSnackbar(response.message);
        return;
      }
      else {
        setIsDisabled(false)
        openSnackbar(response.message);
        return;
      }

    });
  };

  const validationSchema = Yup.object({
    from_time: Yup.string().required("Required"),
    to_time: Yup.string().required("Required"),
    weekday: Yup.string().required("Required")
  });

  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setTsvisible(false);
  };


  if (props.tsvisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.tsvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.tsvisible === true ? "fade" : ""}`}
          style={{ padding: "20px" }}
        >
          <h3>{!props.isEmpty ? "Edit" : "Add"} Store Timeslot</h3>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              //  function updateField(newValue){
              //      values.icon = newValue
              // }

              <Form>
                <div
                  style={{
                    padding: "20px 15px 0px",
                  }}
                >
                  <label>Start Time</label>
                  <TimePicker
                    defaultValue={values.from_time ? moment(values.from_time, 'HH:mm:ss') : undefined}
                    defaultOpenValue={values.from_time ? moment(values.from_time, 'HH:mm:ss') : moment()}
                    onChange={(value) => {
                      values.from_time = value && value.format(str);
                      setFieldValue("from_time", value && value.format(str))
                    }}
                    showSecond={showSecond}
                    minuteStep={1}
                    className="xxx"
                    style={{ width: "100%", height: "40px" }}
                  />
                </div>
                <FormikControl
                  control="input"
                  type="text"
                  name="from_time"
                  style={{ display: "none" }}
                />


                <div
                  style={{
                    margin: "10px 15px 0px",
                  }}
                >
                  <label>End Time</label>
                  <TimePicker
                    defaultValue={values.to_time ? moment(values.to_time, 'HH:mm:ss') : undefined}
                    defaultOpenValue={values.to_time ? moment(values.to_time, 'HH:mm:ss') : moment()}
                    onChange={(value) => {
                      values.to_time = value && value.format(str);
                      setFieldValue("to_time", value && value.format(str))
                    }}
                    showSecond={showSecond}
                    minuteStep={1}
                    className="xxx"
                    style={{ width: "100%", height: "40px" }}
                  />
                </div>
                <FormikControl
                  control="input"
                  type="text"
                  name="to_time"
                  style={{ display: "none" }}
                />

                {props.isEmpty?<FormikControl
                  control="input"
                  type="text"
                  label="Weekday"
                  name="weekday"
                />:undefined}

                <br />
                <button style={buttonStyle} type="submit" disabled={isDisabled}>
                  {!props.isEmpty ? "Update" : "Add"}
                </button>
              </Form>
            )}
          </Formik>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}