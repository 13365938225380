import React, { useState, useEffect, useRef } from "react";
import { ApiService, ImgUrl } from "../services";
import { Grid } from "@material-ui/core";
import CustomSeparator from "../gComponents/gBreadCrumbs";
import GInfo from "../gComponents/gInfo";
import { Card, CardHead } from "../styledComponents/cardStyles";
import GItemTable from "../gComponents/gCartItems";
import { GTableContent } from "../gComponents/gContent";
import { AccountBalanceWallet, Add, Info, Send } from "@material-ui/icons";
import { NoteDiv } from "../styledComponents/notesStyles";
import { MessageBox } from "../styledComponents/messageStyles";
import GLightBox from "../gComponents/gLightBox";
import TooltipComponent from "../gComponents/gTooltip";
import OrderTransactionPopup from "../components/OrderTransactionRecieves";
import OrderDeliveryInfo from "../components/orderDeliveryInfo";
import TaskView from "../gComponents/gTaskView";
import TaskStatusTiming from "../gComponents/gTaskStatusView";
import CancelReasonsView from "../components/orderCancelReasons";
import CancelBox from "../gComponents/gCancelBox";
import GAgentView from "../gComponents/gAssignAgentView";
import { useDetectOutsideClick } from "../components/useDetectOutsideClick";
import GDropdown from "../gComponents/gDropdown";
import useSettings from "../useSettings";
import GButton from "../gComponents/gButton";
import { useSnackbar } from "react-simple-snackbar";
import { GContent } from "../gComponents/gContent";
import OrderEdit from "./orderEdit";
import { RepeatOrder } from "../models/RepeatOrder";
import useCart from "../useCart";
import FaultyReasonPopup from "../components/orderReturnPopup";

const fixedDivStyle = {
  display: "flex",
  bottom: "5px",
  right: "0",
  left: "5px",
  width: "95%",
  position: "absolute",
  paddingRight: "10px",
  borderTop: "1px solid #e2e2e2",
  background: "#fff",
  zIndex: "9",
};
const formInputStyle = {
  background: "transparent",
  border: "0",
  padding: "0.8rem",
  fontSize: "0.95rem",
  outline: "0",
  boxShadow: "none",
  width: "90%",
};

export default function OrderDetail(props) {
  const [openSnackbar] = useSnackbar();
  const [ocrvisible, setOcrvisible] = useState(false);
  const [odInfovisible, setOdInfovisible] = useState(false);
  const [transModalVisible, setTransModalVisible] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [noteVal, setNoteValue] = useState("");
  const [messageVal, setMessageValue] = useState("");
  const [orderData, setOrderData] = useState({
    cancelled_log: {},
  });
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const [ordernotes, setOrderNotes] = useState([]);
  const [orderMessages, setOrderMessages] = useState([]);
  const [isImgPreview, setImgPreview] = useState(false);
  const [selectedImgPreview, setSelectedImgPreview] = useState();
  const [previewImgType, setPreviewImgType] = useState("user");
  const [orderItems, setOrderItems] = useState([]);
  const [orderTransaction, setOrderTransactions] = useState([]);
  const [orderPaySummary, setOrderPaySummary] = useState({
    payment: {},
    data: [],
  });
  const [orderCustomer, setOrderCustomer] = useState({});
  const [orderStore, setOrderStore] = useState({});
  const [orderTask, setOrderTask] = useState({});
  const [orderAgent, setOrderAgent] = useState({});

  const [orderReturns, setOrderReturns] = useState({});
  const settings = useSettings(true);
  const ViewDropdownRef = useRef(null);
  const [isViewDropdownActive, setIsViewDropdownActive] = useDetectOutsideClick(
    ViewDropdownRef,
    false
  );
  const { cart, setCart, cartStore, setCartStore } = useCart();
  const [editOrder, setEditOrder] = useState(false);
  const [faultyReasonVisible, setFaultyResonVisible] = useState(false);

  const invoiceList = [
    {
      title: "Thermal Invoice",
      link: "/order-thermal-invoice/" + props.match.params.orderId,
    },
    {
      title: "A4 Invoice",
      link: "/order-invoice/" + props.match.params.orderId,
    },
  ];

  const getOrderDetail = () => {
    var hitUrl = "orders/" + orderId;

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setOrderData(response.data);
          setOrderItems(response.data.order_products);
          setOrderTransactions(response.data.order_transactions);
          setOrderPaySummary({
            payment: response.data.payment_summary,
            data: response.data.payment_summary.data,
          });
          setOrderCustomer(response.data.customer);
          setOrderStore(response.data.store);
          setOrderTask(response.data.delivery || {});
          setOrderAgent({});
          setOrderReturns(response.data.order_returns || {});
          //setOrderStatusList(response.order_status_list);
          var keepGoing = true;
          const listCopy = [...response.options];
          //Logic to update your list here

          listCopy.forEach((user, index) => {
            if (keepGoing === true) {
              user["color"] = "colored";
              console.log("user ===>",user.title + "--" + response.data.status.title);
              if (user.title === response.data.status.title) {
                keepGoing = false;
              }
            }
          });
          setOrderStatusList(listCopy);

          setOrderStatus(response.data.status.title);
          setOrderNotes(response.data.order_notes);
          setOrderMessages(response.data.order_messages);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    console.log(props.match.params.orderId);
    setOrderId(props.match.params.orderId);

    getOrderDetail();
  }, [props, orderId]);

  function updateField(selectedFieldId, photo) {
    console.log(selectedFieldId);
    var post_note = {
      order_id: orderId,
      user_type: "4",
      user_id: "1",
      text:
        selectedFieldId === "notes"
          ? noteVal
          : selectedFieldId === "messages"
            ? messageVal
            : "",
    };

    if (selectedFieldId === "messages") {
      if (photo) {
        post_note.type = "photo";
        post_note.photo = {
          photo: photo.image,
          thumb_photo: photo.thumb_image,
        };
      } else {
        post_note.type = "text";
      }
    }
    console.log(post_note);
    ApiService("POST", "order/" + selectedFieldId, post_note)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          if (selectedFieldId === "notes") {
            setNoteValue("");
            setOrderNotes([...ordernotes, response.data]);
          } else {
            setMessageValue("");
            setOrderMessages([...orderMessages, response.data]);
          }
          openSnackbar(response.message);
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function approveReturnOrder(returnPickupAt) {
    var postReturnData = {
      pickup_at: returnPickupAt || "",
    };

    console.log(postReturnData);
    ApiService(
      "PuT",
      "order/return-products/approve/" + orderReturns.id,
      postReturnData
    )
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          getOrderDetail();
          openSnackbar(response.message);
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="mainInnerView" style={{ padding: "20px 30px" }}>
      <h1 style={{ display: "inline-block" }}>
        #{orderData.alpha_order_no ? orderData.alpha_order_no : orderId} &nbsp;&nbsp;
        <small
          onClick={() => setIsViewDropdownActive(true)}
          style={{
            color: "#f08c37",
            textDecoration: "underline",
            fontStyle: "italic",
            fontSize: "16px",
          }}
        >
          View Invoice
        </small>
        {isViewDropdownActive === true ? (
          <div ref={ViewDropdownRef}>
            <GDropdown
              id="viewInvoice"
              className={`menu ${isViewDropdownActive ? "active" : "inactive"}`}
              listData={invoiceList}
              onSelectValue={() => {
                setIsViewDropdownActive(false);
              }}
            />
          </div>
        ) : (
          ""
        )}{" "}
      </h1>

      {orderStatus &&
        (orderStatus === "Pending" ||
          orderStatus === "Processing" ||
          orderStatus === "Accepted" ||
          orderStatus === "Ready") ? (
        <CancelBox handleClick={() => setOcrvisible(true)} />
      ) : orderStatus === "Cancelled" ? (
        <CancelBox data={orderData.cancelled_log} />
      ) : (
        ""
      )}

      <CustomSeparator statusList={orderStatusList} class={orderStatus} orderId={orderId} />

      <div
        style={{
          width: "67%",
          display: "inline-block",
          paddingRight: "15px",
          boxSizing: "border-box",
        }}
      >
        {editOrder === true ? (
          <OrderEdit
            orderId={orderId}
            cartStore={cartStore}
            cart={cart}
            onUpdate={() => {
              setEditOrder(false);
              getOrderDetail();
            }}
            onCancel={() => setEditOrder(false)}
          />
        ) : (
          <Card>
            <div>
              <CardHead>
                {orderItems.length} {orderItems.length === 1 ? "Item" : "Items"}
                {orderStatus &&
                  (orderStatus === "Pending" ||
                    orderStatus === "Processing" ||
                    orderStatus === "Accepted" ||
                    orderStatus === "Ready") ? (
                  <span
                    style={{
                      float: "right",
                      fontSize: "15px",
                      color: "#f08c37",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setCartStore(orderStore.id);
                      setCart(orderItems.map((o) => new RepeatOrder(o)));
                      setTimeout(function () {
                        setEditOrder(true);
                      }, 1000);
                    }}
                  >
                    Edit Items
                  </span>
                ) : (
                  ""
                )}
              </CardHead>
              <div style={{ maxHeight: "230px", overflow: "overlay" }}>
                <GItemTable
                  items={orderItems}
                  currencySymbol={settings.currency_symbol}
                />
              </div>
            </div>

            <div style={{ textAlign: "right", marginTop: "20px" }}>
              <div style={{ width: "250px", display: "inline-block" }}>
                {orderPaySummary.data.map((p) => {
                  return (
                    <GTableContent
                      key={p.title}
                      title={p.title}
                      value={settings.currency_symbol + "" + p.value}
                      align="right"
                      lAlign="right"
                      color="#777"
                    />
                  );
                })}
                <hr />

                <GTableContent
                  title="Total"
                  value={
                    settings.currency_symbol +
                    "" +
                    orderPaySummary.payment.total_display
                  }
                  align="right"
                  lAlign="right"
                  bold="550"
                />
              </div>
            </div>
          </Card>
        )}

        {orderTask?.status ? (
          <div
            style={{
              width: "60%",
              display: "inline-block",
              paddingRight: "15px",
              boxSizing: "border-box",
            }}
          >
            <Card>
              <CardHead>
                Delivery Task &nbsp;&nbsp;
                {orderTask.status === "completed" ? (
                  <span
                    onClick={() => setOdInfovisible(true)}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "#f08c37",
                      fontSize: "14px",
                    }}
                  >
                    More Info
                  </span>
                ) : (
                  ""
                )}
                <span
                  style={{
                    float: "right",
                    fontSize: "14px",
                    textTransform: "uppercase",
                    padding: "5px 8px",
                    background: "",
                  }}
                >
                  {orderTask.status}
                </span>
              </CardHead>
              <TaskStatusTiming data={orderTask} />
              <hr />
              <br />
              <div>
                <div
                  style={{
                    // width: "230px",
                    // display: "inline-block",
                    verticalAlign: "top",
                    position: "relative",
                  }}
                >
                  <CardHead>Driver Details</CardHead>
                  <GAgentView
                    orderId={props.orderId}
                    taskLog={orderTask.log}
                    isAssign={!orderTask.agent ? false : true}
                    driverData={orderTask.agent}
                    deliveryId={orderTask.id}
                    onAssignTask={() => getOrderDetail()}
                  />
                </div>
                <div
                  style={{
                    width: "95%",
                    marginTop: "15px",
                  }}
                >
                  <TaskView task={orderTask} storeTitle={orderStore.title} />
                </div>
              </div>
              <br />
            </Card>{" "}
          </div>
        ) : (
          ""
        )}

        <div
          style={{
            width: orderTask?.status ? "40%" : "100%",
            display: "inline-block",
            boxSizing: "border-box",
            verticalAlign: "top",
          }}
        >
          <Grid container spacing={1}>
            <Grid item lg={orderTask?.status ? 12 : 6} md={12} xs={12}>
              <Card>
                <CardHead>Order Summary</CardHead>
                <GContent
                  title="Order Type"
                  value={
                    orderData.order_type &&
                    orderData.order_type.replace("_", " ")
                  }
                />
                {orderData.delivery_type ? (
                  <GContent
                    title="Delivery Type"
                    value={orderData.delivery_type}
                  />
                ) : (
                  ""
                )}
                {orderData.delivery_time ? (
                  <GContent
                    title="Delivery Time"
                    value={orderData.delivery_time}
                  />
                ) : (
                  ""
                )}
                {orderData.instructions ? (
                  <GContent
                    title="Instructions"
                    value={orderData.instructions}
                  />
                ) : (
                  ""
                )}
                <br />
              </Card>
            </Grid>

            <Grid item lg={orderTask?.status ? 12 : 6} md={12} xs={12}>
              {orderStatus === "Completed" && orderReturns?.id ? (
                <Card>
                  <CardHead>
                    Customer Concern
                    {orderReturns.status !== "completed" ? (
                      <span
                        style={{
                          color: "#f08c37",
                          textDecoration: "underline",
                          float: "right",
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          orderReturns.reason === "exipred"
                            ? approveReturnOrder()
                            : setFaultyResonVisible(true);
                        }}
                      >
                        Approve
                      </span>
                    ) : (
                      ""
                    )}
                  </CardHead>

                  <span
                    style={{
                      fontSize: "14px",
                      color: "#777",
                      float: "right",
                    }}
                  >
                    {orderReturns.picked ? orderReturns.picked : ""}
                  </span>
                  <span
                    style={{
                      textTransform: "uppercase",
                      fontStyle: "italic",
                      fontSize: "14px",
                      color: "#f08c37",
                    }}
                  >
                    {orderReturns.status}
                  </span>
                  <GContent title="Queried" value={orderReturns.created} />
                  <GContent
                    title="Reason"
                    value={
                      orderReturns.reason.charAt(0).toUpperCase() +
                      orderReturns.reason.slice(1)
                    }
                  />
                  <GContent
                    title="Description"
                    value={orderReturns.description}
                  />
                  <GContent title="Product Image" value="" />
                  <img
                    src={ImgUrl("faulty") + "/" + orderReturns?.thumb_photo}
                    alt=""
                    style={{
                      width: "100px",
                      border: "1px solid #e2e2e2",
                      padding: "4px",
                    }}
                    onClick={() => {
                      setPreviewImgType("faulty");
                      setSelectedImgPreview(orderReturns?.photo);
                      setImgPreview(true);
                    }}
                  />

                  <GContent
                    title={
                      orderReturns.reason.charAt(0).toUpperCase() +
                      orderReturns.reason.slice(1) +
                      " Products"
                    }
                    value=""
                  />
                  {orderItems
                    .filter((item) => {
                      return orderReturns.products.some(
                        (el) => el === JSON.stringify(item.id)
                      );
                    })
                    .map((t, i) => {
                      return (
                        <li>
                          <img
                            src={ImgUrl("item") + "/" + t?.thumb_photo}
                            alt=""
                            style={{
                              width: "50px",
                              border: "1px solid #e2e2e2",
                              padding: "4px",
                              verticalAlign: "middle",
                            }}
                          />
                          &nbsp;&nbsp;
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#555",
                              display: "inline-block",
                              width: "calc(100% - 90px)",
                              verticalAlign: "middle",
                            }}
                          >
                            {t.title}
                          </span>
                        </li>
                      );
                    })}
                  <br />
                </Card>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      <div
        style={{
          width: "33%",
          display: "inline-block",
          verticalAlign: "top",
          boxSizing: "border-box",
        }}
      >
        <Card style={{ padding: "20px 15px 25px" }}>
          <div>
            <CardHead>Customer Details</CardHead>
            <GInfo
              title={orderCustomer.title}
              key={orderCustomer.id}
              subtitle={orderCustomer.phone}
              photo={orderCustomer.thumb_photo}
              imgType="user"
              link={"/customer/" + orderCustomer.id + "/orders"}
            />
          </div>

          <hr style={{ margin: "18px 0" }} />
          <div>
            <CardHead>Store Details</CardHead>
            <GInfo
              title={orderStore.title}
              subtitle={orderStore.address}
              subtitle2={orderStore.phone}
              photo={orderStore.thumb_photo}
              imgType="store"
              link={"/store/" + orderStore.id + "/orders"}
            />
          </div>

          <hr style={{ margin: "18px 0" }} />

          <div>
            {orderData.pending_payment > 0 ? (
              <GButton
                variant="linkable"
                onClick={() => setTransModalVisible(true)}
                style={{
                  float: "right",
                  textDecoration: "underline",
                  marginTop: "-3px",
                }}
              >
                Recieve Payment
              </GButton>
            ) : (
              ""
            )}
            <CardHead>Payment Details</CardHead>

            {orderTransaction.length > 0 ? (
              orderTransaction.map((s) => {
                return (
                  <div key={s.id} style={{ borderBottom: "1px solid #e2e2e2" }}>
                    <AccountBalanceWallet
                      style={{
                        float: "right",
                        color: "#777",
                      }}
                    />
                    <p
                      style={{
                        textTransform: "capitalize",
                        margin: "15px 0 10px",
                      }}
                    >
                      {settings.currency_symbol + s.amount}{" "}
                      <span style={{ color: "#b2b2b2", fontStyle: "italic" }}>
                        {s.gateway ? "(" + s.gateway + ")" : ""}
                      </span>
                    </p>

                    <span
                      style={{
                        background: "#" + s.background,
                        padding: "2px 5px",
                        fontSize: "14px",
                      }}
                    >
                      {s.status_label}
                    </span>
                    <p
                      style={{
                        color: s.status === "pending" ? "#a2a2a2" : "#333",
                        fontSize: "14px",
                        margin: "12px 0 15px 0",
                      }}
                    >
                      {" "}
                      {s.label}
                    </p>
                  </div>
                );
              })
            ) : (
              <React.Fragment>
                <p
                  style={{
                    margin: "20px 0 5px",
                  }}
                >
                  Pending Amount -{" "}
                  <span
                    style={{
                      textTransform: "capitalize",
                      margin: "5px 0",
                      color: "#777",
                    }}
                  >
                    {settings.currency_symbol + orderData.pending_payment}
                    <span style={{ fontStyle: "italic" }}>
                      {" "}
                      {orderData.gateway ? "(" + orderData.gateway + ")" : ""}
                    </span>
                  </span>{" "}
                </p>
                <p style={{ color: "#a2a2a2", fontSize: "14.5px" }}>
                  {" "}
                  No transaction yet
                </p>
              </React.Fragment>
            )}
          </div>
        </Card>
        <Card style={{ padding: "15px 0px", position: "relative" }}>
          <div style={{ padding: "0 15px" }}>
            <CardHead>
              Notes ({ordernotes.length})
              <TooltipComponent
                title="Notes for Customer & Store!"
                position="bottom"
                id="order_notes"
              >
                <Info
                  fontSize="small"
                  style={{ display: "inline-block", color: "#f08c37" }}
                />
              </TooltipComponent>
            </CardHead>
          </div>
          <div
            style={{
              padding: "5px 15px",
              maxHeight: "340px",
              overflow: "overlay",
              marginBottom: "40px",
            }}
          >
            {ordernotes.map((p) => {
              return (
                <NoteDiv key={p.id}>
                  <h5>{p.text}</h5>

                  <p>{p.created}</p>
                </NoteDiv>
              );
            })}
          </div>
          <div style={fixedDivStyle}>
            <input
              type="text"
              value={noteVal}
              id="notes"
              placeholder="Add a note.."
              onChange={(e) => setNoteValue(e.target.value)}
              style={formInputStyle}
            />
            <GButton
              variant="linkable"
              onClick={() => {
                updateField("notes");
              }}
              style={{
                borderRadius: "50%",
                color: "#fff",
                background: "#f08c37",
                marginTop: "7px",
                height: "30px",
                padding: "3px 5px 0 7px",
              }}
            >
              <Send style={{ fontSize: "1.2rem" }} />
            </GButton>
          </div>
        </Card>

        <Card style={{ padding: "15px 0px", position: "relative" }}>
          <div style={{ padding: "0 15px" }}>
            <CardHead>
              {/* <div style={{ float: "right" }}>
                <GImagePicker
                  type="messages"
                  title={
                    <Attachment
                      style={{
                        display: "block",
                        transform: "rotate(-40deg)",
                        color: "#f08c37",
                        fontSize: "1.5rem",
                        borderRadius: "20px",
                      }}
                    />
                  }
                  height={40}
                  width={40}
                  radius={1}
                  //editor
                  editorProps={{
                    height: 200,
                    width: 200,
                    radius: 100,
                  }}
                  serverProps={{
                    size: 200,
                  }}
                  onSuccess={(v) => {
                    console.log(v);
                    updateField("messages", v);
                  }}
                  isPreviewImg={false}
                  style={{ marginTop: "-5px" }}
                />
              </div> */}
              Messages ({orderMessages.length})
              <TooltipComponent
                title="Messages for Customer & Store!"
                position="bottom"
                id="order_messages"
              >
                <Info
                  fontSize="small"
                  style={{ display: "inline-block", color: "#f08c37" }}
                />
              </TooltipComponent>
            </CardHead>
          </div>
          <div
            style={{
              padding: "5px 15px 15px",
              maxHeight: "350px",
              overflow: "overlay",
              marginBottom: "40px",
            }}
          >
            {orderMessages.map((p) => {
              return (
                <div
                  key={p.id}
                  style={{ textAlign: p.user_type === 4 ? "right" : "left" }}
                >
                  <MessageBox
                    className={p.user_type === 4 ? "send" : "received"}
                  >
                    {p.text ? <h5>{p.text}</h5> : ""}
                    {/* {p.photo ? (
                      <img
                        src={ImgUrl("messages") + "/" + p.photo?.thumb_photo}
                        alt=""
                        onClick={() => {
                          setPreviewImgType("user");
                          setSelectedImgPreview(p.photo?.photo);
                          setImgPreview(true);
                        }}
                      />
                    ) : (
                      ""
                    )} */}

                    <p>
                      {p.user_type === 4 ? "" : p.user_title + ", "}
                      {p.created}
                    </p>
                  </MessageBox>
                </div>
              );
            })}
          </div>
          <div style={fixedDivStyle}>
            <input
              type="text"
              value={messageVal}
              id="messages"
              placeholder="Add a message.."
              onChange={(e) => setMessageValue(e.target.value)}
              style={formInputStyle}
            />
            <GButton
              variant="linkable"
              onClick={() => {
                updateField("messages");
              }}
              style={{
                borderRadius: "50%",
                color: "#fff",
                background: "#f08c37",
                marginTop: "7px",
                height: "30px",
                padding: "3px 5px 0 7px",
              }}
            >
              <Send style={{ fontSize: "1.2rem" }} />
            </GButton>
          </div>
        </Card>
      </div>

      {ocrvisible === true ? (
        <CancelReasonsView
          ocrvisible={ocrvisible}
          setOcrvisible={setOcrvisible}
          linkedId={orderId}
          onSubmit={getOrderDetail}
        />
      ) : (
        ""
      )}

      {transModalVisible === true ? (
        <OrderTransactionPopup
          transModalVisible={transModalVisible}
          setTransModalVisible={setTransModalVisible}
          linkedId={orderId}
          amount={settings.currency_symbol + orderData.pending_payment}
          pendingAmount={orderData.pending_payment}
          onSubmit={getOrderDetail}
        />
      ) : (
        ""
      )}

      {odInfovisible === true ? (
        <OrderDeliveryInfo
          odInfovisible={odInfovisible}
          setOdInfovisible={setOdInfovisible}
          linkedId={orderId}
          onSubmit={getOrderDetail}
          notes={orderTask?.delivery_notes}
          images={orderTask?.delivery_images}
          signatures={orderTask?.delivery_signatures}
        />
      ) : (
        ""
      )}

      {isImgPreview === true ? (
        <GLightBox
          images={selectedImgPreview}
          isImgPreview={isImgPreview}
          setImgPreview={setImgPreview}
          imgType={previewImgType}
        ></GLightBox>
      ) : (
        ""
      )}

      <FaultyReasonPopup
        faultyReasonVisible={faultyReasonVisible}
        setFaultyResonVisible={setFaultyResonVisible}
        onPickupSubmit={(pickup) => {
          approveReturnOrder(pickup);
        }}
      />
    </div>
  );
}
