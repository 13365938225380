import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import GEmpty from "../gComponents/gEmpty";
import GListCard from "../gComponents/gListCards";
import GButton from "../gComponents/gButton";
import GTableShimmer from "../gComponents/gTableShimmer";
import { useSnackbar } from "react-simple-snackbar";
import ZipcodePopup from "../components/zipcodeForm";

export default function Areas() {
  const [zipcodeData, setZipcodeData] = useState([]);
  const [selectedZipcode, setSelectedZipcode] = useState({});
  const [zipcodefvisible, setZipcodefvisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar] = useSnackbar();

  const getZipcodes = () => {
    var hitUrl = "zipcodes";

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setZipcodeData(response.data);
          setIsLoading(false);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getZipcodes();
  }, []);

  function deleteZipcode(selectedId) {
    var hitUrl = "zipcodes/" + selectedId;
    ApiService("DELETE", hitUrl)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          openSnackbar(response.message);
          getZipcodes();
        } else {
          openSnackbar(response.message);
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const editZipcode = (selectedRecord) => {
    setSelectedZipcode(selectedRecord);
    setIsEmpty(false);
    setZipcodefvisible(true);
  };
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView">
      <h2>
        Zipcode
        <GButton
          variant="condensed"
          children="Add"
          onClick={() => {
            setSelectedZipcode({});
            setIsEmpty(true);
            setZipcodefvisible(true);
          }}
          style={{
            float: "right",
          }}
        >
          Add
        </GButton>
      </h2>
      <div className="listPaper">
        {isLoading === true ? (
          <GTableShimmer></GTableShimmer>
        ) : zipcodeData.length === 0 ? (
          <GEmpty></GEmpty>
        ) : (
              zipcodeData.map((d) => {
                return (
                  <GListCard
                    setting="Zipcode"
                    title={d.zipcode}
                    listActions={true}
                    id={d.id}
                    key={d.id}
                    onEdit={editZipcode}
                    onDelete={deleteZipcode}
                    data={d}
                  ></GListCard>
                );
              })
            )}
      </div>

      {zipcodefvisible === true ? (
        <ZipcodePopup
          zipcodefvisible={zipcodefvisible}
          setZipcodefvisible={setZipcodefvisible}
          data={selectedZipcode}
          isEmpty={isEmpty}
          onSubmit={(response) => {
            openSnackbar(response.message);
            getZipcodes();
          }}
        />
      ) : (
          ""
        )}
    </div>
  );
}
