import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  option: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#888",
    width: "40px",
    height: "40px",
    fontSize: "18px",
    borderRadius: "5px",
    cursor: "pointer",
    //transition: 'background 0.3s',
    transitionProperty: "background-color, color",
    transitionDuration: "300ms, 300ms",
    "&:hover": {
      backgroundColor: "#333",
      color: "#bbb",
      fontWeight: "500",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var textStyle = {
  background: "#fff",
  border: "1px solid #d2d2d2",
  fontSize: "14px",
  outline: "0",
  color: "#555",
  borderRadius: "0px",
  width: "300px",
  height: "30px",
  padding: "3px 7px",
  marginTop: "10px",
};

var buttonStyle = {
  background: "#f08c37",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "100%",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  marginTop: "30px",
  fontWeight: "600",
};

export default function ChangePassword(props) {
  const classes = useStyles();
  const oldPassword = useRef(null);
  const newPassword = useRef(null);
  const [openSnackbar] = useSnackbar();

  const handleClose = (e) => {
    if (e.target.id !== "2737") {
      return;
    }
    props.setCpvisible(false);
  };

  const handleChangePassword = () => {
    if (!oldPassword.current.value) {
      openSnackbar("Old password is required");
      return;
    }
    if (!newPassword.current.value) {
      openSnackbar("New password is required");
      return;
    }
    if (newPassword.current.value.length < 5) {
      openSnackbar("New password is too short");
      return;
    }
    ApiService("POST", "change-password", {
      old: oldPassword.current.value,
      new: newPassword.current.value,
    }).then((response) => {
      console.log("r" + response.status_code);
      openSnackbar(response.message);
      if (response.status_code === 1) {
        openSnackbar(response.message);
        props.setCpvisible(false);
      } else {
        return;
      }
    });
  };

  if (props.cpvisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: "200px",
        height: "200px",
      }}
    >
      <Backdrop
        id="2737"
        className={classes.backdrop}
        open={props.cpvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper style={{ padding: "30px" }}>
          <h3>Change Password</h3>
          <input
            style={textStyle}
            placeholder="Old Password"
            type="new-password"
            spellCheck="false"
            ref={oldPassword}
          ></input>
          <br />

          <input
            style={textStyle}
            placeholder="New Password"
            type="new-password"
            spellCheck="false"
            ref={newPassword}
          ></input>
          <br />
          <button style={buttonStyle} onClick={handleChangePassword}>
            Update
          </button>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
