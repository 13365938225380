import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Card, CardHead, CardBody } from "../styledComponents/cardStyles";
import { ApiService } from "../services";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import { Link } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import GEmpty from "../gComponents/gEmpty";
import { useHistory,useParams} from "react-router-dom";
import {
  Add,
  Edit,
  Done,
  ArrowForwardIos,
  ArrowBackIos,
} from "@material-ui/icons";


const ContactQueries = ({filter}) => {
    const [openSnackbar] = useSnackbar();
  const [queryResponse, setQueryResponse] = useState([]);
  const [assignText, setAssignText] = useState("");
  const [id, setId] = useState(null);
  const [assignTextBox, setAssignTextBox] = useState(false);
  const [updatePage, setUpdatePage] = useState(false);
  const [status, setStatus] = useState("0");
  const [page, setPage] = useState("1");
  const [lastPage, setLastPage] = useState("");
  const [allData, setAllData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  //   const [selectedReportId, setSelectedReportId] = useState("");

//   To fetch data ===>
  function getQuery() {
    var hitUrl = `query?page=${page}${filter!==""?"&identifier=":""}${filter!==""?filter:""}`;

    console.log("hitURL======>",hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        // console.log("Query response=======>>", response.data);
        if (response.status_code === 1) {
          setQueryResponse(response.data.data);
          setAllData(response.data);
          setLastPage(response.data.last_page)
          setIsLoading(false);
        } else {
            openSnackbar(response.message);
        //   console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
//   ========####
// Taking input value ===>>>
  const handleChange = (e) => {
    setAssignText(e.target.value);
  };
  //checkbox fuction to update status code ===>.. 
  const handleCheck = (e, q) => {
    // console.log("checkk===>>", e.target.checked, q);

    var hitUrl = `query/${q}`;
    const value = {
      status: e.target.checked ? "1" : "0",
    };
    // console.log(hitUrl);

    ApiService("PUT", hitUrl, value)
      .then((response) => {
        // console.log("Query response=======>>", response);

        if (response.status_code === 1) {
          console.log(response.message);
          setUpdatePage(!updatePage);
          openSnackbar(response.message);
          setIsLoading(true);
        } else {
          console.log(response.message);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

//  Function to update assign to data ======>
  const updateData = () => {
    var hitUrl = `query/${id}`;
    const value = {
      assign_to: assignText,
    };
    // console.log(hitUrl);

    ApiService("PUT", hitUrl, value)
      .then((response) => {
        // console.log("Query response=======>>", response);

        if (response.status_code === 1) {
        //   console.log(response.message);
        
          setId(null);
          setUpdatePage(!updatePage);
          setAssignText("");
          setIsLoading(true);
          openSnackbar(response.message);
        } else {
          console.log(response.message);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
// ======###
// Handle Next and Previous page function
const handleNext =()=>{
    console.log("page",lastPage)
    var pageInt = parseInt(page)
    if(pageInt<lastPage){
        setPage(pageInt+1)
        setIsLoading(true);
        console.log("historryy===>", history)
    }else{
        openSnackbar("Last Page");
    }
}
const handlePrev =()=>{
    var pageInt = parseInt(page)
    if(pageInt> 1 ){
        setPage(pageInt-1)
        setIsLoading(true);
    }else{
        openSnackbar("First Page");
    }
}
// ##################
// const handleFilter =()=>{
//   filter==="All"
// }

  useEffect(() => {
    getQuery();
  }, [updatePage, page]);
  useEffect(() => {
    setPage(1)
    getQuery();
  }, [filter]);


    return (
        <>
        
        <div class="tbl-header">
        <table cellpadding="0" cellspacing="0" border="0"></table>
        <table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Reason</th>
              <th>Other</th>
              <th>Assign To</th>
              <th>Toggle/Edit</th>
            </tr>
          </thead>
        </table>
      </div>
      {isLoading === true ? (
            <GTableShimmer></GTableShimmer>
          ) : (
            queryResponse.length ===
                0 ? (
                  <GEmpty></GEmpty>
                ) :
      <div class="tbl-content">
        <table cellpadding="0" cellspacing="0" border="0">
          <tbody>
            {queryResponse.map((q, i) => (
               <tr key={q.id}>
                <td>{q.first_name}</td>
                <td>{q.last_name}</td>
                <td>{q.email}</td>
                <td>{q.reason}</td>
                <td>{q.other}</td>
                <td>
                  {id !== q.id ? (
                    q.assign_to === "" ? (
                      "Not Assign"
                    ) : (
                      q.assign_to
                    )
                  ) : (
                    <input
                      type="text"
                      value={assignText}
                      onChange={(e) => handleChange(e)}
                    />
                  )}
                </td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      defaultChecked={q.status === "1"}
                      onChange={(e) => handleCheck(e, q.id)}
                    />
                    <span class="slider round"></span>
                  </label>
                  &nbsp; &nbsp;
                  {id !== q.id ? (
                    <Edit
                      onClick={() => {
                        setId(q.id);
                        setAssignTextBox(true);
                      }}
                    />
                  ) : (
                    <Done onClick={updateData} />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
          )}
          <div className="buttons">
        <ArrowBackIos onClick={handlePrev} />
        <p className="pagination">
          {allData.from}-{allData.to} of {allData.total}
        </p>
        <ArrowForwardIos onClick={handleNext} />
      </div>
          </>
    )
}

export default ContactQueries
