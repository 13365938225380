import React, { useState, useEffect, useRef } from "react";
import { ApiService } from "../services";
import "../css/tags.css";
import { Close } from "@material-ui/icons";
import { useDetectOutsideClick } from "../components/useDetectOutsideClick";
import GButton from "./gButton";

function GGeofenceInput(props) {
  const [geoTags, setGeoTags] = useState([]);
  const geoTagsRef = useRef(null);
  const [showGeoSuggestions, setShowGeoSuggestions] = useDetectOutsideClick(
    geoTagsRef,
    false
  );
  const [suggestions, setSuggestions] = useState([]);

  console.log(props.data);
  const getGeoTags = () => {
    ApiService("GET", "geofences")
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setSuggestions(response.data);
          let tag = response.data.map((s) => {
            console.log("yes===" + props.data);
            if (props.data.includes(JSON.stringify(s.id))) {
              console.log("yes===" + s.id);
              const found = geoTags.some((el) => el.id === s.id);
              console.log(s.id + "==" + found);
              if (!found) {
                return s;
              }
            }
            //response.data.map((r) => {
          });
          tag = tag.filter(function (element) {
            return element !== undefined;
          });
          setGeoTags(tag);
          console.log(geoTags);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getGeoTags();
  }, []);

  const handleSuggestions = () => {
    console.log(geoTags);
    let oldList = suggestions.map((s) => {
      const found = geoTags.some((el) => el.id === s.id);
      console.log(s.id + "==" + found);
      if (found === false) {
        return s;
      }
    });

    oldList = oldList.filter(function (element) {
      return element !== undefined;
    });
    console.log(oldList);
    setSuggestions(oldList);
  };

  const handleDelete = (i) => {
    let addedTags = geoTags.filter((t, index) => {
      return index !== i;
    });
    setGeoTags([...addedTags]);
    let remainingTags = geoTags.filter((t, index) => {
      return index === i;
    });
    console.log(remainingTags);
    setSuggestions([...suggestions, ...remainingTags]);
    setShowGeoSuggestions(true);
    if (props.showButton === false) {
      props.onSubmit(addedTags);
    }
  };

  const handleAddition = (event) => {
    if (event.key === "Enter") {
      setGeoTags([...geoTags, { title: event.target.value }]);
      event.target.value = "";
    }
  };

  const handleAdd = (t, i) => {
    const found = geoTags.some((el) => el.id === t.id);
    if (!found) {
      geoTags.push(t);
      setGeoTags(geoTags);
    } else {
      setSuggestions([...suggestions, t]);
    }
    setTimeout(function () {}, 2000);
    if (props.showButton === false) {
      props.onSubmit(geoTags);
    }
  };

  return (
    <div ref={geoTagsRef}>
      {props.showButton !== false ? (
        <GButton
          variant="linkable"
          style={{
            marginTop: "-35px",
            float: "right",
            textDecoration: "underline",
          }}
          children="Update"
          onClick={() => props.onSubmit(geoTags)}
        />
      ) : (
        ""
      )}
      <div className="react-tags">
        <ul className="react-tags-list">
          {geoTags.map((t, i) => {
            return (
              <li className="react-tags__selected-tag" key={i}>
                {t.title}
                <Close onClick={() => handleDelete(i)} />
              </li>
            );
          })}
        </ul>
        <input
          type="text"
          className="react-tags-input"
          placeholder="Add a geofence"
          readOnly={true}
          onFocus={() => {
            handleSuggestions();
            setShowGeoSuggestions(true);
          }}
          onKeyUp={() => handleAddition}
        />
      </div>
      <div className="react-tags-suggestions">
        {showGeoSuggestions === true ? (
          <div className="react-tags__suggestions">
            <ul>
              {suggestions.length > 0 ? (
                suggestions.map((s, i) => {
                  return (
                    <li
                      key={i}
                      className="react-tags__selected-tag"
                      onClick={() => {
                        setShowGeoSuggestions(false);
                        handleAdd(s, i);
                      }}
                    >
                      {s.title}
                    </li>
                  );
                })
              ) : (
                <p style={{ color: "#777" }}> &nbsp;&nbsp;No data found</p>
              )}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default GGeofenceInput;
