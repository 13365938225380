import React from "react";
import { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import GButton from "../gComponents/gButton";
import { StoreFilter } from "../components/storeFilterComponent";
import GImageEditor from "../gComponents/gImageEditor";
import { ApiService, ImgUrl, addDefaultSrc } from "../services";

const useStyles = makeStyles({
  root: {
    width: 400,
  },
  heroContainer: {
    width: 350,
    margin: 20,
    marginLeft: 40,
    marginTop: 40,
  },
  imgUploader: {
    padding: 0,
  },
  StoreFilter: {
    marginBottom: 40,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function CreateBanner({
  toggler,
  addBannerToggler,
  setAddBannerToggler,
  category,
  device
}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });
  const [bannerData, setBannerData] = useState([]);
  const [photo, setPhoto] = useState(undefined);
  const [identi, setIdenti] = useState(category);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setAddBannerToggler(state.right);
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {/* <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
    </div>
  );

  let history = useHistory();
  let params = new URLSearchParams(history.location.search);

  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    order_status: params.get("order_status")
      ? params.get("order_status")
      : "pending",
    store_id: params.get("store_id") ? params.get("store_id") : "",
    customer_id: params.get("customer_id") ? params.get("customer_id") : "",
    delivery_type: params.get("delivery_type")
      ? params.get("delivery_type")
      : "",
    order_type: params.get("order_type") ? params.get("order_type") : "",
    orderby: params.get("orderby") ? params.get("orderby") : "created_at",
    order: params.get("order") ? params.get("order") : "DESC",
  });

  console.log("crreate banner filter====>",filters.store_id);
  const getBannersList = () => {
    var hitUrl = "offer-banners";

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setBannerData(response.data);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateField = (newValue) => {
    setPhoto(newValue);
  };

  const postData = ()=>{
    var hitUrl = "offer-banners";

    if (filters.store_id === "") {
      var send_data = {
        // type: "mobile",
        photo: photo,
        identifier:category,
        store_id: "",
      };
    } else {
      var send_data = {
        // type: "mobile",
        photo: photo,
        identifier:category,
        store_id: filters.store_id,
      };
    }
    ApiService("POST", hitUrl, send_data)
    .then((response) => {
      console.log("gh" + JSON.stringify(response));
      if (response.status_code === 1) {
        getBannersList();
      } else {
        console.log(response.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
    setAddBannerToggler(state.right);
    setState({ ...state, ["right"]: false });
  };
   


  const [permission, setPermission] = useState(false);

  toggler(toggleDrawer("right", true));

  return (
    <div style={{ width: "400px" }}>
      <React.Fragment>
        {/* <Button onClick={toggleDrawer('right', true)}>{'right'}</Button> */}
        <Drawer
          className={classes.root}
          anchor={"right"}
          open={addBannerToggler}
          onClose={toggleDrawer("right", false)}
        >
          {/* {list('right')} */}

          <div className={classes.heroContainer}>
            <Typography variant="h6"> Add banner</Typography>

            <div className={classes.imgUploader}>
              <GImageEditor
                onSubmit={updateField}
                // permission = {permission}
                id="photo"
                type="banners"
                category= {category}
                style={{ height: "190px", paddingTop: "80px" }}
              />
            </div>
            <div className={classes.StoreFilter}>
              <StoreFilter
                required={true}
                value={filters.store_id}
                style={{
                  width: "95%",
                  marginBottom: "10px",
                  marginRight: "10px",
                }}
                handleStoreChange={(value) => {
                  setFilters({
                    store_id: value,
                    order_status: filters.order_status,
                  });
                }}
                />
            </div>
            <div className={classes.StoreFilter}>
            <select disabled style={{
                  width: "95%",
                  marginBottom: "10px",
                  marginRight: "10px",
                }} >
                <option >{category}</option>
              </select>
              {/* <select style={{
                  width: "95%",
                  marginBottom: "10px",
                  marginRight: "10px",
                }} onChange={(e)=>setIdenti(e.target.value)}>
                <option value="app">App</option>
                <option value="web">Website</option>
              </select> */}
            </div>

                {/* {filters.store_id ? ( */}
              <GButton
                variant="condensed"
                // disabled={true}
                
                onSubmit={() => setPermission(true)}
                style={{
                  // margin: "0",
                  width: "95%",
                }}
                children={"Add"}
                type="submit"
                onClick={() => postData()} />
            {/* // ) : (
            //   <GButton
            //     variant="condensed"
            //     disabled={true}
            //     style={{
            //       // margin: "0 15px",
            //       width: "95%",
            //     }}
            //     children={"Add"}
            //     type="submit"
            //     onClick={toggleDrawer("right", false)}
            //   />
            // )} */}

            {/* <GButton
              variant="condensed"
              disabled={true}
              style={{
                margin: "0 15px",
                width: "95%",
              }}
              children={"Add"}
              type="submit"
              onClick={toggleDrawer("right", false)}
            /> */}
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
