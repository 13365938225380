import React, { useState, useEffect } from "react";
import { makeStyles, Paper, TableContainer, Avatar } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { ApiService, ImgUrl } from "../services";
import { ShoppingBasket, Edit, Delete } from "@material-ui/icons";
import itemPlaceholder from "../assets/images/placeholders/p-item.png";
import CatIcon from "../assets/images/category.svg";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GPagination from "../gComponents/gPagination";
import { useHistory, Link } from "react-router-dom";
import ProductCreatePopup from "../components/productMasterCreateForm";
import ProductEditPopup from "../components/productMasterEditForm";
import QueryWith from "../Query";
import GSelectbox from "../gComponents/gSelectbox";
import { GSearch } from "../gComponents/gSearch";
import useSettings from "../useSettings";
//import { browserHistory } from "react-router";

const columns = [
  { id: "title", label: "Title", minWidth: 130 },
  { id: "category", label: "Subcategory", minWidth: 150 },
  { id: "photo", label: "Photo", minWidth: 50, type: "image" },
  { id: "price", label: "Price", minWidth: 120, align: "center" },
  { id: "unit", label: "Unit", minWidth: 120, align: "center" },
  { id: "actions", label: "Actions", minWidth: 60, align: "CENTER" },
];

const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
    marginTop: "10px",
  },
});

export default function Products(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  // const [provisible, setProvisible] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [productsPaginationData, setProductsPaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProductData, setSelectedProductData] = useState({});
  const [selectedProductId, setSelectedProductId] = useState("");
  const [pmcvisible, setPmcvisible] = useState(false);
  const [pmevisible, setPmevisible] = useState(false);
  const [productCatData, setProductCatData] = useState([]);
  const settings = useSettings(false);

  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    search: params.get("search") ? params.get("search") : "",
    category: params.get("category") ? params.get("category") : "",
  });

  console.log(filters);
  const getProducts = () => {
    var params = QueryWith(history.location, filters);
    if (params.length > 0) {
      history.push({
        pathname: "/master-products",
        state: "product",
        search: params[0],
      });
    }

    console.log(params);
    var hitUrl = "product/masters";

    console.log(hitUrl);

    ApiService("GET", hitUrl, params[0])
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setProductsData(response.data.data);
          setProductsPaginationData(response.data);
        } else {
          console.log(response.data);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    ApiService("GET", "product/group").then((response) => {
      console.log("r" + JSON.stringify(response.data));
      if (response.status_code === 1) {
        setProductCatData(response.data);
        getProducts();
      } else {
        // openSnackbar(response.message);
        return;
      }

      //return response.data ? response.data : [];
    });
  }, []);

  useEffect(() => {
    getProducts();
  }, [filters.category, filters.search, filters.status, filters.page]);

  function addDefaultLogo(e) {
    e.target.src = itemPlaceholder;
  }
  const updateSubaCategory = (newVal, selectedProductId, index) => {
    var sendData = {
      categories: newVal,
    };
    console.log(sendData);
    ApiService("PUT", "product/masters/" + selectedProductId, sendData).then(
      (response) => {
        console.log("r" + JSON.stringify(response.data));
        if (response.status_code === 1) {
          productsData[index] = response.data;
          setProductsData(productsData);
          //getProducts();
          openSnackbar(response.message);
        }
        return;
        //return response.data ? response.data : [];
      }
    );
  };

  const deleteProduct = () => {
    var hitUrl = "product/masters/" + selectedProductId;
    ApiService("DELETE", hitUrl)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          openSnackbar(response.message, [5000]);
          getProducts();
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ page: NewPageNo });
  };

  return (
    <div className="mainInnerView">
      <h1>
        <ShoppingBasket /> Master Products
      </h1>

      <React.Fragment>
        <div
          style={{
            display: "inline-flex",
            width: "60%",
            verticalAlign: "top",
          }}
        >
          <GSearch
            placeholder="Search by title.."
            value={filters.search}
            handleChange={(val) => {
              setFilters({
                search: val,
                store_id: filters.store_id,
              });
              // getProducts();
            }}
          />

          <GSelectbox
            placeholder="Select Subcategory"
            defaultvalue={filters.category}
            data={productCatData}
            id="category_filter"
            option_key="title"
            option_value="id"
            optGroup={true}
            onChange={(newVal) => {
              setFilters({
                category: newVal,
                store_id: filters.store_id,
                // status: filters.status,
                // search: filters.search,
              });
              // getProducts();
            }}
            style={{
              border: "1px solid #e2e2e2",
              height: "40px",
              marginLeft: "10px",
            }}
          />
        </div>
        <div
          style={{
            display: "inline-block",
            width: "40%",
            paddingTop: "10px",
          }}
        >
          <GButton
            variant="condensed"
            children="Add"
            onClick={() => {
              setPmcvisible(true);
            }}
            style={{
              float: "right",
              marginTop: "-5px",
            }}
          >
            Add
          </GButton>
          {productsData.length !== 0 ? (
            <GPagination
              count={productsPaginationData.total}
              from={productsPaginationData.from}
              to={productsPaginationData.to}
              onChangeNextPage={() =>
                handleChangePage(productsPaginationData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(productsPaginationData.prev_page_url)
              }
              rowsPerPage={productsPaginationData.per_page}
              prevDisabled={productsPaginationData.prev_page_url ? false : true}
              nextDisabled={productsPaginationData.next_page_url ? false : true}
            ></GPagination>
          ) : (
            ""
          )}
        </div>

        <Paper className={classes.root}>
          {!productsPaginationData ? (
            <GTableShimmer></GTableShimmer>
          ) : (
            <TableContainer className={classes.container}>
              {productsData.length === 0 ? (
                <GEmpty></GEmpty>
              ) : (
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) =>
                        column.id === "product_stock" &&
                        settings.product_stock === "0" ? (
                          ""
                        ) : (
                          <TableHeader
                            key={column.id}
                            style={{
                              minWidth: column.minWidth,
                              textAlign: column.align,
                            }}
                          >
                            {column.label}
                          </TableHeader>
                        )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productsData.map((c, i) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={c.id}
                        >
                          <TableRowData
                            style={{
                              BorderBottom: "1px solid #f4f5f9",
                            }}
                          >
                            {c.title}
                          </TableRowData>
                          <TableRowData>
                            {/* {c.categories_title} */}

                            {productCatData ? (
                              <GSelectbox
                                placeholder=""
                                defaultvalue={c.categories || ""}
                                display="block"
                                data={productCatData}
                                id={c.id}
                                option_key="title"
                                option_value="id"
                                optGroup={true}
                                onChange={(newVal) => {
                                  setSelectedProductId(c.id);
                                  updateSubaCategory(newVal, c.id, i);
                                }}
                                style={{
                                  border: "0",
                                  background: "#edf7fc",
                                  WebkitAppearance: "none",
                                  borderRadius: "20px",
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </TableRowData>
                          <TableRowData>
                            <img
                              alt={c.title}
                              src={ImgUrl("item") + "/" + c.thumb_photo}
                              onError={addDefaultLogo}
                              style={{
                                height: "45px",
                                width: "45px",
                                borderRadius: "50%",
                              }}
                            />
                          </TableRowData>
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {settings.currency_symbol}
                            {c.price}
                          </TableRowData>
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {c.unit || "-"}
                          </TableRowData>

                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <GButton
                              variant="linkable"
                              onClick={(e) => {
                                setSelectedProductData(c);
                                setPmevisible(true);
                              }}
                            >
                              <Edit fontSize="small" />
                            </GButton>
                            <GButton
                              variant="linkable"
                              onClick={(e) => {
                                setSelectedProductId(c.id);
                                setDialogOpen(true);
                              }}
                            >
                              <Delete fontSize="small" />
                            </GButton>
                          </TableRowData>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          )}
        </Paper>
      </React.Fragment>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Master Product"
          text="Are you sure you want to delete this master product?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteProduct();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      {pmcvisible === true ? (
        <ProductCreatePopup
          pmcvisible={pmcvisible}
          setPmcvisible={setPmcvisible}
          onSubmit={getProducts}
        />
      ) : (
        ""
      )}

      {pmevisible === true ? (
        <ProductEditPopup
          pmevisible={pmevisible}
          setPmevisible={setPmevisible}
          storeId={filters.store_id}
          data={selectedProductData}
          onSubmit={getProducts}
          isStockShow={settings.product_stock}
        />
      ) : (
        ""
      )}
    </div>
  );
}
