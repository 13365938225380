import React, { useState, useEffect } from "react";
import { makeStyles, Paper, Backdrop } from "@material-ui/core";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  option: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#888",
    width: "40px",
    height: "40px",
    fontSize: "18px",
    borderRadius: "5px",
    cursor: "pointer",
    //transition: 'background 0.3s',
    transitionProperty: "background-color, color",
    transitionDuration: "300ms, 300ms",
    "&:hover": {
      backgroundColor: "#333",
      color: "#bbb",
      fontWeight: "500",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var buttonStyle = {
  background: "#f08c37",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "100%",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  marginTop: "30px",
  fontWeight: "600",
};

export default function CreateCoupon(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [faqTypesList, setFaqTypesList] = useState([]);
  const initialValues = {
    question: !props.isEmpty ? props.data.question : "",
    answer: !props.isEmpty ? props.data.answer : "",
    user_type: props.userType | "",
    faq_type: !props.isEmpty ? props.data.faq_type : "",
  };

  useEffect(() => {
    console.log(props.faqTypes);
    setFaqTypesList(props.faqTypes);
  }, [props]);
  const onSubmit = (values) => {
    console.log(values);

    //return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "faqs/" + props.data.id : "faqs";
    ApiService(hitMethod, hitUrl, values).then((response) => {
      console.log("r" + response.status_code);
      openSnackbar(response.message);
      if (response.status_code === 0) {
        return;
      }
      props.onSubmit();
      props.setCfvisible(false);
    });
  };

  const validationSchema = Yup.object({
    question: Yup.string().required("Required"),
    answer: Yup.string().required("Required"),
    faq_type: faqTypesList.length > 0 ? Yup.string().required("Required") : "",
  });

  //console.log(handleChange);
  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setCfvisible(false);
  };

  // const handleChange = (e) => {
  //   let value = e.target.value;
  //   this.setState((prevState) => ({
  //     newUser: {
  //       ...prevState.newUser,
  //       name: value,
  //     },
  //   }));
  // };

  if (props.cfvisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.cfvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.cfvisible === true ? "fade" : ""}`}
          style={{ padding: "20px" }}
        >
          <h3>{!props.isEmpty ? "Edit" : "Add"} FAQ</h3>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="Question"
                name="question"
              />
              <FormikControl
                control="input"
                as="textarea"
                label="Answer"
                name="answer"
                rows="4"
              />
              {faqTypesList.length > 0 ? (
                <FormikControl
                  control="select"
                  as="select"
                  label="Faq Type"
                  name="faq_type"
                  options={faqTypesList}
                  key_title="title"
                  key_value="identifier"
                />
              ) : (
                  ""
                )}
              <br />
              <button style={buttonStyle} type="submit">
                {!props.isEmpty ? "Update" : "Create"}
              </button>
            </Form>
          </Formik>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
