import React from "react";
import styled from "styled-components";
import { Info } from "@material-ui/icons";
import TooltipComponent from "../gComponents/gTooltip";
const H4 = styled.h4`
  color: #000;
  font-size: 15px;
  margin: 5px 0;
  font-weight: 500;
`;
const P = styled.p`
  color: #333;
  font-size: 14px;
  margin: 0px;
  line-height: 19px;
`;

const Table = styled.table`
  width: 100%;

  tr.line {
    td {
      padding: 0px;
      border-top: 1px solid #e2e2e2;
    }
  }

  td {
    padding: 6px 5px;
  }
`;

export const GContent = (props) => {
  return (
    <div style={{ padding: "5px 0" }}>
      <H4>
        {props.title}
        {props.tooltip === true ? (
          <TooltipComponent
            title={props.tContent}
            position="bottom"
            id={props.title}
          >
            <Info fontSize="small" />
          </TooltipComponent>
        ) : (
          ""
        )}
      </H4>
      <P> {props.value}</P>
    </div>
  );
};

export const GTableContent = (props) => {
  return (
    <Table>
      <tbody>
        <tr style={{ fontWeight: props.bold }} className={props.title}>
          <td style={{ textAlign: props.lAlign ? "left" : "left" }}>
            {props.title === "line" ? (
              ""
            ) : (
              <P style={{ color: props.color }}>{props.title}</P>
            )}
          </td>
          <td
            style={{
              textAlign: props.align ? props.align : "left",
              width: "50%",
            }}
          >
            {props.title === "line" ? (
              ""
            ) : (
              <P style={{ color: props.color }}>
                {props.value}
                {props.innerValue ? <span>({props.innerValue})</span> : ""}
              </P>
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
