import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { Add, Edit, NavigateBefore } from "@material-ui/icons";
import GInfo from "../gComponents/gInfo";
import { Card, CardHead } from "../styledComponents/cardStyles";
import { GContent } from "../gComponents/gContent";
import { Switch, Route, Link } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import Orders from "../orders/ordersList";
import WalletPoints from "../components/walletPointsList";
import Documents from "../components/documentsList";
import GMap from "../gComponents/gMaps";
import StoreEditForm from "../components/storeEditForm";
import StoreManagerForm from "../components/storeManagerForm";
import { useSnackbar } from "react-simple-snackbar";
import GTagsInput from "../gComponents/gTagsInput";
import GButton from "../gComponents/gButton";
import useSettings from "../useSettings";
import GGeoTagsInput from "../gComponents/gGeofencesInput";
import GEmpty from "../gComponents/gEmpty";
import GRouting from "../gComponents/gRouteBreadCrumbs";
import TimeSlots from "../components/timeSlotsStoreCustomized";

export default function StoreDetail(props) {
  const [storeId, setStoreId] = useState(props.match.params.storeId);
  const [storeData, setStoreData] = useState({});
  const [timeSlotGtab, setTimeSlotGtab] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [storeLocationData, setStoreLocationData] = useState({
    lat: "",
    lon: "",
  });
  const [storeManagerData, setStoreManagerData] = useState({});
  const [typeValue, setTypeValue] = useState("orders");
  const [csmvisible, setCsmvisible] = useState(false);
  const [sevisible, setSevisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [locEdit, setLocEdit] = useState(false);
  const [tagsEdit, setTagsEdit] = useState(false);
  const settings = useSettings(false);
  const [storeLocation, setLocation] = useState({
    latitude: "",
    longitude: "",
    address: "",
  });

  const RoutLinks = [
    {
      title: "Stores",
      link: "/stores",
      state: "store",
    },
  ];

  const faqRoutes = [
    {
      title: "orders",
      link: "/store/" + storeId + "/orders",
      state: "store",
    },
    {
      title: "wallet",
      link: "/store/" + storeId + "/wallet",
      state: "store",
    },
    {
      title: "documents",
      link: "/store/" + storeId + "/documents",
      state: "store",
    },
    {
      title: "manager-documents",
      link: "/store/" + storeId + "/manager-documents",
      state: "store",
    },
    {
      title: "time-slots",
      link: "/store/" + storeId + "/time-slots",
      state: "store",
    },
  ];

  const getStoreDetail = () => {
    var hitUrl = "stores/" + storeId;

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setStoreData(response.data);
          setStoreManagerData(response.data.manager);
          setStoreLocationData({
            lat: response.data.latitude,
            lon: response.data.longitude,
            address: response.data.address,
          });
          console.log(response.data.manager);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    console.log(props.match.params.storeId);
    setStoreId(props.match.params.storeId);

    getStoreDetail();
  }, [props, storeId]);

  function handleChange(newValue) {
    console.log("n" + newValue);
    setTypeValue(newValue);
  }
  function handleClick() {
    setTimeSlotGtab(!timeSlotGtab);
  }
  function handleUpdateImage(imgValue, id, thumbImg) {
    console.log(imgValue);
    var data = { photo: imgValue, thumb_photo: thumbImg };
    ApiService("PUT", "stores/" + storeId, data).then((response) => {
      console.log("r" + response.status_code);
      openSnackbar(response.message);
      if (response.status_code === 1) {
        setStoreData(response.data);
      } else {
        return;
      }
    });
  }
  const handleLocation = (address, loc) => {
    console.log(address);
    console.log(loc);
    console.log(storeLocationData);
    var send_loc_json = {
      latitude: storeLocationData.lat,
      longitude: storeLocationData.lon,
      address: storeLocationData.address,
    };
    console.log(storeLocation);
    var sendData = storeLocation.latitude ? storeLocation : send_loc_json;

    console.log(sendData);
    // return;
    ApiService("PUT", "stores/" + storeId, sendData).then((response) => {
      console.log("r" + response.status_code);
      openSnackbar(response.message);
      if (response.status_code === 1) {
        getStoreDetail();
        setLocEdit(false);
      } else {
        return;
      }
    });
  };
  const handleTagsUpdate = (tags, titlekey) => {
    console.log(tags);
    let obj = {};
    var send_tag_json = [];
    tags.map((s) => {
      send_tag_json.push(s);
    });
    console.log(send_tag_json);
    obj[titlekey] = send_tag_json;
    //return;
    ApiService("PUT", "stores/" + storeId, obj).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        setTagsEdit(false);
        getStoreDetail();
        openSnackbar(response.message);
      } else {
        openSnackbar(response.message);
        return;
      }
    });
  };
  const goBack = () => {
    props.history.goBack();
  };

  return (
    <div className="mainInnerView">
      <GRouting links={RoutLinks} currentPage={storeData.title} />
      <div style={{ width: "50%", display: "inline-block" }}>
        <GInfo
          id={storeData.id}
          title={storeData.title}
          subtitle={storeData.phone}
          key={storeData.id}
          className="headerInfo"
          imgType="store"
          imgEdit={true}
          photo={storeData.photo}
          uploadImage={handleUpdateImage}
        />
      </div>
      <br />

      <div
        style={{
          width: "30%",
          display: "inline-block",
          padding: "10px",
          verticalAlign: "top",
          boxSizing: "border-box",
        }}
      >
        <Card
          style={{
            borderBottom: "1px solid #e2e2e2",
            margin: "0",
            padding: "20px",
          }}
        >
          <div>
            <CardHead>
              Manager
              {storeManagerData.id ? (
                <Edit
                  onClick={() => {
                    setIsEmpty(false);
                    setCsmvisible(true);
                  }}
                />
              ) : (
                <Add
                  onClick={() => {
                    setIsEmpty(true);
                    setCsmvisible(true);
                  }}
                />
              )}
            </CardHead>

            {storeManagerData.id ? (
              <div>
                <GInfo
                  title={storeManagerData.first_name}
                  subtitle={storeManagerData.phone}
                  key={storeManagerData.id}
                  imgType="user"
                  photo={storeManagerData.photo}
                />
              </div>
            ) : (
              <p
                style={{
                  color: "#555",
                  padding: "10px 0 5px",
                  margin: "0",
                  fontSize: "14px",
                }}
              >
                Not Avaliable
              </p>
            )}
          </div>
        </Card>
        <Card style={{ minHeight: "200px", padding: "20px" }}>
          <div>
            <CardHead>
              Store Details
              <Edit
                onClick={() => {
                  setSevisible(true);
                }}
              />
            </CardHead>
            <GContent title="Commission" value={storeData.commission + "%"} />
            <GContent
              title="Store Availability"
              value={storeData.store_status === 1 ? "OPEN" : "CLOSED"}
            />
            <GContent
              title="Balance"
              value={settings.currency_symbol + "" + storeData.balance}
            />
            {/* <GContent
              title="Featured"
              value={storeData.featured === 1 ? "TRUE" : "FALSE"}
              tooltip={true}
              tContent="Featured Stores will be shown on Top of the Stores List."
            /> */}
          </div>
        </Card>
        <Card style={{ minHeight: "130px", padding: "20px" }}>
          <div>
            <CardHead>
              Tags
              {tagsEdit === true ? (
                ""
              ) : (
                <Edit
                  onClick={() => {
                    setTagsEdit(true);
                  }}
                />
              )}
            </CardHead>

            {storeData && storeData.tags ? (
              <GTagsInput
                data={storeData.tags}
                tagsEdit={tagsEdit}
                setTagsEdit={setTagsEdit}
                onSubmit={(tags) => handleTagsUpdate(tags, "tags")}
              />
            ) : (
              ""
            )}
          </div>
        </Card>

        <Card style={{ minHeight: "200px" }}>
          <div>
            <CardHead>
              Location
              {locEdit === true ? (
                <React.Fragment>
                  <GButton
                    variant="linkable"
                    children="Cancel"
                    style={{
                      float: "right",
                      padding: "3px 3px 20px",
                      fontSize: "15px",
                      color: "#fb1d04",
                    }}
                    onClick={() => {
                      setLocation({
                        lat: "",
                        lng: "",
                        address: "",
                      });
                      setLocEdit(false);
                    }}
                  ></GButton>
                  <GButton
                    variant="linkable"
                    children="Update"
                    style={{
                      float: "right",
                      padding: "3px 3px 20px",
                      fontSize: "15px",
                    }}
                    onClick={handleLocation}
                  ></GButton>
                </React.Fragment>
              ) : (
                <Edit
                  onClick={() => {
                    setLocEdit(true);
                  }}
                />
              )}
            </CardHead>
            {locEdit === true ? (
              ""
            ) : (
              <GContent title="" value={storeData.address} />
            )}
            {storeLocationData.lat ? (
              <GMap
                centerLocation={{
                  lat: storeLocationData.lat,
                  lng: storeLocationData.lon,
                }}
                // onchange={handleLocation}
                onChange={(address, loc) =>
                  setLocation({
                    latitude: loc.lat(),
                    longitude: loc.lng(),
                    address: address.address[0].name,
                  })
                }
                search={locEdit}
                height="200px"
                type="viewStoreLoc"
                address={storeData.address}
              />
            ) : (
              ""
            )}
          </div>
        </Card>

        {csmvisible === true ? (
          <StoreManagerForm
            csmvisible={csmvisible}
            storeId={storeId}
            setCsmvisible={setCsmvisible}
            data={storeManagerData}
            isEmpty={isEmpty}
            onSubmit={getStoreDetail}
          />
        ) : (
          ""
        )}

        {sevisible === true ? (
          <StoreEditForm
            sevisible={sevisible}
            setSevisible={setSevisible}
            data={storeData}
            onSubmit={getStoreDetail}
          />
        ) : (
          ""
        )}
      </div>

      <div
        style={{
          width: "calc(100% - 30%)",
          display: "inline-block",
          padding: "10px",
          verticalAlign: "top",
          boxSizing: "border-box",
        }}
      >
        <GTabs
          timeSlotGtab={timeSlotGtab}
          setTimeSlotGtab={setTimeSlotGtab}
          routes={faqRoutes}
          active={typeValue}
          key={storeId}
        ></GTabs>

        <Switch>
          <Route exact path={"/store/" + storeId + "/orders"}>
            <Orders
              onChange={handleChange}
              type="store"
              value={storeId}
              title="store_id"
            />
          </Route>
          <Route exact path={"/store/" + storeId + "/wallet"}>
            <WalletPoints
              onChange={handleChange}
              type="store"
              value={storeId}
              title="store_id"
            />
            {/* <h1>Wallet</h1> */}
          </Route>
          <Route exact path={"/store/" + storeId + "/documents"}>
            <Documents
              onChange={handleChange}
              type="store"
              value={storeId}
              title="store_id"
            />
          </Route>
          <Route exact path={"/store/" + storeId + "/manager-documents"}>
            <Documents
              onChange={handleChange}
              type="manager"
              value={storeManagerData.id}
              title="user_id"
            />
          </Route>
          <Route exact path={"/store/" + storeId + "/time-slots"}>
            <TimeSlots
              props={props}
              onClick={handleClick}
              title="time_slots"
              // onChange={handleChange}
            />
            {/* <Documents
              onChange={handleChange}
              type="store"
              // value={storeManagerData.id}
              title="time-slots"
            /> */}
          </Route>
        </Switch>
      </div>
    </div>
  );
}
