import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import GTabs from "../gComponents/gTabs";
import { Card, CardHead, CardBody } from "../styledComponents/cardStyles";
import { ApiService } from "../services";
import GButton from "../gComponents/gButton";
import { Link } from "react-router-dom";

export default function ReportsList({ match }) {
  // const [reportTypes, setReportTypes] = React.useState([]);
  const [reportsData, setReportData] = React.useState([]);
  const [selectedReportId, setSelectedReportId] = useState("");
  // const reportTypes = ["products", "sales", "stores", "orders", "tasks"];

  // const [typeValue, setTypeValue] = React.useState(reportTypes[0]);
  function getReports() {
    var hitUrl = "reports";

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          //setReportTypes(response.type);
          setReportData(response.data);
          // setTypeValue(response.type[0]);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    // console.log(typeValue);
    getReports();
  }, []);
  // const handleChangeTab = (newVal) => {
  //   console.log(newVal);
  //   setTypeValue(newVal);
  // };

  return (
    <div className="rightInnerView">
      <h2>Reports</h2>

      {/* {reportTypes ? (
        <GTabs
          routes={reportTypes}
          active={typeValue}
          type="single"
          onClick={handleChangeTab}
        ></GTabs>
      ) : (
        ""
      )} */}
      <div className="listPaper" style={{ padding: "30px" }}>
        <Grid container spacing={3}>
          {reportsData
            // .filter((r) => r.type === typeValue)
            .map((c) => {
              return (
                <Grid item lg={4} xs={6} key={c.key}>
                  <Card
                    style={{
                      // background: "#f9f9f9",
                      minHeight: "130px",
                      padding: "18px",
                      border: "1px solid #f2f2f2",
                      marginBottom: "10px",
                    }}
                  >
                    <CardHead>{c.title}</CardHead>
                    <CardBody style={{ fontSize: "15.5px" }}>{c.desc}</CardBody>
                    <Link to={{ pathname: "/report/details/" + c.key, state: 'report' }}>
                      <GButton
                        variant="linkable"
                        children="Generate"
                        style={{
                          float: "right",
                          padding: "12px 3px",
                          fontSize: "15px",
                        }}
                      ></GButton>
                    </Link>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </div>
    </div>
  );
}
