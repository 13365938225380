import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import GButton from "../gComponents/gButton";
import GPricingListCard from "../gComponents/gPricingListCards";
import PricingRuleCreate from "../components/pricingRuleCreate";
import GEmpty from "../gComponents/gEmpty";
import GShimmer from "../gComponents/gTableShimmer"
import { useSnackbar } from "react-simple-snackbar";

export default function PricingRules() {
  const [rulesData, setRulesData] = useState([]);
  const [prvisible, setPrvisible] = useState(false);
  const [selectedRule, setSelectedRule] = useState({});
  const [defaultRule, setDefaultRule] = useState({});
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar] = useSnackbar();
  useEffect(() => {
    getPricingRules();
    getDefaultRuleSetting();
  }, []);

  const getPricingRules = () => {
    ApiService("GET", "task/rules")
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setRulesData(response.data);
          setIsLoading(false)
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDefaultRuleSetting = () => {
    ApiService("GET", "setting/delivery")
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setDefaultRule(response.data);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleDelete = (ruleId) => {
    var hitUrl = "task/rules/" + ruleId;
    ApiService("DELETE", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          getPricingRules();
          openSnackbar(response.message);
        } else {
          openSnackbar(response.message);
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEdit = (sRule) => {
    setSelectedRule(sRule);
    setPrvisible(true);
    setIsEmpty(false);
  };

  const handleDefaultRule = (newVal) => {
    var switchData = {
      key_value: newVal,
    };

    ApiService("PUT", "setting/" + defaultRule.pricing_rule.id, switchData)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setDefaultRule({ pricing_rule: response.data });
        } else {
          console.log(response.data);
          getDefaultRuleSetting();
        }

        openSnackbar(response.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView">
      <GButton
        variant="condensed"
        children="Add"
        onClick={() => {
          setSelectedRule({});
          setPrvisible(true);
          setIsEmpty(true);
        }}
        style={{
          margin: "10px 5px",
          float: "right",
        }}
      ></GButton>
      <h2 style={{ margin: '20px 0 10px' }}>Pricing Rules</h2>
      {rulesData.length > 0 ? <p style={{ margin: '10px 0 20px' }}>Default - {rulesData.filter((rule) => rule.id == parseInt(defaultRule?.pricing_rule?.key_value))
        .map((c) => {
          return (
            <span key={c.id}>{c.title}</span>
          )
        })}</p> : ''}
      <div className="listPaper" style={{ padding: '20px 0' }}>
        <div style={{ height: '70vh', overflow: 'overlay', padding: '0 15px' }}>
          {isLoading === true ? <GShimmer></GShimmer> :
            rulesData.length === 0 ? <GEmpty></GEmpty> :
              <GPricingListCard
                actions={true}
                onEdit={handleEdit}
                onDelete={handleDelete}
                rulesData={rulesData}
                onSubmit={getPricingRules}
                default={defaultRule?.pricing_rule}
                onSelectDefault={handleDefaultRule}
              />
          }
        </div>
      </div>

      <PricingRuleCreate
        prvisible={prvisible}
        setPrvisible={setPrvisible}
        ruleData={selectedRule}
        isEmpty={isEmpty}
        onSubmit={getPricingRules}
      />
    </div>
  );
}
