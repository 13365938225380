import React, { useEffect, useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { useParams } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import { ApiService } from "../services";
import { Add } from "@material-ui/icons";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import TagsPopup from "../components/tagsForm";
import GSwitch from "../gComponents/gSwitch";
import GTags from "../gComponents/gTags";

// const tagsRoutes = [
//   {
//     title: "store",
//     link: "/settings/tags/store",
//   },
//   {
//     title: "item",
//     link: "/settings/tags/item",
//   },
// ];
const tagButtonStyle = {
  background: "#e5e5e5",
  borderRadius: "20px",
  padding: "7px 15px",
  border: "0",
  boxShadow: "none",
  outline: "0",
  fontSize: "15px",
  margin: "0 5px",
};
export default function Tags({ match }) {
  const [openSnackbar] = useSnackbar();
  const [typeValue, setTypeValue] = React.useState("store");
  const [tagsData, setTagsData] = React.useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTagId, setSelectedTagId] = useState("");
  const [tagvisible, setTagvisible] = useState(false);
  const [selectedTag, setSelectedTag] = useState({});
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [tagStatus, setTagStatus] = useState({});
  let { type } = useParams();

  console.log(type);
  const getTagStatus = () => {
    var hitUrl = "setting/identifier/tag_" + type + "_status";

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        getTags();
        if (response.status_code === 1) {
          setTagStatus(response.data);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function getTags() {
    var hitUrl = "tags?type=" + type;

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setTagsData(response.data);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    console.log(typeValue);
    setTypeValue(type ? type : "store");
    getTagStatus();
  }, [type]);

  const deleteTag = () => {
    var hitUrl = "tags/" + selectedTagId;
    ApiService("DELETE", hitUrl)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        openSnackbar(response.message);
        if (response.status_code === 1) {
          getTags();
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function toggleValue(newValue, valueId) {
    console.log(newValue + "--" + valueId);
    var hitUrl = "setting/" + valueId;
    var send_data = {
      key_value: newValue,
    };
    console.log(hitUrl);
    console.log(send_data);
    //return;
    ApiService("PUT", hitUrl, send_data)
      .then((response) => {
        console.log(JSON.stringify(response));
        openSnackbar(response.message);
        if (response.status_code === 1) {
          setTagStatus(response.data);
        } else {
          getTagStatus();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <h2>Tags Management</h2>

      {/* <GTabs routes={tagsRoutes} active={typeValue}></GTabs> */}

      <div className="listPaper">
        {/* <div style={{ padding: "10px" }}>
          <label style={{ fontSize: "15px" }}>Enable {type} Tags</label>{" "}
          <GSwitch
            id={tagStatus.id}
            uId={tagStatus.id}
            currentValue={tagStatus.key_value}
            onSwitchChange={toggleValue}
            style={{
              display: "inline-block",
              verticalAlign: "top",
              marginLeft: "15px",
            }}
            // handleToggle={(e) => {
            //   switchActionValue(t);
            // }}
          />
        </div> */}

        {/* {tagStatus.key_value === "1" ? ( */}
        <div>
          {/* <hr /> */}
          <p style={{ padding: "2px 8px" }}>Tags:</p>
          {tagsData.map((tag, i) => {
            return (
              <GTags
                key={i}
                data={tag}
                onDelete={() => {
                  setSelectedTagId(tag.id);
                  setDialogOpen(true);
                }}
              />
            );
          })}

          <button
            style={tagButtonStyle}
            onClick={() => {
              setIsEmpty(true);
              setSelectedTag({});
              setTagvisible(true);
            }}
          >
            <Add style={{ fontSize: "12px" }} /> Add
          </button>
        </div>
        {/* ) : (
          ""
        )} */}
      </div>
      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete tag"
          text="Are you sure you want to delete this tag?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteTag();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      {tagvisible === true ? (
        <TagsPopup
          tagvisible={tagvisible}
          setTagvisible={setTagvisible}
          isEmpty={isEmpty}
          data={selectedTag}
          type={type}
          onSubmit={getTags}
        />
      ) : (
        ""
      )}
    </div>
  );
}
