import React, { useState } from "react";
import "./sidebar.css";
import CustomerCreate from "../components/createCustomerPopup";
import DriverCreate from "../components/createDriver";
import StoreCreate from "../components/storeCreateForm";
import CouponCreate from "../components/createCoupon";
import { Link, useLocation } from "react-router-dom";
import useSettings from "../useSettings";
import { addDefaultLogo, ImgUrl } from "../services";
import GNestedMenu from "../gComponents/gNestedMenu";

export default function Sidebar() {
  const location = useLocation();
  const [ccvisible, setCcvisible] = useState(false);
  const [cdvisible, setCdvisible] = useState(false);
  const [csvisible, setCsvisible] = useState(false);
  const [ccpnvisible, setCcpnvisible] = useState(false);
  const settings = useSettings(true);
  console.log(location);
  return (
    <div className="Sidebar">
      <div className="App-header">
        <Link to="/dashboard">
          <img
            src={ImgUrl("logo") + "/" + settings?.admin_logo}
            className="App-logo"
            alt="logo"
            onError={addDefaultLogo}
          />
        </Link>
        <span>
          <h3>{settings.project_name}</h3>
          <p></p>
        </span>
      </div>

      <GNestedMenu
        handleClick={(title) => {
          // eslint-disable-next-line no-unused-expressions
          title === "Customers"
            ? setCcvisible(true)
            : title === "Drivers"
            ? setCdvisible(true)
            : title === "Stores"
            ? setCsvisible(true)
            : title === "Promotions"
            ? setCcpnvisible(true)
            : "";
        }}
      />

      <h4>Other Information</h4>
      <ul>
        <li className={location.state === "setting" ? "active" : ""}>
          <Link to={{ pathname: "/settings/basic", state: "setting" }}>
            <span role="img" aria-label="Order" className="icon">
              🛠️
            </span>
            <span>Settings</span>
          </Link>
        </li>
        <li className={location.state === "queries" ? "active" : ""}>
          <Link to={{ pathname: "/queries", state: "queries"}}>
            <span role="img" aria-label="Order" className="icon">
            👓
            </span>
            <span>All Query</span>
          </Link>
        </li>
      </ul>
      <div className="Sidebar-footer">
        <hr />
        <p>
          Powered by{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.ripeontime.com/"
          >
            Ripeontime
          </a>
        </p>
      </div>

      {cdvisible === true ? (
        <DriverCreate cdvisible={cdvisible} setCdvisible={setCdvisible} />
      ) : (
        ""
      )}
      {csvisible === true ? (
        <StoreCreate
          csvisible={csvisible}
          setCsvisible={setCsvisible}
          isEmpty={true}
        />
      ) : (
        ""
      )}
      {ccvisible === true ? (
        <CustomerCreate ccvisible={ccvisible} setCcvisible={setCcvisible} />
      ) : (
        ""
      )}
      {ccpnvisible === true ? (
        <CouponCreate
          ccpnvisible={ccpnvisible}
          setCcpnvisible={setCcpnvisible}
        />
      ) : (
        ""
      )}
    </div>
  );
}
