import React, { useState, useEffect } from "react";
import { makeStyles, Paper } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { ApiService } from "../services";
import { Add } from "@material-ui/icons";
import GEmpty from "../gComponents/gEmpty";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import { useHistory } from "react-router-dom";
import CategoryForm from "../components/productCategoryForm";
import GListCard from "../gComponents/gListCards";
import GActions from "../gComponents/gActionsStyle";

const useStyles = makeStyles({
  root: {
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
    marginTop: "5px",
  },
});

export default function Categories(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [catFormvisible, setCatFormvisible] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCategoryData, setSelectedCategoryData] = useState({});
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [isEmpty, setIsEmpty] = useState(true);
  let history = useHistory();

  console.log(history);

  const getCategories = () => {
    var hitUrl = "product/group";

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setCategoriesData(response.data);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCategories();
  }, [history]);

  const deleteCategory = (id) => {
    var catId = id ? id : selectedCategoryId;
    var hitUrl = "product/categories/" + catId;
    ApiService("DELETE", hitUrl)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          openSnackbar(response.message, [5000]);
          getCategories();
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleEditCtaegory = (data) => {
    setSelectedCategoryData(data);
    setIsEmpty(false);
    setSelectedCategoryId(data.parent_id);
    setCatFormvisible(true);
    console.log(data);
  };

  const handleToggleStatus = (newVal, id, ind, parentInd) => {
    console.log(newVal);
    ApiService("PUT", "product/categories/" + id, {
      status: newVal,
    })
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          categoriesData[parentInd].subcategories[ind].status = newVal;
          setCategoriesData(categoriesData);
          openSnackbar(response.message, [5000]);
        } else {
          console.log(response.data);
          openSnackbar(response.message);
          getCategories();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mainInnerView" style={{ padding: "20px 20px 0" }}>
      <h1>
        Categories
        <GButton
          variant="condensed"
          children="Add"
          onClick={() => {
            setSelectedCategoryData({});
            setIsEmpty(true);
            setSelectedCategoryId("");
            setCatFormvisible(true);
          }}
          style={{
            float: "right",
          }}
        >
          Add
        </GButton>
      </h1>

      <Paper className={classes.root}>
        <div
          style={{
            height: "calc(100vh - 270px)",
            overflowY: "auto",
            padding: "5px 15px",
            margin: "15px 0",
          }}
        >
          {categoriesData.length > 0 ? (
            categoriesData.map((c, pi) => {
              return (
                <React.Fragment key={c.id}>
                  <h4 style={{ margin: "10px 0" }}>
                    {c.title} ({c.subcategories.length} Subcategories)
                    <GActions
                      onEdit={() => {
                        setSelectedCategoryData(c);
                        setIsEmpty(false);
                        setSelectedCategoryId("");
                        setCatFormvisible(true);
                      }}
                      onDelete={() => {
                        setSelectedCategoryId(c.id);
                        setDialogOpen(true);
                      }}
                      style={{ verticalAlign: "middle" }}
                      menuStyle={{ fontWeight: "400" }}
                    />
                    <GButton
                      variant="linkable"
                      children="Add"
                      onClick={() => {
                        setSelectedCategoryData({});
                        setIsEmpty(true);
                        setSelectedCategoryId(c.id);
                        setCatFormvisible(true);
                      }}
                      style={{
                        float: "right",
                      }}
                    >
                      <Add />
                    </GButton>
                  </h4>
                  <hr />

                  {c.subcategories.map((sub, ind) => {
                    return (
                      <GListCard
                        key={ind}
                        id={sub.id}
                        title={sub.title}
                        subtitle={
                          sub.item_count +
                          (sub.item_count > 1 ? " items" : " item")
                        }
                        switch={true}
                        switchValue={sub.status}
                        dropdownActions={true}
                        onEdit={handleEditCtaegory}
                        onDelete={(id) => {
                          //setSelectedCategoryId(id);
                          deleteCategory(id);
                        }}
                        data={sub}
                        onSwitchToggle={(val, id) =>
                          handleToggleStatus(val, id, ind, pi)
                        }
                        style={{ width: "23%" }}
                      ></GListCard>
                      // </div>
                    );
                  })}

                  {c.subcategories.length === 0 ? <GEmpty></GEmpty> : ""}
                </React.Fragment>
              );
            })
          ) : (
            <GEmpty />
          )}
        </div>
      </Paper>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Category"
          text="Are you sure you want to delete this category?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteCategory();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      {catFormvisible === true ? (
        <CategoryForm
          categoryId={selectedCategoryId}
          catFormvisible={catFormvisible}
          setCatFormvisible={setCatFormvisible}
          data={selectedCategoryData}
          isEmpty={isEmpty}
          onSubmit={getCategories}
        />
      ) : (
        ""
      )}
    </div>
  );
}
