import React, { useEffect, useState } from "react";
import { makeStyles, Paper, Backdrop } from "@material-ui/core";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { CardHead } from "../styledComponents/cardStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  option: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#888",
    width: "40px",
    height: "40px",
    fontSize: "18px",
    borderRadius: "5px",
    cursor: "pointer",
    //transition: 'background 0.3s',
    transitionProperty: "background-color, color",
    transitionDuration: "300ms, 300ms",
    "&:hover": {
      backgroundColor: "#333",
      color: "#bbb",
      fontWeight: "500",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var buttonStyle = {
  background: "#FF6500",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "100%",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  marginTop: "30px",
  fontWeight: "600",
};

export default function OrderProductVariants(props) {
  const classes = useStyles();
  const [selectedProduct, setSelectedProduct] = useState({});
  const [openSnackbar] = useSnackbar();
  console.log(props.data + "--");

  useEffect(() => {
    ApiService("GET", "products/" + props.item.id).then((response) => {
      console.log(JSON.stringify(response));
      if (response.status_code === 1) {
        setSelectedProduct(response.data);
      } else {
        openSnackbar(response.message);
      }
    });
  }, []);

  const validateData = () => {
    let validated = true;
    console.log(selectedProduct.variants);
    selectedProduct.variants.every((element) => {
      console.log(element);
      //Maximum Minimum Check
      if (element.options.length > 0) {
        let count = element.options.filter((e) => e.id === element.selected)
          .length;

        if (element.maximum < count) {
          openSnackbar(
            `Maximum Selection for ${element.title} is ${element.maximum}`
          );
          validated = false;
          return false;
        }
        if (element.minimum > count) {
          openSnackbar(`${element.title} selection is required.`);
          validated = false;
          return false;
        }
        return true;
      } else {
        return true;
      }
    });
    return validated;
  };

  const onAddVariants = () => {
    let validate = validateData();
    console.log(validate);

    if (validate === true) {
      let newArray = [];
      selectedProduct.variants.map((element) => {
        console.log(element);
        if (element.selected) {
          let v = element.options.filter((e) => e.id === element.selected);
          console.log(v);
          if (v && v.length > 0) {
            newArray = newArray.concat(v);
            console.log(newArray);
          }
        } else {
          return false;
        }
      });
      let newItem = props.item;
      newItem.variants = newArray;
      console.log(newItem);

      props.onSubmitVariants(newArray);
      props.setOrderVarVisible(false);
    }
  };

  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setOrderVarVisible(false);
  };

  if (props.orderVarVisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.orderVarVisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.orderVarVisible === true ? "fadeM" : ""}`}
          style={{ padding: "20px", maxWidth: "450px" }}
        >
          {/* <h3>Choose Variants</h3> */}

          <CardHead style={{ padding: "8px 8px 0", fontSize: "18px" }}>
            Choose Variants
          </CardHead>

          {selectedProduct.variants && selectedProduct.variants.length > 0
            ? selectedProduct?.variants.map((v, i) => {
                return (
                  <React.Fragment key={i}>
                    {v.options.length > 0 ? (
                      <React.Fragment>
                        <br />
                        <h4 style={{ margin: "10px" }}>{v.title}</h4>
                      </React.Fragment>
                    ) : (
                      ""
                    )}

                    {v.options.map((option, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "block",
                            padding: "5px",
                            color: "#555",
                          }}
                          onClick={() =>
                            (selectedProduct.variants[i].selected = option.id)
                          }
                        >
                          <input
                            name={"option_" + v.title}
                            type="radio"
                            id={"v_option_" + option.title}
                            value={option.id}
                            // checked={option.id}
                          />{" "}
                          &nbsp;&nbsp;
                          <label htmlFor={"v_option_" + option.title}>
                            {option.title} ({option.price})
                          </label>
                        </div>
                      );
                    })}
                  </React.Fragment>
                );
              })
            : ""}
          <button style={buttonStyle} type="button" onClick={onAddVariants}>
            Add to cart
          </button>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
