import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import Child from "./faqs";

const faqRoutes = [
  {
    title: "customer",
    link: "/settings/faqs/customer",
    state: 'setting'
  },
  {
    title: "manager",
    link: "/settings/faqs/manager", state: 'setting'
  },
  {
    title: "driver",
    link: "/settings/faqs/driver", state: 'setting'
  },
];

export default function Faqs({ match }) {
  const [typeValue, setTypeValue] = useState("customer");

  useEffect(() => {
    console.log(typeValue);
  });

  function handleChange(newValue) {
    console.log("n" + newValue);
    setTypeValue(newValue);
  }

  return (
    <Router>
      <div className="rightInnerView">
        <h2>FAQ's</h2>

        <GTabs routes={faqRoutes} active={typeValue}></GTabs>

        <Switch>
          <div className="listPaper">
            <div>
              <Route path="/settings/faqs/:type">
                <Child onChange={handleChange} />
              </Route>
            </div>
          </div>
        </Switch>
      </div>
    </Router>
  );
}
