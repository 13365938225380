import React, { useState, useRef } from "react";
import { GetApp } from "@material-ui/icons";
import { csvFileApiService, ApiDownloadService } from "../services";
import GButton from "../gComponents/gButton";
import { useSnackbar } from "react-simple-snackbar";
import sampleFile from "../assets/files/product-import.csv";
import { StoreFilter } from "../components/storeFilterComponent";
import { StoreFilterCustom } from "../components/storeFilterComponentCustom";

export default function bulkUpload() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fileInput = useRef(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [selectedStore, setSelectedStore] = useState("");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [selectedFile, setSelectedFile] = useState({});
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [openSnackbar] = useSnackbar();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [secStore, setSecStore] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [selectedStoreTwo, setSelectedStoreTwo] = useState("");

  // Form Bulk Master Change - 23/des/21
  function uploadBukMaster() {
    var hitUrl = "bulk/master/upload";
    console.log(hitUrl);
    console.log(selectedFile);
    var sendData = {
      csv: selectedFile,
      store_id: selectedStore,
    };
    console.log(sendData);
    csvFileApiService("POST", hitUrl, sendData)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          openSnackbar(response.message);
          fileInput.current.value = "";
          setSelectedFile({});
          window.location.reload(false);
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function downloadBulkMaster() {
    // return;
    var hitUrl = "bulk/master/download";
    console.log(hitUrl);
    var defaultFileName = "master_items.csv";
    ApiDownloadService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        var blob = new Blob([response]);
        var objectUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.style = "display:none";
        a.href = objectUrl;
        a.download = defaultFileName;
        a.click();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // ************* till here

  function uploadBukItems() {
    var hitUrl = "bulk/products";
    console.log(hitUrl);
    console.log(selectedFile);
    var sendData = {
      csv: selectedFile,
      store_id: selectedStore,
    };
    console.log(sendData);
    csvFileApiService("POST", hitUrl, sendData)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          openSnackbar(response.message);
          fileInput.current.value = "";
          setSelectedFile({});
          window.location.reload(false);
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function downloadMasterItems() {
    // return;
    var hitUrl = "bulk/master-download";
    console.log(hitUrl);
    var defaultFileName = "master_items.csv";
    ApiDownloadService("POST", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        var blob = new Blob([response]);
        var objectUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.style = "display:none";
        a.href = objectUrl;
        a.download = defaultFileName;
        a.click();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function downloadVariantFile() {
    // return;
    var hitUrl = `bulk/variant-download?store_id=${selectedStore}`;

    console.log("----------------------------");
    console.log(hitUrl);
    console.log("----------------------------");
    var defaultFileName = "master_items.csv";
    ApiDownloadService("POST", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        var blob = new Blob([response]);
        var objectUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.style = "display:none";
        a.href = objectUrl;
        a.download = defaultFileName;
        a.click();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function uploadStoreItems() {
    // return;
    var hitUrl = "bulk/store-products";
    console.log(hitUrl);
    console.log(selectedFile);
    var sendData = {
      csv: selectedFile,
      store_id: selectedStore,
    };
    console.log(sendData);
    csvFileApiService("POST", hitUrl, sendData)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          openSnackbar(response.message);
          fileInput.current.value = "";
          setSelectedFile({});
          window.location.reload(false);
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function uploadBulkVariants() {
    // return;
    var hitUrl = "bulk/product-variants";
    console.log(hitUrl);
    console.log(selectedFile);
    var sendData = {
      csv: selectedFile,
      store_id: selectedStore,
    };
    console.log(sendData);
    csvFileApiService("POST", hitUrl, sendData)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          openSnackbar(response.message);
          fileInput.current.value = "";
          setSelectedFile({});
          window.location.reload(false);
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <h2>Bulk Upload</h2>
      <div className="listPaper">
        <React.Fragment>
          <p style={{ marginBottom: "25px", fontFamily: "LatoWebSemibold" }}>
            Upload Master Items
            <a
              href={sampleFile}
              download
              style={{
                float: "right",
                textDecoration: "underline",
                color: "#f08c37",
                fontSize: "14px",
              }}
            >
              Download Sample CSV file
            </a>
          </p>

          <label
            style={{
              color: "#333",
              display: "block",
              margin: "3px 0",
              fontSize: "14px",
              marginTop: "12px",
            }}
          >
            Choose File
          </label>
          <input
            type="file"
            ref={fileInput}
            onChange={(e) => setSelectedFile(e.target.files[0])}
            style={{ margin: "10px 0 20px" }}
          />
          <br />
          <GButton
            variant="condensed"
            children="Upload"
            onClick={uploadBukItems}
            style={{ margin: "10px 0 15px" }}
          ></GButton>
        </React.Fragment>

        <br />
        <hr />
        <React.Fragment>
          <p style={{ marginBottom: "15px", fontFamily: "LatoWebSemibold" }}>
            {" "}
            Download Master Items{" "}
          </p>
          {/* <label
            style={{
              color: "#333",
              display: "block",
              margin: "3px 0",
              fontSize: "14px", marginTop: '12px'
            }}
          >
            Choose File
        </label>
          <input
            type="file"
            ref={fileInput}
            onChange={(e) => setSelectedFile(e.target.files[0])}
            style={{ margin: "10px 0 20px" }}
          />
          <br />*/}
          <GButton
            variant="outlined"
            children="Download"
            onClick={downloadMasterItems}
            style={{
              padding: "0.6em 2em",
              margin: "10px 0 15px",
              display: "inline-flex",
              alignItems: "center",
              fontFamily: "LatoWebSemibold",
            }}
          >
            {" "}
            <GetApp style={{ width: "20px", height: "20px" }} /> &nbsp; Download
          </GButton>
        </React.Fragment>
        <br />
        <hr />
        <React.Fragment>
          <p style={{ marginBottom: "25px", fontFamily: "LatoWebSemibold" }}>
            Upload Bulk Items
          </p>
          <div>
            <label
              style={{
                color: "#333",
                display: "block",
                margin: "8px 0",
                fontSize: "14px",
              }}
            >
              Select Store
            </label>

            <StoreFilterCustom
              value={selectedStore}
              displayValue=""
              style={{
                marginBottom: "10px",
              }}
              handleStoreChange={(value) => {
                setSelectedStore(value);
              }}
            />
          </div>

          <label
            style={{
              color: "#333",
              display: "block",
              margin: "3px 0",
              fontSize: "14px",
              marginTop: "12px",
            }}
          >
            Choose File
          </label>
          <input
            type="file"
            ref={fileInput}
            onChange={(e) => setSelectedFile(e.target.files[0])}
            style={{ margin: "10px 0 20px" }}
          />
          <br />
          <GButton
            variant="condensed"
            children="Upload"
            onClick={uploadStoreItems}
            style={{ margin: "10px 0" }}
          ></GButton>
        </React.Fragment>

        <br />
        <hr />
        <React.Fragment>
          <p style={{ marginBottom: "25px", fontFamily: "LatoWebSemibold" }}>
            Upload Bulk Variants
          </p>
          <div>
            <label
              style={{
                color: "#333",
                display: "block",
                margin: "8px 0",
                fontSize: "14px",
              }}
            >
              Select Store
            </label>

            <StoreFilterCustom
              value={selectedStore}
              displayValue=""
              style={{
                marginBottom: "10px",
              }}
              handleStoreChange={(value) => {
                setSelectedStore(value);
                setSecStore(!secStore);
                console.log(selectedStore);
              }}
            />
          </div>

          {secStore ? (
            <>
              <GButton
                variant="outlined"
                children="Download"
                onClick={downloadVariantFile}
                style={{
                  padding: "0.6em 2em",
                  margin: "10px 0 15px",
                  display: "inline-flex",
                  alignItems: "center",
                  fontFamily: "LatoWebSemibold",
                }}
              >
                {" "}
                <GetApp style={{ width: "20px", height: "20px" }} /> &nbsp;
                Download
              </GButton>
              <label
                style={{
                  color: "#333",
                  display: "block",
                  margin: "3px 0",
                  fontSize: "14px",
                  marginTop: "12px",
                }}
              >
                Choose File
              </label>
              <input
                type="file"
                ref={fileInput}
                onChange={(e) => setSelectedFile(e.target.files[0])}
                style={{ margin: "10px 0 20px" }}
              />
              <br />
              <GButton
                variant="condensed"
                children="Upload"
                onClick={uploadBulkVariants}
                style={{ margin: "10px 0" }}
              ></GButton>
            </>
          ) : (
            ""
          )}
        </React.Fragment>
        <br />
        <hr />

        {/* Bulk Master Section */}
        <React.Fragment>
          <p style={{ marginBottom: "25px", fontFamily: "LatoWebSemibold" }}>
            Upload Bulk Master (Update)
          </p>

          <label
            style={{
              color: "#333",
              display: "block",
              margin: "3px 0",
              fontSize: "14px",
              marginTop: "12px",
            }}
          >
            Choose File
          </label>
          <input
            type="file"
            ref={fileInput}
            onChange={(e) => setSelectedFile(e.target.files[0])}
            style={{ margin: "10px 0 20px" }}
          />
          <br />
          <GButton
            variant="condensed"
            children="Upload"
            onClick={uploadBukMaster}
            style={{ margin: "10px 0 15px" }}
          ></GButton>
        </React.Fragment>

        <br />
        <hr />
        <React.Fragment>
          <p style={{ marginBottom: "15px", fontFamily: "LatoWebSemibold" }}>
            {" "}
            Download Bulk Master {" "} (Update)
          </p>
          {/* <label
            style={{
              color: "#333",
              display: "block",
              margin: "3px 0",
              fontSize: "14px", marginTop: '12px'
            }}
          >
            Choose File
        </label>
          <input
            type="file"
            ref={fileInput}
            onChange={(e) => setSelectedFile(e.target.files[0])}
            style={{ margin: "10px 0 20px" }}
          />
          <br />*/}
          <GButton
            variant="outlined"
            children="Download"
            onClick={downloadBulkMaster}
            style={{
              padding: "0.6em 2em",
              margin: "10px 0 15px",
              display: "inline-flex",
              alignItems: "center",
              fontFamily: "LatoWebSemibold",
            }}
          >
            {" "}
            <GetApp style={{ width: "20px", height: "20px" }} /> &nbsp; Download
          </GButton>
        </React.Fragment>
      </div>
    </div>
  );
}