import React, { useState, useEffect, useRef } from "react";
import {
  Map,
  TileLayer,
  FeatureGroup,
  useLeaflet,
  Circle,
  Tooltip,
} from "react-leaflet";
import { GeoSearchControl, GoogleProvider } from "leaflet-geosearch";
import { EditControl } from "react-leaflet-draw";
import useGeoLocation from "../useGeoLocation";
import L from "leaflet";
import osm from "./osm-providers";
import "leaflet-geosearch/dist/geosearch.css";
import styled from "styled-components";

const LocateButton = styled.span`
  border: 2px solid #ccc;
  color: #000;
  padding: 5px 5px 0;
  border-radius: 4px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
  position: absolute;
  left: 8px;
  bottom: 8px;
  z-index: 9999;
  background: #fff;

  svg {
    width: 0.8em;
    height: 0.8em;
  }
`;

function EditableLayer(props) {
  const leaflet = useLeaflet();
  const editLayerRef = React.useRef();
  let drawControlRef = React.useRef();
  let { map } = leaflet;

  // let coordinates = [];

  useEffect(() => {
    if (!props.layer) {
      map.addControl(drawControlRef.current);
    } else {
      if (!props.showDrawControl) {
        map.removeControl(drawControlRef.current);
      } else {
        map.addControl(drawControlRef.current);
      }

      editLayerRef.current.leafletElement.clearLayers();

      editLayerRef.current.leafletElement.addLayer(props.layer);

      props.layer.on("click", function (e) {
        props.onLayerClicked(e, drawControlRef.current);
      });
    }
  }, [props, map]);

  const _onCreate = (e) => {
    console.log(e);

    const drawnItems = editLayerRef.current.leafletElement._layers;
    if (Object.keys(drawnItems).length > 1) {
      Object.keys(drawnItems).forEach((layerid, index) => {
        if (index > 0) return;
        const layer = drawnItems[layerid];
        editLayerRef.current.leafletElement.removeLayer(layer);
      });
      console.log(drawnItems); // here you will get only the last one
    }
    const { layerType, layer } = e;
    if (layerType === "circle") {
      const { _leaflet_id, _radius, _latlng } = layer;
      console.log(layer);
      let coordinates = [
        {
          id: _leaflet_id,
          radius: _radius,
          lat: _latlng.lat,
          lng: _latlng.lng,
        },
      ];
      console.log(coordinates);
      props.getGeoJSON(coordinates[0]);
    }
  };

  function onMounted(ctl) {
    console.log(ctl);
    drawControlRef.current = ctl;
  }

  return (
    <div>
      <FeatureGroup ref={editLayerRef}>
        <EditControl
          position="topright"
          draw={{
            rectangle: false,
            circle: {
              shapeOptions: { color: "#F08C38" },
            },
            circlemarker: false,
            marker: false,
            polyline: false,
            polygon: false,
          }}
          edit={{
            circle: {
              shapeOptions: { color: "#F08C38" },
            },
          }}
          onMounted={onMounted}
          onCreated={_onCreate}
          onEdited={(e) => {
            console.log(e);
            const {
              layers: { _layers },
            } = e;

            Object.values(_layers).map(
              ({ _leaflet_id, _radius, _latlng, editing }) => {
                let coordinates = [
                  {
                    id: _leaflet_id,
                    radius: _radius,
                    lat: _latlng.lat,
                    lng: _latlng.lng,
                  },
                ];
                console.log(coordinates);
                props.getGeoJSON(coordinates[0]);
              }
            );
          }}
          onDeleted={(e) => {
            console.log(e);
            const {
              layers: { _layers },
            } = e;

            Object.values(_layers).map(({ _leaflet_id }) => {
              props.getGeoJSON({});
            });
          }}
        />
      </FeatureGroup>
    </div>
  );
}

function EditableGroup(props) {
  console.log(props);
  const [selectedLayerIndex, setSelectedLayerIndex] = useState(0);
  function handleLayerClick(e, drawControl) {
    setSelectedLayerIndex(e.target.feature.properties.editLayerId);
  }

  let layers = [];

  let dataLayer = new L.GeoJSON(props.data, {
    style: function (feature) {
      // Style option
      return {
        color: "#F08C38",
      };
    },
  });
  let i = 0;
  dataLayer.eachLayer((layer) => {
    layer.feature.properties.editLayerId = i;
    layers.push(layer);
    i++;
  });

  return (
    <div>
      {layers.length > 0 ? (
        layers.map((layer, i) => {
          return (
            <EditableLayer
              key={i}
              layer={layer}
              showDrawControl={i === selectedLayerIndex}
              onLayerClicked={handleLayerClick}
              getGeoJSON={(coordinates) => {
                console.log(coordinates);
                props.onDataChange(coordinates);
              }}
            />
          );
        })
      ) : (
        <EditableLayer
          getGeoJSON={(coordinates) => {
            console.log(coordinates);
            props.onDataChange(coordinates);
          }}
        />
      )}
    </div>
  );
}

function MapExample(props) {
  console.log(props);
  const mapRef = useRef();

  const location = useGeoLocation();
  const [data, setData] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [center, setCenter] = useState({
    lat: props.circleData?.lat
      ? parseFloat(props.circleData?.lat)
      : process.env.REACT_APP_DEFAULT_LATITUDE,
    lng: props.circleData?.lat
      ? parseFloat(props.circleData?.lat)
      : process.env.REACT_APP_DEFAULT_LONGITUDE,
  });
  const ZOOM_LEVEL = 12;

  useEffect(() => {
    if (props.circleData?.radius) {
      setCenter({
        lat: parseFloat(props.circleData?.lat),
        lng: parseFloat(props.circleData?.lng),
      });
    }

    var f = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [],
      },
      properties: { radius: props.circleData?.radius },
    };

    f.geometry.coordinates.push(props.circleData?.lat);
    f.geometry.coordinates.push(props.circleData?.lng);

    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    if (!map) return;
  }, [props]);

  useEffect(() => {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    if (!map) return;
    const control = GeoSearchControl({
      style: "button",
      showMarker: false,
      provider: new GoogleProvider({
        params: {
          key: process.env.REACT_APP_GOOGLE_KEY,
        },
      }),
    });
    // control.addTo(map);
  }, []);

  const showMyLocation = () => {
    if (location.loaded && !location.error) {
      mapRef.current.leafletElement.flyTo(
        [location.coordinates.lat, location.coordinates.lng],
        ZOOM_LEVEL,
        { animate: true }
      );
    } else {
      alert(location.error.message);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="col text-center">
        <div className="col">
          <Map center={center} zoom={ZOOM_LEVEL} ref={mapRef}>
            <TileLayer
              url={osm.maptiler.url}
              attribution={osm.maptiler.attribution}
            />

            {data && props.circleData ? (
              <React.Fragment>
                <Circle
                  center={[
                    parseFloat(props.circleData?.lat),
                    parseFloat(props.circleData?.lng),
                  ]}
                  color={"#F08C38"}
                  radius={props.circleData?.radius * 1609.34}
                >
                  <Tooltip>
                    {props.circleData?.radius.toFixed(2) + " miles"}
                  </Tooltip>
                </Circle>
              </React.Fragment>
            ) : (
              ""
            )}
          </Map>
        </div>
      </div>
    </div>
  );
}

export default MapExample;
