import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  useHistory,
  useParams,
} from "react-router-dom";
import { ApiService } from "../services";
import { TableContainer } from "@material-ui/core";

import { useSnackbar } from "react-simple-snackbar";
import { Edit } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GSwitch from "../gComponents/gSwitch";
import GButton from "../gComponents/gButton";
import GSelectbox from "../gComponents/gSelectbox";
import NotificationFormPopup from "../components/notificationsForm";

const columns = [
  { id: "event", label: "Event", minWidth: 150 },
  { id: "sms", label: "SMS", minWidth: 100 },
  { id: "email", label: "EMAIL", minWidth: 100 },
  { id: "push", label: "PUSH", minWidth: 100 },
  { id: "actions", label: "Actions", minWidth: 60, align: "center" },
];

const notifyTypesValues = [
  {
    label: "Pickup",
    value: "pickup",
  },
  {
    label: "Delivery",
    value: "delivery",
  },
];

export default function NotificationsSetting(props, { match }) {
  //  let params1 = match.params;
  let history = useHistory();
  console.log(history.location);

  let { type, userType, notifyType } = useParams();
  console.log(type + "---type--" + props.type);
  console.log(userType + "--user--");
  console.log(notifyType + "--notifytype--" + props.nType);
  console.log("route" + JSON.stringify(match));

  let a = history.location.pathname.split("/");
  let aa = a[a.length - 1];

  const [openSnackbar] = useSnackbar();
  const [notificationTypeValue, setNotificationTypeValue] = useState(
    type === "task" ? aa : ""
  );

  const [notificationsData, setNotificationsData] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [nfvisible, setNfvisible] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    userType = type === props.type ? userType : "customer";
    console.log(notificationTypeValue + "---" + props.nType);
    // notifyType = props.type === 'task' ? notifyType ? notifyType : 'delivery' : '';
    // props.type === "task"
    //   ? setNotificationTypeValue(
    //       notificationTypeValue ? notificationTypeValue : "delivery"
    //     )
    //   : setNotificationTypeValue("");

    console.log("mnew" + JSON.stringify(props));
    getNotifications();
    props.onChange(userType, notificationTypeValue);

    // type = props.type
  }, [props.type, userType, notificationTypeValue]);

  function getChangedValue(newValue) {
    console.log("n" + newValue);
    setNotificationTypeValue(newValue ? newValue : 'delivery');
    history.push({
      pathname: "/settings/task-notifications/" + userType + "/" + newValue ? newValue : 'delivery',
      search: "",
    });
    //getNotifications();
  }

  const getNotifications = () => {
    var hitUrl =
      "notification-settings?event_type=" +
      props.type +
      "&user_type=" +
      userType;


    var notificationType = notificationTypeValue ? notificationTypeValue : 'delivery'
    hitUrl = props.type === 'task' ? hitUrl + "&notification_type=" + notificationType : hitUrl;


    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log("rr" + JSON.stringify(response));
        setNotificationsData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSwitchValue = (newValue, selectedId, medium, ind) => {
    var data = {
      medium: medium,
      id: selectedId,
      status: newValue,
    };

    var hitUrl = "notification-settings";
    console.log(JSON.stringify(hitUrl));

    // var val = sTitle === "enable" ? cData.enable : cData.required;
    // val = val === 1 ? 0 : 1;
    // var switchData = {};
    // switchData[sTitle] = val;
    // console.log(hitUrl);
    // console.log(switchData);
    ApiService("PUT", hitUrl, data)
      .then((response) => {
        console.log(JSON.stringify(response));
        openSnackbar(response.message);
        if (response.status_code === 1) {
          //getNotifications();
          let newArr = [];
          newArr = [...notificationsData];
          newArr[ind][medium].status = newValue;
          setNotificationsData(newArr);
        } else {
          console.log(response.data);
          getNotifications();
        }

        console.log(notificationsData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div>
      {isLoading === true ? (
        <GTableShimmer></GTableShimmer>
      ) : (
          <div>
            {/* {props.type === "task" && notificationTypeValue ? (
            <div>
              {/* {notificationTypeValue}----{props.nType} }
              <GSelectbox
                placeholder=""
                defaultvalue={notificationTypeValue}
                display="block"
                data={notifyTypesValues}
                id={"notify-" + notificationTypeValue}
                onChange={getChangedValue}
                option_key="label"
                option_value="value"
                style={{ float: "right" }}
                showSelectOption={false}
              />
            </div>
          ) : (
            ""
          )} */}
            {/* <hr />
          {type + "---type--" + props.type + "==" + typeValue}
          <br />
          {userType + "--user--"} <br />
          {notifyType +
            "--notifytype--" +
            props.nType +
            "---===-" +
            notificationTypeValue} */}
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notificationsData.length > 0 && notificationsData.map((c, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={c.identifier}
                      >
                        <TableRowData
                          style={{
                            BorderBottom: "1px solid #f4f5f9",
                          }}
                        >
                          {c.title}
                        </TableRowData>
                        <TableRowData>
                          {!c.sms || c.sms.status === 2 ? (
                            "-"
                          ) : (
                              <GSwitch
                                id={"sms" + c.identifier}
                                uId={c.sms.id}
                                currentValue={c.sms.status}
                                onSwitchChange={(newvalue, id) =>
                                  handleSwitchValue(newvalue, id, "sms", index)
                                }
                              // onChange={(e) => {
                              //   switchActionValue(t);
                              // }}
                              />
                            )}
                        </TableRowData>
                        <TableRowData>
                          {!c.email || c.email.status === 2 ? (
                            "-"
                          ) : (
                              <GSwitch
                                id={"email" + c.identifier}
                                uId={c.email.id}
                                currentValue={c.email.status}
                                handleToggle={(e) => {
                                  //switchActionValue(c, "email");
                                }}
                                onSwitchChange={(newvalue, id) =>
                                  handleSwitchValue(newvalue, id, "email", index)
                                }
                              />
                            )}
                        </TableRowData>
                        <TableRowData>
                          {!c.push || c.push.status === 2 ? (
                            "-"
                          ) : (
                              <GSwitch
                                id={"push" + c.identifier}
                                uId={c.push.id}
                                currentValue={c.push.status}
                                onSwitchChange={(newvalue, id) =>
                                  handleSwitchValue(newvalue, id, "push", index)
                                }
                                handleToggle={(e) => {
                                  // switchActionValue(c, "push");
                                }}
                              />
                            )}
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              setSelectedNotification(c);
                              setNfvisible(true);
                            }}
                          >
                            <Edit fontSize="small" />
                          </GButton>
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {notificationsData.length === 0 ? <GEmpty></GEmpty> : ""}
            </TableContainer>
          </div>
        )}

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Customer"
          text="Are you sure you want to delete this customer?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
          }}
        ></GConfirmDialog>
      ) : (
          ""
        )}

      {nfvisible === true ? (
        <NotificationFormPopup
          nfvisible={nfvisible}
          setNfvisible={setNfvisible}
          data={selectedNotification}
          userType={userType}
          onSubmit={getNotifications}
          notificationType={notificationTypeValue}
        />
      ) : (
          ""
        )}
    </div>
  );
}
