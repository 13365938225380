import React, { useState, useEffect } from "react";
import { makeStyles, Paper, TableContainer, Avatar } from "@material-ui/core";
import { ApiService, ImgUrl } from "../services";
import { PeopleAlt, Edit, Delete } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import { Link, useHistory } from "react-router-dom";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import { useSnackbar } from "react-simple-snackbar";
import DriverCreate from "../components/createDriver";
import QueryWith from "../Query";
import { GSearch } from "../gComponents/gSearch";
import GSelectbox from "../gComponents/gSelectbox";
import useSettings from "../useSettings";

const columns = [
  { id: "first_name", label: "Name", minWidth: 130 },
  { id: "photo", label: "Photo", minWidth: 50, type: "image" },
  { id: "email", label: "Email", minWidth: 150 },
  { id: "phone", label: "Mobile", minWidth: 120 },
  {
    id: "wallet_points_value",
    label: "Balance",
    minWidth: 90,
    // format: (value) => value.toLocaleString('en-US'),
    format: (value) => value.toFixed(2),
  },
  { id: "created_at_formatted", label: "Created", minWidth: 150 },
  { id: "actions", label: "Actions", minWidth: 60, align: "center" },
];

const statusFilters = [
  {
    title: "Active",
    value: "1",
  },
  {
    title: "Inactive",
    value: "0",
  },
];

const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
    marginTop: "10px",
  },
});

export default function Drivers() {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [cdvisible, setCdvisible] = useState(false);
  const [driverData, setDriverData] = useState([]);
  const [driverPaginationData, setDriverPaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDriverData, setSelectedDriverData] = useState({});
  const [selectedDriverId, setSelectedDriverId] = useState("");
  const settings = useSettings(true);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  console.log(JSON.stringify(history.location.search));
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    search: params.get("search") ? params.get("search") : "",
    driver_status: params.get("driver_status")
      ? params.get("driver_status")
      : "",
  });
  const getDrivers = () => {
    var params = QueryWith(history.location, filters);

    console.log(params);
    if (params.length > 0) {
      history.push({
        pathname: "/drivers",
        state: 'driver',
        search: params[0],
      });
    }
    var hitUrl = "drivers";

    console.log(hitUrl);

    ApiService("GET", hitUrl, params[0])
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setDriverData(response.data.data);
          setDriverPaginationData(response.data);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getDrivers();
  }, [filters, history]);

  const deleteDriver = () => {
    var hitUrl = "drivers/" + selectedDriverId;
    ApiService("DELETE", hitUrl)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          openSnackbar(response.message);
          getDrivers();
        } else {
          console.log(response);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ page: NewPageNo });
  };

  return (
    <div className="mainInnerView">
      <h1>
        <PeopleAlt /> Drivers
      </h1>

      <div style={{ display: "inline-block", width: "60%" }}>
        <GSearch
          placeholder="Search by name, email or phone.."
          value={filters.search}
          handleChange={(val) => setFilters({ search: val, page: 1 })}
        />
        <GSelectbox
          placeholder="All"
          defaultvalue={filters.driver_status}
          data={statusFilters}
          id="driver_status_filter"
          option_key="title"
          option_value="value"
          onChange={(newVal) => {
            setFilters({
              driver_status: newVal,
              search: filters.search,
              page: 1,
            });
          }}
          style={{
            border: "1px solid #e2e2e2",
            height: "40px",
            marginLeft: "10px",
            minWidth: "150px",
          }}
        />
      </div>
      <div
        style={{ display: "inline-block", width: "40%", textAlign: "right" }}
      >
        {driverData.length !== 0 ? (
          <React.Fragment>
            <GPagination
              style={{ display: "inline-block" }}
              count={driverPaginationData.total}
              from={driverPaginationData.from}
              to={driverPaginationData.to}
              onChangeNextPage={() =>
                handleChangePage(driverPaginationData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(driverPaginationData.prev_page_url)
              }
              rowsPerPage={driverPaginationData.per_page}
              prevDisabled={driverPaginationData.prev_page_url ? false : true}
              nextDisabled={driverPaginationData.next_page_url ? false : true}
            ></GPagination>
            {driverPaginationData ? (
              <GPaginationSelect
                defaultvalue={filters.page}
                totalPage={driverPaginationData.last_page}
                onChangePage={(NewPageNo) => setFilters({ page: NewPageNo })}
              />
            ) : (
                ""
              )}
          </React.Fragment>
        ) : (
            ""
          )}
      </div>
      <Paper className={classes.root}>
        {!driverPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
            <TableContainer className={classes.container}>
              {driverData.length === 0 ? <GEmpty></GEmpty> :
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableHeader
                          key={column.id}
                          style={{
                            minWidth: column.minWidth,
                            textAlign: column.align,
                          }}
                        >
                          {column.label}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {driverData.map((c) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                          <TableRowData
                            style={{
                              BorderBottom: "1px solid #f4f5f9",
                            }}
                          >
                            <Link to={{ pathname: "/driver/" + c.id + "/tasks", state: 'driver' }}>
                              {c.first_name} {c.last_name}
                            </Link>
                            {/* {column.type === "image" ? (
                            <Avatar alt={column.name} src={column.photo} />
                          ) : column.format && typeof value === "number" ? (
                            column.format(value)
                          ) : (
                          
                          )} */}
                          </TableRowData>
                          <TableRowData>
                            <Avatar
                              alt={c.first_name}
                              src={ImgUrl("user") + "/" + c.thumb_photo}
                            />
                          </TableRowData>
                          <TableRowData>{c.email}</TableRowData>
                          <TableRowData>{c.phone}</TableRowData>
                          <TableRowData>
                            {settings.currency_symbol + "" + c.balance}
                          </TableRowData>
                          <TableRowData>{c.created}</TableRowData>
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <GButton
                              variant="linkable"
                              //  onClick={(e) =>
                              //       window.confirm(
                              //         "Are you sure you wish to delete this customer?"
                              //       ) && deleteCustomer(c.id)
                              // }

                              onClick={(e) => {
                                setSelectedDriverData(c);
                                setCdvisible(true);
                              }}
                            >
                              <Edit fontSize="small" />
                            </GButton>

                            <GButton
                              variant="linkable"
                              //  onClick={(e) =>
                              //       window.confirm(
                              //         "Are you sure you wish to delete this customer?"
                              //       ) && deleteCustomer(c.id)
                              // }

                              onClick={(e) => {
                                setSelectedDriverId(c.id);
                                setDialogOpen(true);
                              }}
                            >
                              <Delete fontSize="small" />
                            </GButton>
                          </TableRowData>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              }
            </TableContainer>
          )}
      </Paper>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Driver"
          text="Are you sure you want to delete this driver?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteDriver();
          }}
        ></GConfirmDialog>
      ) : (
          ""
        )}

      {cdvisible === true ? (
        <DriverCreate
          cdvisible={cdvisible}
          setCdvisible={setCdvisible}
          data={selectedDriverData}
          onSubmit={getDrivers}
        />
      ) : (
          ""
        )}
    </div>
  );
}
