import React, { useState, useEffect } from "react";
import { makeStyles, Paper, TableContainer } from "@material-ui/core";
import { ApiService } from "../services";
import { Assignment } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import GTableShimmer from "../gComponents/gTableShimmer";
import GEmpty from "../gComponents/gEmpty";
import QueryWith from "../Query";
import OrderStatuses from "../gComponents/gOrderStatus";
import { StoreFilter } from "../components/storeFilterComponent";
import { CustomerFilter } from "../components/customerFilterComponent";
import GSelectbox from "../gComponents/gSelectbox";
import GSorting from "../gComponents/gSorting";
import useSettings from "../useSettings";
import GButton from "../gComponents/gButton";

const columns = [
  { id: "id", label: "Id", minWidth: 30, type: "order" },
  {
    id: "customer",
    label: "Customer",
    minWidth: 100,
    type: "customer_id",
    align: "center",
  },
  {
    id: "store",
    label: "Store",
    minWidth: 150,
    type: "store_id",
    align: "center",
  },
  {
    id: "price",
    label: "Price",
    minWidth: 80,
    // format: (value) => value.toLocaleString('en-US'),
    //format: (value) => value.toFixed(2),
    type: "order",
    align: "center",
  },
  {
    id: "delivery_time",
    label: "Delivery Time",
    minWidth: 110,
    width: 160,
    type: "order",
    align: "center",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 120,
    type: "order",
    align: "center",
  },
  {
    id: "created_at_formatted",
    label: "Created",
    minWidth: 130,
    type: "order",
    align: "center",
  },
];

const filterOrderTypes = [
  {
    title: "Pickup",
    value: "store_pickup",
  },
  {
    title: "Delivery",
    value: "home_delivery",
  },
];

const filterDeliveryTypes = [
  {
    title: "Asap",
    value: "asap",
  },
  {
    title: "Scheduled",
    value: "scheduled",
  },
];
const orderSorting = [
  {
    title: "Created At",
    value: "created_at",
  },
  {
    title: "Delivery Time",
    value: "delivery_to",
  },
];

const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
  },
});

export default function Orders(props) {
  const classes = useStyles();

  const [ordersData, setOrderData] = useState([]);
  const [orderPaginationData, setOrderPaginationData] = useState("");
  const [orderStatusData, setOrderStatusData] = useState([]);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    order_status: params.get("order_status")
      ? params.get("order_status")
      : "pending",
    store_id: params.get("store_id") ? params.get("store_id") : "",
    customer_id: params.get("customer_id") ? params.get("customer_id") : "",
    delivery_type: params.get("delivery_type")
      ? params.get("delivery_type")
      : "",
    order_type: params.get("order_type") ? params.get("order_type") : "",
    orderby: params.get("orderby") ? params.get("orderby") : "created_at",
    order: params.get("order") ? params.get("order") : "DESC",
  });

  const settings = useSettings(true);
  useEffect(() => {
    console.log(props);

    if (props.type) {
      props.onChange("orders");
      var obj = "?page=" + filters.page + "&" + props.title + "=" + props.value;
    } else {
      var params = QueryWith(history.location, filters);
      if (params.length > 0) {
        history.push({
          pathname: "/orders",
          state: "order",
          search: params[0],
        });
      }
    }
    var query = props.type ? obj : params[0];
    var hitUrl = "orders";

    ApiService("GET", hitUrl, query)
      .then((response) => {
        console.log("gh" + response);
        if (response.status_code === 1) {
          setOrderData(response.data.data);
          setOrderPaginationData(response.data);
          setOrderStatusData(response.statuses);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [history, filters]);

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({
      page: NewPageNo,
      order_status: filters.order_status,
    });
  };
  const handleChangeStatus = (oStatus) => {
    console.log(oStatus);
    setFilters({
      page: "1",
      order_status: oStatus,
      store_id: filters.store_id,
    });
  };

  return (
    <div className={props.type ? "" : "mainInnerView"}>
      {!props.type ? (
        <React.Fragment>
          <h1>
            <Assignment /> Orders
          </h1>
        </React.Fragment>
      ) : (
        ""
      )}

      {!props.type ? (
        <React.Fragment>
          <OrderStatuses
            statusList={orderStatusData}
            onClickStatus={handleChangeStatus}
            defaultStatus={filters.order_status}
            currencySymbol={settings.currency_symbol}
          />

          <div
            style={{
              display: "inline-block",
              width: "calc(100% - 300px)",
            }}
          >
            <StoreFilter
              value={filters.store_id}
              style={{
                marginBottom: "10px",
                marginRight: "10px",
              }}
              handleStoreChange={(value) => {
                setFilters({
                  store_id: value,
                  order_status: filters.order_status,
                });
              }}
            />
            <CustomerFilter
              value={filters.customer_id}
              style={{
                marginRight: "10px",
              }}
              handleCustomerChange={(value) => {
                setFilters({
                  customer_id: value,
                  store_id: filters.store_id,
                  order_status: filters.order_status,
                });
              }}
            />
            <GSelectbox
              placeholder="Select Order Type"
              defaultvalue={filters.order_type}
              data={filterOrderTypes}
              id="order_type_filter"
              option_key="title"
              option_value="value"
              onChange={(newVal) => {
                setFilters({
                  order_type: newVal,
                  store_id: filters.store_id,
                  order_status: filters.order_status,
                });
              }}
              style={{
                border: "1px solid #e2e2e2",
                height: "40px",
                marginRight: "10px",
                minWidth: "150px",
              }}
            />
            <GSelectbox
              placeholder="Select Delivery Type"
              defaultvalue={filters.delivery_type}
              data={filterDeliveryTypes}
              id="delivery_type_filter"
              option_key="title"
              option_value="value"
              onChange={(newVal) => {
                setFilters({
                  delivery_type: newVal,
                  store_id: filters.store_id,
                  order_status: filters.order_status,
                });
              }}
              style={{
                border: "1px solid #e2e2e2",
                height: "40px",
                marginRight: "10px",
                minWidth: "150px",
              }}
            />

            <GSorting
              defaultvalue={filters.orderby}
              defaultOrdervalue={filters.order}
              data={orderSorting}
              id="sortBy"
              option_key="title"
              option_value="value"
              onChange={(newVal) => {
                setFilters({
                  orderby: newVal,
                  order_status: filters.order_status,
                });
              }}
              style={{
                height: "40px",
                marginRight: "10px",
                minWidth: "150px",
              }}
              onOrderChange={(newVal) => {
                setFilters({
                  order: newVal,
                  order_status: filters.order_status,
                });
              }}
            />
          </div>

          {ordersData.length > 0 ? (
            <div
              style={{
                textAlign: "right",
                display: "inline-block",
                width: "300px",
              }}
            >
              <GPagination
                style={{ display: "inline-block" }}
                count={orderPaginationData.total}
                from={orderPaginationData.from}
                to={orderPaginationData.to}
                onChangeNextPage={() =>
                  handleChangePage(orderPaginationData.next_page_url)
                }
                onChangePrevPage={() =>
                  handleChangePage(orderPaginationData.prev_page_url)
                }
                rowsPerPage={orderPaginationData.per_page}
                prevDisabled={orderPaginationData.prev_page_url ? false : true}
                nextDisabled={orderPaginationData.next_page_url ? false : true}
              ></GPagination>
              {orderPaginationData ? (
                <GPaginationSelect
                  defaultvalue={filters.page}
                  totalPage={orderPaginationData.last_page}
                  onChangePage={(NewPageNo) =>
                    setFilters({
                      page: NewPageNo,
                      order_status: filters.order_status,
                    })
                  }
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </React.Fragment>
      ) : (
        ""
      )}

      <Paper
        className={classes.root}
        style={{ marginTop: props.type ? "" : "10px" }}
      >
        {ordersData.length !== 0 && props.type ? (
          <GPagination
            count={orderPaginationData.total}
            from={orderPaginationData.from}
            to={orderPaginationData.to}
            onChangeNextPage={() =>
              handleChangePage(orderPaginationData.next_page_url)
            }
            onChangePrevPage={() =>
              handleChangePage(orderPaginationData.prev_page_url)
            }
            rowsPerPage={orderPaginationData.per_page}
            prevDisabled={orderPaginationData.prev_page_url ? false : true}
            nextDisabled={orderPaginationData.next_page_url ? false : true}
          ></GPagination>
        ) : (
          ""
        )}

        {!orderPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer className={classes.container}>
            {ordersData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns
                      .filter((column) => column.type !== props.title)
                      .map((c) => {
                        return (
                          <TableHeader
                            key={c.id}
                            style={{
                              minWidth: c.minWidth,
                              maxWidth: c.maxWidth,
                              width: c.width,
                              textAlign: c.align,
                            }}
                          >
                            {c.label}
                          </TableHeader>
                        );
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ordersData.map((s) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={s.id}>
                        <TableRowData>
                          <Link
                            to={{ pathname: "/order/" + s.id, state: "order" }}
                          >
                            #{s.alpha_order_no ? s.alpha_order_no : s.id}
                          </Link>
                        </TableRowData>
                        {props.title === "customer_id" ? (
                          ""
                        ) : (
                          <TableRowData style={{ textAlign: "center" }}>
                            {s.customer ? (
                              <Link
                                to={{
                                  pathname:
                                    "/customer/" + s.customer.id + "/orders",
                                  state: "customer",
                                }}
                              >
                                {s.customer.title}
                              </Link>
                            ) : (
                              "-"
                            )}
                          </TableRowData>
                        )}
                        {props.title === "store_id" ? (
                          ""
                        ) : (
                          <TableRowData style={{ textAlign: "center" }}>
                            {s.store ? (
                              <Link
                                to={{
                                  pathname: "/store/" + s.store.id + "/orders",
                                  state: "store",
                                }}
                              >
                                {s.store.title}
                              </Link>
                            ) : (
                              ""
                            )}
                          </TableRowData>
                        )}
                        <TableRowData style={{ textAlign: "center" }}>
                          {settings.currency_symbol}
                          {s.amount_display}
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          {s.delivery_time ? s.delivery_time : "-"}
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          <span
                            className="orderStatus"
                            style={{
                              color: "#" + s.status.color1,
                              background: "#" + s.status.color2,
                            }}
                          >
                            {s.status.title}
                          </span>
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          {s.created}
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </Paper>
    </div>
  );
}
