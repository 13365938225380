import React, { useState, useEffect } from "react";
import useAsyncStorage from "./useAsyncStorage";

const UseCart = (refresh = false) => {
  const [cartStore, setCartStore] = useState("");
  const [cart, setCart] = useState([]);

  useEffect(() => {
    setCart(cart);
    console.log(cart);
  }, [cart]);

  const addProduct = (product, variants = []) => {
    if (cart.length === 0) {
      product.quantity = 1;
      setCartStore(product.store_id);
      setCart([product]);
      return;
    }

    // CHECK FOR ANY ITEM FROM DIFFERENT STORE
    if (cartStore && product.store_id != cartStore) {
      if (
        window.confirm(
          "Your cart contains products from another store. Would you like to clear cart and add this product?"
        )
      ) {
        product.variants = variants;
        setCart([product]);
        setCartStore(product.store_id);
        return;
      }

      return;
    }

    // VALIDATE VARIANTS
    if (variants.length > 0) {
      //return;
    }

    let existing = cart.findIndex(
      (c) => c.id == product.id && isSameArray(c.variants, variants)
    );
    console.log(existing);
    if (existing >= 0) {
      let tempArray = [...cart];
      let temp = tempArray[existing];
      temp.quantity = temp.quantity + 1;
      tempArray[existing] = temp;
      setCart(tempArray);
    } else {
      // ADD THE PRODUCT
      product.quantity = 1;
      product.variants = variants;
      setCart([...cart, product]);
    }
    setCartStore(product.store_id);
  };

  const decreaseQuantity = (product) => {
    let existing = cart.findIndex(
      (c) => c.id === product.id && isSameArray(c.variants, product.variants)
    );
    console.log(existing);
    if (existing >= 0) {
      let tempArray = [...cart];
      let temp = tempArray[existing];
      temp.quantity = temp.quantity - 1;
      tempArray[existing] = temp;
      console.log(tempArray);
      tempArray = tempArray.filter((t) => t.quantity > 0);
      setCart(tempArray);

      if (tempArray.length == 0) {
        setCartStore(null);
      }
    } else {
      let tempArrayNew = [...cart];
      tempArrayNew = tempArrayNew.filter((t) => t.quantity > 0);
      setCart(tempArrayNew);
    }
    setCartStore(product.store_id);
  };

  const removeProduct = (product) => {
    let existing = cart.findIndex(
      (c) => c.id === product.id && isSameArray(c.variants, product.variants)
    );
    console.log(existing);
    if (existing >= 0) {
      let tempArray = [...cart];
      let temp = tempArray[existing];
      temp.quantity = 0;
      tempArray[existing] = temp;
      console.log(tempArray);
      tempArray = tempArray.filter((t) => t.quantity > 0);
      setCart(tempArray);

      if (tempArray.length == 0) {
        setCartStore(null);
      }
    }
    setCartStore(product.store_id);
  };

  return {
    cart: cart,
    addProduct: addProduct,
    removeProduct: removeProduct,
    cartStore: cartStore,
    setCartStore: setCartStore,
    setCart: setCart,
    decreaseQuantity: decreaseQuantity,
  };
};

export default UseCart;

const isSameArray = (first, second) => {
  if (first.length == second.length) {
    let firstIds = first.map((f) => f.id);
    let secondIds = second.map((s) => s.id);
    if (firstIds.every((f) => secondIds.includes(f))) {
      return true;
    }
  }
  return false;
};
