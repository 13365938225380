import React, { useState, useEffect } from "react";
import { ApiService, addDefaultSrc } from "../services";
import GEditable from "../gComponents/gEditable";
import GImageEditor from "../gComponents/gImageEditor";
import { ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { GImagePicker } from "../gComponents/gImagePicker";
import { GImagePicker2 } from "../gComponents/gImagePicker2";
import useSettings from "../useSettings";
import ColorPicker from "../gComponents/gColorPicker";

export default function WebsiteSettings(props) {
  const settings = useSettings();
  const [openSnackbar] = useSnackbar();
  const [linksData, setLinksData] = useState({
    web_header_logo: {},
    web_footer_logo: {},
    color_1: {},
    color_2: {},
    color_3: {},
    desc_1: {},
    header_image: {},
    favicon: {},
    color_4: {},
    color_5: {},
    color_6: {},
    color_7: {},
  });
  //const { currency_symbol, admin_logo, email_logo } = useState({});

  useEffect(() => {
    getWebSettings();
  }, []);

  const getWebSettings = () => {
    ApiService("GET", "setting/website")
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setLinksData(response.data);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function updateField(newValue, selectedFieldId, selectedKey) {
    console.log(newValue);
    console.log(selectedFieldId);
    // return;
    var send_data = {
      key_value: newValue,
    };
    var hitUrl = "setting/" + selectedFieldId;
    ApiService("PUT", hitUrl, send_data)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          //getBasicSettings();
          linksData[selectedKey] = response.data;
          setLinksData(linksData);
          openSnackbar(response.message);
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView">
      <h2>Website Settings</h2>

      <div className="listPaper">
        <div
          style={{
            display: "flex",
            verticalAlign: "top",
            boxSizing: "border-box",
            padding: "20px",
          }}
        >
          {/* <div style={{ width: "40%" }}>
            <label style={{ display: "block", marginBottom: "10px" }}>
              Header Logo
            </label>
            <div
              style={{
                background: "#f4f5f9",
                padding: "10px",
                display: "inline-block",
              }}
            >
              <GImageEditor
                image={
                  ImgUrl("logo") + "/" + linksData.web_header_logo.key_value
                }
                onSubmit={(newVal, fieldId) => {
                  updateField(newVal, fieldId, "web_header_logo");
                }}
                id={linksData.web_header_logo.id}
                type="logo"
                isPreviewImg={true}
              />
            </div>
          </div> */}
          <div style={{ width: "40%" }}>
            <label style={{ display: "block", marginBottom: "10px" }}>
              Header Logo
            </label>
            <div
              style={{
                background: "#f4f5f9",
                padding: "10px",
                display: "inline-block",
              }}
            >
              {linksData.web_header_logo.key_value ? (
                <GImagePicker
                  type="website"
                  alt={settings?.project_name}
                  height={100}
                  width={100}
                  radius={1}
                  //editor
                  editorProps={{
                    height: 200,
                    width: 200,
                    radius: 100,
                  }}
                  serverProps={{
                    size: 200,
                  }}
                  onSuccess={(v) => {
                    console.log(v);
                    updateField(
                      v.image,
                      linksData.web_header_logo.id,
                      "web_header_logo"
                    );
                  }}
                  isPreviewImg={true}
                  default={
                    ImgUrl("website") +
                    "/" +
                    linksData.web_header_logo.key_value
                  }
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div style={{ width: "40%" }}>
            <label style={{ display: "block", marginBottom: "10px" }}>
              Footer Logo
            </label>
            <div
              style={{
                background: "#f4f5f9",
                padding: "10px",
                display: "inline-block",
              }}
            >
              {linksData.web_footer_logo.key_value ? (
                <GImagePicker2
                  type="website"
                  alt={settings?.project_name}
                  height={100}
                  width={100}
                  radius={1}
                  //editor
                  editorProps={{
                    height: 200,
                    width: 200,
                    radius: 100,
                  }}
                  serverProps={{
                    size: 200,
                  }}
                  onSuccess={(v) => {
                    console.log(v);
                    updateField(
                      v.image,
                      linksData.web_footer_logo.id,
                      "web_footer_logo"
                    );
                  }}
                  isPreviewImg={true}
                  default={
                    ImgUrl("website") +
                    "/" +
                    linksData.web_footer_logo.key_value
                  }
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "block",
            width: "70%",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <div style={{ marginBottom: "20px" }}>
            <label style={{ margin: "5px 0", display: "block" }}>Color</label>
            <ColorPicker
              id={linksData.color_1.id}
              defaultcolor={linksData.color_1.key_value}
              onChange={(newVal, fieldId) => {
                updateField(newVal, fieldId, "color_1");
              }}
            />
          </div>
          {/* <GEditable
            key={linksData.color_1.id}
            type="text"
            title={true}
            editable={false}
            defaultValue={linksData.color_1.key_value}
            className={props.display}
            placeholder={linksData.color_1.key_display_title}
            id={linksData.color_1.id}
            onSubmit={(newVal, fieldId) => {
              updateField(newVal, fieldId, "color_1");
            }}
            style={{ margin: "10px 0 20px" }}
          /> */}

          <GEditable
            key={linksData.desc_1.id}
            type="textarea"
            title={true}
            editable={false}
            defaultValue={linksData.desc_1.key_value}
            placeholder={linksData.desc_1.key_display_title}
            className={props.display}
            id={linksData.desc_1.id}
            onSubmit={updateField}
            style={{ margin: "10px 0 20px" }}
          />

          <div>
            <label style={{ display: "block", marginBottom: "10px" }}>
              Header Image
            </label>
            <div
              style={{
                background: "#f4f5f9",
                padding: "10px",
                display: "block",
              }}
            >
              <GImageEditor
                image={
                  ImgUrl("website") + "/" + linksData.header_image.key_value
                }
                onSubmit={(newVal, fieldId) => {
                  updateField(newVal, fieldId, "header_image");
                }}
                id={linksData.header_image.id}
                type="website"
                isPreviewImg={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
