import React, { useEffect, useState } from "react";
import { ApiService } from "../services";
import GTableShimmer from "../gComponents/gTableShimmer";
import { useSnackbar } from "react-simple-snackbar";
import GEmpty from "../gComponents/gEmpty";


const LeadQueries = () => {
    const [openSnackbar] = useSnackbar();
  const [queryResponse, setQueryResponse] = useState([]);
  const [objectKeys, seObjectKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  

//   To fetch data ===>
  function getQuery() {
    var hitUrl = `collect-data`;

    console.log("hitURL======>",hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log("Query response=======>>", response.data);
        if (response.status_code === 1) {
          setQueryResponse(response.data);
          seObjectKeys(response.data[0]);
          setIsLoading(false);
        } else {
            openSnackbar(response.message);
        //   console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
// ======###



  useEffect(() => {
    getQuery();
  }, []);


    return (
        <>
        {/* {console.log("response type ==>>", typeof objectKeys)} */}
        <div class="tbl-header">
        <table cellpadding="0" cellspacing="0" border="0"></table>
        <table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Line-1</th>
              <th>City</th>
              <th>State</th>
              <th>Country</th>
              <th>Zipcode</th>
              <th>Latitude</th>
              <th>Longitude</th>
              <th>Created</th>
            </tr>
          </thead>
        </table>
      </div>
      {isLoading === true ? (
            <GTableShimmer></GTableShimmer>
          ) : (
            queryResponse.length ===
                0 ? (
                  <GEmpty></GEmpty>
                ) :
      <div class="tbl-content">
        <table cellpadding="0" cellspacing="0" border="0">
          <tbody>
            {queryResponse.map((q, i) => (
               <tr key={q.id}>
                <td>{q.email}</td>
                <td>{q.line1}</td>
                <td>{q.city}</td>
                <td>{q.state}</td>
                <td>{q.country}</td>
                <td>{q.zipcode}</td>
                <td>{q.latitude}</td>
                <td>{q.longitude}</td>
                <td>{q.created}</td>
               
              </tr>
            ))}
          </tbody>
        </table>
      </div>
          )}
          {/* <div className="buttons">
        <ArrowBackIos onClick={handlePrev} />
        <p className="pagination">
          {allData.from}-{allData.to} of {allData.total}
        </p>
        <ArrowForwardIos onClick={handleNext} />
      </div> */}
          </>
    )
}

export default LeadQueries
