import React, { useEffect, useState } from "react";
import { ApiService } from "../services";
import { Edit, Delete } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import { Formik, Form, Field, FieldArray } from "formik";
import FormikControl from "../formikComponents/formikControl";
import * as Yup from "yup";
import { useSnackbar } from "react-simple-snackbar";
import GConfirmDialog from "../gComponents/gConfirmDialog";

const fieldStyle = {
  border: "0px",
  borderBottom: "1px solid #d2d2d2",
  background: "transparent",
};
function TaxForm(props) {

  const [dialogOpen, setDialogOpen] = useState(false);
  console.log(props)
  const [openSnackbar] = useSnackbar();
  const [taxData, setTaxData] = useState([]);


  const validationSchema = Yup.object({});

  useEffect(() => {
    document.getElementById('resetButton').click();
    setTaxData(props.data)
  }, [props.data]);

  const initialValues = {
    options: props.data,
  };

  const handleUpdateVariant = (values, ind) => {
    console.log(ind);
    console.log(values.options[ind]);
    //return;
    ApiService(
      "PUT",
      "taxes/" + values.options[ind].id,
      values.options[ind]
    ).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        values.options[ind].disabled = true;
        taxData[ind] = ({ ...response.data, disabled: true })

        setTaxData(taxData);

        openSnackbar(response.message);
      } else {
        openSnackbar(response.message);
        return;
      }
    });
  };

  return (
    <div style={props.style}>
      <Formik
        initialValues={initialValues}
        //onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, resetForm }) => (
          <Form>
            <FieldArray name="options">
              {(fieldArrayProps) => {
                console.log(fieldArrayProps);
                const { push, remove, form } = fieldArrayProps;
                const { values } = form;
                const { options } = values;
                const handleDeleteTax = (ind) => {

                  if (window.confirm('Are you sure you want to delete tax?')) {
                    ApiService(
                      "DELETE",
                      "taxes/" + values.options[ind].id
                    ).then((response) => {
                      console.log("r" + response.status_code);
                      if (response.status_code === 1) {
                        remove(ind);
                        // values.options = values.options.splice(ind, 1);
                        setTaxData(taxData.splice(ind, 1));
                        //taxData.splice(ind, 1);
                        props.onTaxRemove(ind);
                        openSnackbar(response.message);
                      } else {
                        openSnackbar(response.message);
                        return;
                      }
                    });
                  }
                };

                return (
                  <div>
                    {/* <div
                      style={{
                        display: "inline-flex",
                        width: "100%",
                        padding: "0px 0 0 20px",
                        boxSizing: "border-box",
                      }}
                      className="arrayFields"
                    >
                      <label style={{ maxWidth: '250px', minWidth: '240px' }}>Title</label>
                      <label style={{ maxWidth: "150px" }}>Percentage</label>
                      <label style={{ width: "40%" }}></label>
                    </div> */}
                    {options.map((o, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "inline-flex",
                            width: "100%",
                            padding: "0px 0 0 15px",
                            boxSizing: "border-box", borderBottom: '1px solid #e2e2e2'
                          }}
                          className="arrayFields"
                        >

                          <FormikControl
                            control="input"
                            type="text"
                            label="Title"
                            name={`options[${index}].title`}
                            divstyle={{ padding: '10px 5px', maxWidth: '250px', minWidth: '250px' }}
                            style={{ width: "92%", margin: '5px 0' }}
                            disabled={options[index].disabled === false ? false : true}
                          />
                          <FormikControl
                            control="input"
                            type="text"
                            label="Percentage"
                            name={`options[${index}].percentage`}
                            divstyle={{ padding: '10px 5px', maxWidth: '200px' }}
                            style={{ width: "92%", margin: '5px 0' }}
                            disabled={options[index].disabled === false ? false : true}
                            maxLength={7}
                            onChange={(event) => {
                              const re = /^[0-9.\b]+$/;
                              if (event.target.value && !re.test(event.target.value)) {
                                return;
                              } else {
                                setFieldValue(`options[${index}].percentage`, event.target.value);
                              }
                            }}
                          />


                          {options[index].disabled === false ? (
                            <span style={{ textAlign: 'right', width: 'calc(100% - 420px)', padding: '13px', marginTop: '25px' }}>

                              <GButton
                                variant="condensed"
                                onClick={(e) => {
                                  handleUpdateVariant(values, index);
                                }}
                              >
                                Update
                              </GButton>
                              <GButton
                                variant="linkable"
                                onClick={(e) => {
                                  setFieldValue(`options[${index}].disabled`, true);
                                  setFieldValue(`options[${index}].title`, props.data[index].title);
                                  setFieldValue(`options[${index}].percentage`, props.data[index].percentage);
                                }}
                              >
                                Cancel
                              </GButton>
                            </span>
                          ) : (
                              <span style={{ textAlign: 'right', width: 'calc(100% - 420px)', padding: '13px', marginTop: '25px' }}>
                                <GButton
                                  variant="linkable"
                                  onClick={(e) => {
                                    setFieldValue(`options[${index}].disabled`, false)
                                    //handleUpdateVariant(values, index);
                                  }}
                                >
                                  <Edit />
                                </GButton>
                                <GButton
                                  variant="linkable"
                                  onClick={(e) => {
                                    handleDeleteTax(index);
                                  }}
                                >
                                  <Delete />
                                </GButton>
                                <button id="resetButton" style={{ display: 'none' }} onClick={() => resetForm()}></button>
                              </span>
                            )}
                        </div>
                      );
                    })}
                  </div>
                );
              }}
            </FieldArray>
          </Form>
        )}
      </Formik>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Tax"
          text="Are you sure you want to delete this tax?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
          }}
        ></GConfirmDialog>
      ) : (
          ""
        )}
    </div>
  );
}
export default TaxForm;
