import React, { useState } from "react";
import { makeStyles, Paper, Backdrop } from "@material-ui/core";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import TooltipComponent from "../gComponents/gTooltip";
import { Info } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var buttonStyle = {
  background: "#f08c37",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "100%",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  marginTop: "30px",
  fontWeight: "600",
};

export default function CreateVariantType(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [switchVal, setSwitchVal] = useState(
    props.variantData.minimum === 1 ? true : false
  );
  const priceOptions = [
    {
      key: "None",
      value: "0",
      tooltipValue:
        "Choosing this will not effect product pricing. Ex: Color Selection",
    },
    {
      key: "Additional",
      value: "1",
      tooltipValue:
        "Price of variant will be added to the actual product price. Ex: Extra Toppings, Extra Hanger",
    },
    {
      key: "Choice",
      value: "2",
      tooltipValue:
        "Price of variant will become the actual product price. Ex: Size or Weight Options",
    },
  ];
  const initialValues = {
    title: props.variantData.title || "",
    price_type: props.variantData.price_type || "",
    minimum: props.variantData.minimum || 0,
    maximum: props.variantData.maximum || "",
    status: props.variantData.status || 0,
  };

  const onSubmit = (values) => {
    console.log(values);
    // values.minimum = switchVal;
    values.minimum = switchVal === true ? 1 : 0;

    if (values.price_type !== "1") {
      delete values.maximum;
      delete values.minimum;
    }

    console.log(values);
    // return;
    if (!props.isEmpty) {
      ApiService(
        "PUT",
        "product/variant/types/" + props.variantData.id,
        values
      ).then((response) => {
        console.log("r" + response.status_code);
        if (response.status_code === 1) {
          props.setVtvisible(false);
          props.onSubmit();
        } else {
          openSnackbar(response.message);
          return;
        }
      });
    } else {
      ApiService("POST", "product/variant/types", values).then((response) => {
        console.log("r" + response.status_code);
        if (response.status_code === 1) {
          props.setVtvisible(false);
          props.onSubmit();
        } else {
          openSnackbar(response.message);
          return;
        }
      });
    }
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    price_type: Yup.string().required("Required"),
    maximum: Yup.string().when("price_type", {
      is: "1",
      then: Yup.string().required("Required"),
    }),
  });

  const handleClose = (e) => {
    if (e.target.id !== (2739).toString()) {
      return;
    }
    props.setVtvisible(false);
  };

  if (props.vtvisible === false) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2739"
        className={classes.backdrop}
        open={props.vtvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.vtvisible === true ? "fade" : ""}`}
          style={{ padding: "20px" }}
        >
          <h3>{props.isEmpty === true ? "Add" : "Edit"} Variant Type</h3>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values }) => (
              <Form>
                <FormikControl
                  control="input"
                  type="text"
                  label="Title"
                  name="title"
                />

                <FormikControl
                  control="radio"
                  label="Price options"
                  name="price_type"
                  options={priceOptions}
                  key_title="key"
                  key_value="value"
                  divstyle={{ display: "block", padding: "5px 0" }}
                />

                {values.price_type === "1" ? (
                  <React.Fragment>
                    <h4 style={{ margin: "15px 15px 5px" }}>
                      Apply Rules on Variants
                    </h4>

                    <div style={{ margin: "15px 15px 5px" }}>
                      <label style={{ display: "inline-block" }}>
                        Selection Mandatory*
                        <TooltipComponent
                          title=" 
                      When Selection Mandatory is turned on, customer must need to Choose at least one option out of the available variant options"
                          position="bottom"
                          id="twilio_number"
                        >
                          <Info fontSize="small" />
                        </TooltipComponent>
                      </label>
                      <FormikControl
                        control="toggle"
                        isToggled={switchVal}
                        name="minimum"
                        onToggle={() => {
                          setSwitchVal(!switchVal);
                        }}
                      />
                    </div>

                    <FormikControl
                      control="input"
                      type="text"
                      label="Maximum Selection"
                      name="maximum"
                      style={{
                        display: "inline-block",
                        width: "70px",
                        marginLeft: "15px",
                        padding: "0.45rem",
                      }}
                      labelStyle={{
                        display: "inline-block",
                        padding: "0px 15px 0 0",
                      }}
                    />
                  </React.Fragment>
                ) : (
                  ""
                )}

                <br />
                <button style={buttonStyle} type="submit">
                  {props.isEmpty ? "Create" : "Update"}
                </button>
              </Form>
            )}
          </Formik>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
