import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import GEmpty from "../gComponents/gEmpty";
import GButton from "../gComponents/gButton";
import { useSnackbar } from "react-simple-snackbar";
import GListCard from "../gComponents/gListCards";
import VariantTypePopup from "../components/variantTypesForm";
import GTableShimmer from "../gComponents/gTableShimmer";

export default function VariantTypes() {
  const [openSnackbar] = useSnackbar();
  const [variantTypeData, setVariantTypeData] = useState([]);
  const [vtvisible, setVtvisible] = useState(false);
  const [selectedVariantType, setSelectedVariantType] = useState({});
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getVariantTypes();
  }, []);

  const getVariantTypes = () => {
    var hitUrl = "product/variant/types";

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setVariantTypeData(response.data);
          setIsLoading(false);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function deleteVariantType(selectedId) {
    var hitUrl = "product/variant/types/" + selectedId;
    ApiService("DELETE", hitUrl)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          getVariantTypes();
          openSnackbar(response.message)
        } else {
          openSnackbar(response.message)
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const editVariantType = (selectedRecord) => {
    setSelectedVariantType(selectedRecord);
    setIsEmpty(false);
    setVtvisible(true);
  };

  const handleSwitch = (newValue, sId, ind) => {
    var switchData = {
      status: newValue,
    };
    console.log(switchData);
    ApiService("PUT", "product/variant/types/" + sId, switchData)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          variantTypeData[ind] = response.data;
          setVariantTypeData(variantTypeData);
        } else {
          console.log(response.data);
          getVariantTypes();
        }
        openSnackbar(response.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView">
      <GButton
        variant="condensed"
        children="Add"
        onClick={() => {
          setSelectedVariantType({});
          setVtvisible(true);
          setIsEmpty(true);
        }}
        style={{
          margin: "10px 5px",
          float: "right",
        }}
      ></GButton>
      <h2>Variant Types</h2>
      <div className="listPaper">
        {isLoading === true ? (
          <GTableShimmer></GTableShimmer>
        ) : variantTypeData.length === 0 ? (
          <GEmpty></GEmpty>
        ) : (
              <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                boxSizing: 'border-box'
              }}>
                {variantTypeData.map((v, i) => {
                  return (
                    <GListCard
                      setting="Variant Type"
                      key={v.id}
                      id={v.id}
                      title={v.title}
                      switch={true}
                      switchValue={v.status}
                      editAction={true}
                      onEdit={editVariantType}
                      onDelete={deleteVariantType}
                      data={v}
                      onSwitchToggle={(value, id) => handleSwitch(value, id, i)}
                    ></GListCard>
                  );
                })}
              </div>
            )}
      </div>
      {vtvisible === true ? (
        <VariantTypePopup
          vtvisible={vtvisible}
          setVtvisible={setVtvisible}
          variantData={selectedVariantType}
          isEmpty={isEmpty}
          onSubmit={getVariantTypes}
        />
      ) : (
          ""
        )}
    </div>
  );
}
