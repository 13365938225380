import React from "react";

import Dashboard from "./dashboard/dashboard";
import Orders from "./orders/ordersList";
import OrderDetail from "./orders/orderDetails";
import OrderInvoice from "./orders/orderInvoice";
import OrderThermalInvoice from "./orders/orderThermalInvoice";
import Customers from "./customers/customersList";
import CustomerDetail from "./customers/customerDetails";
import Drivers from "./drivers/driversList";
import DriverDetail from "./drivers/driverDetails";
import Stores from "./stores/storesList";
import StoreDetail from "./stores/storeDetails";
import Reviews from "./components/reviewsList";
import Products from "./products/productsList";
import MasterProducts from "./products/masterProducts";
import productCategories from "./products/categoriesList";
import Coupons from "./components/couponsList";
import BulkNotifications from "./components/bulkPushNotifications";
import Reports from "./reports/reports";
import ReportDetails from "./reports/reportDetails";
import Withdrawals from "./components/withdrawals";
import Transactions from "./components/transactions";
import TransactionsFailed from "./components/transactionsFailed";
import SettingView from "./settings/settings";
import Queries from "./Queries/Queries";
import Login from "./Login";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import Sidebar from "./sidebar/sidebar";
import Header from "./header/header";

const mainStyle = {
  display: "inline-flex",
  width: "100%",
};
const contentStyle = {
  // background: '#26252a',
  background: "#f4f5f9",
  width: "82%",
  height: "100vh",
  overflow: "auto",
};

export default function App() {
  const location = useLocation();
  let match = useRouteMatch("/order-invoice/:id");
  let match2 = useRouteMatch("/order-thermal-invoice/:id");
  let url = match ? match.url : "";
  let tUrl = match2 ? match2.url : "";

  const isLoggedIn = () => {
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("expires_in") > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      {isLoggedIn() &&
      !(url === location.pathname || tUrl === location.pathname) ? (
        <div style={mainStyle}>
          <Sidebar />
          <div style={contentStyle}>
            <Header />
            <div
              className="mainInnerStyles"
              style={{
                height: "calc(100vh - 70px)",
                width: "100%",
                overflow: "overlay",
              }}
            >
              <Switch>
                <Route path="/dashboard" component={Dashboard}></Route>
                <Route path="/orders" component={Orders}></Route>
                <Route path="/order/:orderId" component={OrderDetail}></Route>

                <Route path="/customers" component={Customers}></Route>
                <Route
                  path="/customer/:customerId/:detailType"
                  component={CustomerDetail}
                ></Route>
                <Route
                  path="/driver/:driverId/:detailType"
                  state="driver"
                  component={DriverDetail}
                ></Route>
                <Route
                  path="/drivers"
                  state="driver"
                  component={Drivers}
                ></Route>
                <Route path="/stores" component={Stores}></Route>
                <Route
                  path="/store/:storeId/:detailType"
                  component={StoreDetail}
                ></Route>
                <Route path="/reviews" component={Reviews}></Route>
                <Route path="/products" component={Products}></Route>
                <Route path="/categories" component={productCategories}></Route>
                <Route
                  path="/master-products"
                  component={MasterProducts}
                ></Route>
                <Route
                  path="/bulk-notifications"
                  component={BulkNotifications}
                ></Route>
                <Route path="/coupons" component={Coupons}></Route>
                <Route path="/reports" component={Reports}></Route>
                <Route
                  path="/report/details/:identifier"
                  component={ReportDetails}
                ></Route>

                <Route path="/transactions" component={Transactions}></Route>
                <Route path="/queries" component={Queries}></Route>
                <Route
                  path="/failed-payments/:type"
                  component={TransactionsFailed}
                ></Route>
                <Route path="/settings/:settingId" component={SettingView} />
                <Route
                  path="/withdrawals/:withdrawId"
                  component={Withdrawals}
                />

                <Redirect to="/dashboard" from="/" />
              </Switch>
            </div>
          </div>
        </div>
      ) : (
        <Switch>
          <Redirect to="/" from="/dashboard" />
          <Route exact path="/" component={Login}></Route>
          <Route
            path="/order-invoice/:orderId"
            component={OrderInvoice}
          ></Route>
          <Route
            path="/order-thermal-invoice/:orderId"
            component={OrderThermalInvoice}
          ></Route>
        </Switch>
      )}
    </div>
  );
}
