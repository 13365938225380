import React from "react";
import styled from "styled-components";

const Button = styled.button`
  padding: 0.65em 2.5em;
  font-size: 15px;
  font-weight: 500;
  /* line-height: 1.5; */
  border-radius: 0px;
  cursor: pointer;
  width: fit-content;
  box-shadow: none;
  outline: 0;

  &[disabled] {
    color: #000;
    cursor: not-allowed;
    background: #d2d2d2;
    border: 0px;

    &:hover {
      color: rgba(0, 0, 0, 0.66);
    }
  }

  &.condensed {
    background: ${(props) => (props.primary ? "palevioletred" : "white")};
    color: ${(props) => (props.primary ? "white" : "palevioletred")};
    color: white;
    background: #f08c37;
    border: 0;
  }
  &.outlined {
    color: #f08c37;
    background: transparent;
    border: 1px solid #f08c37;
  }

  &.linkable {
    color: #f08c37;
    background: transparent;
    border: 0px;
    padding: 5px;
  }

  &.text {
    background: transparent;
    padding: 6px 12px;
    border: 0px;
    color: #f08c37;

    &:hover {
      background: rgba(25, 118, 210, 0.04);
    }
  }
`;

function GButton(props) {
  return (
    <Button
      className={props.variant}
      onClick={props.onClick}
      style={props.style}
      disabled={props.disabled}
    >
      {props.children}
    </Button>
  );
}

export default GButton;
