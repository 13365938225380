import React from "react";
import { makeStyles, Paper, Backdrop, Link } from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import GButton from "../gComponents/gButton";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var buttonStyle = {
  background: "#f08c37",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "100%",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  marginTop: "30px",
  fontWeight: "600",
};

export default function CreatePricingRule(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const applyToOptions = [
    {
      key: "Flat Fare",
      value: "1",
    },
    {
      key: "Distance and Time based Fare",
      value: "2",
    },
    {
      key: "Distance Slots based Fare",
      value: "3",
    },
  ];
  const initialValues = {
    title: props.ruleData.title || "",
    rule_type: props.ruleData.rule_type || "",
    base_fare: props.ruleData.base_fare || "",
    duration_fee: props.ruleData.duration_fee || "",
    distance_fee: props.ruleData.distance_fee || "",
    minimum_fare: props.ruleData.minimum_fare || "",
    options: props.ruleData.options || [{ upto: "", cost: "" }],
  };

  const onSubmit = (values) => {
    console.log(values);
    if (values.rule_type !== "3") {
      delete values.options;
    }
    if (values.rule_type !== "2") {
      delete values.minimum_fare;
      delete values.distance_fee;
      delete values.duration_fee;
    }
    if (!(values.rule_type === "2" || values.rule_type === "1")) {
      delete values.base_fare;
    }

    var hitUrl = !props.isEmpty
      ? "task/rules/" + props.ruleData.id
      : "task/rules";
    var hitMethod = !props.isEmpty ? "PUT" : "POST";

    ApiService(hitMethod, hitUrl, values).then((response) => {
      console.log("r" + response.status_code);
      openSnackbar(response.message);
      if (response.status_code === 1) {
        props.setPrvisible(false);
        props.onSubmit();
      }
      return;
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    rule_type: Yup.string().required("Required"),
    base_fare: Yup.string().when("rule_type", {
      is: "1" || "2",
      then: Yup.string().required("Required"),
    }),
    duration_fee: Yup.string().when("rule_type", {
      is: "2",
      then: Yup.string().required("Required"),
    }),
    distance_fee: Yup.string().when("rule_type", {
      is: "2",
      then: Yup.string().required("Required"),
    }),
    minimum_fare: Yup.string().when("rule_type", {
      is: "2",
      then: Yup.string().required("Required"),
    }),

    options: Yup.array().when("rule_type", {
      is: "3",
      then: Yup.array()
        .of(
          Yup.object({
            upto: Yup.string().required("Required"), // these constraints take precedence
            cost: Yup.string().required("Required"), // these constraints take precedence
          })
        )
        .required("required") // these constraints are shown if and only if inner constraints are satisfied
        .min(1, "Minimum of 1 option"),
    }),
  });

  const handleClose = (e) => {
    if (e.target.id !== (2739).toString()) {
      return;
    }
    props.setPrvisible(false);
  };

  if (props.prvisible === false) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2739"
        className={classes.backdrop}
        open={props.prvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          // className="modalRight"
          className={`modalRight
              ${props.prvisible === true ? "fadeMW" : ""}`}
          style={{ padding: "20px", maxWidth: "600px" }}
        >
          <h3>{props.isEmpty === true ? "Add" : "Edit"} Pricing Rule</h3>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values }) => (
              <Form>
                <FormikControl
                  control="input"
                  type="text"
                  label="Rule name"
                  name="title"
                />
                <h4 style={{ margin: "15px 15px 5px" }}>Fare Calculations</h4>
                <FormikControl
                  control="radio"
                  label="Apply to"
                  name="rule_type"
                  options={applyToOptions}
                  key_title="key"
                  key_value="value"
                />
                {values.rule_type === "1" ? (
                  <FormikControl
                    control="input"
                    type="text"
                    label="Base Fare"
                    name="base_fare"
                    style={{
                      display: "inline-block",
                      width: "30%",
                      verticalAlign: "top",
                    }}
                  />
                ) : values.rule_type === "2" ? (
                  <div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "33%",
                        verticalAlign: "top",
                      }}
                    >
                      <FormikControl
                        control="input"
                        type="text"
                        label="Base Fare"
                        name="base_fare"
                      />
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "33%",
                        verticalAlign: "top",
                      }}
                    >
                      <FormikControl
                        control="input"
                        type="text"
                        label="Duration Fee"
                        name="duration_fee"
                      />
                    </div>
                    <div style={{ display: "inline-block", width: "33%" }}>
                      <FormikControl
                        control="input"
                        type="text"
                        label="Distance Fee"
                        name="distance_fee"
                      />
                    </div>
                    <FormikControl
                      control="input"
                      type="text"
                      label=" Minimum Fare (Leave empty if there is no minimum fare)"
                      name="minimum_fare"
                    />
                  </div>
                ) : values.rule_type === "3" ? (
                  <div className="form-control">
                    <p
                      style={{
                        color: "#ccc",
                        fontSize: "14px",
                        fontStyle: "italic",
                      }}
                    >
                      (If the distance is more than the last slot, then the
                      price of last slot will be applied.)
                    </p>
                    <FieldArray name="options">
                      {(fieldArrayProps) => {
                        console.log(fieldArrayProps);
                        const { push, remove, form } = fieldArrayProps;
                        const { values } = form;
                        const { options } = values;
                        // if (options.length === 0) {
                        //   push({ upto: "", cost: "" });
                        // }
                        return (
                          <div>
                            <div
                              style={{
                                display: "inline-flex",
                                width: "100%",
                              }}
                              className="arrayFields"
                            >
                              <label style={{ width: "45%" }}>Upto</label>
                              <label style={{ width: "43%" }}>Cost</label>
                              <a
                                type="button"
                                href
                                //role="button"
                                variant="linkable"
                                onClick={(e) => {
                                  push({ upto: "", cost: "" });
                                }}
                              >
                                <Add />
                              </a>
                            </div>
                            {options.map((o, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    display: "inline-flex",
                                    width: "100%",
                                  }}
                                  className="arrayFields"
                                >
                                  <FormikControl
                                    control="input"
                                    type="text"
                                    name={`options[${index}].upto`}
                                    divstyle={{ padding: "0" }}
                                    style={{
                                      display: "inline-block",
                                      width: "90%",
                                      verticalAlign: "top",
                                    }}
                                  />
                                  {/* <Field
                                    type="text"
                                    placeholder=""
                                    name={`options[${index}].upto`}
                                  /> */}
                                  {/* <Field
                                    type="text"
                                    placeholder=""
                                    name={`options[${index}].cost`}
                                  /> */}
                                  <FormikControl
                                    control="input"
                                    type="text"
                                    name={`options[${index}].cost`}
                                    divstyle={{ padding: "0" }}
                                    style={{
                                      display: "inline-block",
                                      width: "90%",
                                      verticalAlign: "top",
                                    }}
                                  />
                                  <Link
                                    style={{
                                      textDecoration: "none",
                                      padding: "7px",
                                    }}
                                    onClick={(e) => {
                                      if (options.length > 1) {
                                        remove(index);
                                      }
                                    }}
                                  >
                                    <Close />
                                  </Link>
                                </div>
                              );
                            })}
                          </div>
                        );
                      }}
                    </FieldArray>
                  </div>
                ) : (
                        ""
                      )}

                <br />
                <button style={buttonStyle} type="submit">
                  {props.isEmpty ? "Create" : "Update"}
                </button>
              </Form>
            )}
          </Formik>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
