import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import GEmpty from "../gComponents/gEmpty";
import GListCard from "../gComponents/gListCards";
import GButton from "../gComponents/gButton";
import GTableShimmer from "../gComponents/gTableShimmer";
import { useSnackbar } from "react-simple-snackbar";
import CityPopup from "../components/cityForm";

import GeofencePopup from "../components/geofenceForm";
import GeofenceEditPopup from "../components/geofenceEditForm";
import { Add } from "@material-ui/icons";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import { useHistory } from "react-router-dom";
import QueryWith from "../Query";
import GActions from "../gComponents/gActionsStyle";
//import { browserHistory } from "react-router";

export default function Cities() {
  const [citiesData, setCitiesData] = useState([]);
  const [selectedCity, setSelectedCity] = useState({});
  const [selectedCityId, setSelectedCityId] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedGeofence, setSelectedGeofence] = useState({});
  const [geofvisible, setGeofvisible] = useState(false);
  const [geofEditvisible, setGeofEditvisible] = useState(false);
  const [cityfvisible, setCityfvisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar] = useSnackbar();

  const getCities = () => {
    var hitUrl = "cities";

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setCitiesData(response.data);
          setIsLoading(false);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCities();
  }, []);

  function deleteCity() {
    var hitUrl = "cities/" + selectedCityId;
    ApiService("DELETE", hitUrl)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          openSnackbar(response.message);
          getCities();
        } else {
          openSnackbar(response.message);
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const editCity = (selectedRecord) => {
    setSelectedCity(selectedRecord);
    setIsEmpty(false);
    setCityfvisible(true);
  };
  function deleteGeofence(selectedId) {
    var hitUrl = "geofences/" + selectedId;
    ApiService("DELETE", hitUrl)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          openSnackbar(response.message);
          getCities();
        } else {
          openSnackbar(response.message);
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const editGeofence = (selectedRecord) => {
    ApiService("GET", "geofences/" + selectedRecord.id)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setSelectedGeofence(response.data);
          setIsEmpty(false);
          setGeofEditvisible(true);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="rightInnerView">
      <h2>
        Cities
        <GButton
          variant="condensed"
          children="Add"
          onClick={() => {
            setSelectedCity({});
            setIsEmpty(true);
            setCityfvisible(true);
          }}
          style={{
            float: "right",
          }}
        >
          Add
        </GButton>
      </h2>
      <div className="listPaper">
        <div
          style={{
            height: "calc(100vh - 270px)",
            overflowY: "auto",
            padding: "5px",
          }}
        >
          {citiesData.length > 0 ? (
            citiesData.map((c, pi) => {
              return (
                <React.Fragment key={c.id}>
                  <h4 style={{ margin: "10px 0" }}>
                    {c.title} ({c.geofences.length} Geofence)
                    <GActions
                      onEdit={() => editCity(c)}
                      onDelete={() => {
                        setSelectedCityId(c.id);
                        setDialogOpen(true);
                      }}
                      style={{ verticalAlign: "middle" }}
                      menuStyle={{ fontWeight: "400" }}
                    />
                    <GButton
                      variant="linkable"
                      children="Add"
                      onClick={() => {
                        setSelectedGeofence({});
                        setIsEmpty(true);
                        setSelectedCityId(c.id);
                        setGeofvisible(true);
                      }}
                      style={{
                        float: "right",
                      }}
                    >
                      <Add />
                    </GButton>
                  </h4>
                  <hr />

                  {c.geofences.map((d, ind) => {
                    return (
                      // <div style={{ display: 'inline-block', minWidth: '220px', width: '23%' }}>
                      <GListCard
                        setting="Geofence"
                        title={d.title}
                        listActions={true}
                        id={d.id}
                        key={d.id}
                        onEdit={(g) => {
                          editGeofence(g);
                          setSelectedCityId(c.id);
                        }}
                        onDelete={deleteGeofence}
                        data={d}
                      ></GListCard>
                    );
                  })}

                  {c.geofences.length === 0 ? <GEmpty></GEmpty> : ""}
                </React.Fragment>
              );
            })
          ) : (
            <GEmpty />
          )}
        </div>
      </div>

      {/* <GToast title="Success" message=""></GToast> */}

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete City"
          text="Are you sure you want to delete this city?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteCity();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      {cityfvisible === true ? (
        <CityPopup
          cityfvisible={cityfvisible}
          setCityfvisible={setCityfvisible}
          data={selectedCity}
          isEmpty={isEmpty}
          onSubmit={(response) => {
            openSnackbar(response.message);
            getCities();
          }}
        />
      ) : (
        ""
      )}

      {geofvisible === true ? (
        <GeofencePopup
          geofvisible={geofvisible}
          setGeofvisible={setGeofvisible}
          data={selectedGeofence}
          selectedCity={selectedCityId}
          isEmpty={isEmpty}
          onSubmit={(response) => {
            openSnackbar(response.message);
            getCities();
          }}
        />
      ) : (
        ""
      )}
      {geofEditvisible === true ? (
        <GeofenceEditPopup
          geofEditvisible={geofEditvisible}
          setGeofEditvisible={setGeofEditvisible}
          data={selectedGeofence}
          selectedCity={selectedCityId}
          isEmpty={false}
          onSubmit={(response) => {
            openSnackbar(response.message);
            getCities();
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
}

//export default Customers;
