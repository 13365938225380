import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
// import TimePicker from "../gComponents/gTimePicker";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  option: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#888",
    width: "40px",
    height: "40px",
    fontSize: "18px",
    borderRadius: "5px",
    cursor: "pointer",
    //transition: 'background 0.3s',
    transitionProperty: "background-color, color",
    transitionDuration: "300ms, 300ms",
    "&:hover": {
      backgroundColor: "#333",
      color: "#bbb",
      fontWeight: "500",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var buttonStyle = {
  background: "#FF6500",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "100%",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  marginTop: "30px",
  fontWeight: "600",
};

export default function OrderReturnPopup(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);

  const initialValues = {
    pickup_at: "",
  };

  const handleChange = (d) => {
    var date = ("0" + new Date(d).getDate()).slice(-2); //Current Date
    var month = ("0" + (new Date(d).getMonth() + 1)).slice(-2); //Current Month
    var year = new Date(d).getFullYear(); //Current Year
    var hour = new Date(d).getHours();
    var minutes =
      (new Date(d).getMinutes() < 10 ? "0" : "") + new Date(d).getMinutes();
    var seconds =
      (new Date(d).getSeconds() < 10 ? "0" : "") + new Date(d).getSeconds();
    console.log(
      year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minutes +
        ":" +
        seconds
    );
    return (
      year +
      "-" +
      month +
      "-" +
      date +
      " " +
      hour +
      ":" +
      minutes +
      ":" +
      seconds
    );
  };
  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);
    values.pickup_at = values.pickup_at ? handleChange(values.pickup_at) : "";

    props.setFaultyResonVisible(false);
    props.onPickupSubmit(values.pickup_at);
  };

  const validationSchema = Yup.object({
    pickup_at: Yup.string().required("Required"),
  });

  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setFaultyResonVisible(false);
  };

  if (props.faultyReasonVisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.faultyReasonVisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.faultyReasonVisible === true ? "fade" : ""}`}
          style={{ padding: "20px" }}
        >
          <h3>{!props.isEmpty ? "Edit" : "Add"} Timeslot</h3>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              //  function updateField(newValue){
              //      values.icon = newValue
              // }

              <Form>
                <FormikControl
                  control="date"
                  name="pickup_at"
                  format="yyyy-MM-dd HH:mm:ss"
                  placeholderText="Choose Pickup"
                  showTimeSelect={true}
                  minDate={new Date()}
                  className="noBorder noMargin"
                  divstyle={{ width: "170px" }}
                />
                <br />
                <button style={buttonStyle} type="submit" disabled={isDisabled}>
                  Approve
                </button>
              </Form>
            )}
          </Formik>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
