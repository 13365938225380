import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import { Link } from "react-router-dom";
import { matchPath } from "react-router";
import GTabs from "../gComponents/gTabs";
import NotificationsSetting from "./notificationSettings";

export default function NotificationTypes({ match, route }) {
  let params = match.params;
  let { type } = useParams();
  let { userType } = useParams();
  let { notifyType } = useParams();

  console.log("ppp" + JSON.stringify(params));
  // notifyType = notifyType ? notifyType : ''
  console.log("l--" + notifyType);
  const [usertypeValue, setUsertypeValue] = useState("");
  const [notificationTypeValue, setNotificationTypeValue] = useState("");

  const notificationRoutes =
    type === "order"
      ? [
        {
          title: "customer",
          link: "/settings/order-notifications/customer", state: 'setting'
        },
        {
          title: "manager",
          link: "/settings/order-notifications/manager", state: 'setting'
        },
        {
          title: "admin",
          link: "/settings/order-notifications/admin", state: 'setting'
        },
      ]
      : [
        {
          title: "customer",
          link:
            "/settings/task-notifications/customer/" + notificationTypeValue, state: 'setting'
        },
        {
          title: "driver",
          link:
            "/settings/task-notifications/driver/" + notificationTypeValue, state: 'setting'
        },
        {
          title: "admin",
          link: "/settings/task-notifications/admin/" + notificationTypeValue, state: 'setting'
        },
      ];

  useEffect(() => {
    //  console.log(userType + "--n--" + usertypeValue);
    //    getChangedValue(type === 'task' ? notifyType ? notifyType : 'delivery' : '')
    handleChange(
      params.userType ? params.userType : "customer",
      type === "task" ? (notifyType ? notifyType : "delivery") : ""
    );
    //notifyType = type === 'task' ? notifyType ? notifyType : 'delivery' : '';
    //  console.log(notificationTypeValue)
  }, [type, params.userType]);

  function handleChange(newValue, nTypeVal) {
    setUsertypeValue(newValue);
    setNotificationTypeValue(nTypeVal ? nTypeVal : 'delivery');
  }
  // function getChangedValue(newValue) {
  //    // console.log("n" + newValue)
  //     setNotificationTypeValue(newValue);

  // }

  return (
    <Router>
      {/* {match.url} */}
      <div className="rightInnerView">
        <h2 style={{ textTransform: "capitalize" }}>{type} Notifications</h2>
        {/* {notifyType}--{notificationTypeValue} */}

        <GTabs routes={notificationRoutes} active={usertypeValue}></GTabs>

        <Switch>
          <div className="listPaper">
            <div>
              {/* {usertypeValue}--{userType} */}

              <Route path="/settings/:type-notifications/:userType">
                <NotificationsSetting
                  onChange={handleChange}
                  type={type}
                  nType={notificationTypeValue}
                />
              </Route>

              <Route path="/settings/:type-notifications/:type/:userType/:notifyType">
                <NotificationsSetting
                  onChange={handleChange}
                  type={type}
                  nType={notifyType ? notifyType : notificationTypeValue}
                />
              </Route>

              {/* {type === 'order' ?
                                    <Route path="/settings/order-notifications/:userType" >
                                        fvgf
                                        <NotificationsSetting onChange={handleChange} type='order' />
                                    
                                    </Route>
                                    :
                                    (
                                        <div>
                                    {/* <NotificationsSetting onChange={handleChange} type='task' nType={notificationTypeValue} /> *
                                     <Route path="/settings/task-notifications/:userType/:notifyType" >
                                        jjj
                                    <NotificationsSetting onChange={handleChange} type='task' nType={notificationTypeValue} />
                                            </Route>
                                            </div>
                                    )
                                } */}
              {/* {notificationTypeValue ?
                                
                                    <Route path="/settings/:type-notifications/:type/:userType/:notifyType" >
                                        <NotificationsSetting onChange={handleChange} type={type} nType={notificationTypeValue} />
                                    </Route>
                                    :
                                    <Route path="/settings/:type-notifications/:type/:userType" >
                                        <NotificationsSetting onChange={handleChange} type={type} nType={notificationTypeValue} />
                                    </Route>
                                } */}
            </div>
          </div>
        </Switch>
      </div>
    </Router>
  );
}
