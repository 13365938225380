import React from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var buttonStyle = {
  background: "#f08c37",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "100%",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  marginTop: "30px",
  fontWeight: "600",
};

export default function CreateCustomer(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();

  const initialValues = {
    store_id: props.storeId,
    first_name: !props.isEmpty ? props.data.first_name : "",
    last_name: !props.isEmpty ? props.data.last_name : "",
    email: !props.isEmpty ? props.data.email : "",
    phone: !props.isEmpty ? props.data.phone : "",
    password: ""
  };

  const onSubmit = (values) => {
    console.log(values);

    if (!props.isEmpty) {
      if (!values.password) {
        delete values.password;
      }
    }

    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "store/manager/" + props.data.id
      : "store/manager";
    ApiService(hitMethod, hitUrl, values).then((response) => {
      console.log("r" + response.status_code);
      openSnackbar(response.message);
      if (response.status_code === 0) {
        return;
      }
      props.onSubmit();
      props.setCsmvisible(false);
    });
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid Email Address").required("Required"),
    phone: Yup.string().required("Required"),
    password: props.isEmpty ? Yup.string().required("Required") : "",
  });

  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setCsmvisible(false);
  };

  if (props.csmvisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.csmvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.csmvisible === true ? "fade" : ""}`}
          style={{ padding: "20px" }}
        >
          <h3>{!props.isEmpty ? "Edit" : "Create"} Manager</h3>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="First Name"
                name="first_name"
              />
              <FormikControl
                control="input"
                type="text"
                label="Last Name"
                name="last_name"
              />
              <FormikControl
                control="input"
                type="email"
                label="Email ID"
                name="email"
              />

              <FormikControl
                control="input"
                type="password"
                label="Password"
                name="password"
              />
              <FormikControl
                control="input"
                type="text"
                label="Phone"
                name="phone"
              />
              <br />
              <button style={buttonStyle} type="submit">
                {!props.isEmpty ? "Update" : "Create"}
              </button>
            </Form>
          </Formik>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
