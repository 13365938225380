import React, { useState, useEffect, useRef } from "react";
import { ApiService } from "../services";
import styled from "styled-components";
import { Search, Close, ArrowDropDown } from "@material-ui/icons";
import { useDetectOutsideClick } from "./useDetectOutsideClick";

const LI = styled.li`
  padding: 6px 8px;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: #f08c37;
    color: #fff;
  }
`;

const Input = styled.input`
  width: 82%;
  /* min-width: 350px; */
  max-width: 450px;
  margin: 5px 0 0;
  height: 30px;
  background: transparent;
  border: 0px;
  outline: 0;
  box-shadow: none;
  color: #777;
  padding-left: 10px;
`;
export const ProductFilter = ({
  value,
  handleProductChange,
  handleBlur,
  handleFocus,
  storeId,
  type,
  placeholder,
  style,
}) => {
  const [searchWord, setSearchWord] = useState("");
  const wrapperSRef = useRef(null);
  const [showFilterList, setShowFilterList] = useDetectOutsideClick(
    wrapperSRef,
    false
  );
  const [selectedTitle, setSelectedTitle] = useState("");
  const [productsFilterList, setProducts] = useState([]);

  useEffect(() => {

    if (type === 'master') {
      var hitUrl = "product/masters?per_page=30&search=" + searchWord;
    }
    else {
      var hitUrl = "products?per_page=30&store_id=" + storeId + "&search=" + searchWord;
    }

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log("gh" + response);
        if (response.status_code === 1) {
          console.log(response);
          setProducts(response.data.data);
          response.data.data.map((values) => {
            if (values.id === value) {
              setSelectedTitle(values.title);
            }
          });

          //setSelectedValue(value);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [searchWord, storeId]);

  return (
    <div
      ref={wrapperSRef}
      style={{
        width: "222px",
        display: "inline-block",
        marginLeft: "10px",
        verticalAlign: "middle",
      }}
    >
      <div
        style={{
          background: "#fff",
          border: "1px solid rgb(226, 226, 226)",
          height: "40px",
          fontSize: "0.97rem",
          color: "#555",
          boxSizing: "border-box",
          padding: "10px",
          fontFamily: "sans-serif", width: '100%'
        }}
        onClick={() => {
          setShowFilterList(true);
          setSearchWord("");
        }}
      >
        {selectedTitle ? (
          selectedTitle
        ) : (
            <span style={{ color: "#555" }}>All Products</span>
          )}
        <ArrowDropDown
          style={{
            float: "right",
            fontSize: "19px",
            color: "#555",
          }}
        />
        {selectedTitle ? (
          <Close
            fontSize="small"
            style={{
              float: "right",
              fontSize: "18px",
              color: "#777",
              cursor: "pointer",
            }}
            onClick={() => {
              handleProductChange();
              setSelectedTitle();
            }}
          />
        ) : (
            ""
          )}
      </div>
      {showFilterList === true ? (
        <div
          style={{
            background: "#fff",
            position: "absolute",
            zIndex: "9",
            width: "200px",
            padding: "0 5px 5px",
            border: "1px solid rgb(226, 226, 226)",
          }}
        >
          <React.Fragment>
            <Input
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Search.."
            />
            <Search
              fontSize="small"
              style={{
                verticalAlign: "middle",
                fontSize: "22px",
                color: "#ccc",
              }}
            />
          </React.Fragment>
          <ul
            style={{
              listStyleType: "none",
              padding: "5px 0",
              margin: "0",
              borderTop: "1px solid #e2e2e2",
              height: "220px",
              overflow: "auto",
            }}
          >
            {productsFilterList.map((c, i) => {
              return (
                <LI
                  key={i}
                  onClick={() => {
                    setSelectedTitle(c.title);
                    setShowFilterList(false);
                    handleProductChange(c.id, c);
                  }}
                >
                  {c.title}
                </LI>
              );
            })}
          </ul>
        </div>
      ) : (
          ""
        )}
    </div>
  );
};

// export default storeFilterComponent;
