import React, { useState, useEffect } from "react";
import { Edit } from "@material-ui/icons";
import { ApiService } from "../services";
import GInfo from "../gComponents/gInfo";
import { Card, CardHead } from "../styledComponents/cardStyles";
import { GContent, GTableContent } from "../gComponents/gContent";
import { Switch, Route } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import GSwitch from "../gComponents/gSwitch";
import Tasks from "../tasks/tasksList";
// import Vehicles from "../components/vehiclesList";
import WalletPoints from "../components/walletPointsList";
import Documents from "../components/documentsList";
import { useSnackbar } from "react-simple-snackbar";
import DriverCreate from "../components/createDriver";
import useSettings from "../useSettings";
import GRouting from "../gComponents/gRouteBreadCrumbs"

const RoutLinks = [{
  title: 'Drivers',
  link: '/drivers',
  state: 'driver'
}]

export default function DriverDetail(props) {
  const [driverId, setDriverId] = useState(props.match.params.driverId);
  // const [driverAssignedVehicle, setDriverAssignedVehicle] = useState("");
  const [driverData, setDriverData] = useState([]);
  const [openSnackbar] = useSnackbar();
  const [cdvisible, setCdvisible] = useState(false);
  const [typeValue, setTypeValue] = useState("wallet");
  const settings = useSettings(true);

  const faqRoutes = [
    {
      title: "tasks",
      link: "/driver/" + driverId + "/tasks",
      state: 'driver'
    },
    // {
    //   title: "vehicles",
    //   link: "/driver/" + driverId + "/vehicles",
    // },
    {
      title: "wallet",
      link: "/driver/" + driverId + "/wallet",
      state: 'driver'
    },
    {
      title: "documents",
      link: "/driver/" + driverId + "/documents",
      state: 'driver'
    },
  ];

  const getDriverDetail = () => {
    var hitUrl = "drivers/" + driverId;

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setDriverData(response.data);
          // setDriverAssignedVehicle(response.data.ag_vehicle_info.vehicle_id);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log(props.match.params.driverId);
    getDriverDetail();
  }, [props, driverId]);

  function handleChange(newValue) {
    console.log("n" + newValue);
    setTypeValue(newValue);
  }

  function toggleValue(newValue, selectedFieldId) {
    // return;
    ApiService("PUT", "drivers/" + selectedFieldId, {
      driver_status: newValue,
    })
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          driverData.driver_status = newValue;
          setDriverData(driverData);
        } else {
          console.log(response.data);
        }
        openSnackbar(response.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // function handleAssignVehicle(newVehicle) {
  //   console.log(newVehicle);
  //   setDriverAssignedVehicle(newVehicle);
  // }
  function handleUpdateImage(imgValue, id, thumbImg) {
    console.log(imgValue);
    var data = { photo: imgValue, thumb_photo: thumbImg };
    ApiService("PUT", "drivers/" + driverId, data).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        setDriverData(response.data);
        openSnackbar(response.message);
      } else {
        openSnackbar(response.message);
        return;
      }
    });
  }
  return (
    <div className="mainInnerView">
      <GRouting links={RoutLinks} currentPage={driverData.title} />
      {driverData ? (
        <GInfo
          id={driverData.id}
          title={driverData.title}
          key={driverData.id}
          className="headerInfo"
          imgType="user"
          photo={driverData?.photo}
          imgEdit={true}
          uploadImage={handleUpdateImage}
          style={{ width: "auto" }}
        />
      ) : (
          ""
        )}
      <GSwitch
        id={driverData.id}
        uId={driverData.id}
        currentValue={driverData.driver_status}
        style={{
          margin: "8px 0 0 25px",
          display: "inline-block",
          verticalAlign: "top",
        }}
        onSwitchChange={toggleValue}
      />

      <br />

      <div
        style={{
          width: "30%",
          display: "inline-block",
          padding: "10px",
          verticalAlign: "top",
        }}
      >
        <Card style={{ minHeight: "200px" }}>
          <div>
            <CardHead>
              Contact Details <Edit onClick={() => setCdvisible(true)} />
            </CardHead>
            <GContent title="Email" value={driverData.email} />
            <GContent title="Phone" value={driverData.phone} />
          </div>
        </Card>

        <Card style={{ minHeight: "200px" }}>
          <div>
            <CardHead>Other Information</CardHead>
            <GTableContent
              title="Balance"
              value={settings.currency_symbol + "" + driverData.balance}
              align="right"
            />
            <GTableContent
              title="Service Fee"
              value={driverData.commission + "%"}
              align="right"
            />
            <GTableContent
              title="Last location update"
              value={
                driverData.location_updated ? driverData.location_updated : "-"
              }
              align="right"
            />
          </div>
        </Card>
        {cdvisible === true ? (
          <DriverCreate
            cdvisible={cdvisible}
            setCdvisible={setCdvisible}
            data={driverData}
            onSubmit={getDriverDetail}
          />
        ) : (
            ""
          )}
      </div>

      <div
        style={{
          width: "65%",
          display: "inline-block",
          padding: "10px",
          verticalAlign: "top",
        }}
      >
        <GTabs routes={faqRoutes} active={typeValue}></GTabs>

        <Switch>
          <Route exact path={"/driver/" + driverId + "/tasks"}>
            <Tasks
              onChange={handleChange}
              type="driver"
              value={driverId}
              title="agent_id"
            />
          </Route>
          {/* <Route exact path={"/driver/" + driverId + "/vehicles"}>
            <Vehicles
              onChange={handleChange}
              type="driver"
              title="driver_id"
              value={driverId}
              assignedVehicle={driverAssignedVehicle}
              onAssign={handleAssignVehicle}
            />
          </Route> */}
          <Route exact path={"/driver/" + driverId + "/wallet"}>
            <WalletPoints
              onChange={handleChange}
              type="driver"
              value={driverId}
              title="driver_id"
            />
          </Route>
          <Route exact path={"/driver/" + driverId + "/documents"}>
            <Documents
              onChange={handleChange}
              type="driver"
              value={driverId}
              title="driver_id"
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
