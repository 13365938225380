import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import { ApiService } from "../services";
import { Add } from "@material-ui/icons";
import GEmpty from "../gComponents/gEmpty";
import GButton from "../gComponents/gButton";
import GTabs from "../gComponents/gTabs";
import GListCard from "../gComponents/gListCards"; import { useSnackbar } from "react-simple-snackbar";
import DocumentTypePopup from "./../components/documentTypesForm";
import GTableShimmer from "../gComponents/gTableShimmer";

const docRoutes = [
  {
    title: "customer",
    link: "/settings/document-types/customer", state: 'setting'
  },
  {
    title: "manager",
    link: "/settings/document-types/manager", state: 'setting'
  },
  {
    title: "driver",
    link: "/settings/document-types/driver", state: 'setting'
  },
  {
    title: "store",
    link: "/settings/document-types/store", state: 'setting'
  },
];

export default function DocumentTypes() {
  const [typeValue, setTypeValue] = React.useState("customer");
  useEffect(() => {
    console.log("value" + typeValue);
  });

  function handleChange(newValue) {
    setTypeValue(newValue);
  }
  return (
    <Router>
      <div className="rightInnerView">
        <h2>Document Types</h2>

        <GTabs routes={docRoutes} active={typeValue}></GTabs>

        <Switch>
          <div className="listPaper">
            <div>
              <Route path="/settings/document-types/:type">
                <Child onChange={handleChange} />
              </Route>
            </div>
          </div>
        </Switch>
      </div>
    </Router>
  );
}

function Child(props) {
  let { type } = useParams();
  let typeId =
    type === "customer"
      ? "1"
      : type === "manager"
        ? "2"
        : type === "driver"
          ? "3"
          : "4";
  const [docTypeData, setDocTypeData] = useState([]);
  const [selectedDocType, setSelectedDocType] = useState({});
  const [dtvisible, setDtvisible] = React.useState(false);
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar] = useSnackbar();
  useEffect(() => {
    console.log("id" + typeId);
    getDocTypes();
    props.onChange(type);
  }, [type]);

  const getDocTypes = () => {
    var hitUrl = "document/types?user_type=" + typeId;

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setDocTypeData(response.data);
          setIsLoading(false);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function deleteDocType(selectedId) {
    var hitUrl = "document/types/" + selectedId;
    ApiService("DELETE", hitUrl)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          getDocTypes();
          openSnackbar(response.message)
        } else {
          openSnackbar(response.message)
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function editDocType(selectedRecord) {
    setSelectedDocType(selectedRecord);
    setIsEmpty(false);
    setDtvisible(true);
  }
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div>
      <GButton
        variant="linkable"
        children="Add"
        onClick={() => {
          setIsEmpty(true);
          setSelectedDocType({});
          setDtvisible(true);
        }}
        style={{
          margin: "-60px -15px 0px",
          float: "right",
        }}
      >
        <Add style={{ fontSize: "1.8rem" }} />
      </GButton>

      {isLoading === true ? (
        <GTableShimmer></GTableShimmer>
      ) : docTypeData.length === 0 ? (
        <GEmpty></GEmpty>
      ) : (
            docTypeData.map((d) => {
              return (
                <GListCard
                  setting="Document Type"
                  id={d.id}
                  title={d.title}
                  listActions={true}
                  key={d.id}
                  onEdit={editDocType}
                  onDelete={deleteDocType}
                  data={d}
                ></GListCard>
              );
            })
          )}

      {dtvisible === true ? (
        <DocumentTypePopup
          dtvisible={dtvisible}
          setDtvisible={setDtvisible}
          isEmpty={isEmpty}
          type={typeId}
          data={selectedDocType}
          onSubmit={getDocTypes}
        />
      ) : (
          ""
        )}
    </div>
  );
}
