/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { Add, SubdirectoryArrowRight } from "@material-ui/icons";
import { menus } from "../sidebar/sidebarRoutes";

const ParentDiv = styled.div`
  height: calc(90vh - 210px);
  overflow: overlay;

  .pList {
    margin: 0;
  }
  .pListItem {
    font-size: 14px;
    font-family: LatoWebSemibold;
    letter-spacing: 0.2px;
    padding: 8px 8px 9px;
    color: #dddddd;
    display: flex;
    margin: 0;

    &.active,
    &:hover {
      background: #3a393e;
      .cList {
        background: transparent;
      }
      .pLink {
        color: #fff;
      }
    }
    .pListIcon {
      margin-right: 10px;
    }
    .pLink {
      color: #dddddd;
      text-decoration: none;
      display: inline-block;
      width: 90%;
      padding: 2px 0 0 0;
    }
    .addIcon {
      color: #b2b2b2;
      font-size: 1.3rem;
      padding: 0;
      cursor: pointer;
    }
  }

  .cList {
    background: transparent;
    list-style-type: none;
    padding: 0 0 5px 10px;
    height: 0px;
    display: none;
    opacity: 0;
    margin: 0;
    &.collapse-in {
      display: block;
      opacity: 1;
      height: auto;
      animation-duration: 0.5s;
      animation-name: slideHight;
      transition: height 0.5s;
    }
    &.collapse {
      display: block;
      opacity: 1;
      height: auto;
      animation-duration: 0.3s;
      animation-name: slideMaxHight;
      transition: height 0.3s;
    }
    .cListItem {
      height: auto;
      padding: 0 10px;
      font-size: 14px;
      &.active,
      &:hover {
        background: transparent;
        .cLink {
          color: #fff;
        }
      }
      .cListIcon {
        vertical-align: middle;
        svg {
          margin-right: 5px;
          float: none;
          padding: 0px;
        }
      }
      .cLink {
        color: #b2b2b2;
        display: block;
        padding: 3px 0;
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
`;

function GNestedMenu(props) {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState({});
  const [isActive, setIsActive] = useState(false);
  console.log(location);

  useEffect(() => {
    let activeKey = "";

    const found = menus.some((el) => el.path === location.pathname);
    console.log(found);
    if (!found) {
      setIsMenuOpen({});
      setIsActive(false);
    }

    menus.map((s) => {
      s.children &&
        s.children.map((el) => {
          if (s.path === location.pathname) {
            setIsMenuOpen(s);
          }
          if (el.path === location.pathname) {
            activeKey = s;
            setIsMenuOpen(s);
          }
        });
    });

  }, [location]);


  const handleToggle = (data) => {
    setIsMenuOpen(data);
    setIsActive(true);
  };

  const renderMenuItem = (menu, i) => {
    const { title, path, state, icon, children, isLink, isAdd } = menu;

    return (
      <React.Fragment key={i}>
        <li
          className={
            !state ? "pListItem" : state === location.state
              ? "pListItem active"
              : "pListItem"
          }
          onClick={() => handleToggle(menu, i)}
          href={"#" + menu.path}
        >
          {isLink ? (
            <Link className="pLink" to={{ pathname: path, state: state }}>
              <span className="pListIcon">{icon}</span> {title}
            </Link>
          ) : (
              <React.Fragment>
                <span className="pListIcon">{icon}</span> {title}
              </React.Fragment>
            )}
          {isAdd ? (
            <span>
              {
                <Add
                  className="addIcon"
                  onClick={() => props.handleClick(title)}
                />
              }
            </span>
          ) : (
              ""
            )}
        </li>
        {children ? renderSubMenuItem(menu) : ""}
      </React.Fragment >

    );
  };

  const renderSubMenuItem = (menu) => {
    const { children } = menu;

    return (
      <ul
        key={menu.path}
        className={`cList ${
          isMenuOpen === menu ? (menu.path ? "collapse-in" : "collapse") : ""
          }`}
        id={menu.path}
      >
        {children &&
          children.map((child, i) => {
            return (
              <li
                className={
                  child.path === location.pathname
                    ? "cListItem active"
                    : "cListItem"
                }
                key={i}
              >
                <Link className="cLink" to={{ pathname: child.path, state: child.state }}>
                  <span className="cListIcon">
                    <SubdirectoryArrowRight />
                  </span>
                  {child.title}
                </Link>
              </li>
            );
          })}
      </ul>
    );
  };

  return (
    <ParentDiv
      mode="inline"
      theme="dark"
    // selectedKeys={selectedKeys}
    // openKeys={openKeys}
    // onClick={handleMenuClick}
    // onOpenChange={handleOpenChange}
    >
      <ul className="pList">
        {menus.map((menu, i) => renderMenuItem(menu, i))}
      </ul>
    </ParentDiv>
  );
}

export default GNestedMenu;
