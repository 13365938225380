import React from "react";
import { makeStyles, Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  option: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#888",
    width: "40px",
    height: "40px",
    fontSize: "18px",
    borderRadius: "5px",
    cursor: "pointer",
    //transition: 'background 0.3s',
    transitionProperty: "background-color, color",
    transitionDuration: "300ms, 300ms",
    "&:hover": {
      backgroundColor: "#333",
      color: "#bbb",
      fontWeight: "500",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var buttonStyle = {
  background: "#f08c37",
  border: "0px",
  fontSize: "14px",
  outline: "0",
  color: "#eee",
  borderRadius: "0px",
  width: "100%",
  height: "35px",
  padding: "3px 7px",
  cursor: "pointer",
  marginTop: "30px",
  fontWeight: "600",
};

export default function CreateDriver(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const location = useLocation();
  console.log(props.data);
  const initialValues = {
    first_name: props.data ? props.data.first_name : "",
    last_name: props.data ? props.data.last_name : "",
    email: props.data ? props.data.email : "",
    phone: props.data ? props.data.phone : "",
    password: "",
    commission: props.data ? props.data.commission : 0,
  };

  const onSubmit = (values) => {
    if (props.data) {
      if (!values.password) {
        delete values.password;
      }
    }
    console.log(values);

    var hitMethod = props.data ? "PUT" : "POST";
    var hitUrl = props.data ? "drivers/" + props.data.id : "drivers";
    ApiService(hitMethod, hitUrl, values).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        if (props.onSubmit) {
          props.onSubmit();
          openSnackbar(response.message);
        }

        if (!props.data) {
          openSnackbar(response.message);
          if (location.pathname === "/drivers") {
            window.location.reload(false);
          } else {
            window.location = "/drivers";
          }
        }
        props.setCdvisible(false);
      } else {
        openSnackbar(response.message);
        return;
      }
    });
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid Email Address").required("Required"),
    phone: Yup.string().required("Required"),
    password: !props.data ? Yup.string().required("Required") : "",
  });

  //console.log(handleChange);
  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setCdvisible(false);
  };

  // const handleChange = (e) => {
  //   let value = e.target.value;
  //   this.setState((prevState) => ({
  //     newUser: {
  //       ...prevState.newUser,
  //       name: value,
  //     },
  //   }));
  // };

  if (props.cdvisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.cdvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.cdvisible === true ? "fade" : ""}`}
          style={{ padding: "20px" }}
        >
          <h3>{props.data ? "Edit" : "Create"} Driver</h3>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="First Name"
                name="first_name"
              />
              <FormikControl
                control="input"
                type="text"
                label="Last Name"
                name="last_name"
              />
              <FormikControl
                control="input"
                type="email"
                label="Email ID"
                name="email"
              />
              <FormikControl
                control="input"
                type="password"
                label="Password"
                name="password"
              />
              <FormikControl
                control="input"
                type="text"
                label="Phone"
                name="phone"
              />
              <FormikControl
                control="input"
                type="text"
                label="Service Fee (in %)"
                name="commission"
              />
              <br />
              <button style={buttonStyle} type="submit">
                {props.data ? "Update" : "Create"}
              </button>
            </Form>
          </Formik>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
