import React, { useEffect, useState } from "react";
import { ApiService } from "../services";
import { Add, Delete } from "@material-ui/icons";
// import GConfirmDialog from "../gComponents/gConfirmDialog";
import GButton from "../gComponents/gButton";
import { Formik, Form, Field, FieldArray } from "formik";
import FormikControl from "../formikComponents/formikControl";
import * as Yup from "yup";
import { useSnackbar } from "react-simple-snackbar";

const fieldStyle = {
  border: "0px",
  borderBottom: "1px solid #d2d2d2",
  background: "transparent",
};
function ProductVariantForm(props) {
  console.log("kkkkkkk===" + JSON.stringify(props));
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openSnackbar] = useSnackbar();
  // const [selectedVariantId, setSelectedVariantId] = useState("");
  const [selectedProductVar, setSelectedProductVar] = useState({
    data: {},
    options: [],
  });
  console.log(JSON.stringify(props.selectedVariantType));

  // const onSubmit = (values) => {
  //   console.log(values);
  //   var hitMethod = values.id ? "PUT" : "POST";
  //   var hitUrl = values.id
  //     ? "products/variants/" + values.id
  //     : "products/variants";

  //   ApiService(hitMethod, hitUrl, values)
  //     .then((response) => {
  //       console.log("gh" + JSON.stringify(response));
  //       if (response.status_code === 1) {
  //         //values.options = [];
  //       } else {
  //         console.log(response.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const validationSchema = Yup.object({});

  useEffect(() => {

    if(props.type !== "master"){
    document.getElementById("resetFormBtn").click();
    }
    //setSelectedProductVar(props.selectedVariantType);
    setSelectedProductVar({
      data: props.selectedVariantType.data,
      options: props.selectedVariantType.options,
    });
  }, [props]);

  const initialValues = {
    options: props.selectedVariantType.options,
  };

  const handleUpdateVariant = (values, ind) => {
    console.log(ind);
    console.log(values.options[ind]);
    //return;

    if (props.type === "master") {
      var hitUrl = "product/variant/masters/" + values.options[ind].id;
    } else {
      // eslint-disable-next-line no-redeclare
      var hitUrl = "product/variants/" + values.options[ind].id;
    }
    ApiService("PUT", hitUrl, values.options[ind]).then((response) => {
      console.log("r" + response.status_code);
      openSnackbar(response.message);
      if (response.status_code === 1) {
        props.onVariantEdit(response.data, ind);
        //setProductImgaes([...productImages, response.data]);
      } else {
        setSelectedProductVar({
          data: props.selectedVariantType.data,
          options: props.selectedVariantType.options,
        });
        return;
      }
    });
  };

  return (
    <div style={props.style}>
      <Formik
        initialValues={initialValues}
        //onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, resetForm }) => (
          <Form>
            <FieldArray name="options">
              {(fieldArrayProps) => {
                {/* console.log("fieldArry====>",fieldArrayProps); */}
                const { push, remove, form } = fieldArrayProps;
                const { values } = form;
                const { options } = values;
                console.log("options=====>",options)
                const handleAddVariant = (ind) => {
                  values.options[ind].product_id = props.productId;
                  values.options[ind].product_variant_type_id =
                    selectedProductVar.data.id;
                  if (props.type === "master") {
                    var hitUrl = "product/variant/masters";
                  } else {
                    var hitUrl = "product/variants";
                    values.options[ind].master_id = values.options[ind].id;
                    delete values.options[ind].id;
                  }
                  ApiService("POST", hitUrl, values.options[ind]).then(
                    (response) => {
                      console.log("r" + response.data);
                      openSnackbar(response.message);
                     //return;
                      if (response.status_code === 1) {
                        setFieldValue(`options[${ind}]`, response.data);
                        props.onVariantAdd(response.data);

                        return;
                      } else {
                        setSelectedProductVar({
                          data: props.selectedVariantType.data,
                          options: props.selectedVariantType.options,
                        });
                        return;
                      }
                    }
                  );
                };
                const handleDeleteVariant = (ind) => {
                  if (props.type === "master") {
                    var hitUrl =
                      "product/variant/masters/" + values.options[ind].id;
                  } else {
                    // eslint-disable-next-line no-redeclare
                    var hitUrl = "product/variants/" + values.options[ind].id;
                  }
                  ApiService("DELETE", hitUrl).then((response) => {
                    console.log("r" + response.status_code);
                    openSnackbar(response.message);
                    if (response.status_code === 1) {
                      values.options[ind].price = "";
                      setFieldValue(values.options[ind].price, "");
                      props.onVariantRemove(ind);
                      //remove(ind);
                      //setProductImgaes([...productImages, response.data]);
                    } else {
                      return;
                    }
                  });
                };

                return (
                  <div>
                    <div
                      style={{
                        display: "inline-flex",
                        width: "100%",
                        padding: "0px 0 0 20px",
                        boxSizing: "border-box",
                      }}
                      className="arrayFields"
                    >
                      <label style={{ width: "30%" }}>Title</label>

                      <label style={{ width: "20%" }}>Price</label>
                      <label style={{ width: "20%" }}>Barcode</label>
                      {props.type !== "master" ? (
                        <React.Fragment>
                          <label style={{ width: "15%" }}>Status</label>
                          <label style={{ width: "40%" }}>Unlimited</label>
                        </React.Fragment>
                      ) : (
                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              push({
                                title: "",
                                price: "",
                                barcode_id:"",
                                status: "0",
                                unlimited: 0,
                                stock: "0",
                              });
                            }}
                            style={{ width: "150px" }}
                          >
                            <Add />
                          </GButton>
                        )}
                    </div>
                    {options.map((o, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "inline-flex",
                            width: "100%",
                            padding: "0px 0 0 15px",
                            boxSizing: "border-box",
                          }}
                          className="arrayFields"
                        >
                          <Field
                            type="text"
                            placeholder=""
                            name={`options[${index}].title`}
                            style={Object.assign({}, fieldStyle, {
                              width: "35%",
                            })}
                            disabled={props.type === "master" ? false : true}
                          />
                          <Field
                            type="text"
                            placeholder=""
                            name={`options[${index}].price`}
                            style={Object.assign({}, fieldStyle, {
                              width: "20%",
                            })}
                            disabled={props.type === "master" ? false : false}
                          />
                          <Field
                            type="text"
                            placeholder=""
                            name={`options[${index}].barcode_id`}
                            style={Object.assign({}, fieldStyle, {
                              width: "20%",
                            })}
                            disabled={props.type === "master" ? false : false}
                          />
                          {props.type == "master" ? (
                            ""
                          ) : (
                              <React.Fragment>
                                <FormikControl
                                  control="toggle"
                                  isToggled={o.status === "1" ? 1 : 0}
                                  name={`options[${index}].status`}
                                  onToggle={() => {
                                    let statusVal =
                                      o.status === "1" ? true : false;
                                    statusVal = !statusVal;
                                    statusVal = statusVal === true ? "1" : "0";
                                    setFieldValue(
                                      `options[${index}].status`,
                                      statusVal
                                    );
                                  }}
                                  style={{ width: "15%" }}
                                />

                                <div style={{ width: "40%" }}>
                                  <FormikControl
                                    control="toggle"
                                    isToggled={
                                      o.unlimited === "1" ||
                                        o.unlimited === 1 ||
                                        o.unlimited === true
                                        ? 1
                                        : 0
                                    }
                                    name={`options[${index}].unlimited`}
                                    onToggle={() => {
                                      let unlimitedVal =
                                        o.unlimited === "1" ? true : false;
                                      unlimitedVal = !unlimitedVal;
                                      unlimitedVal =
                                        unlimitedVal === true ? "1" : "0";
                                      setFieldValue(
                                        `options[${index}].unlimited`,
                                        unlimitedVal
                                      );
                                      console.log(!o.unlimited);
                                    }}
                                    style={{ width: "35%", padding: "4px 5px" }}
                                  />
                                  {props.isVarStockShow === "1" &&
                                    !(o.unlimited === "1" || o.unlimited === 1) ? (
                                      <Field
                                        type="text"
                                        placeholder=""
                                        name={`options[${index}].stock`}
                                        style={Object.assign({}, fieldStyle, {
                                          width: "40%",
                                          marginLeft: "10px",
                                        })}
                                      />
                                    ) : (
                                      ""
                                    )}
                                </div>
                              </React.Fragment>
                            )}

                          {props.type === "master" ? (
                            !options[index].id ? (
                              <GButton
                                variant="linkable"
                                onClick={(e) => {
                                  handleAddVariant(index);
                                }}
                                style={{ width: "150px" }}
                              >
                                Add
                              </GButton>
                            ) : (
                                <span style={{ display: "inline-flex" }}>
                                  <GButton
                                    variant="linkable"
                                    onClick={(e) => {
                                      handleUpdateVariant(values, index);
                                    }}
                                  >
                                    Update
                                </GButton>
                                  <GButton
                                    variant="linkable"
                                    onClick={(e) => {
                                      handleDeleteVariant(index);
                                    }}
                                  >
                                    <Delete />
                                  </GButton>
                                </span>
                              )
                          ) : !props.selectedVariantType?.options[index]
                            ?.stock ? (
                                <GButton
                                  variant="linkable"
                                  onClick={(e) => {
                                    handleAddVariant(index);
                                  }}
                                  style={{ width: "150px" }}
                                >
                                  Add
                            </GButton>
                              ) : (
                                <span style={{ display: "inline-flex" }}>
                                  <GButton
                                    variant="linkable"
                                    onClick={(e) => {
                                      handleUpdateVariant(values, index);
                                    }}
                                  >
                                    Update
                              </GButton>
                                  <GButton
                                    variant="linkable"
                                    onClick={(e) => {
                                      handleDeleteVariant(index);
                                    }}
                                  >
                                    <Delete />
                                  </GButton>
                                </span>
                              )}
                          {/* <button style={buttonStyle} type="submit">
                            {!props.isEmpty ? "Update" : "Create"}
                          </button> */}
                        </div>
                      );
                    })}
                  </div>
                );
              }}
            </FieldArray>
            <button type="reset" id="resetFormBtn" style={{ display: "none" }}>
              Reset
            </button>
          </Form>
        )}
      </Formik>
      {/* {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Driver"
          text="Are you sure you want to delete this Product variant?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            handleDeleteVariant();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )} */}
    </div>
  );
}
export default ProductVariantForm;
