import React, { useEffect, useState } from "react";
import { makeStyles, Paper, Backdrop } from "@material-ui/core";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import GPolygon from "../leaflet/gPolygonEdit";
import GCircle from "../leaflet/gCircleEdit";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const geoTypes = [
  { title: "Circle", value: "circle" },
  { title: "Polygon", value: "polygon" },
];

export default function GeofencePopup(props) {
  console.log(props);
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [citiesData, setCities] = useState([]);
  const [selectedPolygons, setSelectedPolygons] = useState(
    props.data.polygon || []
  );

  useEffect(() => {
    ApiService("GET", "cities").then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        setCities(response.data);
      }
    });
  }, [props]);

  const initialValues = {
    title: props.data.title || "",
    city_id: props.selectedCity || "",
    type: props.data.type || "",
    polygon: props.data.polygon || [],
    circle: props.data.circle || {},
  };

  const onSubmit = (values) => {
    // values.polygon = selectedPolygons || [];
    console.log(JSON.stringify(values));
    if (values.type === "polygon") {
      if (values.polygon.length === 0) {
        openSnackbar("Polygon Area is required");
        return;
      }
    }

    // if(values.type === 'circle'){
    //   if (values.polygon.length === 0) {
    //     openSnackbar("Polygon Area is required");
    //     return;
    //   }
    // }

    console.log(values);
    //return;

    ApiService("PUT", "geofences/" + props.data.id, values).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        props.setGeofEditvisible(false);
        openSnackbar(response.message);
        props.onSubmit(response);
      } else {
        openSnackbar(response.message);
        return;
      }
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
  });

  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setGeofEditvisible(false);
  };

  if (props.geofvisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.geofEditvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.geofEditvisible === true ? "fade" : ""}`}
          style={{
            width: "50%",
            maxWidth: "900px",
            minWidth: "700px",
            padding: "20px",
          }}
        >
          <h3>{!props.isEmpty ? "Edit" : "Create"} Geofence</h3>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <GButton
                  type="submit"
                  variant="condensed"
                  children="Update"
                  style={{
                    margin: "0 15px",
                    width: "150px",
                    float: "right",
                    marginTop: "-50px",
                  }}
                />

                <FormikControl
                  control="input"
                  type="text"
                  label="Title"
                  name="title"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />
                {citiesData ? (
                  <FormikControl
                    control="select"
                    as="select"
                    label="City"
                    placeholder="Select city"
                    name="city_id"
                    options={citiesData}
                    key_title="title"
                    key_value="id"
                    divstyle={{
                      display: "inline-block",
                      width: "50%",
                      boxSizing: "border-box",
                      verticalAlign: "top",
                    }}
                  />
                ) : (
                  ""
                )}

                {/* <FormikControl
                  control="select"
                  as="select"
                  label="Type"
                  name="type"
                  placeholder="Select type"
                  options={geoTypes}
                  key_title="title"
                  key_value="value"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                /> */}

                <div style={{ margin: "15px" }}>
                  {values.type === "polygon" ? (
                    <React.Fragment>
                      <label>Area</label>

                      <GPolygon
                        polygonData={values.polygon}
                        getGeoJSON={(jsonArray) => {
                          console.log(jsonArray);
                          setFieldValue("polygon", jsonArray);
                          setSelectedPolygons(jsonArray);
                        }}
                      />
                    </React.Fragment>
                  ) : values.type === "circle" ? (
                    <React.Fragment>
                      <label>Radius</label>
                      <GCircle
                        circleData={values.circle}
                        getRadiusValue={(json) => {
                          console.log(json);
                          setFieldValue("radius", json);
                          //setSelectedRadius(json);
                        }}
                      />
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
