import React, { useEffect, useState, useReducer, useRef } from "react";
import GTabs from "../gComponents/gTabs";
import { ApiService } from "../services";
import GButton from "../gComponents/gButton";
import GInput from "../gComponents/gInput";
import { useSnackbar } from "react-simple-snackbar";
import styled from "styled-components";
import TooltipComponent from "../gComponents/gTooltip";
import { Info } from "@material-ui/icons";
import GConfirmDialog from "../gComponents/gConfirmDialog"
import { orange } from "@material-ui/core/colors";

const smsTypes = ["twilio", "custom", "orange"];
export default function EmailSettings({ match }) {
  const testPhone = useRef(null);
  const [typeValue, setTypeValue] = useState();
  const [smsSettingsData, setSmsSettingsData] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [testResponse, setTestResponse] = useState("")
  const [openSnackbar] = useSnackbar();
  const [twilioData, setTwilioData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      default: "twilio",
      twilio: {
        secret: "",
        token: "",
        url: "",
      },
    }
  );
  const [customData, setCustomData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      default: "custom_sms",
      custom_sms: {
        url: "",
      },
    }
  );
  const [orangeData, setOrangeData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      default: "orange",
      orange: {
        client_id: "",
        client_secret: "",
        sender_address: "",
        sender_name: "",
      },
    }
  );

  const fieldStyle = {
    display: "block",
    width: "350px",
    padding: "10px 15px 10px 0",
  };
  const lableStyle = {
    fontSize: "0.9rem",
    lineHeight: "1.5rem",
    padding: "3px",textTransform:'capitalize'
  };

  const P = styled.p`
    font-size: 13px;
    color: #a2a2a2;
    letter-spacing: 0.1px;
    line-height: 20px;
    font-style: italic;
    margin: 5px 3px 10px;
    span {
      color: #f08c37;
    }
  `;
  function getSmsSettings() {
    var hitUrl = "sms-settings";

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setSmsSettingsData(response.data);
          setTypeValue(
            response.data.default !== "custom_sms"
              ? response.data.default
              : "custom"
          );
          setTwilioData({
            twilio: {
              secret: response.data.twilio.secret,
              token: response.data.twilio.token,
              url: response.data.twilio.url,
            },
          });
          setOrangeData({
            orange: {
              client_id: response.data.orange.client_id,
              client_secret: response.data.orange.client_secret,
              sender_address: response.data.orange.sender_address,
              sender_name: response.data.orange.sender_name,
            },
          });
          setCustomData({
            custom_sms: {
              url: response.data.custom_sms.url,
            }
          });
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    console.log(typeValue);
    getSmsSettings();
  }, []);

  const handleChangeTab = (newVal) => {
    console.log(testPhone.current);
    setTypeValue(newVal);
    if (testPhone.current) {
      testPhone.current.value = "";
    }
  };

  function updateSmsSettings() {
    var hitUrl = "sms-settings";

    console.log(twilioData);
    console.log(customData)
    console.log(orangeData);
   // return;
    ApiService(
      "PUT",
      hitUrl,
      typeValue === "twilio"
        ? twilioData
        : typeValue === "orange"
        ? orangeData
        : customData
    )
      .then((response) => {
        console.log(JSON.stringify(response));
        openSnackbar(response.message);
        if (response.status_code === 1) {
          getSmsSettings();
          // setOrangeData({
          //   default: "orange",
          //   orange: {},
          // });
          // setTwilioData({
          //   default: "twilio",
          //   twilio: {},
          // });
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function testSmsSettings() {
    var testData = {
      phone: testPhone.current.value,
      driver: typeValue === "custom" ? "custom_sms" : typeValue,
    };
    console.log(testData);
    // return;
    ApiService("POST", "sms-settings", testData)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          testPhone.current.value = "";
          setTestResponse(response.data? JSON.stringify(response.data): response.data);
          setTimeout(function () {
            setDialogOpen(true)
          },800)
         
          // openSnackbar(response.message);
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <div style={{ float: "right", marginTop: "25px" }}>
        <h4
          style={{
            margin: "0",
            fontSize: "17px",
            color: "#777",
            fontStyle: "italic",
            fontFamily: "LatoWeb",
            fontWeight: "400",
          }}
        >
          Default Method :{" "}
          {smsSettingsData.default ? <span style={{ color: "#f08c37",textTransform:'capitalize' }}>{smsSettingsData.default.replace("_", " ")}</span> : ''}
        </h4>
        <br />
      </div>
      <h2>SMS Notification Settings</h2>

      <GTabs
        routes={smsTypes}
        active={typeValue}
        type="single"
        onClick={handleChangeTab}
      ></GTabs>

      <div className="listPaper" style={{ padding: "30px" }}>
        {typeValue === "twilio" ? (
          <div>
            <div style={fieldStyle}>
              <label style={lableStyle}>Account SID</label>
              <GInput
                type="text"
                placeholder=""
                name="secret"
                id="secret"
                value={twilioData.twilio.secret}
                display="block"
                onChange={(e) => {
                  twilioData.twilio.secret = e.target.value;
                }}
                onBlur={(e) => {
                  twilioData.twilio.secret = e;
                }}
                // onChange={handleChange}
              />
            </div>
            <div style={fieldStyle}>
              <label style={lableStyle}>Auth Token</label>
              <GInput
                type="text"
                placeholder=""
                name="token"
                id="token"
                value={twilioData.twilio.token}
                display="block"
                onChange={(e) => {
                  twilioData.twilio.token = e.target.value;
                }}
                onBlur={(e) => {
                  twilioData.twilio.token = e;
                }}
              />
            </div>
            <div style={fieldStyle}>
              <label style={lableStyle}>
                Twilio number/ Sender ID/ Short Code
                <TooltipComponent
                  title="
                  Twilio have different options for this based on your country. Try contacting their support, if you are not able to find in your Twilio account.
"
                  position="bottom"
                  id="twilio_number"
                >
                  <Info fontSize="small" />
                </TooltipComponent>
              </label>
              <GInput
                type="text"
                placeholder=""
                name="url"
                id="url"
                value={twilioData.twilio.url}
                display="block"
                onChange={(e) => {
                  twilioData.twilio.url = e.target.value;
                }}
                onBlur={(e) => {
                  twilioData.twilio.url = e;
                }}
              />
            </div>
            <GButton
              variant="condensed"
              children="Update"
              onClick={updateSmsSettings}
              style={{
                margin: "10px 5px",
              }}
            ></GButton>
          </div>
        ) : typeValue === "orange" ? (
          <div>
            <div style={fieldStyle}>
              <label style={lableStyle}>Client Id</label>
              <GInput
                type="text"
                placeholder=""
                name="clientId"
                id="clientId"
                value={orangeData.orange.client_id}
                display="block"
                  onChange={(e) => {
                  orangeData.orange.client_id = e.target.value;
                  }}
                  onBlur={(e) => {
                   orangeData.orange.client_id = e;
                  }}
              />
            </div>
            <div style={fieldStyle}>
              <label style={lableStyle}>Client Secret</label>
              <GInput
                type="text"
                placeholder=""
                name="clientSecret"
                id="clientSecret"
                value={orangeData.orange.client_secret}
                display="block"
                onChange={(e) => {
                  orangeData.orange.client_secret = e.target.value;
                }}
                  onBlur={(e) => {
                    orangeData.orange.client_secret = e;
                  }}
                // onChange={handleChange}
              />
            </div>
            <div style={fieldStyle}>
              <label style={lableStyle}>Sender Address</label>
              <GInput
                type="text"
                placeholder=""
                name="senderAddress"
                id="senderAddress"
                value={orangeData.orange.sender_address}
                display="block"
                onChange={(e) => {
                  orangeData.orange.sender_address = e.target.value;
                }}
                  onBlur={(e) => {
                    orangeData.orange.sender_address = e;
                  }}
              />
            </div>
            <div style={fieldStyle}>
              <label style={lableStyle}>Sender Name</label>
              <GInput
                type="text"
                placeholder=""
                name="senderName"
                id="senderName"
                value={orangeData.orange.sender_name}
                display="block"
                onChange={(e) => {
                  orangeData.orange.sender_name = e.target.value;
                }}
                  onBlur={(e) => {
                    orangeData.orange.sender_name = e;
                  }}
              />
            </div>
            <GButton
              variant="condensed"
              children="Update"
              onClick={updateSmsSettings}
              style={{
                margin: "10px 5px",
              }}
            ></GButton>
          </div>
        ) : (
          <div>
            <div style={{ width: "80%" }}>
              <label style={lableStyle}>Url</label>
              <P>
                Use <span>[text]</span> for SMS text and <span>[phone]</span>
                for the recipient phone number.
                <br /> Ex:
                http://example.com/sms/api.php?to=[phone]&message=[text]
              </P>
              <GInput
                type="text"
                placeholder=""
                name="url"
                id="url"
                value={customData.custom_sms.url}
                display="block"
                onChange={(e) => {
                  customData.custom_sms.url = e.target.value;
                }}
                    onBlur={(e) => {
                      customData.custom_sms.url = e;
                    }}
                // onChange={handleChange}
              />
            </div>
            <br />
            <GButton
              variant="condensed"
              children="Update"
              onClick={updateSmsSettings}
            ></GButton>
          </div>
        )}

        <hr style={{ margin: "20px 0" }} />

        <label style={lableStyle}>Check {typeValue} Integration</label>
        <br />
        <div
          style={{
            display: "inline-flex",
            verticalAlign: "middle",
          }}
        >
          <input
            type="text"
            ref={testPhone}
            placeholder="+1234567890"
            name="testNo"
            id="testNo"
            display="block"
            style={{
              padding: "10px",
              margin: "4px", outline: '0', border: '1px solid #ccc'
            }}
          />
          <GButton
            variant="condensed"
            children="Send Test SMS"
            onClick={testSmsSettings}
            style={{
              height: "38px",
              marginTop: "4px",
            }}
          ></GButton>
        </div>
      </div>


      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title={typeValue + " Gateway Response"}
          text={testResponse}
          handleClose={() => setDialogOpen(false)}
          handleCloseText="Okay"
        ></GConfirmDialog>
      ) : (
          ""
        )}

      
    </div>
  );
}
