import React, { useState, useEffect } from "react";
import { makeStyles, Paper, TableContainer } from "@material-ui/core";
import { ApiService } from "../services";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GPagination from "../gComponents/gPagination";
import earnPoints from "../assets/images/point-earn.png";
import withdrawPoints from "../assets/images/point-not-earn.png";

const columns = [
  { id: "earn", label: "" },
  { id: "points", label: "Points", minWidth: 60, align: "" },
  { id: "text", label: "Text", minWidth: 130, align: "center" },
  { id: "date", label: "Date", minWidth: 80, align: "center" },
];

const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
  },
});

export default function WalletPoints(props) {
  const classes = useStyles();
  const [walletData, setWalletData] = useState([]);
  const [walletPaginationData, setWalletPaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [currentPage, setCurrentPage] = useState("");

  useEffect(() => {
    console.log(props);
    if (props.type) {
      props.onChange("wallet");
    }
    setSelectedCustomerId(props.value);
    async function getWalletPoints() {
      var query = "?" + props.title + "=" + props.value;
      var hitUrl = props.type + "/wallet";

      console.log(hitUrl);

      ApiService("GET", hitUrl, query)
        .then((response) => {
          console.log(JSON.stringify(response));
          if (response.status_code === 1) {
            setWalletData(response.data.data);
            setWalletPaginationData(response.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    getWalletPoints();
  }, [currentPage, selectedCustomerId]);

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setCurrentPage(NewPageNo);
  };

  return (
    <div className={props.type ? "" : "mainInnerView"}>
      {props.type ? "" : <h1>Addresses</h1>}

      <Paper className={classes.root}>
        {walletData.length !== 0 ? (
          <GPagination
            count={walletPaginationData.total}
            from={walletPaginationData.from}
            to={walletPaginationData.to}
            onChangeNextPage={() =>
              handleChangePage(walletPaginationData.next_page_url)
            }
            onChangePrevPage={() =>
              handleChangePage(walletPaginationData.prev_page_url)
            }
            rowsPerPage={walletPaginationData.per_page}
            prevDisabled={walletPaginationData.prev_page_url ? false : true}
            nextDisabled={walletPaginationData.next_page_url ? false : true}
          ></GPagination>
        ) : (
            ""
          )}
        {!walletPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
            <TableContainer className={classes.container}>
              {walletData.length === 0 ? <GEmpty></GEmpty> :
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableHeader
                          key={column.id}
                          style={{
                            minWidth:
                              walletData.length > 0 && !column.label
                                ? "30px"
                                : column.minWidth,
                            textAlign: column.align,
                          }}
                        >
                          {column.label}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {walletData.map((c) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                          <TableRowData>
                            {c.amount < 0 ? (
                              <img
                                src={withdrawPoints}
                                alt=""
                                style={{ width: "15px" }}
                              />
                            ) : (
                                <img
                                  src={earnPoints}
                                  alt=""
                                  style={{ width: "15px" }}
                                />
                              )}
                          </TableRowData>
                          <TableRowData style={{ textAlign: "" }}>
                            {c.amount}
                          </TableRowData>
                          <TableRowData style={{ textAlign: "center" }}>
                            {c.title}
                          </TableRowData>
                          <TableRowData style={{ textAlign: "center" }}>
                            {c.created}
                          </TableRowData>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              }
            </TableContainer>
          )}
      </Paper>

      {/* <GToast title="Success" message=""></GToast> */}

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Customer"
          text="Are you sure you want to delete this customer?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
          }}
        ></GConfirmDialog>
      ) : (
          ""
        )}
    </div>
  );
}

//export default Customers;
