import React, { useEffect, useState } from "react";
import { ApiService } from "../services";
import { Delete } from "@material-ui/icons";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import styled from "styled-components";
import VariantForm from "../components/productVariantsForm";

const Tab = styled.a`
  font-size: 15px;
  padding: 10px 20px;
  cursor: pointer;
  opacity: 0.6;
  border: 0;
  display: block;
  border-radius: 0px;
  border-left: 2px solid transparent;
  outline: 0;
  ${({ active }) =>
    active &&
    `
    border-left: 2px solid #f08c37;
    opacity: 1;
  `}
`;
const ButtonGroup = styled.div`
  display: block;
  /* border-bottom: 1px solid #e2e2e2; */
  margin-bottom: 15px;
  width: 130px;
  max-height: 200px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #98989c;
    background: #f08c37;
    border-radius: 8px;
    display: block;
  }
  &::-webkit-scrollbar-track {
    background: #d9e5f4;
  }
`;

function ProductVariantsView(props) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [productVar, setProductVar] = useState(props.productVariants);

  const [selectedTab, setSelectedTab] = useState(props.selectedIndex);

  const [formVisisble, setFormVisible] = useState(false);
  // props.productVariants[0].title

  const [selectedVariantType, setSelectedVariantType] = useState({
    data: {},
    options: [],
  });
  console.log(props.productVariants[0]);

  useEffect(() => {
    setProductVar(props.productVariants);
    var data = props.productVariants[0];
    console.log(props.selectedIndex);
    props.productVariants.map((k, v) => {
      if (props.selectedIndex === v) {
        setSelectedVariantType({
          data: k,
          options: k.options,
        });
        setFormVisible(true);
      }
    });
  }, [props]);

  const handleRemove = (data) => {
    setSelectedVariantType(
      selectedVariantType.options.filter((item) => item.id !== data.id)
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <ButtonGroup>
        {productVar.map((type, i) => (
          <Tab
            key={i}
            active={selectedTab === i}
            onClick={() => {
              setFormVisible(false);
              setSelectedVariantType({
                data: type,
                options: type.options,
              });
              setSelectedTab(i);
              setTimeout(function () {
                setFormVisible(true);
              }, 10);
            }}
          >
            {type.title}
          </Tab>
        ))}
      </ButtonGroup>

      <div style={{ width: "80%" }}>
        {formVisisble === true ? (
          <VariantForm
            selectedVariantType={selectedVariantType}
            selectedVariant={selectedTab}
            productId={props.productId}
            type={props.type || ""}
            //onVariantRemove={handleRemove}
            onVariantRemove={(ind) =>
              props.handleVariantRemove(selectedTab, ind)
            }
            onVariantAdd={(data) => {
              props.handleVariantAdd(selectedTab, data);
            }}
            onVariantEdit={(data, ind) => {
              props.handleVariantEdit(selectedTab, data, ind);
            }}
            isVarStockShow={props.isVarStockShow}
          />
        ) : (
            ""
          )}
      </div>

      {/* {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Driver"
          text="Are you sure you want to delete this Product Image?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteProductImage();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )} */}
    </div>
  );
}
export default ProductVariantsView;
