import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { ApiService, ImgUrl, addDefaultSrc } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import ProductImages from "../products/productImages";
import ProductVariants from "../products/productVariants";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  option: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#888",
    width: "40px",
    height: "40px",
    fontSize: "18px",
    borderRadius: "5px",
    cursor: "pointer",
    //transition: 'background 0.3s',
    transitionProperty: "background-color, color",
    transitionDuration: "300ms, 300ms",
    "&:hover": {
      backgroundColor: "#333",
      color: "#bbb",
      fontWeight: "500",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var formTitle = {
  fontWeight: "400",
  margin: "20px 15px",
};

export default function ProductEditForm(props) {
  const classes = useStyles();
  const [categoriesList, setCategoriesList] = useState([]);
  const [productBasicInfo, setProductBasicInfo] = useState({});
  const [productVariants, setProductVariants] = useState([]);
  const [productImages, setProductImgaes] = useState([]);
  const [variantTypeIndex, setVariantTypeIndex] = useState(0);
  const [openSnackbar] = useSnackbar();
  const initialValues = {
    title: "",
    // price: "",
    unit: "",
    categories: "",
    keywords: "",
    // tax: 0,
  };

  console.log(props.data + "--");

  const getProductDetail = () => {
    ApiService("GET", "product/masters/" + props.data.id).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 0) {
        //openSnackbar(response.message);
        return;
      }
      setProductBasicInfo(response.data);
      initialValues.title = response.data.title;
      initialValues.unit = response.data.unit;
      initialValues.keywords = response.data.keywords;
      initialValues.barcode_id = response.data.barcode_id;
      initialValues.id = response.data.id;
      initialValues.price = response.data.price;
      initialValues.categories = response.data.categories;
      initialValues.tax = response.data.tax;

      setProductVariants(response.data.variants);
      setProductImgaes(response.data.images);
    });
  };
  useEffect(() => {
    getProductDetail();

    ApiService("GET", "product/group").then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 0) {
        //openSnackbar(response.message);
        return;
      }
      setCategoriesList(response.data);
    });
  }, []);

  const onSubmit = (values) => {
    console.log(values);
    values.stock = values.stock ? values.stock : "0";
    // return;
    ApiService("PUT", "product/masters/" + props.data.id, values).then(
      (response) => {
        console.log("r" + response.status_code);
        if (response.status_code === 1) {
          //openSnackbar(response.message);
          props.setPmevisible(false);
          props.onSubmit();
        } else {
          openSnackbar(response.message);
          return;
        }
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    price: Yup.string().required("Required"),
    unit: Yup.string().required("Required"),
    categories: Yup.string().required("Required"),
  });

  //console.log(handleChange);
  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setPmevisible(false);
  };

  if (props.pmevisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.pmevisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.pmevisible === true ? "fadeMW" : ""}`}
          style={{ padding: "20px", minWidth: "680px" }}
        >
          <h3>Edit Master Product</h3>

          <h2 style={formTitle}>Basic Infromation</h2>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              //  function updateField(newValue){
              //      values.icon = newValue
              // }

              <Form>
                <FormikControl
                  control="input"
                  type="text"
                  label="Title"
                  name="title"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />

                {categoriesList.length >= 0 ? (
                  <FormikControl
                    control="selectGroup"
                    as="select"
                    label="Category"
                    name="categories"
                    options={categoriesList}
                    key_title="title"
                    key_value="id"
                    divstyle={{
                      display: "inline-block",
                      width: "50%",
                      boxSizing: "border-box",
                      verticalAlign: "top",
                    }}
                  />
                ) : (
                  ""
                )}
                <FormikControl
                  control="input"
                  type="text"
                  label="Master ID"
                  name="id"
                  disabled={true}
                  divstyle={{
                    display: "inline-block",
                    width: "65%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />

                <FormikControl
                  control="input"
                  type="text"
                  label="Unit"
                  name="unit"
                  divstyle={{
                    display: "inline-block",
                    width: "33.3%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Keywords"
                  name="keywords"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Barcode Id"
                  name="barcode_id"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />
                {/* <FormikControl
                  control="input"
                  type="text"
                  label="Price"
                  name="price"
                  onChange={(event) => {
                    const re = /^[0-9.\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("price", event.target.value);
                    }
                  }}
                  divstyle={{
                    display: "inline-block",
                    width: "33.3%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />

                <FormikControl
                  control="input"
                  type="number"
                  maxlength={7}
                  label="Tax"
                  name="tax"
                  onChange={(event) => {
                    const re = /^[0-9.\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue('tax', event.target.value);
                    }
                  }}
                  divstyle={{
                    display: "inline-block",
                    width: "33.3%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                /> */}

                <h2 style={formTitle}>Product Images</h2>

                <div style={{ margin: "15px" }}>
                  <ProductImages
                    onUpload={(data) => {
                      setProductImgaes([...productImages, data]);
                    }}
                    onRemove={(id) => {
                      setProductImgaes(
                        productImages.filter((item) => item.id !== id)
                      );
                    }}
                    productImages={productImages}
                    add={true}
                    productId={props.data.id}
                  />
                </div>

                {productVariants && productVariants.length > 0 ? (
                  <React.Fragment>
                    <h2 style={formTitle}>Product Variants</h2>
                    <div style={{ background: "#f8f9fd", padding: "15px" }}>
                      <ProductVariants
                        productVariants={productVariants}
                        productId={props.data.id}
                        selectedIndex={variantTypeIndex}
                        handleVariantAdd={(index, data) => {
                          setVariantTypeIndex(index);
                          productVariants[index].options.push(data);
                          // setProductVariants(productVariants);
                        }}
                        handleVariantRemove={(index, i) => {
                          setVariantTypeIndex(index);
                          console.log(i);
                          productVariants[index].options.splice(i, 1);
                          setProductVariants(productVariants);
                        }}
                        handleVariantEdit={(index, data, i) => {
                          setVariantTypeIndex(index);
                          productVariants[index].options[i] = data;
                          setProductVariants(productVariants);
                        }}
                        isVarStockShow={props.isStockShow}
                        type="master"
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )}

                <br />
                <GButton
                  variant="condensed"
                  style={{
                    margin: "0 15px",
                    width: "95%",
                  }}
                  children={!props.isEmpty ? "Update" : "Create"}
                  type="submit"
                />
              </Form>
            )}
          </Formik>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
