import React, { useEffect, useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { useParams } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import { TableContainer, Link } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import { ApiService } from "../services";
import { Add, Edit, Delete } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import GEmpty from "../gComponents/gEmpty";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GTableShimmer from "../gComponents/gTableShimmer";
import TimeslotsPopup from "../components/timeslotsForm";

const columns = [
  { id: "from_time", label: "From Time", minWidth: 150 },
  { id: "to_time", label: "To Time", minWidth: 150 },
  { id: "limit", label: "Order Limit", minWidth: 150, align: "center" },
  { id: "actions", label: "Actions", minWidth: 60, align: "center" },
];

const timeRoutes = [
  {
    title: "mon",
    link: "/settings/timeslots/mon", state: "setting"
  },
  {
    title: "tue",
    link: "/settings/timeslots/tue", state: "setting"
  },
  {
    title: "wed",
    link: "/settings/timeslots/wed", state: "setting"
  },
  {
    title: "thu",
    link: "/settings/timeslots/thu", state: "setting"
  },
  {
    title: "fri",
    link: "/settings/timeslots/fri", state: "setting"
  },
  {
    title: "sat",
    link: "/settings/timeslots/sat", state: "setting"
  },
  {
    title: "sun",
    link: "/settings/timeslots/sun", state: "setting"
  },
];

export default function Timeslots({ match }) {
  const [openSnackbar] = useSnackbar();
  const [typeValue, setTypeValue] = React.useState("mon");
  const [timeslotData, setTimeslotData] = React.useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTimeslotId, setSelectedTimeslotId] = useState("");
  const [tsvisible, setTsvisible] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [isLoading, setIsLoading] = useState(true);
  let { weekday } = useParams();

  function getTimeslots() {
    var hitUrl = "timeslots";

    console.log(hitUrl);

    ApiService("GET", hitUrl)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          setTimeslotData(response.data);
          setIsLoading(false);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    console.log(typeValue);
    getTimeslots();
    setTypeValue(weekday ? weekday : "mon");
  }, [weekday]);

  const deleteTimeslot = () => {
    var hitUrl = "timeslots/" + selectedTimeslotId;
    ApiService("DELETE", hitUrl)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          openSnackbar(response.message, [5000]);
          getTimeslots();
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="rightInnerView">
      <h2>
        Timeslots{" "}
        <GButton
          variant="condensed"
          children="Add"
          onClick={() => {
            setIsEmpty(true);
            setSelectedSlot({});
            setTsvisible(true);
          }}
          style={{
            float: "right",
          }}
        >
          Add
        </GButton>
      </h2>

      <GTabs routes={timeRoutes} active={typeValue}></GTabs>

      <div className="listPaper">
        <div>
          {isLoading === true ? (
            <GTableShimmer></GTableShimmer>
          ) : (


              timeslotData.filter((t) => t.weekday === typeValue).length ===
                0 ? (
                  <GEmpty></GEmpty>
                ) :

                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableHeader
                            key={column.id}
                            style={{
                              minWidth: column.minWidth,
                              textAlign: column.align,
                            }}
                          >
                          
                            {column.label}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {timeslotData
                        .filter((t) => t.weekday === typeValue)
                        .map((c) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={c.id}
                            >
                              <TableRowData>{c.from_time}</TableRowData>
                              <TableRowData>{c.to_time}</TableRowData>
                              <TableRowData style={{
                                textAlign: "center",
                              }}>{c.order_limit}</TableRowData>
                              <TableRowData
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <GButton
                                  variant="linkable"
                                  onClick={(e) => {
                                    setSelectedSlot(c);
                                    setIsEmpty(false);
                                    setTsvisible(true);
                                  }}
                                >
                                  <Edit fontSize="small" />
                                </GButton>

                                <GButton
                                  variant="linkable"
                                  onClick={(e) => {
                                    setSelectedTimeslotId(c.id);
                                    setDialogOpen(true);
                                  }}
                                >
                                  <Delete fontSize="small" />
                                </GButton>
                              </TableRowData>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>

                </TableContainer>
            )}
        </div>
      </div>
      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Timeslot"
          text="Are you sure you want to delete this timeslot?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteTimeslot();
          }}
        ></GConfirmDialog>
      ) : (
          ""
        )}

      {tsvisible === true ? (
        <TimeslotsPopup
          tsvisible={tsvisible}
          setTsvisible={setTsvisible}
          isEmpty={isEmpty}
          data={selectedSlot}
          weekday={typeValue}
          onSubmit={getTimeslots}
        />
      ) : (
          ""
        )}
    </div>
  );
}
