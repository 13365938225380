import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { ApiService } from "../services";
import { makeStyles, Paper, TableContainer } from "@material-ui/core";
import { Print } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GButton from "../gComponents/gButton";

const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
    marginTop: "10px",
  },
  container: { paddingBottom: "30px" },
});
const reportHeader = {
  textAlign: "center",
  fontSize: "25px",
  fontWeight: "500",
  margin: "10px 0",
};
const reportTitle = {
  textAlign: "center",
  fontSize: "20px",
  fontWeight: "500",
  textTransform: "uppercase",
  margin: "0",
  padding: "0 0 5px",
};
const reportSubtitle = {
  textAlign: "center",
  fontSize: "15.5px",
  fontWeight: "400",
  margin: "5px 0",
  paddingBottom: "10px",
};

function ReportDetails({ match }) {
  const classes = useStyles();
  let { identifier } = useParams();
  let history = useHistory();

  const [selectedReportType, setSelectedReportType] = useState(identifier);
  const [reportDetailData, setDetailData] = useState({});
  const [reportDetailColumns, setDetailColumns] = useState([]);
  const [reportDetailRows, setDetailRows] = useState([]);
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    from: params.get("from") ? params.get("from") : "",
    to: params.get("to") ? params.get("to") : "",
  });

  useEffect(() => {
    const getReportDetail = () => {
      var hitUrl = "report/" + selectedReportType;
      console.log(hitUrl);

      ApiService("GET", hitUrl)
        .then((response) => {
          console.log(JSON.stringify(response));
          if (response.status_code === 1) {
            setDetailData(response.data);
            setDetailColumns(response.data.columns);
            setDetailRows(response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getReportDetail();
  }, [selectedReportType]);

  const printDocument = () => {
    var printContents = document.getElementById("divToPrint");
    console.log(window);
    params = "width=" + window.screen.width;
    params += ", height=" + window.screen.height;
    params += ", top=0, left=0";
    params += ", fullscreen=yes";

    var popupWin = window.open("", "_blank", params);
    //return;
    popupWin.document.open();
    popupWin.document.write(
      '<html><style>table{table-layout:auto!important}th:first-child,td:first-child{text-align:left!important}th:last-child,td:last-child{text-align:right!important}@page {size: auto;margin-top: 5mm;margin-bottom:80px}table{width:100%;table-layout:fixed}table tr td,table,tr,th,h2,h3,p{font-family:"PT Serif",serif;}</style> <body onload = "window.print()" onafterprint = "window.close()" > ' +
        printContents.innerHTML +
        "</html>"
    );

    popupWin.document.close();
  };

  return (
    <div className="mainInnerView">
      <Paper className={classes.root}>
        <GButton
          variant="linkable"
          style={{ float: "right" }}
          onClick={() => printDocument()}
        >
          <Print />
        </GButton>

        <br />
        <br />
        <div id="divToPrint">
          <h2 style={reportHeader}>{reportDetailData.company}</h2>
          <h3 style={reportTitle}>{reportDetailData.title}</h3>
          {reportDetailData.date_from ? (
            <p style={reportSubtitle}>
              {reportDetailData.date_from} - {reportDetailData.date_upto}
            </p>
          ) : (
            <br />
          )}
          {reportDetailData ? (
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                aria-label="sticky table"
                className="reportsTable"
              >
                <TableHead>
                  <TableRow>
                    {reportDetailColumns.map((column, value) => (
                      <TableHeader
                        key={value}
                        style={{
                          textAlign: "center",
                          borderTop: "2px solid #888888",
                          borderBottom: "2px solid #888888",
                          textTransform: "uppercase",
                          padding: "14px",
                        }}
                      >
                        {column.replace("_", " ")}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                {reportDetailRows.map((data, i) => {
                  return (
                    <TableBody key={i} style={{ borderTop: "2px solid #ddd" }}>
                      <TableRow>
                        <TableRowData
                          style={{
                            padding: "8px",
                            fontStyle: "italic",
                            textTransform: "uppercase",
                            fontSize: "16px",
                            background: "#fff",
                            borderTop: data.header ? "1px solid #ccc" : "",
                          }}
                        >
                          {data.header}
                        </TableRowData>
                        {reportDetailColumns
                          .filter((f, i) => !(i === 0))
                          .map((col, v) => {
                            return (
                              <TableRowData
                                style={{
                                  borderTop: data.header
                                    ? "1px solid #ccc"
                                    : "",
                                }}
                              ></TableRowData>
                            );
                          })}
                      </TableRow>
                      {data.rows.map((c, value) => {
                        return (
                          <TableRow hover tabIndex={-1} key={value}>
                            {Object.keys(c).map((keyName, keyIndex) => {
                              return (
                                <TableRowData
                                  key={keyIndex}
                                  style={{
                                    textAlign: "center",
                                    background: "#fff",
                                    padding: "10px 8px",
                                  }}
                                >
                                  {c[keyName]}
                                </TableRowData>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                      <TableRow>
                        <TableRowData
                          style={{
                            padding: "8px",
                            fontStyle: "italic",
                            fontSize: "16px",
                            fontWeight: "550",
                            background: "#fff",
                            fontFamily: "LatoWebSemibold",
                          }}
                        >
                          {data.footer}
                        </TableRowData>
                        {reportDetailData.columns
                          .filter((f, i) => !(i === 0 || i === 1))
                          .map((col, v) => {
                            return (
                              <TableRowData
                                style={{ background: "#fff" }}
                              ></TableRowData>
                            );
                          })}
                        <TableRowData
                          style={{
                            background: "#fff",
                            textAlign: "right",
                            fontWeight: "550",
                            fontFamily: "LatoWebSemibold",
                            padding: "14px 8px",
                          }}
                        >
                          {" "}
                          {data.footer_value}
                        </TableRowData>
                      </TableRow>
                    </TableBody>
                  );
                })}
              </Table>
              {reportDetailColumns.length === 0 ? <GEmpty></GEmpty> : ""}
            </TableContainer>
          ) : (
            ""
          )}

          {reportDetailData.footer_value ? (
            <div
              style={{
                width: "100%",
                boxSizing: "border-box",
                display: "flex",
                borderTop: "1px solid #ccc",
                padding: "0 8px",
              }}
            >
              <p
                style={{
                  width: "50%",
                  fontFamily: "LatoWebSemibold",
                }}
              >
                {reportDetailData.footer}
              </p>
              <p
                style={{
                  width: "50%",
                  textAlign: "right",
                  fontFamily: "LatoWebSemibold",
                }}
              >
                {reportDetailData.footer_value}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </Paper>
    </div>
  );
}

export default ReportDetails;
