import React, { useState, useEffect } from "react";
import { makeStyles, Paper, TableContainer } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import storePlaceholder from "../assets/images/placeholders/p-store.png";
import { useSnackbar } from "react-simple-snackbar";
import { ApiService, ImgUrl, addDefaultSrc } from "../services";
import { StorefrontSharp, Delete } from "@material-ui/icons";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GSelectbox from "../gComponents/gSelectbox";
import QueryWith from "../Query";
import useSettings from "../useSettings";

const columns = [
  { id: "id", label: "Id", minWidth: 30 },
  { id: "title", label: "Title", minWidth: 100 },
  { id: "address", label: "Address", minWidth: 180 },
  { id: "photo", label: "Photo", minWidth: 70, type: "image" },
  { id: "manager", label: "Store Manager", minWidth: 120, align: "center" },
  {
    id: "wallet_points_value",
    label: "Balance",
    minWidth: 100,
    align: "center",
    // format: (value) => value.toLocaleString('en-US'),
    format: (value) => value.toFixed(2),
  },
  {
    id: "created_at_formatted",
    label: "Created",
    minWidth: 150,
    align: "center",
  },
  { id: "actions", label: "Actions", minWidth: 60, align: "right" },
];

const statusFilters = [
  {
    title: "Active",
    value: "1",
  },
  {
    title: "Inactive",
    value: "0",
  },
];

const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
  },
});

export default function Stores() {
  const classes = useStyles();

  const [openSnackbar] = useSnackbar();
  const [storesData, setStoreData] = useState([]);
  const [storePaginationData, setStorePaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const settings = useSettings(true);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  console.log(JSON.stringify(history.location.search));
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    store_status: params.get("store_status") ? params.get("store_status") : "",
  });
  const getStores = () => {
    var params = QueryWith(history.location, filters);

    if (params.length > 0) {
      history.push({
        pathname: "/stores",
        state: 'store',
        search: params[0],
      });
    }
    var hitUrl = "stores";
    ApiService("GET", hitUrl, params[0])
      .then((response) => {
        console.log("gh" + response);
        if (response.status_code === 1) {
          console.log(response);
          setStoreData(response.data.data);
          setStorePaginationData(response.data);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log("sd");

    getStores();
  }, [filters]);

  function addDefaultLogo(e) {
    e.target.src = storePlaceholder;
  }

  const deleteStore = () => {
    var hitUrl = "stores/" + selectedStoreId;
    ApiService("DELETE", hitUrl)
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.status_code === 1) {
          openSnackbar(response.message);
          getStores();
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ page: NewPageNo });
    getStores();
  };

  return (
    <div className="mainInnerView">
      <h1>
        <StorefrontSharp /> Stores
      </h1>

      <div style={{ display: "inline-block", width: "60%" }}>
        <GSelectbox
          placeholder="All"
          defaultvalue={filters.store_status}
          data={statusFilters}
          id="store_status_filter"
          option_key="title"
          option_value="value"
          onChange={(newVal) => {
            setFilters({ store_status: newVal });
          }}
          style={{
            border: "1px solid #e2e2e2",
            height: "40px",
            marginBottom: "10px",
            minWidth: "150px",
          }}
        />
      </div>
      <div
        style={{ display: "inline-block", width: "40%", textAlign: "right" }}
      >
        {storesData.length !== 0 ? (
          <React.Fragment>
            <GPagination
              style={{ display: "inline-block" }}
              count={storePaginationData.total}
              from={storePaginationData.from}
              to={storePaginationData.to}
              onChangeNextPage={() =>
                handleChangePage(storePaginationData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(storePaginationData.prev_page_url)
              }
              rowsPerPage={storePaginationData.per_page}
              prevDisabled={storePaginationData.prev_page_url ? false : true}
              nextDisabled={storePaginationData.next_page_url ? false : true}
            ></GPagination>
            {storePaginationData ? (
              <GPaginationSelect
                defaultvalue={filters.page}
                totalPage={storePaginationData.last_page}
                onChangePage={(NewPageNo) => setFilters({ page: NewPageNo })}
              />
            ) : (
                ""
              )}
          </React.Fragment>
        ) : (
            ""
          )}
      </div>

      <Paper className={classes.root}>
        {!storePaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
            <TableContainer className={classes.container}>
              {storesData.length === 0 ? <GEmpty></GEmpty> :
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableHeader
                          key={column.id}
                          style={{
                            minWidth: column.minWidth,
                            textAlign: column.align,
                          }}
                        >
                          {column.label}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {storesData.map((s) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={s.id}>
                          <TableRowData>#{s.id}</TableRowData>
                          <TableRowData>
                            <Link to={{ pathname: "/store/" + s.id + "/orders", state: 'store' }}>{s.title}</Link>
                          </TableRowData>
                          <TableRowData>{s.address}</TableRowData>
                          <TableRowData>
                            <img
                              alt={setStoreData.title}
                              src={ImgUrl("store") + "/" + s.thumb_photo}
                              onError={addDefaultLogo}
                              style={{
                                height: "45px",
                                width: "45px",
                                borderRadius: "50%",
                              }}
                            />
                          </TableRowData>
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {s.manager.title ? s.manager.title : "NA"}
                          </TableRowData>
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {settings.currency_symbol + "" + s.balance}
                          </TableRowData>
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {s.created}
                          </TableRowData>
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <GButton
                              variant="linkable"
                              onClick={(e) => {
                                setSelectedStoreId(s.id);
                                setDialogOpen(true);
                              }}
                            >
                              <Delete fontSize="small" />
                            </GButton>
                          </TableRowData>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              }
            </TableContainer>
          )}
      </Paper>
      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Customer"
          text="Are you sure you want to delete this store?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteStore();
          }}
        ></GConfirmDialog>
      ) : (
          ""
        )}
    </div>
  );
}
